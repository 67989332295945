import { useController } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useModal } from 'shared/hooks/useModal';
import { TVideoPostFields } from 'widgets/videoForm/types';

type TUseVideoFormPublishButtonArgs = Partial<UseFormReturn<TVideoPostFields>> & {
  isEdit: boolean;
  isPublish: boolean;
  createVideoPost: (data: TVideoPostFields) => Promise<void>;
  editVideoPost: (data: TVideoPostFields) => Promise<void>;
};

export const useVideoFormPublishButton = ({
  isEdit,
  isPublish,
  createVideoPost,
  editVideoPost,
  trigger,
  control,
  handleSubmit,
}: TUseVideoFormPublishButtonArgs) => {
  const { openPublishModal, closePublishModal, openedPublishModal } =
    useModal('Publish');

  const { openChooseDateModal, closeChooseDateModal, openedChooseDateModal } =
    useModal('ChooseDate');

  const {
    field: { onChange: onPublishDateChange, value: publishDate },
  } = useController({
    control,
    name: 'publishDate',
  });

  const onClickPublish = async () => {
    const isValid = await trigger?.();
    if (!isValid) {
      return;
    }

    if (isEdit && isPublish) {
      await handleSubmit?.(editVideoPost)();
      return;
    }

    openPublishModal();
  };

  const onDateChange = (date: Date | undefined) => {
    onPublishDateChange(date);
    openPublishModal();
    closeChooseDateModal();
  };

  const onPublish = () => {
    if (!publishDate) {
      onPublishDateChange(new Date());
    }

    if (isEdit) {
      handleSubmit?.(editVideoPost)();
    } else {
      handleSubmit?.(createVideoPost)();
    }
  };

  const onClickChooseDate = () => {
    closePublishModal();
    openChooseDateModal();
  };

  const onCloseChooseDate = () => {
    closeChooseDateModal();
    openPublishModal();
  };

  return {
    publishDate,
    isOpenPublishModal: openedPublishModal,
    onClosePublishModal: closePublishModal,
    isOpenChooseDateModal: openedChooseDateModal,
    onCloseChooseDate,
    onClickPublish,
    onDateChange,
    onPublish,
    onClickChooseDate,
  };
};

import { useMutation } from '@tanstack/react-query';
import { createMatch, deleteMatch, editMatch } from 'entities/matches/api';

export const useDeleteMatchMutation = () => {
  return useMutation({
    mutationFn: deleteMatch,
  });
};

export const useCreateMatchMutation = () => {
  return useMutation({
    mutationFn: createMatch,
  });
};

export const useEditMatchMutation = () => {
  return useMutation({
    mutationFn: editMatch,
  });
};

import { TFunction } from 'i18next';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TNavigationLink } from 'widgets/navigation/types';

export const getNavigationLinks = (t: TFunction): TNavigationLink[] => [
  {
    name: t('content.navigation.contentLabel'),
    children: [
      {
        name: t('content.story.stories'),
        route: routes.stories,
        roles: DEFAULT_ROUTER_ROLES,
      },
      {
        name: t('content.navigation.newsLabel'),
        route: routes.news,
        roles: DEFAULT_ROUTER_ROLES,
      },
      {
        name: t('content.video'),
        route: routes.video,
        roles: DEFAULT_ROUTER_ROLES,
      },
      {
        name: t('content.navigation.photoGallery'),
        route: routes.photoGallery,
        roles: DEFAULT_ROUTER_ROLES,
      },
    ],
  },
  {
    name: t('content.navigation.teamsLabel'),
    children: [
      {
        name: t('content.navigation.ourTeamsLabel'),
        route: routes.teams,
        roles: DEFAULT_ROUTER_ROLES,
      },
      {
        name: t('content.opponentTeams'),
        route: routes.opponentTeams,
        roles: DEFAULT_ROUTER_ROLES,
      },
      {
        name: t('content.navigation.aboutClubLabel'),
        route: routes.aboutClub,
        roles: DEFAULT_ROUTER_ROLES,
      },
    ],
  },
  {
    name: t('content.adBanners.page.title'),
    route: routes.adBanners,
    roles: DEFAULT_ROUTER_ROLES,
  },
  {
    name: t('content.matches.match'),
    route: routes.matches,
    roles: DEFAULT_ROUTER_ROLES,
  },
  /*  {
    name: t('content.tournamentSeasons.tournamentTable'),
    route: routes.tournamentSeasons,
    roles: DEFAULT_ROUTER_ROLES,
  },*/
];

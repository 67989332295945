import { CreateAdBannerRoute } from 'pages/adBanners/create';
import { EditAdBannerRoute } from 'pages/adBanners/edit';
import { AdBannersPageRoute } from 'pages/adBanners/list';
import { TProtectedRecord } from 'shared/types/router';

export const AdBannersRoutes = {
  List: AdBannersPageRoute,
  Create: CreateAdBannerRoute,
  Edit: EditAdBannerRoute,
} satisfies TProtectedRecord;

import cn from 'classnames';
import { Icon } from 'shared/components/Icon/Icon';
import { TPicPlaceholderImageSize } from 'shared/components/Pic/type';

import styles from './PicPlaceholderImage.module.css';

type TProps = {
  size?: TPicPlaceholderImageSize;
};

export const PicPlaceholderImage = ({ size }: TProps) => {
  if (!size) {
    return null;
  }

  return (
    <Icon className={cn(styles.icon, styles[size])} customSize kind="image-03" />
  );
};

import { useEditClubMutation } from 'entities/club/mutations';
import { useLocales } from 'entities/config/lib/useLocales';
import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useDebounce } from 'react-use';
import { autoSaveNotify } from 'shared/components/Notification/AutoSaveNotification/AutoSaveNotification';
import { AUTO_SAVE_DELAY } from 'widgets/ClubForm/constants';
import { mapClubFormFieldsToDTO } from 'widgets/ClubForm/mapper/mapClubFormFieldsToDTO';
import { TClubFormFields } from 'widgets/ClubForm/types';

export const useDebounceEditClub = (formMethods: UseFormReturn<TClubFormFields>) => {
  const { getValues, trigger, formState, reset, watch } = formMethods;
  const { locales } = useLocales();

  const createdLocales = watch('locale.created');
  const darkImage = watch('darkImage');
  const whiteImage = watch('whiteImage');

  const localizationDescriptions = useMemo(
    () => createdLocales.map((locale) => watch(`description.${locale}`)),
    [createdLocales, watch],
  );

  const { mutateAsync: editClub } = useEditClubMutation();

  useDebounce(
    async () => {
      const formValues = getValues();
      const descriptionIsChanges = createdLocales.some(
        (locale) => formState.dirtyFields.description?.[locale] ?? false,
      );
      const darkImageIsChanged = !isEqual(
        formState.defaultValues?.darkImage,
        formValues.darkImage,
      );
      const whiteImageIsChanged = !isEqual(
        formState.defaultValues?.whiteImage,
        formValues.whiteImage,
      );

      const formIsChanges =
        darkImageIsChanged || whiteImageIsChanged || descriptionIsChanges;

      const isValid = await trigger();
      if (formIsChanges && isValid) {
        await editClub(
          await mapClubFormFieldsToDTO(
            {
              ...formValues,
              whiteImage: whiteImageIsChanged ? whiteImage : undefined,
              darkImage: darkImageIsChanged ? darkImage : undefined,
            },
            locales,
          ),
        );
        reset(formValues);
        autoSaveNotify();
      }
    },
    AUTO_SAVE_DELAY,
    [localizationDescriptions, darkImage, whiteImage],
  );
};

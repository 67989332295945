import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToken } from 'shared/api/token';
import { routes } from 'shared/routes';

export const AuthRedirect = () => {
  const token = useToken();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!token) {
      navigate(routes.auth);
    }
  }, [token, navigate]);

  return null;
};

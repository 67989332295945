import { useTranslation } from 'react-i18next';
import { Slide, toast } from 'react-toastify';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './AutoSaveNotification.module.css';

export const AutoSaveNotification = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Typography color="var(--gray-100)" size={14} weight="SemiBold">
        {t('content.autoSaveLabel')}
      </Typography>
    </div>
  );
};

export const autoSaveNotify = () =>
  toast(() => <AutoSaveNotification />, {
    type: 'info',
    closeButton: false,
    icon: false,
    position: 'bottom-center',
    hideProgressBar: true,
    draggable: false,
    closeOnClick: false,
    pauseOnHover: false,
    transition: Slide,
    className: styles.autoSaveContainer,
    autoClose: 1000,
  });

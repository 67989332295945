import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import i18n from 'i18next';
import { MouseEvent } from 'react';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Typography } from 'shared/components/Typography/Typography';
import { TContentLang, TTranslationsMapper } from 'shared/types/common';

import styles from './LocalesControl.module.css';

const localeNames = (): TTranslationsMapper => ({
  ru: i18n.t('shared.LocalesControl.russian'),
  en: i18n.t('shared.LocalesControl.english'),
  de: i18n.t('shared.LocalesControl.german'),
  fr: i18n.t('shared.LocalesControl.french'),
});

type TProps = {
  locale: TContentLang;
  isCreated: boolean;
  isDisabled: boolean;
  isSelected: boolean;
  onLocaleSelect: (locale: TContentLang) => void;
  onLocaleAdd: (locale: TContentLang) => void;
  onLocaleDelete: (locale: TContentLang) => void;
};

export const LocaleControl = ({
  locale,
  isCreated,
  isDisabled,
  isSelected,
  onLocaleSelect,
  onLocaleAdd,
  onLocaleDelete,
}: TProps) => {
  const { defaultLocale } = useLocales();

  const handleLocaleClick = () => {
    if (isDisabled) {
      return;
    }
    onLocaleSelect(locale);
  };
  const handleButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (isDisabled) {
      return;
    }
    if (isCreated) {
      return onLocaleDelete(locale);
    }
    return onLocaleAdd(locale);
  };
  return (
    <div
      className={cn(styles.localeControl, {
        [styles.isSelected]: isSelected,
        [styles.isDisabled]: isDisabled,
        [styles.selectable]: isCreated,
      })}
      onClick={handleLocaleClick}
    >
      <Typography>{localeNames()[locale]}</Typography>
      {locale !== defaultLocale && (
        <IconButton
          className={styles.button}
          disabled={isDisabled}
          icon={isCreated ? 'x' : 'plus'}
          iconSize="s"
          onClick={handleButtonClick}
        />
      )}
    </div>
  );
};

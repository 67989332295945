import { EditorBulletedListElement } from 'shared/components/Editor/elements/EditorBulletedListElement/EditorBulletedListElement';
import { EditorHeadingTwoElement } from 'shared/components/Editor/elements/EditorHeadingTwoElement';
import {
  EditorImageElement,
  TEditorImageElementProps,
} from 'shared/components/Editor/elements/EditorImageElement/EditorImageElement';
import { EditorLinkElement } from 'shared/components/Editor/elements/EditorLinkElement/EditorLinkElement';
import { EditorListItemElement } from 'shared/components/Editor/elements/EditorListItemElement/EditorListItemElement';
import { EditorParagraphElement } from 'shared/components/Editor/elements/EditorParagraphElement';
import {
  EditorSliderElement,
  TEditorSliderElementProps,
} from 'shared/components/Editor/elements/EditorSliderElement/EditorSliderElement';
import {
  EditorVideoElement,
  TEditorVideoElementProps,
} from 'shared/components/Editor/elements/EditorVideoElement/EditorVideoElement';
import { EEditorElementType } from 'shared/components/Editor/types';
import { TEditorDisableStructureChange } from 'shared/components/Editor/utils/structureChange/types';
import { RenderElementProps } from 'slate-react';

export const editorRenderElements = (
  props: RenderElementProps &
    TEditorImageElementProps &
    TEditorSliderElementProps &
    TEditorVideoElementProps &
    TEditorDisableStructureChange,
) => {
  switch (props.element.type) {
    case EEditorElementType.Paragraph:
      return (
        <EditorParagraphElement {...props}>{props.children}</EditorParagraphElement>
      );
    case EEditorElementType.Slider:
      return <EditorSliderElement {...props}>{props.children}</EditorSliderElement>;
    case EEditorElementType.Image:
      return <EditorImageElement {...props}>{props.children}</EditorImageElement>;
    case EEditorElementType.Video:
      return <EditorVideoElement {...props}>{props.children}</EditorVideoElement>;
    case EEditorElementType.Link:
      return <EditorLinkElement {...props}>{props.children}</EditorLinkElement>;
    case EEditorElementType.BulletedList:
      return (
        <EditorBulletedListElement {...props}>
          {props.children}
        </EditorBulletedListElement>
      );
    case EEditorElementType.ListItem:
      return (
        <EditorListItemElement {...props}>{props.children}</EditorListItemElement>
      );
    case EEditorElementType.HeadingTwo:
      return (
        <EditorHeadingTwoElement {...props}>
          {props.children}
        </EditorHeadingTwoElement>
      );
  }
};

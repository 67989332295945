import commonElementFormStyles from 'pages/stories/edit/EditStory/components/forms/ElementForm.module.css';
import { TQuizElementFormValues } from 'pages/stories/edit/EditStory/components/forms/types';
import { ReactElement } from 'react';
import { Control } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';
import { Typography } from 'shared/components/Typography/Typography';

type TProps = {
  control: Control<TQuizElementFormValues>;
};

export const TitleColors = ({ control }: TProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        className={commonElementFormStyles.subtitle}
        size={14}
        tag="h2"
        weight="Regular"
      >
        {t('content.story.colorHeading')}
      </Typography>
      <ColorInput
        control={control}
        fieldName="fill"
        inline
        inlineInputClassName={commonElementFormStyles.formField}
        label={t('content.story.fill')}
        placeholder=""
      />
      <ColorInput
        control={control}
        fieldName="background"
        inline
        inlineInputClassName={commonElementFormStyles.formField}
        label={t('content.story.colorInputLabel')}
        placeholder=""
      />
    </>
  );
};

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { EAppLanguages } from 'shared/types/common';

import { resources } from './@types/resources';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: Object.values(EAppLanguages),
    lng: EAppLanguages.en,
    interpolation: {
      escapeValue: false,
    },
  });

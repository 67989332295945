import { useLocales } from 'entities/config/lib/useLocales';
import { TMatchesFilterParams } from 'entities/matches/utils';
import { useFetchSeasonsQuery } from 'entities/seasons/queries';
import { TFilterProps } from 'feature/filters/types';
import { EntitiesFilter } from 'feature/filters/ui/EntitiesFilter/EntitiesFilter';
import { TEntityFilter } from 'feature/filters/ui/EntitiesFilter/type';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';

export const MatchesSeasonFilter = ({
  setFilter,
  filter,
}: TFilterProps<TMatchesFilterParams>) => {
  const { defaultLocale } = useLocales();
  const { data: { data: seasons } = {}, isLoading } = useFetchSeasonsQuery({
    defaultPagination: {
      pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
    },
  });

  const mappedSeasons = seasons?.map<TEntityFilter>(({ id, translations }) => ({
    id,
    name: translations[defaultLocale].name,
  }));

  return (
    <EntitiesFilter
      entities={mappedSeasons ?? []}
      filter={filter.season_id}
      isLoading={isLoading}
      onChangeFilter={(seasonIds) => setFilter({ ...filter, season_id: seasonIds })}
    />
  );
};

import i18n, { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EEditorElementType,
  TEditor,
  TEditorHeadingElements,
} from 'shared/components/Editor/types';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';
import { Select } from 'shared/components/Form/Select/Select';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { useWorkspaceHeadingActions } from 'shared/components/Workspace/components/WorkspacePanel/WorkspacePanelHeadingSelect/useWorkspaceHeadingActions';

import styles from './WorkspacePanelHeadingSelect.module.css';

type TProps = {
  editor: TEditor;
  disabled?: boolean;
};

const headingOptions = (t: TFunction): TSelectOption[] => [
  {
    value: EEditorElementType.HeadingTwo,
    label: t('shared.Workspace.labelHead'),
    className: styles.headingTwoOption,
  },
  {
    value: EEditorElementType.Paragraph,
    label: t('shared.Workspace.labelText'),
  },
];

const getPlaceholder = (type: TEditorHeadingElements) => {
  switch (type) {
    case EEditorElementType.HeadingTwo:
      return i18n.t('shared.Workspace.labelHead');
    case EEditorElementType.Paragraph:
      return i18n.t('shared.Workspace.labelText');
    default:
      return '';
  }
};

export const WorkspacePanelHeadingSelect = ({ editor, disabled }: TProps) => {
  const { t } = useTranslation();
  const { toggleHeading } = useWorkspaceHeadingActions(editor);
  const [activeElement, setActiveElement] = useState<TEditorHeadingElements>(
    EEditorElementType.Paragraph,
  );

  useEffect(() => {
    if (editor?.selection) {
      const nodeElement = editorController.getElement(
        editor,
        EEditorElementType.HeadingTwo,
      );

      if (nodeElement) {
        setActiveElement(EEditorElementType.HeadingTwo);
      } else {
        setActiveElement(EEditorElementType.Paragraph);
      }
    }
  });

  const handleChange = (option: TSelectOption | null) => {
    const newValue = option && (option.value as TEditorHeadingElements);
    if (newValue) {
      toggleHeading(newValue);
      setActiveElement(newValue);
    }
  };

  const options = headingOptions(t);

  return (
    <Select
      className={styles.select}
      isDisabled={disabled}
      isMulti={false}
      menuPlacement="top"
      options={options}
      placeholder={getPlaceholder(activeElement)}
      size="small"
      value={options.find((option) => option.value === activeElement)}
      onChange={handleChange}
    />
  );
};

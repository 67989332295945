import { TAdLocation } from 'entities/ad/locations/model/types';
import { TAdSlot } from 'entities/ad/slots/model/types';
import {
  TEntityId,
  TImageContent,
  TImageUpload,
  TTranslationsObject,
} from 'shared/types/common';

export enum EAdBannerType {
  Image = 'image',
  Video = 'video',
}

export type TAdBanner = {
  id: TEntityId;
  mainBanner: boolean;
  type: EAdBannerType;
  content: TImageContent;
  link: string;
  createdAt: string;
  locations: TAdLocation[];
  slot: Omit<TAdSlot, 'locations'>;
} & TTranslationsObject<{
  name: string;
}>;

export type TCreateAdBannerDTO = {
  slotId: TEntityId;
  bannerType: string;
  locations: TEntityId[];
  mainBanner: boolean;
  link: string;
  content: TImageUpload;
} & TTranslationsObject<{
  name: string;
}>;

export type TEditAdBannerDTO = Partial<TCreateAdBannerDTO> & {
  id: TEntityId;
};

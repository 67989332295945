import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './Dashboard.module.css';

export const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Typography size={30} tag="h2" weight="SemiBold">
        {t('content.welcome')}
      </Typography>
      <Typography size={24}>{t('content.startWork')}</Typography>
    </div>
  );
};

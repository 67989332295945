import { AdBannersPage } from 'pages/adBanners/list/AdBannersPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const AdBannersPageRoute = {
  path: routes.adBanners,
  element: <AdBannersPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

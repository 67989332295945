import { TConfigData } from 'entities/config/model/types';
import { TFunction } from 'i18next';
import { getTextMinLengthMessage } from 'shared/consts';
import { TContentLang } from 'shared/types/common';
import { isAllowedVideoHosting, isSecuredLink } from 'shared/utils/links';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import z from 'zod';

export const videoFormValidationSchema = (t: TFunction, config?: TConfigData) =>
  z
    .object({
      videoLink: z
        .string()
        .min(1, getTextMinLengthMessage())
        .refine(
          (videoLink) =>
            isAllowedVideoHosting(videoLink, config?.whitelistVideoHostings),
          t('content.video.warningNotDomen'),
        )
        .refine(
          (videoLink) => isSecuredLink(videoLink),
          `${t('content.video.startLink')}  https://`,
        ),
      locale: z.object({
        created: z.array(z.string()),
        selected: z.string(),
      }),
      preview: z.object({
        file: fileZodValidation({
          errorMessage: t('shared.validation.preview'),
        }),
        data: z.string(),
      }),
      slider: z.object({
        file: z.optional(
          fileZodValidation({
            errorMessage: t('shared.validation.context'),
          }),
        ),
        data: z.optional(z.string()),
      }),
      important: z.boolean(),
    })
    .passthrough()
    .superRefine((fields, context) => {
      const validatedContent = translationsValidation(
        fields.locale.selected as TContentLang,
      ).safeParse(fields);

      if (!validatedContent.success) {
        validatedContent.error.issues.forEach((issue) => {
          context.addIssue(issue);
        });
      }
    });

const translationsValidation = (selected: TContentLang) =>
  z.object({
    translations: z.object({
      [selected]: z.object({
        title: z.string().min(1, getTextMinLengthMessage()),
      }),
    }),
  });

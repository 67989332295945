import { SettingsCategoriesRoutes } from 'pages/settings/categories';
import { SettingsLabelsRoute } from 'pages/settings/labels';
import { SettingsLanguageRoute } from 'pages/settings/language';
import { SettingsSeasonsRoute } from 'pages/settings/seasons';
import { SettingsTournamentsRoute } from 'pages/settings/tournaments';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';
import { SettingsLayout } from 'widgets/settingsLayout/ui/SettingsLayout/SettingsLayout';

export const SettingsRoute = {
  path: routes.settings,
  element: <SettingsLayout />,
  roles: DEFAULT_ROUTER_ROLES,
  children: [
    SettingsLabelsRoute,
    SettingsCategoriesRoutes.News,
    SettingsCategoriesRoutes.Video,
    SettingsCategoriesRoutes.PhotoGallery,
    SettingsLanguageRoute,
    SettingsSeasonsRoute,
    SettingsTournamentsRoute,
  ],
} satisfies TProtectedRoute;

import { useFetchMatchesQuery } from 'entities/matches/queries';
import { TMatchInclude } from 'entities/matches/types';
import { TMatchesFilterParams, TMatchesSortParams } from 'entities/matches/utils';
import { getMatchesColumnsConfig } from 'pages/matches/list/columnsConfig';
import { getMatchesPageFilter } from 'pages/matches/list/hooks/getMatchesPageFilter';
import { MatchActions } from 'pages/matches/list/MatchActions';
import { createSortQuery } from 'pages/video/VideoPage/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useCommonListTableSort } from 'widgets/elementsList/hooks/useCommonListTableSort';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './MatchesPage.module.css';

const DEFAULT_MATCHES_SORT_OPTIONS = {
  direction: 'desc',
  sortBy: 'start_at',
} as const;

export const MatchesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { sortOptions, handleSort } = useCommonListTableSort<TMatchesSortParams>(
    DEFAULT_MATCHES_SORT_OPTIONS,
  );
  const [filter, setFilter] = useState<TMatchesFilterParams | null>(null);

  const {
    data: { data: matches = [], meta: { page = 1, total = 0 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchMatchesQuery<TMatchInclude>({
    params: {
      sort: createSortQuery(sortOptions),
      include: ['team', 'opponent_team', 'season', 'tournament'],
      filter: getMatchesPageFilter(filter),
    },
    options: {
      cacheTime: 0,
    },
  });

  const handleFilter = (filter: TMatchesFilterParams) => {
    changePage(1);
    setFilter(filter);
  };

  return (
    <CommonList
      configCreator={getMatchesColumnsConfig}
      emptyComponentDescription={t('content.matches.createFirstMatch')}
      error={error}
      isLoading={isLoading}
      title={t('content.matches.match')}
      createButtonProps={{
        content: t('shared.create'),
        onClick: () => navigate(routes.newMatch),
      }}
      paginationProps={{
        selectedPage: page,
        totalItems: total,
        onPageChange: changePage,
      }}
      tableProps={{
        onSort: handleSort,
        sortOptions,
        data: matches,
        rowClassName: styles.rowGridTemplate,
        ActionComponent: MatchActions,
        onFilter: handleFilter,
        filter,
      }}
    />
  );
};

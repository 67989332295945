import {
  EEditorElementType,
  TEditor,
  TEditorImage,
  TEditorSliderElement,
} from 'shared/components/Editor/types';
import { editorParagraphController } from 'shared/components/Editor/utils/paragraph/editorParagraphController';
import { createSliderElement } from 'shared/components/Editor/utils/slider/createSliderElement';
import { toBoolean } from 'shared/lib/toBoolean';
import { TContentLang } from 'shared/types/common';
import { Element, Node, Transforms } from 'slate';

export const editorSliderController = {
  isSliderElement(node?: Node): node is TEditorSliderElement {
    return toBoolean(
      node && Element.isElement(node) && node.type === EEditorElementType.Slider,
    );
  },

  insertSlider(editor: TEditor, files: TEditorImage[], defaultLocale: TContentLang) {
    const slider = createSliderElement(files);
    Transforms.insertNodes(editor, slider);
    editorParagraphController.insertParagraph(editor, '', defaultLocale);
  },

  changeSlider(editor: TEditor, path: number[], files: TEditorImage[]) {
    Transforms.setNodes(
      editor,
      {
        files,
      },
      { at: path },
    );
  },
};

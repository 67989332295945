import {
  TEntityId,
  TImageContent,
  TImageUpload,
  TTranslationsObject,
} from 'shared/types/common';

export enum EOpponentPlayerStatus {
  PartOfTeam = 'part_of_team',
  Outstaff = 'outstaff',
}

export type TOpponentPlayer = {
  id: TEntityId;
  status: EOpponentPlayerStatus;
  number: string;
  discardedAt?: string;
  nickname?: string;
  picture?: TImageContent;
  team: string;
  country: string;
} & TTranslationsObject<{
  firstName: string;
  lastName: string;
}>;

export type TOpponentPlayerDTO = {
  number: string;
  status: EOpponentPlayerStatus;
  discardedAt?: string | null;
  nickname: string;
  picture?: TImageUpload | null;
  teamId: number;
  countryId: number;
} & TTranslationsObject<{
  firstName: string;
  lastName: string;
}>;

export type TEditOpponentPlayerDTO = {
  id: TEntityId;
} & TOpponentPlayerDTO;

import { TacticPage } from 'pages/matches/tactic/TacticPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const TacticRoute = {
  path: routes.tacticMatch.url,
  element: <TacticPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

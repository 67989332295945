import cn from 'classnames';
import { ReactNode } from 'react';
import { Icon } from 'shared/components/Icon/Icon';
import { Typography } from 'shared/components/Typography/Typography';
import { TWithChildren } from 'shared/types/common';

import styles from './Collapse.module.css';

export type TCollapseProps = {
  label: ReactNode;
  isError?: boolean;
  disabled?: boolean;
  isOpen: boolean;
  onClick: () => void;
};

export const Collapse = ({
  label,
  isError = false,
  isOpen = false,
  disabled = false,
  onClick,
  children,
}: TCollapseProps & TWithChildren) => {
  return (
    <div className={styles.root}>
      <button
        disabled={disabled}
        type="button"
        className={cn(styles.header, {
          [styles.active]: isOpen,
          [styles.error]: isError,
        })}
        onClick={onClick}
      >
        {typeof label === 'string' ? (
          <Typography size={14}>{label}</Typography>
        ) : (
          label
        )}
        <Icon
          className={cn(styles.button, { [styles.activeButton]: isOpen })}
          kind="chevron-down"
          size="s"
        />
      </button>
      {isOpen && <div className={styles.body}>{children}</div>}
    </div>
  );
};

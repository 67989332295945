import cn from 'classnames';
import { TEntityFilter } from 'feature/filters/ui/EntitiesFilter/type';
import { useEntitiesFilterForm } from 'feature/filters/ui/EntitiesFilter/useEntitiesFilterForm';
import styles from 'feature/filters/ui/Filter.module.css';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { Checkbox } from 'shared/components/Form';
import { Loader } from 'shared/components/Loader/Loader';
import { Typography } from 'shared/components/Typography/Typography';
import { toString } from 'shared/lib/toString';

export type TEntitiesFilterProps<TEntityValue extends string | number> = {
  onChangeFilter: (entities?: TEntityValue[]) => void;
  filter?: TEntityValue[];
  entities: TEntityFilter<TEntityValue>[];
  isLoading?: boolean;
  renderCheckboxLabel?: (id: TEntityValue) => ReactElement;
};

export const EntitiesFilter = <TEntityValue extends string | number>({
  entities,
  isLoading,
  onChangeFilter,
  filter,
  renderCheckboxLabel,
}: TEntitiesFilterProps<TEntityValue>) => {
  const { t } = useTranslation();

  const { control, submit } = useEntitiesFilterForm({
    entities,
    filter,
    onChangeFilter,
  });

  return (
    <form
      className={cn(styles.container, {
        [styles.loading]: isLoading,
      })}
      onSubmit={submit}
    >
      <div
        className={cn(styles.entitiesWrapper, {
          [styles.isLoading]: isLoading,
        })}
      >
        {isLoading ? (
          <Loader showLabel size="s" />
        ) : (
          entities.map(({ id, name }) => (
            <Checkbox
              key={id}
              control={control}
              fieldName={toString(id)}
              labelClassName={styles.labelContainer}
              checkboxLabel={
                renderCheckboxLabel ? (
                  renderCheckboxLabel(id)
                ) : (
                  <div className={styles.label}>
                    <Typography className={styles.labelText} size={12}>
                      {name}
                    </Typography>
                  </div>
                )
              }
            />
          ))
        )}
      </div>
      <Button htmlType="submit" isDisabled={isLoading} onClick={submit}>
        {t('shared.confirm')}
      </Button>
    </form>
  );
};

import { useCallback, useRef } from 'react';
import { Control, useFormContext, useWatch } from 'react-hook-form';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';
import { compareIds } from 'shared/utils/entityIds';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';

type TProps = {
  control: Control<TAdBannerFormFields>;
};

export const useAdBannerChangeSlot = ({ control }: TProps) => {
  const { reset, setValue, formState } = useFormContext<TAdBannerFormFields>();

  const { openedSlotChangeModal, closeSlotChangeModal, openSlotChangeModal } =
    useModal('SlotChange');
  const selectedSlotId = useWatch({ control, name: 'slotId' });
  const currentSelectedSlotId = useRef(selectedSlotId);

  const onChangeSlot = useCallback(() => {
    reset();
    setValue('slotId', currentSelectedSlotId.current);
    closeSlotChangeModal();
  }, [closeSlotChangeModal, reset, setValue]);

  const onSelectSlot = useCallback(
    (id: TEntityId) => {
      if (compareIds(selectedSlotId, id)) {
        return;
      }
      currentSelectedSlotId.current = id;

      if (formState.isDirty) {
        openSlotChangeModal();
        return;
      }

      onChangeSlot();
    },
    [formState.isDirty, onChangeSlot, openSlotChangeModal, selectedSlotId],
  );

  return {
    onSelectSlot,
    onChangeSlot,
    openedSlotChangeModal,
    closeSlotChangeModal,
  };
};

import { createDefaultElement } from 'shared/components/Editor/helpers';
import {
  EEditorElementType,
  TEditorVideo,
  TEditorVideoElement,
} from 'shared/components/Editor/types';

export const createVideoElement = (file: TEditorVideo) =>
  ({
    type: EEditorElementType.Video,
    file,
    children: createDefaultElement(''),
  }) as TEditorVideoElement;

import { EMatchType } from 'entities/matches/types';
import { TMatchPlayer } from 'entities/matchPlayer/type';
import React from 'react';
import { TEntityId } from 'shared/types/common';
import {
  TMatchPlacementAddPlayer,
  TMatchPlacementPlayer,
} from 'widgets/matchPlacement/types';
import { MatchPlacementPlayerPositions } from 'widgets/matchPlacement/ui/MatchPlacementPlayerPositions/MatchPlacementPlayerPositions';
import { MatchPlacementTacticSelect } from 'widgets/matchPlacement/ui/MatchPlacementTacticSelect/MatchPlacementTacticSelect';
import { MatchPlacementTeamCompositionTactics } from 'widgets/matchPlacement/ui/MatchPlacementTeamCompositionTactics/MatchPlacementTeamCompositionTactics';

import styles from './MatchPlacement.module.css';

type TProps = {
  players: TMatchPlacementPlayer[];
  matchPlayers: TMatchPlayer[];
  matchType?: EMatchType;
  onAddPlayer: (data: TMatchPlacementAddPlayer[]) => void;
  onDeletePlayer: (data: TEntityId) => void;
  tacticId?: TEntityId;
  teamName?: string;
  onChangeTactic: ({ tacticId }: { tacticId: TEntityId }) => void;
  onEditPlayer: (matchPlayer: TMatchPlayer) => void;
  isLoadingMatchPlayer: boolean;
};

export const MatchPlacement = ({
  players,
  matchPlayers,
  matchType,
  onAddPlayer,
  tacticId,
  teamName,
  onDeletePlayer,
  onChangeTactic,
  onEditPlayer,
  isLoadingMatchPlayer,
}: TProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <MatchPlacementTacticSelect
          matchType={matchType}
          tacticId={tacticId}
          onChangeTactic={onChangeTactic}
        />

        <MatchPlacementPlayerPositions
          isLoadingMatchPlayer={isLoadingMatchPlayer}
          matchPlayers={matchPlayers}
          players={players}
          selectedTacticId={tacticId}
          onAddPlayer={onAddPlayer}
          onEditPlayer={onEditPlayer}
        />
      </div>
      <MatchPlacementTeamCompositionTactics
        matchPlayers={matchPlayers}
        players={players}
        teamName={teamName}
        onAddPlayer={onAddPlayer}
        onDeletePlayer={onDeletePlayer}
        onEditPlayer={onEditPlayer}
      />
    </div>
  );
};

import { useApiSelection } from 'feature/auth/lib/useApiSelection';
import styles from 'feature/auth/ui/AuthForm/AuthForm.module.css';
import { API_LIST } from 'shared/api/constants';
import { Select } from 'shared/components/Form/Select/Select';
import { isProduction } from 'shared/constants';

export const AuthSelectSandbox = () => {
  const { selectedApi, onChangeApi } = useApiSelection();

  if (isProduction) {
    return null;
  }

  return (
    <Select
      className={styles.apiSelect}
      isSearchable={false}
      options={API_LIST}
      placeholder="Select API"
      value={selectedApi}
      onChange={onChangeApi}
    />
  );
};

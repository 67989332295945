import {
  useDeleteVideoCategoryMutation,
  useEditVideoCategoryMutation,
} from 'entities/videoCategories/mutations';
import { TVideoCategory } from 'entities/videoCategories/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { useModal } from 'shared/hooks/useModal';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { toString } from 'shared/lib/toString';
import { TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';
import { TVideoCategoriesFormFields } from 'widgets/videoCategoriesForm/types';
import { VideoCategoriesFormModal } from 'widgets/videoCategoriesForm/VideoCategoriesFormModal';

export const VideoCategoriesActions = ({
  onIconClick,
  closeActiveItemId,
  item: videoCategory,
  isActive,
}: TTableActions<TVideoCategory>) => {
  const { t } = useTranslation();
  const { mutateAsync: deleteCategory, isLoading: isLoadingDelete } =
    useDeleteVideoCategoryMutation();
  const { mutateAsync: editCategory, isLoading: isLoadingEdit } =
    useEditVideoCategoryMutation();

  const { openDeleteModal, closeDeleteModal, openedDeleteModal } =
    useModal('Delete');

  const { openEditModal, closeEditModal, openedEditModal } = useModal('Edit');

  const [currentCategory, setCurrentCategory] =
    useState<TVideoCategoriesFormFields>();

  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditModal,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: openDeleteModal,
    },
  ];
  const isLoading = isLoadingDelete || isLoadingEdit;
  const fetchData = async () => {
    const picture = await getFileFromUrl(videoCategory.picture.x3);
    setCurrentCategory({
      importance: toString(videoCategory.importance),
      picture,
      translations: videoCategory.translations,
    });
  };
  useEffect(() => {
    if (isActive) {
      fetchData();
    }
  }, [isActive, videoCategory]);
  const editVideoCategory = async (fields: TVideoCategoriesFormFields) => {
    closeEditModal();
    const base64 = await getBase64FromFile(fields.picture);
    editCategory({
      categoryId: videoCategory.id,
      data: {
        ...fields,
        picture: {
          data: clearMetaDataFromBase64Url(base64),
          filename: fields!.picture!.name,
        },
        importance: fields.importance ?? '0',
      },
    });
    closeActiveItemId();
  };
  const onConfirm = () => {
    deleteCategory(videoCategory.id);
    closeDeleteModal();
    closeActiveItemId();
  };
  return (
    <>
      <Actions
        actions={actions}
        isLoading={isLoading}
        isOpen={isActive}
        itemId={videoCategory.id}
        onIconClick={onIconClick}
      />
      <ConfirmationModal
        isOpened={openedDeleteModal}
        title={t('content.settings.deleteCategory')}
        onClose={closeDeleteModal}
        onConfirm={onConfirm}
      />
      {currentCategory && (
        <VideoCategoriesFormModal
          actionSubmit={editVideoCategory}
          closeModal={closeEditModal}
          initialValues={currentCategory}
          isOpened={openedEditModal}
          subtitle={t('content.settings.nameCategory')}
          title={t('content.settings.editCategory')}
        />
      )}
    </>
  );
};

import { useFetchTeamsQuery } from 'entities/teams/queries';
import { TeamCard } from 'entities/teams/ui/TeamCard/TeamCard';
import styles from 'pages/team/TeamsPage/TeamsPage.module.css';
import { TeamsPageCreateButton } from 'pages/team/TeamsPage/TeamsPageCreateButton';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CARD_WIDTH } from 'shared/components/Card/Card';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { useCalcDynamicPagination } from 'shared/hooks/useCalcDynamicPagination';
import { routes } from 'shared/routes';

const PAGE_GAP = 24;
const CREATE_BUTTON_COUNT = 1;

export const TeamsList = () => {
  const navigate = useNavigate();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { dynamicItems, isCalculateItems } = useCalcDynamicPagination({
    parent: wrapperRef,
    itemWidth: CARD_WIDTH,
    wrapperGap: PAGE_GAP,
  });

  const {
    data: {
      data: teams = [],
      meta: { total = 0, pageSize = 0, page = 1 } = {},
    } = {},
    error,
    isLoading,
    changePage,
  } = useFetchTeamsQuery(
    { pageSize: dynamicItems - CREATE_BUTTON_COUNT },
    { cacheTime: 0, enabled: isCalculateItems },
  );

  if (error) {
    throw error;
  }

  const isShowPagination = total > 0 && total > pageSize && !isLoading;

  return (
    <>
      <div ref={wrapperRef}>
        {isLoading ? (
          <Centered>
            <Loader showLabel />
          </Centered>
        ) : (
          <>
            <div className={styles.listWrapper} style={{ gap: `${PAGE_GAP}px` }}>
              <TeamsPageCreateButton />
              {teams.map((item) => {
                return (
                  <TeamCard
                    key={item.id}
                    team={item}
                    onClick={() => navigate(routes.editTeam.as(item.id))}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      {isShowPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={pageSize}
          selectedPage={page}
          totalItems={total}
          onPageChange={changePage}
        />
      )}
    </>
  );
};

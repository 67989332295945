import { AxiosRequestConfig } from 'axios';
import { del, get, patch, post } from 'shared/api/methods';
import { TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

type TApiRequestGenerator = {
  baseUrl: string;
};

export const apiRequestGenerator = <TEntity>({ baseUrl }: TApiRequestGenerator) => {
  const entityApiKeys = {
    getEntities: baseUrl,
    getEntity: (entityId: TEntityId) => `${baseUrl}/${entityId}`,
    editEntity: (entityId: TEntityId) => `${baseUrl}/${entityId}`,
    createEntity: baseUrl,
    deleteEntity: (entityId: TEntityId) => `${baseUrl}/${entityId}`,
  };

  return {
    fetchEntities: (config?: AxiosRequestConfig) =>
      get<TEntity[], TPaginatedMeta>(entityApiKeys.getEntities, config).then(
        (response) => response.data,
      ),

    fetchEntity: (id: TEntityId, config?: AxiosRequestConfig) =>
      get<TEntity>(entityApiKeys.getEntity(id), config).then(
        (response) => response.data.data,
      ),

    createEntity: <TDTO>(data: TDTO, config?: AxiosRequestConfig) =>
      post<TEntity>(entityApiKeys.createEntity, data, config).then(
        (response) => response.data.data,
      ),

    editEntity: <TDTO>({
      id,
      data,
      config,
    }: {
      id: TEntityId;
      data: TDTO;
      config?: AxiosRequestConfig;
    }) =>
      patch<TEntity>(entityApiKeys.editEntity(id), data, config).then(
        (response) => response.data.data,
      ),

    deleteEntity: (id: TEntityId) =>
      del(entityApiKeys.deleteEntity(id)).then((response) => response.data.data),
  };
};

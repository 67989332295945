import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'shared/hooks/useModal';
import { routes } from 'shared/routes';

export const useAdBannerBackButton = () => {
  const navigate = useNavigate();
  const { openClosingModal, closeClosingModal, openedClosingModal } =
    useModal('Closing');

  const onConfirmBack = useCallback(() => {
    navigate(routes.adBanners);
  }, [navigate]);

  return {
    onClickBackButton: openClosingModal,
    closeClosingModal,
    openedClosingModal,
    onConfirmBack,
  };
};

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { tournamentQueryKeys } from 'entities/tournaments/consts';
import {
  TFetchTournamentsParams,
  TTournament,
  TTournamentInclude,
} from 'entities/tournaments/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchTournament, fetchTournaments } from './api';

export const useFetchTournamentsQuery = <
  Include extends TTournamentInclude = never,
>({
  options = {},
  params = {},
}: {
  options?: UseQueryOptions<TResponseData<TTournament<Include>[], TPaginatedMeta>>;
  params?: TFetchTournamentsParams;
}) => {
  const { include, ...defaultPagination } = params;

  return usePaginationQuery(
    (pagination) => ({
      queryKey: [tournamentQueryKeys.tournaments, pagination],
      queryFn: () =>
        fetchTournaments<Include>({
          params: {
            ...pagination,
            include,
          },
        }),
      ...options,
    }),
    defaultPagination,
  );
};

export const useFetchTournamentQuery = (id: TEntityId) =>
  useQuery({
    queryKey: [tournamentQueryKeys.tournament(id)],
    queryFn: () => fetchTournament(id),
    enabled: toBoolean(id),
  });

import cn from 'classnames';
import { useProtectedSettingsLinks } from 'feature/settingsNavigation/useProtectedSettingsLinks';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { API_LIST } from 'shared/api/constants';
import { Icon } from 'shared/components/Icon/Icon';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Typography } from 'shared/components/Typography/Typography';
import { ELocalStorageKeys, isProduction } from 'shared/constants';
import { useProtectedLinks } from 'shared/hooks/useProtectedLinks';
import { toBoolean } from 'shared/lib/toBoolean';
import { routes } from 'shared/routes';
import logo from 'widgets/navigation/assets/logo.svg';
import { NavigationLink } from 'widgets/navigation/NavigationLink/NavigationLink';
import { getNavigationLinks } from 'widgets/navigation/navigationLinks';

import styles from './Navigation.module.css';

const getBackendName = () => {
  const savedBackend = API_LIST.find(
    (item) => item.value === localStorage.getItem(ELocalStorageKeys.backendSandbox),
  );
  if (!savedBackend) {
    return;
  }

  return savedBackend.label;
};

export const Navigation = () => {
  const { t } = useTranslation();
  const getProtectedLinks = useProtectedLinks();
  const { links: settingsLinks } = useProtectedSettingsLinks();
  const links = getProtectedLinks(getNavigationLinks(t)).map((link) => (
    <NavigationLink key={link.name} link={link} />
  ));

  const navigate = useNavigate();

  const canOpenSettings = toBoolean(settingsLinks.length);

  return (
    <div className={styles.root}>
      <Link className={styles.logo} to={routes.root}>
        <img alt="logo" src={logo} />
      </Link>
      <div className={styles.beta}>BETA</div>
      <div className={styles.links}>{links}</div>
      <div className={styles.leftWrapper}>
        {!isProduction && (
          <Typography className={styles.sandbox}>{getBackendName()}</Typography>
        )}
        {canOpenSettings && (
          <NavLink
            to={routes.settings}
            className={({ isActive }) =>
              cn(styles.iconSettings, {
                [styles.linkActive]: isActive,
              })
            }
          >
            <Icon kind="settings-01" size="m" />
          </NavLink>
        )}
        <IconButton
          className={styles.user}
          icon="logout"
          iconSize="m"
          onClick={() => navigate(routes.logout)}
        />
      </div>
    </div>
  );
};

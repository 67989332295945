import cn from 'classnames';
import { Icon } from 'shared/components/Icon/Icon';
import { TPicPlaceholderPlayerSize } from 'shared/components/Pic/type';

import styles from './PicPlaceholderPlayer.module.css';

type TProps = {
  size?: TPicPlaceholderPlayerSize;
};

export const PicPlaceholderPlayer = ({ size = 'l' }: TProps) => {
  return (
    <Icon className={cn(styles.icon, styles[size])} customSize kind="player-02" />
  );
};

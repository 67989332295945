import { useLocales } from 'entities/config/lib/useLocales';
import { TMatchesFilterParams } from 'entities/matches/utils';
import { useFetchTournamentsQuery } from 'entities/tournaments/queries';
import { TFilterProps } from 'feature/filters/types';
import { EntitiesFilter } from 'feature/filters/ui/EntitiesFilter/EntitiesFilter';
import { TEntityFilter } from 'feature/filters/ui/EntitiesFilter/type';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';

export const MatchesTournamentFilter = ({
  setFilter,
  filter,
}: TFilterProps<TMatchesFilterParams>) => {
  const { defaultLocale } = useLocales();
  const { data: { data: tournaments = [] } = {}, isLoading } =
    useFetchTournamentsQuery({
      params: {
        pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
      },
    });

  const mappedTournaments = tournaments.map<TEntityFilter>(
    ({ id, translations }) => ({
      id,
      name: translations[defaultLocale].name,
    }),
  );

  return (
    <EntitiesFilter
      entities={mappedTournaments}
      filter={filter.tournament_id}
      isLoading={isLoading}
      onChangeFilter={(tournamentIds) =>
        setFilter({ ...filter, tournament_id: tournamentIds })
      }
    />
  );
};

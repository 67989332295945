import { useLocales } from 'entities/config/lib/useLocales';
import { useRef } from 'react';
import {
  EEditorElementType,
  TEditor,
  TEditorLink,
  TEditorLinkElement,
} from 'shared/components/Editor/types';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';
import { editorLinkController } from 'shared/components/Editor/utils/link/editorLinkController';
import { useModal } from 'shared/hooks/useModal';
import { Editor, Path } from 'slate';

const ENTRY_ELEMENT_INDEX = 0;
const ENTRY_PATH_INDEX = 1;

export const useWorkspacePanelLinkActions = (editor: TEditor) => {
  const { defaultLocale } = useLocales();

  const { openAddLinkModal, closeAddLinkModal, openedAddLinkModal } =
    useModal('AddLink');
  const selectedText = useRef('');
  const selectedLink = useRef<TEditorLinkElement>();

  const openAddLinkModalHandler = () => {
    const editorSelectedText = editor.selection
      ? Editor.string(editor, editor.selection)
      : '';
    let editorSelectedLink;

    const [linkEntry] = editorLinkController.getSelectedLinks(editor);

    if (linkEntry && editor.selection) {
      const linkPath = linkEntry[ENTRY_PATH_INDEX];
      const linkText = linkPath ? Editor.string(editor, linkPath) : '';
      const linkElement = linkEntry[ENTRY_ELEMENT_INDEX];

      const startElement = Editor.node(editor, editor.selection.anchor, {
        edge: 'end',
      });
      const endElement = Editor.node(editor, editor.selection.focus, {
        edge: 'end',
      });

      if (
        linkText === editorSelectedText &&
        Path.equals(startElement[ENTRY_PATH_INDEX], endElement[ENTRY_PATH_INDEX])
      ) {
        editorSelectedLink = linkElement as TEditorLinkElement;
      }
    }

    selectedLink.current = editorSelectedLink;
    selectedText.current = editorSelectedText;
    openAddLinkModal();
  };

  const isActiveLink = () => {
    return editorController.isActiveElement(editor, EEditorElementType.Link);
  };

  const deleteLinkElement = () => {
    editorLinkController.deleteLinkElement(editor);
  };

  const createLinkElement = (props: TEditorLink) => {
    editorLinkController.toggleLinkElement(editor, props, defaultLocale);
  };

  return {
    selectedLink: selectedLink.current,
    selectedText: selectedText.current,
    isActiveLink,
    deleteLinkElement,
    createLinkElement,
    openedAddLinkModal,
    closeAddLinkModal,
    openAddLinkModal: openAddLinkModalHandler,
  };
};

import { AboutClubPage } from 'pages/aboutClubPage/AboutClubPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const AboutClubRoute = {
  path: routes.aboutClub,
  element: <AboutClubPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

import { useQuery } from '@tanstack/react-query';
import { fetchAdBanner } from 'entities/ad/banners/model/api';
import { useLocales } from 'entities/config/lib/useLocales';
import { useParams } from 'react-router-dom';
import { toBoolean } from 'shared/lib/toBoolean';
import { mapAdBannerToFormFields } from 'widgets/adBannerForm/mapAdBannerToFormFields';

export const AD_BANNER_FORM_FIELDS_KEY = 'adBannerForFormFields';

export const useGetAdBannerFormValues = () => {
  const { bannerId } = useParams<{ bannerId: string }>();
  const { defaultLocale } = useLocales();

  const {
    data: values,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [AD_BANNER_FORM_FIELDS_KEY, bannerId],
    queryFn: async () =>
      mapAdBannerToFormFields(await fetchAdBanner(bannerId ?? ''), defaultLocale),
    enabled: toBoolean(bannerId),
    cacheTime: 0,
  });

  return {
    values,
    isLoading,
    isError,
    error,
  };
};

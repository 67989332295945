import { FloatingPortal, useMergeRefs } from '@floating-ui/react';
import cn from 'classnames';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { Badge } from 'shared/components/Badge/Badge';
import { Button } from 'shared/components/Button/Button';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { BaseCheckbox } from 'shared/components/Form/Checkbox/BaseCheckbox';
import { BaseSelect } from 'shared/components/Form/OldSelect/BaseSelect';
import {
  TBaseSelectProps,
  TSelectProps,
} from 'shared/components/Form/OldSelect/types';
import { useSelectSearch } from 'shared/components/Form/OldSelect/useSelectSearch';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Typography } from 'shared/components/Typography/Typography';
import { useFloat } from 'shared/hooks/useFloat';
import { toBoolean } from 'shared/lib/toBoolean';

import styles from './OldSelect.module.css';

const SELECT_DROPDOWN_OFFSET = 2;

export const OldSelect = ({
  placement = 'bottom',
  value,
  size = 's',
  options,
  onChange,
  multiple,
  placeholderClassName,
  disabled = false,
  search,
  optionsContainerClassName,
  dropdownWidth = 0,
  selectClassName,
  downButton,
  ...props
}: TSelectProps & Omit<TBaseSelectProps, 'onClick' | 'icon'>) => {
  const { t } = useTranslation();
  const [isOpen, toggleOpen] = useToggle(false);
  const ref = useRef<HTMLDivElement | null>();
  const [selectWidth, setSelectWidth] = useState(dropdownWidth);
  const isHaveValue = multiple ? toBoolean(value.length) : toBoolean(value);
  const { searchedOptions, resetSearch, onSearch } = useSelectSearch(options);

  useEffect(() => {
    if (!dropdownWidth) {
      const syncDropdownWidth = debounce(() => {
        if (ref.current && ref.current.clientWidth !== selectWidth) {
          setSelectWidth(ref.current.clientWidth);
        }
      });
      syncDropdownWidth();

      window.addEventListener('resize', syncDropdownWidth);

      return () => {
        window.removeEventListener('resize', syncDropdownWidth);
      };
    }

    return () => {};
  }, []);

  const {
    trigger: { triggerRef, ...trigger },
    floating,
  } = useFloat({
    isOpen,
    placement,
    onChange: (newIsOpen) => {
      resetSearch();
      toggleOpen(newIsOpen);
    },
    customOutsidePress: true,
    offset: SELECT_DROPDOWN_OFFSET,
  });

  const baseSelectRef = useMergeRefs([ref, triggerRef]);

  return (
    <BaseSelect
      {...props}
      className={selectClassName}
      disabled={disabled}
      iconClassName={styles.selectIcon}
      isActive={isOpen}
      nextIcon={isOpen ? 'chevron-up' : 'chevron-down'}
      ref={baseSelectRef}
      search={search && isOpen}
      size={size}
      placeholderClassName={cn(placeholderClassName, {
        [styles.placeholderWithValue]: isHaveValue,
      })}
      onSearch={onSearch}
      {...trigger}
    >
      {multiple && isHaveValue && (
        <div className={styles.valueWrapper}>
          {value.map((item) => (
            <Badge
              key={item}
              color="green"
              content={options.find((o) => o.value === item)?.label ?? ''}
              shape="pilled"
              size="s"
              type="solid"
              closeIconProps={{
                size: 'xs',
                onClick: () => {
                  onChange(value.filter((v) => v !== item));
                },
              }}
            />
          ))}
        </div>
      )}
      {isOpen && (
        <FloatingPortal>
          <Dropdown className={styles.dropdown} width={selectWidth} {...floating}>
            <div className={cn(styles.optionsContainer, optionsContainerClassName)}>
              {searchedOptions.length === 0 && (
                <Typography>{t('shared.Form.select.noOptions')}</Typography>
              )}
              {multiple &&
                searchedOptions.map((option) => (
                  <BaseCheckbox
                    key={option.value}
                    checked={value.includes(option.value)}
                    className={cn(styles.checkbox, option.className)}
                    labelClassName={styles.checkboxLabel}
                    size={option.size}
                    label={
                      option.renderLabel
                        ? option.renderLabel(option.label)
                        : option.label
                    }
                    onChange={(checked) => {
                      if (checked) {
                        onChange([...value, option.value]);
                      } else {
                        onChange(value.filter((v) => v !== option.value));
                      }
                    }}
                  />
                ))}
              {!multiple &&
                searchedOptions.map((option) => (
                  <RadioButton
                    key={option.value}
                    checked={option.value === value}
                    className={cn(styles.radioButton, option.className)}
                    name={option.value}
                    size={option.size}
                    value={option.value}
                    label={
                      option.renderLabel
                        ? option.renderLabel(option.label)
                        : option.label
                    }
                    onChange={(_, value) => {
                      onChange(value as string);
                    }}
                  />
                ))}
            </div>
            {downButton && (
              <Button className={styles.downButton} onClick={downButton.onClick}>
                {downButton.text}
              </Button>
            )}
          </Dropdown>
        </FloatingPortal>
      )}
    </BaseSelect>
  );
};

import * as Sentry from '@sentry/react';
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { JSONAPIDocument } from 'json-api-serializer';
import { API_VERSION } from 'shared/api/constants';
import { handleJsonApiResponse } from 'shared/api/jsonApi/handleJsonApiResponse';
import { getTokenState } from 'shared/api/token';

export const handleApiOnResponse = (response: AxiosResponse<JSONAPIDocument>) =>
  handleJsonApiResponse(response);

export const handleApiOnRejection = (error: AxiosError) => {
  const resetToken = getTokenState().resetToken;
  Sentry.captureException(error);
  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    resetToken();
  }

  return Promise.reject(error);
};

export const handleApiOnRequest = <D>(
  config: InternalAxiosRequestConfig<D>,
): InternalAxiosRequestConfig<D> => {
  const { token } = getTokenState();

  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers['x-api-version'] = API_VERSION;

  return config;
};

import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchTournamentSeasonsQuery } from 'entities/tournament-season/queries';
import { TTournamentSeasonsInclude } from 'entities/tournament-season/types';
import { useMemo } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';
import { compareIds } from 'shared/utils/entityIds';
import { TMatchFormFields } from 'widgets/match-form/types/types';

export const useTournamentSelectorsMatch = ({
  control,
}: {
  control: Control<TMatchFormFields>;
  defaultTournament?: string;
}) => {
  const { t } = useTranslation();

  const { defaultLocale } = useLocales();

  const selectTournament = useWatch({
    control,
    name: 'tournament',
  });

  const {
    field: { onChange: onChangeSelectTournamentSeason },
  } = useController({
    control,
    name: 'tournamentSeason',
  });

  const { data: tournamentSeasons } =
    useFetchTournamentSeasonsQuery<TTournamentSeasonsInclude>({
      params: {
        include: ['tournament', 'season'],
        pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
      },
    });

  const tournamentOptions: TSelectOption[] = useMemo(() => {
    return (
      tournamentSeasons?.data.reduce<TSelectOption[]>((acc, item) => {
        const id = toString(item.tournament.id);

        const isDuplicate = acc.some((option) => compareIds(option.value, id));

        if (!isDuplicate) {
          acc.push({
            value: id,
            label: item.tournament.translations[defaultLocale]?.name || '',
          });
        }

        return acc;
      }, []) ?? []
    );
  }, [tournamentSeasons, defaultLocale]);

  const seasonOptions: TSelectOption[] = useMemo(() => {
    return (
      tournamentSeasons?.data.reduce<TSelectOption[]>((acc, item) => {
        if (compareIds(selectTournament, item.tournament.id)) {
          acc.push({
            value: toString(item.id),
            label: item.season.translations[defaultLocale]?.name || '',
          });
        }
        return acc;
      }, []) ?? []
    );
  }, [tournamentSeasons, selectTournament, defaultLocale]);

  return {
    season: {
      options: seasonOptions,
      placeholder: t('content.matches.chooseSeason'),
    },
    tournament: {
      options: tournamentOptions,
      placeholder: t('content.matches.chooseTournament'),
      selected: selectTournament,
      onChange: onChangeSelectTournamentSeason,
    },
  };
};

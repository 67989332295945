import { SettingsNavigation } from 'feature/settingsNavigation/ui/SettingsNavigation';
import { useProtectedSettingsLinks } from 'feature/settingsNavigation/useProtectedSettingsLinks';
import { Outlet } from 'react-router-dom';
import { useRedirectByNoPermissions } from 'shared/hooks/useRedirectByNoPermissions';

import styles from './SettingsLayout.module.css';

export const SettingsLayout = () => {
  const { links } = useProtectedSettingsLinks();

  useRedirectByNoPermissions({
    needRedirect: !links.length,
  });

  if (!links.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <SettingsNavigation links={links} />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

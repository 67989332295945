import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Typography } from 'shared/components/Typography/Typography';
import { TImageToUpload } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormDropzone/types';
import { PhotoGalleryFormUploadModalItem } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormUploadModalItem/PhotoGalleryFormUploadModalItem';

import styles from './PhotoGalleryFormUploadModal.module.css';

type TProps = {
  isOpen: boolean;
  uploadingImages: [string, TImageToUpload][];
  totalUploading: number;
  actions: {
    onDeleteUploading: (id: string) => void;
    confirmCloseUploadModal: () => void;
  };
};

export const PhotoGalleryFormUploadModal = ({
  uploadingImages,
  totalUploading,
  isOpen,
  actions: { onDeleteUploading, confirmCloseUploadModal },
}: TProps) => {
  const { t } = useTranslation();
  const [isOpenCollapse, setIsOpenCollapse] = useState(true);
  const toggleCollapse = () => setIsOpenCollapse((prev) => !prev);

  const haveLoadingImages = totalUploading > 0;

  if (!isOpen) {
    return null;
  }

  return (
    <FloatingPortal>
      <div className={styles.uploadModal}>
        <div
          className={cn(styles.collapseHeader, {
            [styles.showBorder]: isOpenCollapse,
          })}
        >
          <Typography
            className={styles.title}
            color="var(--dark-blue-400)"
            size={14}
            weight="SemiBold"
          >
            {haveLoadingImages
              ? t('content.photoGallery.uploadModal.uploadFilesTitle', {
                  totalUploading,
                })
              : t('content.photoGallery.uploadModal.completeUploadFilesTitle')}
          </Typography>
          <div className={styles.actions}>
            <IconButton
              className={cn({ [styles.activeButton]: isOpenCollapse })}
              icon="chevron-up"
              onClick={toggleCollapse}
            />
            <IconButton
              disabled={haveLoadingImages}
              icon="x"
              onClick={confirmCloseUploadModal}
            />
          </div>
        </div>
        {isOpenCollapse && (
          <div className={styles.collapseBody}>
            <ul className={styles.uploadList}>
              {uploadingImages.map(([id, image]) => (
                <PhotoGalleryFormUploadModalItem
                  key={id}
                  id={id}
                  onDeleteUploading={onDeleteUploading}
                  {...image}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </FloatingPortal>
  );
};

import { TEntityId } from 'shared/types/common';

export const tacticsQueryKeys = {
  tactics: 'tactics',
};

const tacticsBaseUrl = 'football/tactics';

export const tacticsApiKeys = {
  getTactics: tacticsBaseUrl,
  fetchTactic: (id: TEntityId) => `${tacticsBaseUrl}/${id}`,
};

import { useLocales } from 'entities/config/lib/useLocales';
import { useMemo } from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generateSelectedText } from 'shared/lib/generateSelectedText';

import { TCommonOptionType, TSelectFieldProps } from '../types';
import { generateOptionsArray } from '../utils';

type TUseRightPanelLabel<T extends FieldValues> = {
  labels?: TCommonOptionType[];
  categories?: TCommonOptionType[];
  labelsFieldName: Path<T>;
  categoriesFieldName: Path<T>;
};

type TUseRightPanelLabelReturn<T extends FieldValues> = {
  labels: TSelectFieldProps<T>;
  categories: TSelectFieldProps<T>;
};

export const useRightPanelLabel = <T extends FieldValues>({
  labels,
  categories,
  labelsFieldName,
  categoriesFieldName,
}: TUseRightPanelLabel<T>): TUseRightPanelLabelReturn<T> => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();

  const labelsForSelect = useMemo(
    () => generateOptionsArray(defaultLocale, labels),
    [labels],
  );

  const categoriesForSelect = useMemo(
    () => generateOptionsArray(defaultLocale, categories),
    [categories],
  );

  return {
    labels: {
      data: labelsForSelect,
      fieldName: labelsFieldName,
      placeholder: t('content.selectFromList'),
      getMultiValueDescription: (values) =>
        generateSelectedText({
          count: values.length,
          singular: t('content.label'),
          few: t('content.labels'),
          many: t('content.labelWithOtherEnding'),
          defaultText: t('content.selectFromList'),
        }),
    },
    categories: {
      data: categoriesForSelect,
      fieldName: categoriesFieldName,
      placeholder: t('content.selectFromList'),
      getMultiValueDescription: (values) =>
        generateSelectedText({
          count: values.length,
          singular: t('content.categoryWithSmallLetter'),
          few: t('content.categories'),
          many: t('content.categoryWithOtherEnding'),
          defaultText: t('content.selectFromList'),
          ruWordGender: 'female',
        }),
    },
  };
};

import { TPhotoGalleryDTO } from 'entities/photoGallery/types';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang, TTranslationsMapper } from 'shared/types/common';

const defaultPhotoGalleryNames = {
  ru: 'Введите название',
  en: 'Enter title',
  de: 'Titel eingeben',
  fr: 'Entrer le titre',
} satisfies TTranslationsMapper;

export const mapDefaultPhotoGalleryToDTO = (
  availableLocales: TContentLang[],
): TPhotoGalleryDTO => ({
  availableLocales,
  translations: reduceTranslationObject(availableLocales, (locale) => ({
    name: defaultPhotoGalleryNames[locale],
  })),
  relationships: { labels: [] },
});

import { TMatch, TMatchInclude } from 'entities/matches/types';
import { useMatchActions } from 'pages/matches/list/hooks/useMatchActions';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { TTableActions } from 'widgets/elementsList/types';

export const MatchActions = (props: TTableActions<TMatch<TMatchInclude>>) => {
  const { t } = useTranslation();
  const {
    actions,
    isDeletingMatch,
    openedDeleteModal,
    handleConfirmDeleteCareer,
    handleCloseDeleteModal,
    itemId,
    isActive,
    onIconClick,
  } = useMatchActions(props);

  return (
    <>
      <Actions
        actions={actions}
        isLoading={isDeletingMatch}
        isOpen={isActive}
        itemId={itemId}
        onIconClick={onIconClick}
      />
      <ConfirmationModal
        description={t('content.deleteWarning')}
        isOpened={openedDeleteModal}
        title={t('content.matches.delete')}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteCareer}
      />
    </>
  );
};

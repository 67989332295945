import cn from 'classnames';
import range from 'lodash/range';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/Icon/Icon';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Typography } from 'shared/components/Typography/Typography';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'shared/consts';

import styles from './Pagination.module.css';

export type TPaginationProps = {
  totalItems: number;
  pageSize?: number;
  selectedPage: number;
  siblingRange?: number;

  onPageChange: (page: number) => void;
  className?: string;
};

const insertEllipsis = (pages: number[]) => {
  const newPages: Array<number | string> = [];
  pages.forEach((page, index, pagesArray) => {
    newPages.push(page);
    if (pagesArray[index + 1] - page > 1) {
      newPages.push('···');
    }
  });
  return newPages;
};

export const Pagination = forwardRef<HTMLDivElement, TPaginationProps>(
  (
    {
      totalItems,
      pageSize = DEFAULT_PAGINATION_PAGE_SIZE,
      selectedPage,
      onPageChange,
      siblingRange = 1,
      className,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const prevButtonRef = useRef<HTMLButtonElement | null>(null);
    const nextButtonRef = useRef<HTMLButtonElement | null>(null);
    const allPages = useMemo(
      () => range(1, Math.ceil(totalItems / pageSize + 1)),
      [totalItems, pageSize],
    );

    const siblingsVisibleRange = useMemo(
      () => range(selectedPage - siblingRange, selectedPage + siblingRange + 1),
      [selectedPage, siblingRange],
    );
    const visiblePages = allPages.filter(
      (page) =>
        page === allPages[0] ||
        page === allPages[allPages.length - 1] ||
        siblingsVisibleRange.includes(page),
    );

    const visiblePagesWithEllipsis = insertEllipsis(visiblePages);

    const isPrevEnabled = selectedPage > visiblePages[0];
    const isNextEnabled = selectedPage < visiblePages[visiblePages.length - 1];

    const onPrevClick = useCallback(() => {
      onPageChange(selectedPage - 1);
      if (prevButtonRef.current) {
        prevButtonRef.current.blur();
      }
    }, [selectedPage, prevButtonRef]);

    const onNextClick = useCallback(() => {
      onPageChange(selectedPage + 1);
      if (nextButtonRef.current) {
        nextButtonRef.current.blur();
      }
    }, [selectedPage, nextButtonRef]);

    const itemsShown =
      totalItems < pageSize
        ? totalItems
        : selectedPage !== visiblePages[visiblePages.length - 1]
          ? pageSize
          : totalItems % pageSize || pageSize;

    return (
      <div className={cn(styles.container, className)} ref={ref}>
        <Typography className={styles.itemsInfo} size={14}>
          {t('shared.Pagination.text', { itemsShown, totalItems })}
        </Typography>
        <div className={styles.pagination}>
          <IconButton
            className={styles.navButton}
            disabled={!isPrevEnabled}
            icon="chevron-left"
            ref={prevButtonRef}
            onClick={onPrevClick}
          />
          <ul className={styles.pages}>
            {visiblePagesWithEllipsis.map((page, index) => {
              if (typeof page === 'string') {
                return (
                  <li key={`${page}.${index}`} className={styles.ellipsis}>
                    <Icon kind="dotsHorizontal" />
                  </li>
                );
              }
              return (
                <li
                  key={`${page}.${index}`}
                  className={cn(styles.page, {
                    [styles.selectedPage]: page === selectedPage,
                  })}
                >
                  <a onClick={() => onPageChange(page)}>{page}</a>
                </li>
              );
            })}
          </ul>
          <IconButton
            className={styles.navButton}
            disabled={!isNextEnabled}
            icon="chevron-right"
            ref={nextButtonRef}
            onClick={onNextClick}
          />
        </div>
      </div>
    );
  },
);

Pagination.displayName = 'Pagination';

import { useRightPanelPreview } from 'feature/rightPanel/hooks/useRightPanelPreview';
import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { CommonPreview } from 'feature/rightPanel/ui/CommonPreview/CommonPreview';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { getImageSettings } from 'shared/constants';

export const AddNewsPostRightPanelPreview = (
  commonProps: TDefaultRightPanelProps,
) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TEditNewsPostFields>();
  const { field: previewFile, fieldState } = useController({
    control,
    name: 'preview.file',
  });
  const { field: previewData } = useController({
    control,
    name: 'preview.data',
  });

  useRightPanelPreview({
    file: previewFile.value,
    onChange: previewData.onChange,
  });

  return (
    <CommonPreview
      aspectRatio={getImageSettings(t).news.preview.aspectRatio}
      control={control}
      description={t('shared.NewsPostForm.AddNewsPostRightPanelPreview.description')}
      fieldState={fieldState}
      fileFieldName="preview.file"
      mode="optional"
      previewDataUrl={previewData.value}
      previewFile={previewFile.value}
      {...commonProps}
    />
  );
};

import { useFetchClubContactsQuery } from 'entities/club-contacts/queries';
import { useState } from 'react';
import { Table } from 'shared/components/SimpleTable';
import { TEntityId } from 'shared/types/common';
import { compareIds } from 'shared/utils/entityIds';
import { ClubContactsRow } from 'widgets/ClubForm/ui/ClubContactsRow';

export const ClubContactsTable = ({ clubId }: { clubId: TEntityId }) => {
  const [activeItemId, setActiveItemId] = useState<TEntityId | null>(null);
  const { data: { data: clubContacts = [] } = {} } = useFetchClubContactsQuery({
    page: 1,
    pageSize: 100,
  });

  return (
    <Table>
      <Table.Body>
        {clubContacts.map((clubContact) => {
          const isActive = compareIds(activeItemId, clubContact.id);

          return (
            <ClubContactsRow
              key={clubContact.id}
              closeActiveItemId={() => setActiveItemId(null)}
              clubContact={clubContact}
              clubId={clubId}
              isActive={isActive}
              onIconClick={() => {
                setActiveItemId(isActive ? null : clubContact.id);
              }}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
};

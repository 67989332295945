import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchManagersQuery } from 'entities/managers/api/queries';
import { useFetchPlayersQuery } from 'entities/players/api/queries';
import { useFetchTeamsQuery } from 'entities/teams/queries';
import { useFetchTournamentsQuery } from 'entities/tournaments/queries';
import { TRightPanelMatchProps, TSelectFieldProps } from 'feature/rightPanel/types';
import {
  generateNameOptionsArray,
  generateOptionsArray,
  generateTeamOptionsArray,
} from 'feature/rightPanel/utils';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Control,
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { generateSelectedText } from 'shared/lib/generateSelectedText';
import { TEntityId } from 'shared/types/common';
import { compareIds } from 'shared/utils/entityIds';
import { TVideoPostFields } from 'widgets/videoForm/types';

type TUseRightPanelConnections<T extends FieldValues> = {
  teamsFieldName?: Path<T>;
  playersFieldName: Path<T>;
  tournamentFieldName: Path<T>;
  seasonFieldName: Path<T>;
  managersFieldName: Path<T>;
  matchFieldName: Path<T>;
  control: Control<T>;
};

type TUseRightPanelConnectionsReturn<T extends FieldValues> = {
  tournament: TSelectFieldProps<T>;
  season: TSelectFieldProps<T>;
  players: TSelectFieldProps<T>;
  managers: TSelectFieldProps<T>;
  match: TRightPanelMatchProps;
  teams?: TSelectFieldProps<T>;
};

export const useRightPanelConnections = <T extends FieldValues>({
  tournamentFieldName,
  seasonFieldName,
  playersFieldName,
  managersFieldName,
  matchFieldName,
  teamsFieldName = '' as Path<T>,
  control,
}: TUseRightPanelConnections<T>): TUseRightPanelConnectionsReturn<T> => {
  const { t } = useTranslation();

  const { defaultLocale } = useLocales();
  const { setValue } = useFormContext<TVideoPostFields>();
  const { data: { data: tournaments = [] } = {} } =
    useFetchTournamentsQuery<'seasons'>({
      params: {
        include: 'seasons',
        pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
      },
    });

  const { data: { data: teams } = {} } = useFetchTeamsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const { data: { data: players } = {} } = useFetchPlayersQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const { data: { data: managers } = {} } = useFetchManagersQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const { field: selectedMatch } = useController({
    control,
    name: matchFieldName,
  });

  const { field: selectedTournamentId, fieldState: selectedTournamentState } =
    useController({
      control,
      name: tournamentFieldName,
    });

  const { field: selectedSeasonId, fieldState: selectedSeasonState } = useController(
    {
      control,
      name: seasonFieldName,
    },
  );

  const selectedTournament = useMemo(() => {
    return tournaments.find(({ id }) => compareIds(id, selectedTournamentId.value));
  }, [tournaments, selectedTournamentId.value]);

  const tournamentsOptions = useMemo(
    () => generateOptionsArray(defaultLocale, tournaments),
    [tournaments],
  );

  const seasonsOptions = useMemo(() => {
    if (selectedTournament) {
      return generateOptionsArray(defaultLocale, selectedTournament.seasons);
    }

    return [];
  }, [selectedTournament]);

  const teamsOptions = useMemo(
    () => generateTeamOptionsArray(defaultLocale, teams),
    [teams],
  );

  const playersOptions = useMemo(
    () => generateNameOptionsArray(defaultLocale, players),
    [players],
  );

  const managersOptions = useMemo(
    () => generateNameOptionsArray(defaultLocale, managers),
    [managers],
  );

  const changeMatchHandler = useCallback((id: TEntityId) => {
    if (!id) {
      setValue('review', false);
    }
    selectedMatch.onChange(id);
  }, []);

  useEffect(() => {
    if (selectedTournamentState.isDirty) {
      selectedSeasonId.onChange('');
    }
  }, [selectedTournamentId.value, selectedTournamentState.isDirty]);

  useEffect(() => {
    if (selectedSeasonState.isDirty) {
      changeMatchHandler('');
    }
  }, [selectedSeasonId.value, selectedSeasonState.isDirty]);

  return {
    match: {
      selectedTournamentId: selectedTournamentId.value,
      selectedSeasonId: selectedSeasonId.value,
      selectedMatchId: selectedMatch.value,
      onChangeMatch: changeMatchHandler,
      disabled: !selectedTournamentId.value || !selectedSeasonId.value,
      seasons: selectedTournament?.seasons ?? [],
    },
    teams: teamsFieldName
      ? {
          fieldName: teamsFieldName,
          data: teamsOptions,
          placeholder: t('content.selectFromList'),
          getMultiValueDescription: (values) =>
            generateSelectedText({
              count: values.length,
              singular: t('content.team.teamSmallFirstLetter'),
              few: t('content.team.teams'),
              many: t('content.team.teamsWithOtherEnding'),
              defaultText: t('content.selectFromList'),
              ruWordGender: 'female',
            }),
        }
      : undefined,
    season: {
      fieldName: seasonFieldName,
      data: seasonsOptions,
      placeholder: t('content.selectFromList'),
      disabled: !selectedTournamentId.value,
    },
    tournament: {
      fieldName: tournamentFieldName,
      data: tournamentsOptions,
      placeholder: t('content.selectFromList'),
    },
    players: {
      fieldName: playersFieldName,
      placeholder: t('content.selectFromList'),
      data: playersOptions,
      getMultiValueDescription: (values) =>
        generateSelectedText({
          count: values.length,
          singular: t('content.player.playerSmallFirstLetter'),
          few: t('content.player.players'),
          many: t('content.player.playerWithOtherEnding'),
          defaultText: t('content.selectFromList'),
        }),
    },
    managers: {
      fieldName: managersFieldName,
      placeholder: t('content.selectFromList'),
      getMultiValueDescription: (values) =>
        generateSelectedText({
          count: values.length,
          singular: t('content.manager.trainer'),
          few: t('content.manager.trainers'),
          many: t('content.manager.trainerWithOtherEnding'),
          defaultText: t('content.selectFromList'),
        }),
      data: managersOptions,
    },
  } satisfies TUseRightPanelConnectionsReturn<T>;
};

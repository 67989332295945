import { useQueryClient } from '@tanstack/react-query';
import { clubSocialNetworksQueryKeys } from 'entities/club-social-network/consts';
import { useCreateClubSocialNetworkMutation } from 'entities/club-social-network/mutations';
import { TSocialNetworkModalValue } from 'feature/socialNetworkModal/types';
import { useTranslation } from 'react-i18next';
import { handleValidationError } from 'shared/api/errors';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';

type TProps = {
  clubId: TEntityId;
};

export const useCreateClubSocialNetwork = ({ clubId }: TProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    openCreateClubSocialNetworkModal,
    closeCreateClubSocialNetworkModal,
    openedCreateClubSocialNetworkModal,
  } = useModal('CreateClubSocialNetwork');

  const { mutate: createClubSocialNetworkMutation, isLoading } =
    useCreateClubSocialNetworkMutation();

  const createClubSocialNetwork = (socialNetwork: TSocialNetworkModalValue) => {
    createClubSocialNetworkMutation(
      {
        ...socialNetwork,
        clubId,
      },
      {
        onError: (error) =>
          handleValidationError(error, () => {
            notify(t('content.club.notifyNotCreateSocial'), { type: 'error' });
          }),
        onSuccess: () => {
          return queryClient.resetQueries([
            clubSocialNetworksQueryKeys.clubSocialNetworks,
          ]);
        },
      },
    );
    closeCreateClubSocialNetworkModal();
  };

  return {
    openCreateClubSocialNetworkModal,
    createSocialNetworkModalProps: {
      title: t('content.addLink'),
      closeModal: closeCreateClubSocialNetworkModal,
      actionSubmit: createClubSocialNetwork,
      isOpened: openedCreateClubSocialNetworkModal,
      isLoading,
      initialValue: {
        url: 'https://',
        socialNetworkId: '',
      },
    },
  };
};

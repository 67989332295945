import { useLocales } from 'entities/config/lib/useLocales';
import { useRef } from 'react';
import { TEditor, TEditorVideo } from 'shared/components/Editor/types';
import { editorVideoController } from 'shared/components/Editor/utils/video/editorVideoController';
import { useModal } from 'shared/hooks/useModal';

export type TWorkspaceVideoContext = {
  isOpenAttachVideoLinkModal: boolean;
  openAttachVideoLinkModal: () => void;
  openChangeVideoLinkModal: (path: number[]) => void;
  closeAttachVideoLinkModal: () => void;
  attachLink: (link: TEditorVideo) => void;
};

export const useWorkspaceVideoContext = (editor: TEditor) => {
  const {
    openAttachVideoLinkModal,
    closeAttachVideoLinkModal,
    openedAttachVideoLinkModal,
  } = useModal('AttachVideoLink');

  const { defaultLocale } = useLocales();
  const changeVideoPath = useRef([] as number[]);

  const insertVideo = (file: TEditorVideo) => {
    editorVideoController.insertVideo(editor, file, defaultLocale);
  };

  const changeVideo = (file: TEditorVideo) => {
    editorVideoController.changeVideo(editor, changeVideoPath.current, file);
    changeVideoPath.current = [];
  };

  const attachLink = (props: TEditorVideo) => {
    if (changeVideoPath.current.length) {
      changeVideo(props);
    } else {
      insertVideo(props);
    }
  };

  const openChangeVideoLinkModal = (path: number[]) => {
    changeVideoPath.current = path;
    openAttachVideoLinkModal();
  };

  return {
    isOpenAttachVideoLinkModal: openedAttachVideoLinkModal,
    openAttachVideoLinkModal,
    closeAttachVideoLinkModal,
    openChangeVideoLinkModal,
    attachLink,
  };
};

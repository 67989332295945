import { TEntityId } from 'shared/types/common';

export const matchesQueryKeys = {
  matches: 'matches',
  match: 'match',
};

const matchesBaseUrl = 'matches';

export const matchesApiKeys = {
  getMatches: matchesBaseUrl,
  getMatch: (matchId: TEntityId) => `${matchesBaseUrl}/${matchId}`,
  editMatch: (matchId: TEntityId) => `${matchesBaseUrl}/${matchId}`,
  createMatch: matchesBaseUrl,
  deleteMatch: (matchId: TEntityId) => `${matchesBaseUrl}/${matchId}`,
};

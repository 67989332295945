import { useController } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import {
  TAddNewsPostFields,
  TEditNewsPostFields,
} from 'shared/components/NewsPostForm/types';
import { useModal } from 'shared/hooks/useModal';

type TUseNewsWorkspacePanelPublish = Partial<UseFormReturn<TEditNewsPostFields>> & {
  isEdit: boolean;
  isPublish: boolean;
  createNewsPost: (data: TAddNewsPostFields) => Promise<void>;
  editNewsPost: (data: TEditNewsPostFields) => Promise<void>;
};

export const useNewsWorkspacePanelPublish = ({
  trigger,
  control,
  handleSubmit,
  createNewsPost,
  editNewsPost,
  isEdit,
  isPublish,
}: TUseNewsWorkspacePanelPublish) => {
  const { openOpenPublishModal, closeOpenPublishModal, openedOpenPublishModal } =
    useModal('OpenPublish');

  const { openChooseDateModal, closeChooseDateModal, openedChooseDateModal } =
    useModal('ChooseDate');

  const {
    field: { onChange: onPublishDateChange, value: publishDate },
  } = useController({
    control,
    name: 'publishDate',
  });

  const onClickPublish = async () => {
    const isValid = await trigger?.();
    if (!isValid) {
      return;
    }

    if (isEdit && isPublish) {
      await handleSubmit?.(editNewsPost)();
      return;
    }

    openOpenPublishModal();
  };

  const onDateChange = (date: Date | undefined) => {
    onPublishDateChange(date);
    openOpenPublishModal();
    closeChooseDateModal();
  };

  const onPublish = () => {
    if (!publishDate) {
      onPublishDateChange(new Date());
    }

    if (isEdit) {
      handleSubmit?.(editNewsPost)();
    } else {
      handleSubmit?.(createNewsPost)();
    }
  };

  const onClickChooseDate = () => {
    closeOpenPublishModal();
    openChooseDateModal();
  };

  const onCloseChooseDate = () => {
    closeChooseDateModal();
    openOpenPublishModal();
  };

  return {
    publishDate,
    isOpenPublishModal: openedOpenPublishModal,
    onClosePublishModal: closeOpenPublishModal,
    isOpenChooseDateModal: openedChooseDateModal,
    onCloseChooseDate,
    onClickPublish,
    onDateChange,
    onPublish,
    onClickChooseDate,
  };
};

import { useQueryClient } from '@tanstack/react-query';
import { clubContactsQueryKeys } from 'entities/club-contacts/consts';
import { useDeleteClubContactMutation } from 'entities/club-contacts/mutations';
import { useTranslation } from 'react-i18next';
import { TConfirmationModalProps } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';

type TProps = {
  clubContactId: TEntityId;
  closeActiveItemId: () => void;
};

export const useDeleteClubContact = ({
  clubContactId,
  closeActiveItemId,
}: TProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    openDeleteClubContactModal,
    closeDeleteClubContactModal,
    openedDeleteClubContactModal,
  } = useModal('DeleteClubContact');
  const { mutate: deleteClubContact, isLoading } = useDeleteClubContactMutation();

  const onConfirmDeleteClubContact = () => {
    deleteClubContact(clubContactId, {
      onSuccess: () =>
        queryClient.resetQueries([clubContactsQueryKeys.clubContacts]),
      onError: () =>
        notify(t('content.club.notifyNotDeleteContact'), { type: 'error' }),
    });
  };

  const onClose = () => {
    closeDeleteClubContactModal();
    closeActiveItemId();
  };

  return {
    openDeleteClubContactModal,
    deleteModalProps: {
      onClose,
      onConfirm: onConfirmDeleteClubContact,
      isOpened: openedDeleteClubContactModal,
      description: t('content.deleteWarning'),
      title: t('content.contactModal.deleteContact.title'),
      isLoading,
    } satisfies TConfirmationModalProps,
  };
};

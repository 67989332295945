import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { CommonImportance } from 'feature/rightPanel/ui/CommonImportance/CommonImportance';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { getImageSettings } from 'shared/constants';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';

export const AddNewsPostRightPanelImportance = (
  commonProps: TDefaultRightPanelProps,
) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TEditNewsPostFields>();
  const { field: sliderImage, fieldState } = useController({
    control,
    name: 'slider.file',
  });
  const { field: sliderImageData } = useController({
    control,
    name: 'slider.data',
  });

  const { field: important } = useController({
    control,
    name: 'important',
  });

  useEffect(() => {
    getBase64FromFile(sliderImage.value).then((data) => {
      sliderImageData.onChange(data);
    });
  }, [sliderImage.value]);

  return (
    <CommonImportance
      aspectRatio={getImageSettings(t).news.slider.aspectRatio}
      control={control}
      fieldState={fieldState}
      sliderImage={sliderImage.value}
      title={t('shared.NewsPostForm.AddNewsPostRightPanelImportance.title')}
      cover={{
        imageUrl: sliderImageData.value,
        fieldName: 'slider.file',
        important: important.value,
        tip: t('shared.NewsPostForm.AddNewsPostRightPanelImportance.coverTip'),
      }}
      description={t(
        'shared.NewsPostForm.AddNewsPostRightPanelImportance.description',
      )}
      switchField={{
        fieldName: 'important',
        description: t(
          'shared.NewsPostForm.AddNewsPostRightPanelImportance.switchField',
        ),
      }}
      onChange={sliderImageData.onChange}
      {...commonProps}
    />
  );
};

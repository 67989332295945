import { zodResolver } from '@hookform/resolvers/zod';
import { useConfig } from 'entities/config/lib/useConfig';
import { EMatchType } from 'entities/matches/types';
import { useForm } from 'shared/hooks/useReactHookForm';
import { TMatchFormFields } from 'widgets/match-form/types/types';
import { matchFormValidationSchema } from 'widgets/match-form/ui/MatchForm/validationSchema';

const initialValues: TMatchFormFields = {
  tournament: '',
  tournamentSeason: '',
  tourId: undefined,
  startAt: '',
  stadiumId: '',
  homeTeam: '',
  guestTeam: '',
  ticketsUrl: undefined,
  matchType: EMatchType.Home,
};

export const useMatchForm = (values?: TMatchFormFields) => {
  const { config } = useConfig();

  return useForm<TMatchFormFields>({
    values: values || initialValues,
    mode: 'onBlur',
    resolver: zodResolver(matchFormValidationSchema(config!.whitelistDomains)),
  });
};

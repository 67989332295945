import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { Typography } from 'shared/components/Typography/Typography';
import { NavigationLinkWithMenu } from 'widgets/navigation/NavigationLink/NavigationLinkWithMenu';
import { TNavigationLink } from 'widgets/navigation/types';

import styles from './NavigationLink.module.css';

type TProps = {
  link: TNavigationLink;
};

export const NavigationLink = ({ link }: TProps) => {
  return (
    <>
      {link.children ? (
        <NavigationLinkWithMenu link={link} />
      ) : (
        <NavLink
          className={({ isActive }) => cn({ [styles.activeLink]: isActive })}
          to={link.route}
        >
          <div className={cn(styles.linkWrapper)}>
            <Typography className={cn(styles.linkLabel)} size={14}>
              {link.name}
            </Typography>
          </div>
        </NavLink>
      )}
    </>
  );
};

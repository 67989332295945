import { TFunction } from 'i18next';
import { ButtonElementForm } from 'pages/stories/edit/EditStory/components/forms/ButtonElementForm/ButtonElementForm';
import { CopyElementForm } from 'pages/stories/edit/EditStory/components/forms/CopyElementForm/CopyElementForm';
import { QuizElementForm } from 'pages/stories/edit/EditStory/components/forms/QuizElementForm/QuizElementForm';
import { TextElementForm } from 'pages/stories/edit/EditStory/components/forms/TextElementForm/TextElementForm';
import { TElementFormComponents } from 'pages/stories/edit/EditStory/components/forms/types';
import { VotingElementForm } from 'pages/stories/edit/EditStory/components/forms/VotingElementForm/VotingElementForm';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { Icon } from 'shared/components/Icon/Icon';

export const FORM_DEBOUNCE_DELAY = 300;

export const fontFamilyOptions: TSelectOption[] = [
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Playfair Display', label: 'Playfair Display' },
  { value: 'Roboto', label: 'Roboto' },
];

export const fontStyleOptions: TSelectOption[] = [
  { value: 'Italic', label: 'Italic' },
  { value: 'Regular', label: 'Regular' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Bold', label: 'Bold' },
];

export const fontSizeOptions: TSelectOption[] = [
  { value: 12, label: '12' },
  { value: 16, label: '16' },
  { value: 20, label: '20' },
  { value: 24, label: '24' },
];

export const fontStyleMap = {
  Italic: {
    fontStyle: 'italic',
  },
  Regular: {
    fontWeight: 400,
  },
  Medium: {
    fontWeight: 500,
  },
  Bold: {
    fontWeight: 700,
  },
};

export const alignmentOptions = [
  { value: 'left', label: <Icon kind="align-left" size="s" /> },
  { value: 'center', label: <Icon kind="align-center" size="s" /> },
  { value: 'right', label: <Icon kind="align-right" size="s" /> },
];

export const sizeOptions = (t: TFunction) => [
  { value: 'Small', label: t('content.story.small') },
  { value: 'Medium', label: t('content.story.medium') },
  { value: 'Large', label: t('content.story.large') },
];

const stub = () => null;
export const formComponentsMap: TElementFormComponents = {
  text: TextElementForm,
  button: ButtonElementForm,
  copy: CopyElementForm,
  swipeUp: stub,
  quiz: QuizElementForm,
  countdown: stub,
  voting: VotingElementForm,
  feedback: stub,
};

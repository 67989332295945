import cn from 'classnames';
import { TFilter } from 'feature/filters/types';
import i18n from 'i18next';
import { Button } from 'shared/components/Button/Button';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { Typography } from 'shared/components/Typography/Typography';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'shared/consts';
import { toBoolean } from 'shared/lib/toBoolean';
import { TDataItem } from 'widgets/elementsList/types';
import { TCommonListProps } from 'widgets/elementsList/ui/CommonList/types';
import { CommonListTable } from 'widgets/elementsList/ui/CommonListTable/CommonListTable';

import { EmptyListPage } from '../EmptyList/EmptyListPage';
import styles from './CommonList.module.css';

export const CommonList = <
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
>({
  className,
  configCreator,
  error,
  emptyComponentDescription = i18n.t('shared.Form.select.noOptions'),
  isLoading,
  title,
  createButtonProps: {
    content,
    onClick: onCreateButtonClick,
    icon: createButtonIcon = 'plus',
    iconPosition: createButtonIconPosition = 'before',
    iconSize: createButtonIconSize = 'xs',
    className: createButtonClassName,
    ...restCreateButtonProps
  },
  tableProps: { wrapperClassName, data, filter, ...tableProps },
  paginationProps: {
    totalItems,
    pageSize = DEFAULT_PAGINATION_PAGE_SIZE,
    className: paginationClassName,
    ...restPaginationProps
  },
  isEmpty,
}: TCommonListProps<T, SortBy, FilterType>) => {
  const columnsConfig = configCreator();

  const hasFilters =
    filter && Object.values(filter).every((value) => toBoolean(value));

  isEmpty ??= !hasFilters && (!data || (totalItems === 0 && !isLoading));

  if (isLoading) {
    return (
      <div className={styles.root}>
        <Loader className={styles.loading} showLabel />
      </div>
    );
  }

  if (error) {
    throw error;
  }

  if (isEmpty) {
    return (
      <EmptyListPage
        createButtonContent={content}
        message={emptyComponentDescription}
        onCreateButtonClick={onCreateButtonClick}
      />
    );
  }

  const isShowPagination = totalItems > 0 && totalItems > pageSize && !isLoading;

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.header}>
        <Typography size={20} tag="h1" weight="Bold">
          {title}
        </Typography>
        <Button
          className={cn(styles.createButton, createButtonClassName)}
          icon={createButtonIcon}
          iconPosition={createButtonIconPosition}
          iconSize={createButtonIconSize}
          onClick={onCreateButtonClick}
          {...restCreateButtonProps}
        >
          {content}
        </Button>
      </div>
      <div className={styles.wrapper}>
        <div className={wrapperClassName}>
          <CommonListTable
            columns={columnsConfig}
            data={data}
            filter={filter}
            {...tableProps}
          />
        </div>
        {isShowPagination && (
          <Pagination
            className={cn(styles.pagination, paginationClassName)}
            pageSize={pageSize}
            totalItems={totalItems}
            {...restPaginationProps}
          />
        )}
      </div>
    </div>
  );
};

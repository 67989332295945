import { useLocales } from 'entities/config/lib/useLocales';
import { EMatchType, TMatch } from 'entities/matches/types';
import { TMatchesFilterParams, TMatchesSortParams } from 'entities/matches/utils';
import { useFetchOpponentTeamsQuery } from 'entities/opponentTeams/queries';
import { useFetchTeamsQuery } from 'entities/teams/queries';
import i18n from 'i18next';
import { MatchesGuestTeamFilter } from 'pages/matches/list/filters/MatchesGuestTeamFilter';
import { MatchesHomeTeamFilter } from 'pages/matches/list/filters/MatchesHomeTeamFilter';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { ImageCell } from 'shared/components/SimpleTable/components/ImageCell/ImageCell';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { TColumnConfig } from 'widgets/elementsList/types';

import styles from '../MatchesPage.module.css';

export const useGetMatchesTeamColumns = (): Record<
  'guestTeamColumn' | 'homeTeamColumn',
  TColumnConfig<
    TMatch<'team' | 'opponentTeam'>,
    TMatchesSortParams,
    TMatchesFilterParams
  >
> => {
  const { defaultLocale } = useLocales();

  const {
    data: { data: opponentTeams = [] } = {},
    isLoading: isLoadingOpponentTeams,
  } = useFetchOpponentTeamsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const { data: { data: teams = [] } = {}, isLoading: isLoadingTeams } =
    useFetchTeamsQuery({
      pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
    });

  const allTeams = [...teams, ...opponentTeams];

  const isLoading = isLoadingTeams || isLoadingOpponentTeams;

  return {
    homeTeamColumn: {
      title: i18n.t('content.matches.homeTeam'),
      headCellProps: {
        filter: {
          filterName: 'home_team_id',
          getIsFiltered: (filter) =>
            toBoolean(filter.home_team_id && filter.home_team_id.length > 0),
          getFilterBadge: (filter) =>
            filter.home_team_id?.length
              ? toString(filter.home_team_id.length)
              : null,
          renderFilter: (props) => (
            <MatchesHomeTeamFilter
              isLoading={isLoading}
              teams={allTeams}
              {...props}
            />
          ),
        },
      },
      render: (item) => (
        <ImageCell
          className={styles.imageCell}
          previewWrapperClassName={styles.image}
          picProps={{
            placeholderBackgroundColor: 'transparent',
            src:
              item.matchType === EMatchType.Home
                ? item.team.picture.x1
                : item.opponentTeam.picture.x1,
            placeholder: {
              type: 'soccerTeam',
              size: 28,
            },
          }}
          title={
            item.matchType === EMatchType.Home
              ? item.team.translations[defaultLocale].name
              : item.opponentTeam.translations[defaultLocale].name
          }
        />
      ),
    },
    guestTeamColumn: {
      title: i18n.t('content.matches.guestTeam'),
      headCellProps: {
        filter: {
          filterName: 'guest_team_id',
          getIsFiltered: (filter) =>
            toBoolean(filter.guest_team_id && filter.guest_team_id.length > 0),
          getFilterBadge: (filter) =>
            filter.guest_team_id?.length
              ? toString(filter.guest_team_id.length)
              : null,
          renderFilter: (props) => (
            <MatchesGuestTeamFilter
              isLoading={isLoading}
              teams={allTeams}
              {...props}
            />
          ),
        },
      },
      render: (item) => (
        <ImageCell
          className={styles.imageCell}
          previewWrapperClassName={styles.image}
          picProps={{
            placeholderBackgroundColor: 'transparent',
            src:
              item.matchType === EMatchType.Home
                ? item.opponentTeam.picture.x1
                : item.team.picture.x1,
            placeholder: {
              type: 'soccerTeam',
              size: 28,
            },
          }}
          title={
            item.matchType === EMatchType.Home
              ? item.opponentTeam.translations[defaultLocale].name
              : item.team.translations[defaultLocale].name
          }
        />
      ),
    },
  };
};

import { RightPanelCollapseWrapper } from 'feature/rightPanel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Form/FormSelect/FormSelect';

import {
  TDefaultRightPanelProps,
  TRightPanelMatchProps,
  TSelectFieldProps,
} from '../../types';
import { MatchSelect } from '../MatchSelect/MatchSelect';

type TProps<T extends FieldValues> = {
  tournament: TSelectFieldProps<T>;
  season: TSelectFieldProps<T>;
  players: TSelectFieldProps<T>;
  teams?: TSelectFieldProps<T>;
  managers: TSelectFieldProps<T>;
  match: TRightPanelMatchProps;
  description: string;
  control: Control<T>;
};

export const CommonConnections = <T extends FieldValues>({
  control,
  tournament,
  season,
  description,
  players,
  teams,
  managers,
  match,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  const { t } = useTranslation();
  return (
    <RightPanelCollapseWrapper
      description={description}
      label={t('content.connection')}
      title={t('content.chooseConnection')}
      {...restProps}
    >
      {teams && (
        <FormSelect
          closeMenuOnSelect={false}
          control={control}
          fieldName={teams.fieldName}
          getMultiValueDescription={teams.getMultiValueDescription}
          hideSelectedOptions={false}
          isMulti
          isSearchable
          label={t('content.team.team')}
          placeholder={teams.placeholder}
          options={teams.data.map((t) => ({
            size: 's',
            ...t,
          }))}
        />
      )}
      <FormSelect
        control={control}
        fieldName={tournament.fieldName}
        isMulti={false}
        isSearchable
        label={t('content.tournamentLabel')}
        placeholder={tournament.placeholder}
        options={tournament.data.map((t) => ({
          size: 's',
          ...t,
        }))}
      />
      <FormSelect
        control={control}
        fieldName={season.fieldName}
        isDisabled={season.disabled}
        isMulti={false}
        isSearchable
        label={t('content.season')}
        placeholder={season.placeholder}
        options={season.data.map((s) => ({
          size: 's',
          ...s,
        }))}
      />
      <MatchSelect {...match} />
      <FormSelect
        closeMenuOnSelect={false}
        control={control}
        fieldName={players.fieldName}
        getMultiValueDescription={players.getMultiValueDescription}
        hideSelectedOptions={false}
        isMulti
        isSearchable
        label={t('content.player.player')}
        options={players.data}
        placeholder={players.placeholder}
      />
      <FormSelect
        closeMenuOnSelect={false}
        control={control}
        fieldName={managers.fieldName}
        getMultiValueDescription={managers.getMultiValueDescription}
        hideSelectedOptions={false}
        isMulti
        isSearchable
        label={t('content.manager.coachLabel')}
        options={managers.data}
        placeholder={managers.placeholder}
      />
    </RightPanelCollapseWrapper>
  );
};

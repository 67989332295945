import { TSeason } from 'entities/seasons/types';
import { TTeam } from 'entities/teams/types';
import { TTournament } from 'entities/tournaments/types';
import { TEntityId, TImageContent } from 'shared/types/common';
import { TWithInclude } from 'shared/types/withInclude';

export enum EMatchType {
  Guest = 'guest',
  Home = 'home',
}

export enum EMatchStatus {
  Upcoming = 'upcoming',
  Ended = 'ended',
  Canceled = 'canceled',
  Rescheduled = 'rescheduled',
  Live = 'live',
  Interrupted = 'interrupted',
}

type TBaseMatch = {
  id: TEntityId;
  broadcastUrl: string;
  reviewUrl?: string;
  ticketsUrl?: string;
  airlineTicketsUrl: string;
  matchType: EMatchType;
  matchPeriod: string;
  status: EMatchStatus;
  result: string;
  teamSchema: string;
  opponentTeamSchema: string;
  startAt: string;
  exactTime: boolean;
  endAt?: string;
  picture: TImageContent;
  tournamentSeason: string;
  stadium: string;
  tour: string;
  team: string;
  opponentTeam: string;
  coefficient: string;
  season: string;
  tournament: string;
  opponentTeamPenaltyScore: string;
  teamPenaltyScore: string;
  teamScore: string;
  tactic: string;
  opponentTactic: string;
  opponentTeamScore: string;
};

type TMatchIncludedProperties = {
  team: TTeam;
  opponentTeam: TTeam;
  season: TSeason;
  tournament: TTournament;
};

export type TMatchInclude = keyof TMatchIncludedProperties;

export type TMatch<Include extends TMatchInclude = never> = TWithInclude<
  TBaseMatch,
  TMatchIncludedProperties,
  Include
>;

export type TMatchDTO = {
  exactTime?: boolean;
  tourId?: TEntityId;
  ticketsUrl?: string;
  tournamentSeasonId?: TEntityId;
  startAt: string | Date;
  stadiumId: TEntityId;
  matchType: EMatchType;
  teamId: TEntityId;
  opponentTeamId: TEntityId;
  tacticId?: TEntityId | string;
  opponentTacticId?: TEntityId | string;
};

import i18n, { TFunction } from 'i18next';
import { TContentLang } from 'shared/types/common';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import { MAX_CLUB_DESCRIPTION_LENGTH } from 'widgets/ClubForm/constants';
import z from 'zod';

export const clubFormValidationSchema = (t: TFunction) =>
  z
    .object({
      locale: z.object({
        created: z.array(z.string()),
        selected: z.string(),
      }),
      whiteImage: fileZodValidation({
        errorMessage: t('shared.validation.preview'),
        isFileRequired: false,
      }),
      darkImage: fileZodValidation({
        errorMessage: t('shared.validation.preview'),
        isFileRequired: false,
      }),
    })
    .passthrough()
    .superRefine((fields, context) => {
      const validatedContent = descriptionValidation(
        fields.locale.selected as TContentLang,
      ).safeParse(fields);

      if (!validatedContent.success) {
        validatedContent.error.issues.forEach((issue) => {
          context.addIssue(issue);
        });
      }
    });

const descriptionValidation = (selected: TContentLang) =>
  z.object({
    description: z.object({
      [selected]: z.string().max(
        MAX_CLUB_DESCRIPTION_LENGTH,
        i18n.t('content.club.validation.descriptionMaxLength', {
          maxLength: MAX_CLUB_DESCRIPTION_LENGTH,
        }),
      ),
    }),
  });

import { useLocales } from 'entities/config/lib/useLocales';
import { useRef } from 'react';
import { TEditor, TEditorImage } from 'shared/components/Editor/types';
import { editorImageController } from 'shared/components/Editor/utils/image/editorImageController';
import { useModal } from 'shared/hooks/useModal';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';
import { toBoolean } from 'shared/lib/toBoolean';
import { Editor } from 'slate';

export const useWorkspaceImageContext = (editor: TEditor) => {
  const { defaultLocale } = useLocales();
  const changedImagePath = useRef([] as number[]);
  const croppedImagePath = useRef([] as number[]);
  const { openLoadImageModal, closeLoadImageModal, openedLoadImageModal } =
    useModal('LoadImage');

  const { openCropImageModal, closeCropImageModal, openedCropImageModal } =
    useModal('CropImage');

  const insertImage = (file: TEditorImage) => {
    editorImageController.insertImage(editor, file, defaultLocale);
  };

  const changeImage = (file: TEditorImage, path: number[]) => {
    editorImageController.changeImage(editor, path, file);
  };

  const closeLoadImageModalHandler = () => {
    closeLoadImageModal();
    changedImagePath.current = [];
  };

  const loadImage = async (files: File[]) => {
    const file = files[0];

    const url = await getBase64FromFile(file);
    if (changedImagePath.current.length) {
      changeImage(
        {
          url,
          filename: file.name,
        },
        changedImagePath.current,
      );
      changedImagePath.current = [];
    } else {
      insertImage({
        filename: file.name,
        url,
      });
    }
    closeLoadImageModalHandler();
  };

  const openChangeImageModal = (path: number[]) => {
    changedImagePath.current = path;
    openLoadImageModal();
  };

  const openCropImageModalHandler = (path: number[]) => {
    croppedImagePath.current = path;
    openCropImageModal();
  };

  const closeCropImageModalHandler = () => {
    closeCropImageModal();
    croppedImagePath.current = [];
  };

  const getImageElementFromPath = () => {
    if (!croppedImagePath.current.length) {
      return null;
    }

    const nodeEntry = Editor.node(editor, croppedImagePath.current);
    const imageElement = nodeEntry[0];

    if (!editorImageController.isImageElement(imageElement)) {
      return null;
    }

    return imageElement;
  };

  const getCroppingImageUrl = () => {
    const imageElement = getImageElementFromPath();

    if (!imageElement) {
      return null;
    }

    return imageElement.file.url;
  };

  const replaceCroppedImage = (url: string) => {
    const imageElement = getImageElementFromPath();
    if (!imageElement) {
      return;
    }

    changeImage(
      {
        url,
        filename: imageElement.file.filename,
      },
      croppedImagePath.current,
    );
    closeCropImageModal();
  };

  return {
    isOpenImageModal: openedLoadImageModal,
    isOpenCropImageModal: openedCropImageModal,
    croppingImageUrl: getCroppingImageUrl(),
    openLoadImageModal,
    closeLoadImageModal: closeLoadImageModalHandler,
    closeCropImageModal: closeCropImageModalHandler,
    openChangeImageModal,
    isEditImage: toBoolean(changedImagePath.current.length),
    openCropImageModal: openCropImageModalHandler,
    loadImage,
    replaceCroppedImage,
  };
};

export type TWorkspaceImageContext = ReturnType<typeof useWorkspaceImageContext>;

import {
  opponentMatchPlayerBaseUrl,
  opponentMatchPlayerButchesBaseUrl,
} from 'entities/opponentMatchPlayer/consts';
import {
  TApiMatchOpponentPlayer,
  TMatchOpponentPlayerDTO,
} from 'entities/opponentMatchPlayer/type';
import { apiRequestGenerator } from 'shared/api/apiRequestGenerator';

export const opponentMatchPlayerButchesApi =
  apiRequestGenerator<TMatchOpponentPlayerDTO>({
    baseUrl: opponentMatchPlayerButchesBaseUrl,
  });

export const opponentMatchPlayerApi = apiRequestGenerator<TApiMatchOpponentPlayer>({
  baseUrl: opponentMatchPlayerBaseUrl,
});

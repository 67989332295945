import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cn from 'classnames';
import { MODAL_CLASS_NAME } from 'shared/constants';
import { TWithChildren } from 'shared/types/common';

import styles from './OverlayModal.module.css';

export type TOverlayModalProps = {
  onClose?: () => void;
  opened: boolean;
  width?: number;
  innerClassName?: string;
};

export const OverlayModal = ({
  onClose,
  opened,
  width,
  innerClassName,
  children,
}: TOverlayModalProps & TWithChildren) => {
  const { refs, context } = useFloating({
    open: opened,
    onOpenChange: (open) => {
      if (!open) {
        onClose?.();
      }
    },
  });

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    escapeKey: false,
  });

  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  if (!opened) {
    return null;
  }

  return (
    <FloatingPortal>
      <FloatingOverlay className={styles.root} id={MODAL_CLASS_NAME} lockScroll>
        <FloatingFocusManager context={context} initialFocus={refs.floating} modal>
          <div
            ref={refs.setFloating}
            {...getFloatingProps()}
            className={cn(styles.inner, innerClassName)}
            style={width ? { maxWidth: `${width}px` } : undefined}
          >
            {children}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

import { noop } from 'lodash';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';
import { Typography } from 'shared/components/Typography/Typography';

export const CardsExample = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Typography size={20}>Карточка игрока</Typography>
      <Card
        type={ECardType.Player}
        entity={{
          name: 'First Name',
          lastName: 'Last name',
          picture:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaWijJYIlymw4n0lFctsEW9tl1RvDHPAIikA&s',
          position: 'position',
          number: 99,
        }}
        onClick={noop}
        onDelete={noop}
        onEdit={noop}
      />
      <br />
      <Typography size={20}>Карточка тренера</Typography>
      <Card
        type={ECardType.Manager}
        entity={{
          name: 'First Name',
          lastName: 'Last name',
          picture:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaWijJYIlymw4n0lFctsEW9tl1RvDHPAIikA&s',
          position: 'position',
        }}
        onClick={noop}
        onDelete={noop}
        onEdit={noop}
      />
      <br />
      <Typography size={20}>Карточка команды</Typography>
      <Card
        type={ECardType.Team}
        entity={{
          name: 'First Name',
          picture:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaWijJYIlymw4n0lFctsEW9tl1RvDHPAIikA&s',
        }}
        onClick={noop}
        onDelete={noop}
        onEdit={noop}
      />
      <Typography size={20}>Карточка баннера</Typography>
      <Card
        badge="Баннер"
        type={ECardType.Banner}
        entity={{
          name: 'Баннер 23.08.24',
          picture: 'https://i.postimg.cc/kgnKs5jR/Horizontal-Banner-Example.jpg',
        }}
        onClick={noop}
        onDelete={noop}
        onEdit={noop}
        onPreview={noop}
      />
      <Card
        badge="Баннер"
        type={ECardType.Banner}
        entity={{
          name: 'Баннер динамо москва один 23.08.24',
          picture: 'https://i.postimg.cc/P52ZRkc3/Vertical-Banner-Example.jpg',
        }}
        onClick={noop}
        onDelete={noop}
        onEdit={noop}
        onPreview={noop}
      />
    </div>
  );
};

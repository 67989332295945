import { useMutation } from '@tanstack/react-query';
import { clubContactsApi } from 'entities/club-contacts/api';
import { TClubContactDTO } from 'entities/club-contacts/types';

export const useDeleteClubContactMutation = () =>
  useMutation({
    mutationFn: clubContactsApi.deleteEntity,
  });

export const useEditClubContactMutation = () =>
  useMutation({
    mutationFn: clubContactsApi.editEntity<TClubContactDTO>,
  });

export const useCreateClubContactMutation = () =>
  useMutation({
    mutationFn: clubContactsApi.createEntity<TClubContactDTO>,
  });

import { zodResolver } from '@hookform/resolvers/zod';
import isEmpty from 'lodash/isEmpty';
import { BackButton } from 'pages/stories/edit/EditStory/components/forms/components/BackButton/BackButton';
import { FontSettings } from 'pages/stories/edit/EditStory/components/forms/components/FontSettings/FontSettings';
import { sizeOptions } from 'pages/stories/edit/EditStory/components/forms/constants';
import { useFormErrors } from 'pages/stories/edit/EditStory/components/forms/hooks/useFormErrors';
import {
  TCommonElementFormProps,
  TCopyElementFormValues,
} from 'pages/stories/edit/EditStory/components/forms/types';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { useTranslation } from 'react-i18next';
import { Divider } from 'shared/components/Divider';
import { Form, FormInput } from 'shared/components/Form';
import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';
import { FormSelect } from 'shared/components/Form/FormSelect/FormSelect';
import { useForm } from 'shared/hooks/useReactHookForm';

import styles from '../ElementForm.module.css';
import { PROMOCODE_MAX_LENGTH } from './constants';
import { copyElementValidationSchema } from './validationSchema';

export const CopyElementForm = ({
  selectedElement,
}: TCommonElementFormProps<'copy'>) => {
  const { t } = useTranslation();
  const { selectedLocale } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();

  const actionSubmit = (data: TCopyElementFormValues) => {
    dispatch({ type: 'updateSelectedStoryElementSettings', payload: data });
  };

  const {
    submit,
    formState: { errors },
    control,
    getValues,
  } = useForm<TCopyElementFormValues>({
    mode: 'onSubmit',
    values: selectedElement.translations[selectedLocale].settings,
    resolver: zodResolver(copyElementValidationSchema()),
    shouldFocusError: false,
    actionSubmit,
    validateOnLoad: true,
    submitOnChange: true,
  });

  useFormErrors({
    errors,
    selectedElementId: selectedElement.id,
    updateElement: () => {
      if (!isEmpty(errors)) {
        dispatch({
          type: 'updateSelectedStoryElementSettings',
          payload: getValues(),
        });
      }
    },
  });

  return (
    <div className={styles.root}>
      <Form action={submit} className={styles.formComponent}>
        <FormInput
          control={control}
          fieldName="title"
          inputClassName={styles.input}
          label={t('content.heading')}
          maxLength={20}
          placeholder={t('content.inputPlaceholder')}
        />
        <FormInput
          control={control}
          fieldName="promocode"
          inputClassName={styles.input}
          label={t('content.story.promocode')}
          maxLength={PROMOCODE_MAX_LENGTH}
          placeholder={t('content.story.inputPromocode')}
          required
        />
        <Divider />
        <FormSelect
          control={control}
          fieldName="size"
          inline
          inlineInputClassName={styles.formField}
          isSearchable={false}
          label={t('content.story.buttonSize')}
          menuWidth={116}
          options={sizeOptions(t)}
          placeholder="Select"
          selectWidth={116}
        />
        <ColorInput
          control={control}
          fieldName="fill"
          inline
          inlineInputClassName={styles.formField}
          label={t('content.story.textColor')}
          placeholder=""
        />
        <ColorInput
          control={control}
          fieldName="background"
          inline
          inlineInputClassName={styles.formField}
          label={t('content.story.colorInputLabel')}
          placeholder=""
        />
        <Divider />
        <FontSettings
          control={control}
          fontFamilyName="fontFamily"
          fontStyleName="fontStyle"
          withTitle
        />
      </Form>
      <BackButton disabled={!isEmpty(errors)} />
    </div>
  );
};

import { EditVideoRoute } from 'pages/video/edit';
import { AddVideoRoute } from 'pages/video/new';
import { VideoPage } from 'pages/video/VideoPage/VideoPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRecord } from 'shared/types/router';

export const VideoRoutes = {
  List: {
    path: routes.video,
    element: <VideoPage />,
    roles: DEFAULT_ROUTER_ROLES,
  },
  Add: AddVideoRoute,
  Edit: EditVideoRoute,
} satisfies TProtectedRecord;

import { useLocales } from 'entities/config/lib/useLocales';
import { useCreateSeasonMutation } from 'entities/seasons/mutations';
import { useFetchSeasonsQuery } from 'entities/seasons/queries';
import { useSeasonsColumnsConfig } from 'pages/settings/seasons/SeasonsPage/columnsConfig';
import { SeasonsActions } from 'pages/settings/seasons/SeasonsPage/SeasonsActions';
import { useTranslation } from 'react-i18next';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { useModal } from 'shared/hooks/useModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './SeasonsPage.module.css';

export const SeasonsPage = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const createSeason = useCreateSeasonMutation();
  const { openModal, closeModal, openedModal } = useModal();

  const {
    data: { data: seasons = [], meta: { page = 1, total = 0 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchSeasonsQuery({
    options: {
      cacheTime: 0,
    },
  });

  const onCreateSeason = (translations: TTranslations<string>) => {
    createSeason.mutate({
      translations: reduceTranslationObject(locales, (locale) => ({
        name: translations[locale],
      })),
    });
    closeModal();
  };
  return (
    <>
      <CommonList
        configCreator={useSeasonsColumnsConfig}
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.settings.seasons')}
        createButtonProps={{
          content: t('shared.create'),
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
        tableProps={{
          data: seasons,
          rowClassName: styles.rowGridTemplate,
          ActionComponent: SeasonsActions,
        }}
      />
      <LocalizationModal
        actionSubmit={onCreateSeason}
        closeModal={closeModal}
        isOpened={openedModal}
        subtitle={t('content.title')}
        title={t('content.settings.createSeason')}
        inputProps={{
          maxLength: 9,
          placeholder: t('content.inputPlaceholderText'),
          required: true,
          size: 's',
        }}
      />
    </>
  );
};

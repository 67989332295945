import { createDefaultElement } from 'shared/components/Editor/helpers';
import {
  EEditorElementType,
  TEditorImage,
  TEditorSliderElement,
} from 'shared/components/Editor/types';

export const createSliderElement = (files: TEditorImage[]) =>
  ({
    type: EEditorElementType.Slider,
    files,
    children: createDefaultElement(''),
  }) as TEditorSliderElement;

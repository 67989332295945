import cn from 'classnames';
import { useCallback } from 'react';
import { OptionProps } from 'react-select';
import { BaseCheckbox } from 'shared/components/Form/Checkbox/BaseCheckbox';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { useSelectContext } from 'shared/components/Form/Select/hooks';
import { TSelectOption, TSelectSize } from 'shared/components/Form/Select/types';
import { TCheckboxSize } from 'shared/components/Form/types';
import { toString } from 'shared/lib/toString';

import styles from '../Select.module.css';

const selectSizeToRadioCheckboxSizeMap: Record<TSelectSize, TCheckboxSize> = {
  small: 's',
  medium: 'm',
  large: 'm',
};

export const SelectOption = (props: OptionProps<TSelectOption>) => {
  const { isSelected, isMulti, data, selectOption, clearValue } = props;
  const { value, label, renderLabel } = data;
  const renderedLabel = renderLabel?.(label) ?? label;
  const { size: selectSize } = useSelectContext();
  const size = selectSize ? selectSizeToRadioCheckboxSizeMap[selectSize] : undefined;
  const onSelectRadio = useCallback(
    () => (isSelected ? clearValue() : selectOption(data)),
    [isSelected, clearValue, selectOption, data],
  );

  if (isMulti) {
    return (
      <BaseCheckbox
        key={value}
        checked={isSelected}
        className={cn(styles.checkbox, data.className)}
        label={renderedLabel}
        labelClassName={styles.checkboxLabel}
        size={size}
        onChange={() => {
          selectOption(data);
        }}
      />
    );
  }

  return (
    <RadioButton
      key={value}
      checked={isSelected}
      className={cn(styles.radioButton, data.className)}
      label={renderedLabel}
      name={toString(label)}
      size={size}
      value={value}
      onClick={onSelectRadio}
    />
  );
};

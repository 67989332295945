import { EEditorElementType, TEditor } from 'shared/components/Editor/types';
import { createEditorParagraphElement } from 'shared/components/Editor/utils/paragraph/createEditorParagraphElement';
import { TContentLang } from 'shared/types/common';
import { Element, Node, Transforms } from 'slate';

export const editorWithLinks = (editor: TEditor, defaultLocale: TContentLang) => {
  const { isInline, normalizeNode } = editor;

  editor.isInline = (element) => {
    return element.type === EEditorElementType.Link ? true : isInline(element);
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node)) {
      const children = Array.from(Node.children(editor, path));
      for (const [child, childPath] of children) {
        const isEmptyLink =
          Element.isElement(child) &&
          child.type === EEditorElementType.Link &&
          child.children[0].text === '';

        if (isEmptyLink) {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(
              editor,
              createEditorParagraphElement('', defaultLocale),
            );
          } else {
            Transforms.removeNodes(editor, { at: childPath });
          }
          return;
        }
      }
    }
    normalizeNode(entry);
  };

  return editor;
};

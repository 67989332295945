import cn from 'classnames';
import { memo } from 'react';
import { Pic } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './CountryLabel.module.css';

type TProps = {
  label: string;
  image: string;
  flagSize?: 's' | 'm';
};

export const CountryLabel = memo(({ image, label, flagSize = 's' }: TProps) => (
  <div className={styles.optionLabel}>
    <div className={cn(styles.countryFlag, styles[flagSize])}>
      <Pic
        alt={`country ${label}`}
        src={image}
        placeholder={{
          type: 'image',
        }}
      />
    </div>
    <Typography className={styles.text} color="var(--gray-500)" size={12}>
      {label}
    </Typography>
  </div>
));

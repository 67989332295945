import { FieldValues, useController } from 'react-hook-form';
import { Field } from 'shared/components/Form';
import { OldSelect } from 'shared/components/Form/OldSelect/OldSelect';
import { TFormSelectProps } from 'shared/components/Form/OldSelect/types';

export const OldFormSelect = <T extends FieldValues>({
  label,
  className,
  fieldName,
  control,
  rules,
  tip,
  required,
  disabled,
  labelClassName,
  onChange,
  ...props
}: TFormSelectProps<T>) => {
  const {
    field: { value, onChange: onChangeController },
  } = useController({
    control,
    name: fieldName,
    rules,
  });

  const onChangeHandler = (value: string | string[]) => {
    if (onChange) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange(value as any);
    }
    onChangeController(value);
  };

  return (
    <Field
      className={className}
      disabled={disabled}
      label={label}
      labelClassName={labelClassName}
      required={required}
      tip={tip}
    >
      <OldSelect
        name={fieldName}
        value={value}
        onChange={onChangeHandler}
        {...props}
      />
    </Field>
  );
};

import { TFilterProps, TImportanceFilter } from 'feature/filters/types';
import styles from 'feature/filters/ui/Filter.module.css';
import { TFunction } from 'i18next/typescript/t';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { RadioButton } from 'shared/components/Form/RadioButton';

type TImportanceRadioButtonIn = {
  value: 'in';
  name: 'Слайдер';
};

type TImportanceRadioButtonOut = {
  value: 'out';
  name: 'Без слайдера';
};

type TImportanceRadioButton = [TImportanceRadioButtonIn, TImportanceRadioButtonOut];

type TProps<FilterType extends { importance?: TImportanceFilter }> =
  TFilterProps<FilterType>;
const getValues = (t: TFunction): TImportanceRadioButton => [
  { value: 'in', name: t('shared.slider') },
  { value: 'out', name: t('shared.outSlider') },
];

export const ImportanceFilter = <
  FilterType extends { importance?: TImportanceFilter },
>({
  filter,
  setFilter,
}: TProps<FilterType>) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<TImportanceFilter>(
    filter?.importance ?? 'in',
  );

  const onSubmit = () => {
    setFilter({ ...filter, importance: filterValue });
  };
  const onClick = (_name: string, value: TImportanceFilter) => {
    setFilterValue(value);
  };

  return (
    <form className={styles.container} onSubmit={onSubmit}>
      {getValues(t).map((radio) => (
        <RadioButton
          key={radio.value}
          checked={filterValue === radio.value}
          className={styles.radio}
          label={radio.name}
          name={radio.name}
          value={radio.value}
          onChange={onClick}
        />
      ))}
      <Button htmlType="submit" onClick={onSubmit}>
        {t('shared.confirm')}
      </Button>
    </form>
  );
};

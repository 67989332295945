import axios from 'axios';
import { AuthRedirect } from 'feature/auth/ui/AuthRedirect/AuthRedirect';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import { Button } from 'shared/components/Button/Button';
import { Typography } from 'shared/components/Typography/Typography';
import { getDefaultErrorMessage, getDefaultErrorTitle } from 'shared/validation';
import { Navigation } from 'widgets/navigation/ui/Navigation/Navigation';

import styles from './ErrorPage.module.css';

type TErrorContent = {
  status?: number;
  title?: string;
  message: string;
};

const getStatusText = (): { [key: number]: string } =>
  ({
    401: i18n.t('content.errorAutorization'),
    403: i18n.t('content.errorRights'),
    404: i18n.t('content.errorNotFound'),
    500: i18n.t('content.errorServer'),
  }) as const;

const getErrorContent = (error: unknown): TErrorContent => {
  if (isRouteErrorResponse(error)) {
    const { status } = error;
    let message = getStatusText()[status];
    if (!message) {
      message = getDefaultErrorMessage();
    }

    return {
      status,
      message,
    };
  }

  if (axios.isAxiosError(error)) {
    const status = error?.status ?? error.response?.status;

    return {
      status,
      message: error.message,
    };
  }

  // TODO: Implement custom error classes: [WEB-1012]
  // It is assumed, that manually thrown errors will contain 'title' and 'message' properties
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  if (typeof error === 'object' && error !== null && error.title && error.message) {
    //@ts-ignore
    return { title: error.title, message: error.message };
  }
  /* eslint-enable */

  return {
    title: getDefaultErrorTitle(),
    message: getDefaultErrorMessage(),
  };
};

export const ErrorPage = () => {
  const { t } = useTranslation();
  const error = useRouteError();
  const navigate = useNavigate();
  const { status, title, message } = getErrorContent(error);

  return (
    <div className={styles.root}>
      <Navigation />
      <div className={styles.content}>
        {status && !title && (
          <Typography className={styles.errorStatus} weight="SemiBold">
            {status}
          </Typography>
        )}
        {!status && title && (
          <Typography className={styles.errorTitle}>{title}</Typography>
        )}
        <Typography size={20}>{message}</Typography>
        <Button
          className={styles.refreshButton}
          icon="refresh-ccw-01"
          onClick={() => navigate(0)}
        >
          {t('content.update')}
        </Button>
      </div>
      <AuthRedirect />
    </div>
  );
};

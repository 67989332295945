import cn from 'classnames';
import { EMatchStatus } from 'entities/matches/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';
import { TWithClassName } from 'shared/types/common';

import styles from './MatchStatus.module.css';

type TStatusColor =
  `var(--status-${'error' | 'draft' | 'interrupted' | 'completed' | 'published' | 'postponed'})`;

type TMatchStatus = {
  status: EMatchStatus;
} & TWithClassName;

type TMatchStatusTranslationKey =
  | 'content.matches.cancelled'
  | 'content.matches.ended'
  | 'content.matches.interrupted'
  | 'content.matches.live'
  | 'content.matches.upcoming'
  | 'content.matches.rescheduled';

const colorDictionary: Record<
  EMatchStatus,
  { color: TStatusColor; text: TMatchStatusTranslationKey }
> = {
  [EMatchStatus.Canceled]: {
    color: 'var(--status-error)',
    text: 'content.matches.cancelled',
  },
  [EMatchStatus.Ended]: {
    color: 'var(--status-draft)',
    text: 'content.matches.ended',
  },
  [EMatchStatus.Interrupted]: {
    color: 'var(--status-interrupted)',
    text: 'content.matches.interrupted',
  },
  [EMatchStatus.Live]: {
    color: 'var(--status-completed)',
    text: 'content.matches.live',
  },
  [EMatchStatus.Upcoming]: {
    color: 'var(--status-published)',
    text: 'content.matches.upcoming',
  },
  [EMatchStatus.Rescheduled]: {
    color: 'var(--status-postponed)',
    text: 'content.matches.rescheduled',
  },
};

export const MatchStatus = ({ status, className }: TMatchStatus) => {
  const { t } = useTranslation();
  const { color, text } = colorDictionary[status];

  return (
    <div className={cn(styles.textWrapper, className)} style={{ color }}>
      <div className={styles.circle} style={{ background: color }} />
      <Typography>{t(text)}</Typography>
    </div>
  );
};

import { useFetchMatchQuery } from 'entities/matches/queries';
import { EMatchType } from 'entities/matches/types';
import { MatchLayout } from 'feature/match-layout/MatchLayout';
import { useEditMatch } from 'pages/matches/edit/hooks/useEditMatch';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toString } from 'shared/lib/toString';
import { MatchForm } from 'widgets/match-form/ui/MatchForm/MatchForm';
import { useMatchForm } from 'widgets/match-form/ui/MatchForm/useMatchForm';

export const EditMatchPage = () => {
  const { t } = useTranslation();

  const { matchId } = useParams<{
    matchId: string;
  }>();

  const { data: match } = useFetchMatchQuery<'season' | 'tournament'>({
    id: toString(matchId),
    params: {
      include: ['season', 'tournament'],
    },
  });
  const { editMatch, isLoading } = useEditMatch();

  const values = {
    tournament: toString(match?.tournament.id) ?? '',
    tournamentSeason: match?.tournamentSeason ?? '',
    tourId: match?.tour ?? undefined,
    startAt: match?.startAt ? new Date(match.startAt) : '',
    stadiumId: match?.stadium ?? '',
    homeTeam:
      (match?.matchType === EMatchType.Home ? match?.team : match?.opponentTeam) ??
      '',
    guestTeam:
      (match?.matchType === EMatchType.Guest ? match?.team : match?.opponentTeam) ??
      '',
    ticketsUrl: match?.ticketsUrl ?? '',
    matchType: match?.matchType ?? EMatchType.Home,
  };

  const {
    getValues,
    control,
    formState: { isValid },
  } = useMatchForm(values);

  const onContinueHandler = async () => {
    const formData = getValues();

    editMatch(formData, toString(matchId));
  };

  return (
    <MatchLayout
      title={t('content.matches.editMatch')}
      confirmButtonProps={{
        onClick: onContinueHandler,
        children: t('shared.confirm'),
        isDisabled: !isValid,
        isLoading: isLoading,
      }}
    >
      <MatchForm control={control} />
    </MatchLayout>
  );
};

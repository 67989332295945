import { TEntityId } from 'shared/types/common';

const adBannerBaseUrl = 'ad/banners';

export const adBannerQueryKeys = {
  adBanners: 'adBanners',
};

export const adBannerApiKeys = {
  getAdBanner: (adBannerId: TEntityId) => `${adBannerBaseUrl}/${adBannerId}`,
  getAdBanners: adBannerBaseUrl,
  createAdBanner: adBannerBaseUrl,
  editAdBanner: (adBannerId: TEntityId) => `${adBannerBaseUrl}/${adBannerId}`,
  deleteAdBanner: (adBannerId: TEntityId) => `${adBannerBaseUrl}/${adBannerId}`,
};

import cn from 'classnames';
import { ECardType, TCardInformation } from 'shared/components/Card/types';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './Information.module.css';

export const CardBannerInformation = ({ type, entity }: TCardInformation) => {
  if (type !== ECardType.Banner) {
    return null;
  }

  const { name } = entity;

  return (
    <div className={cn(styles.wrapper, styles.wrapperTeam)}>
      <Typography className={styles.text} size={20} tag="p" weight="Bold">
        {name}
      </Typography>
    </div>
  );
};

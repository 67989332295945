import cn from 'classnames';
import i18n from 'i18next';
import { Button, TButtonProps } from 'shared/components/Button/Button';
import {
  IconButton,
  TIconButtonProps,
} from 'shared/components/IconButton/IconButton';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';
import { Typography } from 'shared/components/Typography/Typography';
import { TWithChildren } from 'shared/types/common';

import styles from './ActionModal.module.css';

export type TActionModalProps = {
  opened: boolean;
  title: string;
  onClose: () => void;
  confirmButtonProps: TButtonProps;
  cancelButtonProps?: TButtonProps;
  cancelText?: string;
  confirmText?: string;
  width?: number;
  contentClassName?: string;
  closeButtonProps?: Partial<TIconButtonProps>;
  showCloseButton?: boolean;
};

export const ActionModal = ({
  confirmText = i18n.t('shared.confirm'),
  onClose,
  title,
  cancelText = i18n.t('shared.cancel'),
  opened,
  width,
  cancelButtonProps,
  confirmButtonProps = {},
  contentClassName,
  children,
  closeButtonProps,
  showCloseButton = true,
}: TActionModalProps & TWithChildren) => {
  return (
    <OverlayModal opened={opened} width={width} onClose={onClose}>
      <div className={styles.root}>
        <div className={styles.header}>
          <Typography className={styles.title} size={16} weight="SemiBold">
            {title}
          </Typography>
          {showCloseButton && (
            <IconButton icon="x" onClick={onClose} {...closeButtonProps} />
          )}
        </div>
        <div className={cn(styles.content, contentClassName)}>{children}</div>
        <div className={styles.buttons}>
          <Button
            color="gray"
            kind="outlined"
            onClick={onClose}
            {...cancelButtonProps}
          >
            {cancelText}
          </Button>
          <Button {...confirmButtonProps}>{confirmText}</Button>
        </div>
      </div>
    </OverlayModal>
  );
};

import { matchesApiKeys } from 'entities/matches/consts';
import { TMatch, TMatchDTO, TMatchInclude } from 'entities/matches/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchMatches = <Include extends TMatchInclude = never>({
  params,
}: TFetchParams) =>
  get<TMatch<Include>[], TPaginatedMeta>(matchesApiKeys.getMatches, {
    params,
  }).then((response) => response.data);

export const fetchMatch = <Include extends TMatchInclude = never>(
  id: TEntityId,
  { params }: TFetchParams,
) =>
  get<TMatch<Include>>(matchesApiKeys.getMatch(id), {
    params,
  }).then((response) => response.data.data);

export const createMatch = (data: TMatchDTO) =>
  post<TMatch>(matchesApiKeys.createMatch, {
    match: data,
  }).then((response) => response.data.data);

export const editMatch = ({ id, data }: { id: TEntityId; data: TMatchDTO }) =>
  patch<TMatch>(matchesApiKeys.editMatch(id), {
    match: data,
  }).then((response) => response.data.data);

export const deleteMatch = (id: TEntityId) =>
  del(matchesApiKeys.deleteMatch(id)).then((response) => response.data.data);

import { ManagerCareerModal } from 'feature/managerCareerModal/ui/ManagerCareerModal/ManagerCareerModal';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';

export const ManagerCareerModalExample = () => {
  const { t } = useTranslation();
  const { openModal, closeModal, openedModal } = useModal();

  return (
    <div>
      <Button onClick={openModal}>{t('content.managers.openManagerModal')}</Button>
      <ManagerCareerModal
        actionSubmit={(values) => notify(JSON.stringify(values, undefined, 2))}
        closeModal={closeModal}
        isLoading={false}
        isOpened={openedModal}
        title={t('content.createCareer')}
      />
    </div>
  );
};

import { TAdLocation } from 'entities/ad/locations/model/types';
import { useLocales } from 'entities/config/lib/useLocales';
import { useEffect } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Form/FormSelect/FormSelect';
import { generateSelectedText } from 'shared/lib/generateSelectedText';
import { toString } from 'shared/lib/toString';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';

type TProps = {
  control: Control<TAdBannerFormFields>;
  locations: TAdLocation[];
  className?: string;
};

export const AdBannerFormLocations = ({ control, locations, className }: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const { setValue } = useFormContext<TAdBannerFormFields>();

  const options = locations.map((location) => ({
    value: toString(location.id),
    label: location.translations[defaultLocale].name,
  }));

  useEffect(() => {
    if (locations.length === 1) {
      setValue(
        'locations',
        locations.map((location) => toString(location.id)),
      );
    }
  }, [locations, setValue]);

  if (locations.length <= 1) {
    return null;
  }

  return (
    <FormSelect
      className={className}
      closeMenuOnSelect={false}
      control={control}
      fieldName="locations"
      hideSelectedOptions={false}
      isMulti
      label={t('content.adBanners.form.location.label')}
      options={options}
      placeholder={t('content.adBanners.form.location.placeholder.default')}
      required
      size="small"
      tip={t('content.adBanners.form.location.tip')}
      getMultiValueDescription={(values) =>
        generateSelectedText({
          count: values.length,
          defaultText: t('content.adBanners.form.location.placeholder.default'),
          singular: t('content.adBanners.form.location.placeholder.singular'),
          few: t('content.adBanners.form.location.placeholder.few'),
          many: t('content.adBanners.form.location.placeholder.many'),
          ruWordGender: 'male',
        })
      }
    />
  );
};

import { SocialNetworkModal } from 'feature/socialNetworkModal/ui/SocialNetworkModal/SocialNetworkModal';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { Typography } from 'shared/components/Typography/Typography';
import { TEntityId } from 'shared/types/common';
import { useCreateClubSocialNetwork } from 'widgets/ClubForm/hooks/useCreateClubSocialNetwork';
import { ClubSocialLinksTable } from 'widgets/ClubForm/ui/ClubSocialLinksTable';

import styles from './ClubSocialLinks.module.css';

type TProps = {
  clubId: TEntityId;
};

export const ClubSocialLinks = ({ clubId }: TProps) => {
  const { t } = useTranslation();
  const { createSocialNetworkModalProps, openCreateClubSocialNetworkModal } =
    useCreateClubSocialNetwork({
      clubId,
    });

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Typography color="var(--gray-500)" size={20} weight="Bold">
          {t('content.club.socialLinksLabel')}
        </Typography>
        <Button
          color="blue"
          icon="plus"
          iconPosition="before"
          iconSize="xs"
          kind="ghost"
          size="s"
          onClick={openCreateClubSocialNetworkModal}
        >
          {t('shared.add')}
        </Button>
      </div>
      <ClubSocialLinksTable clubId={clubId} />
      {createSocialNetworkModalProps.isOpened && (
        <SocialNetworkModal {...createSocialNetworkModalProps} />
      )}
    </div>
  );
};

import { LabelsPage } from 'pages/settings/labels/LabelsPage/LabelsPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const SettingsLabelsRoute = {
  element: <LabelsPage />,
  path: routes.labels,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';
import { Ref, useMemo } from 'react';
import { useToggle } from 'react-use';
import { DROPDOWN_OFFSET } from 'shared/components/constants';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { TCustomSimpleSelectProps } from 'shared/components/Form/types';
import { Icon } from 'shared/components/Icon/Icon';
import { TIconType } from 'shared/components/Icon/types';
import { useFloat } from 'shared/hooks/useFloat';

import styles from './SimpleSelect.module.css';

const getIconType = (icon?: TIconType, isOpen?: boolean): TIconType => {
  if (icon) {
    return icon;
  }
  if (isOpen) {
    return 'chevron-up';
  }
  return 'chevron-down';
};

export const CustomSelect = <O, V>(
  {
    containerRef,
    placeholder,
    onChange,
    controlClassName,
    options,
    labelResolver,
    className,
    value,
    valueResolver,
    selectedResolver = (option) => value === valueResolver(option),
    menuPlacement = 'bottom',
    menuWidth,
    icon,
    iconSize = 's',
  }: TCustomSimpleSelectProps<O, V>,
  _ref: Ref<HTMLDivElement>,
) => {
  const [isOpen, toggleOpened] = useToggle(false);

  const {
    trigger: { triggerRef, ...trigger },
    floating,
    elements,
  } = useFloat({
    isOpen,
    offset: DROPDOWN_OFFSET,
    onChange: toggleOpened,
    placement: menuPlacement,
    customOutsidePress: true,
  });

  const handleOptionClick = (option: O) => {
    if (onChange) {
      onChange(valueResolver(option));
    }
    toggleOpened(false);
  };

  const selectedOption = useMemo(() => {
    return options.find(selectedResolver);
  }, [options, selectedResolver]);

  return (
    <div className={cn(styles.root, className)}>
      <div
        ref={triggerRef}
        className={cn(styles.control, controlClassName, {
          [styles.controlActive]: isOpen,
        })}
        {...trigger}
      >
        <span>{selectedOption ? labelResolver(selectedOption) : placeholder}</span>
        <Icon kind={getIconType(icon, isOpen)} size={iconSize} />
      </div>
      {isOpen && (
        <FloatingPortal root={containerRef}>
          <Dropdown
            minWidth={menuWidth}
            width={elements.reference?.getBoundingClientRect().width}
            {...floating}
          >
            <div className={styles.optionsContainer}>
              {options.map((option, index) => (
                <RadioButton
                  key={index}
                  checked={selectedResolver(option)}
                  className={styles.option}
                  label={labelResolver(option)}
                  onClick={() => handleOptionClick(option)}
                />
              ))}
            </div>
          </Dropdown>
        </FloatingPortal>
      )}
    </div>
  );
};

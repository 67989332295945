import { noop } from 'lodash';
import styles from 'pages/uikit/UIKit.module.css';
import { CardButton } from 'shared/components/CardButton/CardButton';

export const CardButtonExample = () => {
  return (
    <div className={styles.buttonContain}>
      <CardButton title="title" onClick={noop} />
      <CardButton title="another title" onClick={noop} />
      <CardButton disabled={true} title="another title" onClick={noop} />
    </div>
  );
};

import { TClubContact } from 'entities/club-contacts/types';
import { useLocales } from 'entities/config/lib/useLocales';
import { ContactModal } from 'feature/contact-modal/ui/ContactModal';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { Table } from 'shared/components/SimpleTable';
import { Typography } from 'shared/components/Typography/Typography';
import { TEntityId, TGroupAction } from 'shared/types/common';
import { useDeleteClubContact } from 'widgets/ClubForm/hooks/useDeleteClubContact';
import { useEditClubContact } from 'widgets/ClubForm/hooks/useEditClubContact';

import styles from './ClubContacts/ClubContacts.module.css';

type TProps = {
  isActive: boolean;
  onIconClick: () => void;
  closeActiveItemId: () => void;
  clubContact: TClubContact;
  clubId: TEntityId;
};

export const ClubContactsRow = ({
  clubContact,
  closeActiveItemId,
  isActive,
  clubId,
  onIconClick,
}: TProps) => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();

  const { editModalProps, openEditClubContactModal } = useEditClubContact({
    clubContact,
    clubId,
    closeActiveItemId,
  });

  const { deleteModalProps, openDeleteClubContactModal } = useDeleteClubContact({
    clubContactId: clubContact.id,
    closeActiveItemId,
  });

  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditClubContactModal,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: openDeleteClubContactModal,
    },
  ];

  return (
    <Table.Row className={styles.row} isActive={isActive}>
      <Table.Cell>
        <Typography color="var(--gray-500)" size={12}>
          {clubContact.translations[defaultLocale].title}
        </Typography>
      </Table.Cell>
      <Table.Cell>
        <Actions
          actions={actions}
          isOpen={isActive}
          itemId={clubContact.id}
          onIconClick={onIconClick}
        />
        {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}

        {editModalProps.isOpened && <ContactModal {...editModalProps} />}
      </Table.Cell>
    </Table.Row>
  );
};

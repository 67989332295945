import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchTeamsQuery } from 'entities/teams/queries';
import { useMemo } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { FormSelect } from 'shared/components/Form/FormSelect/FormSelect';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  className?: string;
};

export const TeamSelect = <T extends FieldValues>({
  control,
  fieldName,
  className,
}: TProps<T>) => {
  const { t } = useTranslation();

  const { defaultLocale } = useLocales();
  const { data: { data: teams = [] } = {} } = useFetchTeamsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const teamOptions = useMemo(
    () =>
      teams.map<TSelectOption>((team) => ({
        value: toString(team.id),
        label: team.translations[defaultLocale].name,
        size: 's',
      })),
    [teams],
  );

  return (
    <FormSelect
      className={className}
      control={control}
      fieldName={fieldName}
      isMulti={false}
      isSearchable
      label={t('content.teams.determinationTeam')}
      options={teamOptions}
      placeholder={t('content.teams.chooseTeam')}
      required
    />
  );
};

import cn from 'classnames';
import { TAdSlot } from 'entities/ad/slots/model/types';
import { FieldNamesMarkedBoolean, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TButtonProps } from 'shared/components/Button/Button';
import { FormInput, SwitchField } from 'shared/components/Form';
import { Typography } from 'shared/components/Typography/Typography';
import { toString } from 'shared/lib/toString';
import { compareIds } from 'shared/utils/entityIds';
import {
  MAX_AD_BANNER_FORM_LINK_LENGTH,
  MAX_AD_BANNER_FORM_NAME_LENGTH,
} from 'widgets/adBannerForm/constants';
import { useAdBannerForm } from 'widgets/adBannerForm/hooks/useAdBannerForm';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';
import { AdBannerFormBlock } from 'widgets/adBannerForm/ui/AdBannerFormBlock/AdBannerFormBlock';
import { AdBannerFormHeader } from 'widgets/adBannerForm/ui/AdBannerFormHeader/AdBannerFormHeader';
import { AdBannerFormImage } from 'widgets/adBannerForm/ui/AdBannerFormImage';
import { AdBannerFormLocations } from 'widgets/adBannerForm/ui/AdBannerFormLocations/AdBannerFormLocations';
import { AdBannerFormSlots } from 'widgets/adBannerForm/ui/AdBannerFormSlots/AdBannerFormSlots';

import styles from './AdBannerForm.module.css';

type TProps = {
  slots: TAdSlot[];
  confirmButtonProps?: TButtonProps;
  values?: TAdBannerFormFields;
  onSubmit: (
    fields: TAdBannerFormFields,
    dirtyFields: FieldNamesMarkedBoolean<TAdBannerFormFields>,
  ) => void;
  isEdit?: boolean;
};

export const AdBannerForm = ({
  slots,
  confirmButtonProps,
  onSubmit,
  values,
  isEdit = false,
}: TProps) => {
  const { t } = useTranslation();
  const methods = useAdBannerForm({
    defaultSlotId: values?.slotId ?? toString(slots[0].id),
    values,
  });

  const { control, handleSubmit, formState } = methods;

  const selectedSlotId = useWatch({ control, name: 'slotId' });

  const selectedSlot = slots.find(({ id }) => compareIds(id, selectedSlotId));

  const onSubmitHandler = (fields: TAdBannerFormFields) => {
    onSubmit(fields, formState.dirtyFields);
  };

  return (
    <FormProvider {...methods}>
      <form className={styles.root} onSubmit={handleSubmit(onSubmitHandler)}>
        <AdBannerFormHeader
          confirmButtonProps={confirmButtonProps}
          isEdit={isEdit}
        />
        <div className={styles.formWrapper}>
          <div className={styles.form}>
            <AdBannerFormBlock title={t('content.adBanners.form.firstBlock.title')}>
              <AdBannerFormSlots slots={slots} />
              <FormInput
                className={styles.field}
                control={control}
                fieldName="name"
                label={t('content.adBanners.form.name.title')}
                maxLength={MAX_AD_BANNER_FORM_NAME_LENGTH}
                placeholder={t('content.adBanners.form.name.placeholder')}
                required
                showMaxLength={false}
                size="s"
                tip={t('content.adBanners.form.name.tip')}
              />
            </AdBannerFormBlock>

            <AdBannerFormBlock title={t('content.adBanners.form.secondBlock.title')}>
              <AdBannerFormLocations
                className={styles.field}
                control={control}
                locations={selectedSlot?.locations ?? []}
              />
              {selectedSlot?.hasMainBanner && (
                <SwitchField
                  control={control}
                  fieldName="mainBanner"
                  label={t('content.adBanners.form.mainBanner.label')}
                  fieldProps={{
                    className: styles.switch,
                    tip: t('content.adBanners.form.mainBanner.tip'),
                  }}
                />
              )}
              <AdBannerFormImage selectedSlot={selectedSlot} />
              <div className={cn(styles.linkWrapper, styles.field)}>
                <FormInput
                  control={control}
                  fieldName="link"
                  inputClassName={styles.field}
                  label={t('content.adBanners.form.link.label')}
                  maxLength={MAX_AD_BANNER_FORM_LINK_LENGTH}
                  placeholder={t('content.adBanners.form.link.placeholder')}
                  required
                  showMaxLength={false}
                  size="s"
                />
                <Typography color="var(--dark-blue-250)" size={12}>
                  {t('content.adBanners.form.link.hint')}
                </Typography>
              </div>
            </AdBannerFormBlock>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

import { TManagerCareerModalProps } from 'feature/managerCareerModal/types';
import { TranslationInputs } from 'feature/managerCareerModal/ui/TranslationInputs/TranslationInputs';
import { useManagerCareerModal } from 'feature/managerCareerModal/useManagerCareerModal';
import {
  MANAGER_CAREER_JOB_TITLE_LENGTH,
  MANAGER_CAREER_SEASON_MAX_LENGTH,
  MANAGER_CAREER_TEAM_NAME_MAX_LENGTH,
} from 'feature/managerCareerModal/validationSchema';
import { UploadImage } from 'feature/rightPanel/ui/UploadImage/UploadImage';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { Typography } from 'shared/components/Typography/Typography';
import { getImageSettings } from 'shared/constants';
import { TContentLang } from 'shared/types/common';

import styles from './ManagerCareerModal.module.css';

export const ManagerCareerModal = (props: TManagerCareerModalProps) => {
  const { t } = useTranslation();
  const { actionModalProps, locales, control } = useManagerCareerModal(props);

  return (
    <ActionModal {...actionModalProps}>
      <TranslationInputs
        control={control}
        locales={locales}
        maxLength={MANAGER_CAREER_TEAM_NAME_MAX_LENGTH}
        pathResolver={(locale: TContentLang) => `translations.${locale}.teamName`}
        title={t('content.manager.clubName')}
      />
      <TranslationInputs
        className={styles.section}
        control={control}
        locales={locales}
        maxLength={MANAGER_CAREER_JOB_TITLE_LENGTH}
        pathResolver={(locale: TContentLang) => `translations.${locale}.jobTitle`}
        title={t('content.manager.jobTitleName')}
      />
      <div className={styles.section}>
        <Typography size={14} tag="h3" weight="SemiBold">
          {t('content.manager.gameData')}
        </Typography>
        <div className={styles.inputWrapper}>
          <FormInput
            control={control}
            fieldName="season"
            inputClassName={styles.inputForm}
            label={t('content.season')}
            maxLength={MANAGER_CAREER_SEASON_MAX_LENGTH}
            placeholder={t('content.seasonPeriod')}
            required
            size="s"
          />
          <UploadImage
            aspectRatio={getImageSettings(t).managerCareer.teamPicture.aspectRatio}
            control={control}
            fieldName="teamPicture"
            hint={getImageSettings(t).managerCareer.teamPicture.hint}
            label={t('content.manager.logo')}
            modalTitle={t('shared.imageSetting')}
            required
            showIcon={false}
            imageRequirementText={t('shared.imageRequirementText.warningUpload', {
              size: '1:1',
              formats: 'JPEG, JPG, PNG, WebP',
            })}
          />
        </div>
      </div>
    </ActionModal>
  );
};

import { TSocialNetwork } from 'entities/socialNetworks/types';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

type TEntitySocialNetwork<Entity> = {
  entitySocialLinks: (Entity & { socialNetworkId: TEntityId })[];
  socialNetworks: TSocialNetwork[];
};

export const combineEntitySocialNetworks = <Entity>({
  entitySocialLinks,
  socialNetworks,
}: TEntitySocialNetwork<Entity>): TSocialNetworkWith<Entity>[] => {
  const serializedSocialNetworks = socialNetworks.reduce((acc, network) => {
    acc.set(toString(network.id), network);

    return acc;
  }, new Map<string, TSocialNetwork>());

  return entitySocialLinks.map<TSocialNetworkWith<Entity>>(
    ({ socialNetworkId, ...entity }) => {
      const socialNetwork = serializedSocialNetworks.get(
        toString(socialNetworkId),
      ) as TSocialNetwork;

      return {
        ...(entity as Entity),
        socialNetwork,
      };
    },
  );
};

export type TSocialNetworkWith<Entity> = Omit<Entity, 'socialNetworkId'> & {
  socialNetwork: TSocialNetwork;
};

import { TMatchesFilterParams } from 'entities/matches/utils';
import { TApiFilterArray } from 'shared/api/types';

export const getMatchesPageFilter = (
  filter: TMatchesFilterParams | null,
): TApiFilterArray | undefined => {
  if (!filter) {
    return undefined;
  }

  const entries = Object.entries(filter).filter(
    ([_, value]) => value && value.length,
  );

  if (entries.length === 0) {
    return undefined;
  }

  return [
    {
      operation: 'and',
      conditions: entries.map(([field, value]) => ({
        field,
        predicate: 'in',
        value: value?.join(',') ?? '',
      })),
    },
  ];
};

import { PlaceholderProps } from 'react-select';
import { TSelectOption } from 'shared/components/Form/Select/types';

type TProps = PlaceholderProps<TSelectOption>;

export const SelectPlaceholder = (props: TProps) => {
  const { getClassNames, innerProps, children, hasValue } = props;

  if (hasValue) {
    return null;
  }

  return (
    <div className={getClassNames('placeholder', props)} {...innerProps}>
      {children}
    </div>
  );
};

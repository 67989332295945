import i18n from 'i18next';
import { getTextMinLengthMessage } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import z from 'zod';

export const MANAGER_CAREER_SEASON_MAX_LENGTH = 9;
export const MANAGER_CAREER_TEAM_NAME_MAX_LENGTH = 255;
export const MANAGER_CAREER_JOB_TITLE_LENGTH = 255;

const createTranslationsSchema = (locales: TContentLang[]) => {
  return reduceTranslationObject(locales, () =>
    z.object({
      teamName: z
        .string()
        .min(1, getTextMinLengthMessage())
        .max(
          MANAGER_CAREER_TEAM_NAME_MAX_LENGTH,
          `${i18n.t('content.manager.teamNameNotMore')} ${MANAGER_CAREER_TEAM_NAME_MAX_LENGTH} ${i18n.t('content.symbols')}`,
        ),
      jobTitle: z
        .string()
        .min(1, getTextMinLengthMessage())
        .max(
          MANAGER_CAREER_JOB_TITLE_LENGTH,
          `${i18n.t('content.manager.jobNameNotMore')} ${MANAGER_CAREER_JOB_TITLE_LENGTH} ${i18n.t('content.symbols')}`,
        ),
    }),
  );
};

export const managerCareerModalValidationSchema = (locales: TContentLang[]) =>
  z.object({
    translations: z.object({ ...createTranslationsSchema(locales) }),
    teamPicture: fileZodValidation({
      errorMessage: i18n.t('shared.validation.preview'),
    }),
    season: z
      .string()
      .min(1, getTextMinLengthMessage())
      .max(
        MANAGER_CAREER_SEASON_MAX_LENGTH,
        `${i18n.t('content.manager.periodSeasonNotMore')} ${MANAGER_CAREER_SEASON_MAX_LENGTH} ${i18n.t('shared.validation.preview')}`,
      ),
  });

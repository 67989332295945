import { TFilter } from 'feature/filters/types';
import { ReactElement, useMemo, useState } from 'react';
import { createTable } from 'shared/components/SimpleTable';
import {
  TCommonCellProps,
  TSortableCellProps,
} from 'shared/components/SimpleTable/components/THeadCell/THeadCell';
import { TEntityId } from 'shared/types/common';
import { TSortOptions } from 'shared/types/table';
import { compareIds } from 'shared/utils/entityIds';
import { TColumnConfig, TDataItem, TTableActions } from 'widgets/elementsList/types';
import { CommonListTableHeadCell } from 'widgets/elementsList/ui/CommonListTable/components/CommonListTableHeadCell';
import { CommonListTableHeadCellFilterable } from 'widgets/elementsList/ui/CommonListTable/components/CommonListTableHeadCellFilterable';
import { CommonListTableHeadCellSortable } from 'widgets/elementsList/ui/CommonListTable/components/CommonListTableHeadCellSortable';

import styles from './CommonListTable.module.css';

export type TCommonListTableProps<
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
> = {
  data: T[];
  columns: TColumnConfig<T, SortBy, FilterType>[];
  rowClassName: string;
  headRowClassName?: string;
  onFilter?: (args: FilterType) => void;
  filter?: FilterType | null;
  sortOptions?: TSortOptions<SortBy>;
  onSort?: (sortOptions: TSortOptions<SortBy>) => void;
  ActionComponent: (props: TTableActions<T>) => ReactElement;
  actionsCellClassName?: string;
};

export const CommonListTable = <
  T extends TDataItem,
  SortBy extends string,
  FilterType extends TFilter,
>({
  data,
  columns,
  rowClassName,
  headRowClassName,
  sortOptions,
  filter,
  onSort,
  onFilter,
  ActionComponent,
  actionsCellClassName,
}: TCommonListTableProps<T, SortBy, FilterType>) => {
  const [activeItemId, setActiveItemId] = useState<TEntityId | null>(null);
  const Table = useMemo(() => createTable<SortBy>(), []);

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <Table.Head>
          <Table.Row className={headRowClassName || rowClassName} hoverable={false}>
            {columns.map((column, index) => {
              const { headCellProps, ...restProps } = column;

              if (column.headCellProps?.filter) {
                return (
                  <CommonListTableHeadCellFilterable
                    key={index}
                    {...restProps}
                    filter={filter}
                    headCellProps={headCellProps}
                    tableComponent={Table}
                    onFilter={onFilter}
                  />
                );
              }

              if (column.headCellProps?.sort && sortOptions && onSort) {
                return (
                  <CommonListTableHeadCellSortable
                    key={index}
                    {...restProps}
                    headCellProps={headCellProps as TSortableCellProps<SortBy>}
                    sortOptions={sortOptions}
                    tableComponent={Table}
                    onSort={onSort}
                  />
                );
              }

              return (
                <CommonListTableHeadCell
                  key={index}
                  {...column}
                  headCellProps={headCellProps as TCommonCellProps}
                  tableComponent={Table}
                />
              );
            })}
            <Table.HeadCell />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map((item) => {
            const isActive = compareIds(activeItemId, item.id);

            return (
              <Table.Row key={item.id} className={rowClassName} isActive={isActive}>
                {columns.map((column, index) => (
                  <Table.Cell key={index} className={column?.cellClassName}>
                    {column.render(item)}
                  </Table.Cell>
                ))}
                <Table.Cell key="actions" className={actionsCellClassName}>
                  <ActionComponent
                    closeActiveItemId={() => setActiveItemId(null)}
                    isActive={isActive}
                    item={item}
                    onIconClick={() => {
                      setActiveItemId(isActive ? null : item.id);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

import { useLocales } from 'entities/config/lib/useLocales';
import { EMatchType } from 'entities/matches/types';
import { useFetchOpponentTeamsQuery } from 'entities/opponentTeams/queries';
import { useFetchTeamsQuery } from 'entities/teams/queries';
import { useEffect, useMemo } from 'react';
import { Control, useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';
import { compareIds } from 'shared/utils/entityIds';
import { TMatchFormFields } from 'widgets/match-form/types/types';

export const useTeamSelectorsMatch = (control: Control<TMatchFormFields>) => {
  const { t } = useTranslation();

  const selectHomeTeam = useWatch({
    control,
    name: 'homeTeam',
  });

  const selectGuestTeam = useWatch({
    control,
    name: 'guestTeam',
  });

  const {
    field: { onChange: onChangeEMatchType },
  } = useController({
    control,
    name: 'matchType',
  });

  const { defaultLocale } = useLocales();

  const { data: opponentTeamsData } = useFetchOpponentTeamsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });
  const { data: teamsData } = useFetchTeamsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const teams = useMemo(() => {
    const opponentTeams = opponentTeamsData?.data ?? [];
    const homeTeams = teamsData?.data ?? [];
    return [...opponentTeams, ...homeTeams];
  }, [opponentTeamsData, teamsData]);

  const homeTeamsOptions: TSelectOption[] = useMemo(() => {
    return teams.reduce<TSelectOption[]>((acc, team) => {
      if (!compareIds(team.id, selectGuestTeam)) {
        acc.push({
          value: toString(team.id),
          label: team.translations[defaultLocale]?.name ?? '',
        });
      }
      return acc;
    }, []);
  }, [opponentTeamsData, teamsData, selectGuestTeam, defaultLocale]);

  const guestTeamsOptions: TSelectOption[] = useMemo(() => {
    return teams.reduce<TSelectOption[]>((acc, team) => {
      if (!compareIds(team.id, selectHomeTeam)) {
        acc.push({
          value: toString(team.id),
          label: team.translations[defaultLocale]?.name || '',
        });
      }
      return acc;
    }, []);
  }, [opponentTeamsData, teamsData, selectHomeTeam, defaultLocale]);

  useEffect(() => {
    const isHomeType = teamsData?.data.some((team) =>
      compareIds(selectHomeTeam, team.id),
    );

    onChangeEMatchType(isHomeType ? EMatchType.Home : EMatchType.Guest);
  }, [selectHomeTeam, teamsData]);

  return {
    homeTeams: {
      options: homeTeamsOptions,
      placeholder: t('content.matches.chooseTeam'),
    },
    guestTeams: {
      options: guestTeamsOptions,
      placeholder: t('content.matches.chooseTeam'),
    },
  };
};

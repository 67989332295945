import { components } from 'react-select';
import { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import { TSelectOption } from 'shared/components/Form/Select/types';

type TProps<
  TIsMulti extends boolean,
  TGroup extends GroupBase<TSelectOption>,
> = MenuPortalProps<TSelectOption, TIsMulti, TGroup>;

export const SelectMenuPortal = <
  TIsMulti extends boolean,
  TGroup extends GroupBase<TSelectOption>,
>(
  props: TProps<TIsMulti, TGroup>,
) => {
  const { children, getClassNames, innerProps } = props;

  return (
    <components.MenuPortal
      className={getClassNames('menuPortal', {
        position: 'absolute',
        offset: 4,
        rect: { left: 4, width: 100 },
      })}
      {...props}
    >
      <div {...innerProps}>{children}</div>
    </components.MenuPortal>
  );
};

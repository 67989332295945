import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchPlayerPositionsQuery } from 'entities/playerPositions/query';
import { useMemo } from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Form/FormSelect/FormSelect';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  className?: string;
};

export const PlayerPositionSelect = <T extends FieldValues>({
  control,
  fieldName,
  className,
}: TProps<T>) => {
  const { t } = useTranslation();

  const { defaultLocale } = useLocales();
  const { data } = useFetchPlayerPositionsQuery();

  const playerPositionOptions = useMemo(
    () =>
      data?.map<TSelectOption>((position) => ({
        value: toString(position.id),
        label: position.translations[defaultLocale].jobTitle,
        size: 's',
      })) ?? [],
    [data],
  );

  return (
    <FormSelect
      className={className}
      control={control}
      fieldName={fieldName}
      isMulti={false}
      isSearchable
      label={t('content.position')}
      options={playerPositionOptions}
      placeholder={t('content.choosePosition')}
      required
    />
  );
};

import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { Divider } from 'shared/components/Divider';
import { useModal } from 'shared/hooks/useModal';
import { TContentLang } from 'shared/types/common';

import { LocaleControl } from './LocaleControl';
import styles from './LocalesControl.module.css';

export type TLocalesControlProps = {
  selectedLocale: TContentLang;
  onLocaleSelect: (locale: TContentLang) => void;
  onLocaleAdd: (locale: TContentLang) => void;
  onLocaleDelete: (locale: TContentLang) => void;
  createdLocales: TContentLang[];
  className?: string;
  disabled?: boolean;
};

export const LocalesControl = ({
  selectedLocale,
  onLocaleSelect,
  onLocaleDelete,
  onLocaleAdd,
  createdLocales,
  disabled = false,
  className,
}: TLocalesControlProps) => {
  const { t } = useTranslation();
  const { openDeleteModal, closeDeleteModal, openedDeleteModal } =
    useModal('Delete');

  const [localeToDelete, setLocaleToDelete] = useState<TContentLang | null>(null);
  const { locales } = useLocales();

  const handleLocaleDelete = (locale: TContentLang) => {
    openDeleteModal();
    setLocaleToDelete(locale);
  };

  const onConfirm = () => {
    if (localeToDelete) {
      onLocaleDelete(localeToDelete);
    }

    closeDeleteModal();
  };
  return (
    <div className={cn(styles.localesControl, className)}>
      {locales.map((locale, index) => (
        <Fragment key={locale}>
          <LocaleControl
            isCreated={createdLocales.includes(locale)}
            isDisabled={disabled}
            isSelected={selectedLocale === locale}
            locale={locale}
            onLocaleAdd={onLocaleAdd}
            onLocaleDelete={handleLocaleDelete}
            onLocaleSelect={onLocaleSelect}
          />
          {index !== locales.length - 1 &&
            locales?.[index + 1] !== selectedLocale &&
            selectedLocale !== locale && (
              <Divider className={styles.divider} variant="vertical" />
            )}
        </Fragment>
      ))}
      <ConfirmationModal
        description={t('shared.LocalesControl.description')}
        isOpened={openedDeleteModal}
        title={t('shared.LocalesControl.title')}
        onClose={closeDeleteModal}
        onConfirm={onConfirm}
      />
    </div>
  );
};

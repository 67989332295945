import { useLocales } from 'entities/config/lib/useLocales';
import { useDeleteTeamMutation } from 'entities/teams/mutations';
import { TTeam } from 'entities/teams/types';
import { TeamModal } from 'feature/teamModal/TeamModal';
import { useEditTeam } from 'pages/team/TeamsPage/hooks/useEditTeam';
import { useTranslation } from 'react-i18next';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';

type TProps = {
  team: TTeam;
  onClick: () => void;
};

export const TeamCard = ({ team, onClick }: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const { mutateAsync: deleteTeam, isLoading } = useDeleteTeamMutation();

  const { openDeleteModal, closeDeleteModal, openedDeleteModal } =
    useModal('Delete');

  const onDeleteHandler = () => {
    deleteTeam(team.id, {
      onError: () => notify(t('content.validation.notifyDeleteTeam')),
    });
  };

  const {
    editTeam,
    isLoading: isLoadingEditTeam,
    openModal,
    isOpenModal,
    closeModal,
    initialValues,
  } = useEditTeam({ team });

  return (
    <>
      <Card
        type={ECardType.Team}
        entity={{
          name: team.translations[defaultLocale].name,
          picture: team.picture.x3,
        }}
        onClick={onClick}
        onDelete={openDeleteModal}
        onEdit={openModal}
      />
      <TeamModal
        actionSubmit={editTeam}
        closeModal={closeModal}
        initialValues={initialValues}
        isLoading={isLoadingEditTeam}
        isOpened={isOpenModal}
        title={t('content.team.edit')}
      />
      <ConfirmationModal
        description={t('content.team.warningDelete')}
        isLoading={isLoading}
        isOpened={openedDeleteModal}
        title={t('content.team.notifyDelete')}
        onClose={closeDeleteModal}
        onConfirm={onDeleteHandler}
      />
    </>
  );
};

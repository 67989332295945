import { EditMatchRoute } from 'pages/matches/edit';
import { MatchesPageRoute } from 'pages/matches/list';
import { NewMatchRoute } from 'pages/matches/new';
import { TacticRoute } from 'pages/matches/tactic';
import { TProtectedRecord } from 'shared/types/router';

export const MatchesRoutes = {
  List: MatchesPageRoute,
  New: NewMatchRoute,
  Edit: EditMatchRoute,
  Tactic: TacticRoute,
} satisfies TProtectedRecord;

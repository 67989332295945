import { useFetchOpponentMatchPlayersQuery } from 'entities/opponentMatchPlayer/query';
import { useFetchOpponentPlayersQuery } from 'entities/opponentPlayers/queries';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { getApiFilters } from 'shared/lib/apiFilters';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

export const useFetchOpponentMatchPlacementPlayers = ({
  matchId,
  teamOpponentId,
}: {
  matchId: TEntityId;
  teamOpponentId: TEntityId;
}) => {
  const { data: { data: matchPlayers = [] } = {} } =
    useFetchOpponentMatchPlayersQuery({
      defaultPagination: { pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE },
      filter: getApiFilters({
        name: 'match_id',
        type: 'eq',
        value: toString(matchId),
      }),
    });

  const { data: { data: players = [] } = {} } = useFetchOpponentPlayersQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
    filter: getApiFilters({
      name: 'team_id',
      type: 'eq',
      value: toString(teamOpponentId),
    }),
  });

  return { matchPlayers, players };
};

import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchSocialNetworksQuery } from 'entities/socialNetworks/queries';
import { SocialNetworkLabel } from 'feature/socialNetworkModal/ui/SocialNetworkLabel/SocialNetworkLabel';
import { ReactNode, useMemo } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Form/FormSelect/FormSelect';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';
import { compareIds } from 'shared/utils/entityIds';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
};

export const SocialNetworkSelect = <T extends FieldValues>({
  control,
  fieldName,
}: TProps<T>) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const {
    field: { value: selectedSocialNetworkId },
  } = useController({
    control,
    name: fieldName,
  });

  const { data: { data: socialNetworks = [] } = {} } = useFetchSocialNetworksQuery();

  const socialNetworkOptions = useMemo(
    () =>
      socialNetworks.map<TSelectOption>((socialNetwork) => ({
        value: toString(socialNetwork.id),
        label: socialNetwork.translations[defaultLocale].name,
        renderLabel: (label: string | ReactNode) => (
          <SocialNetworkLabel image={socialNetwork.picture.x3} label={label} />
        ),
      })),
    [socialNetworks],
  );

  const socialNetworkPlaceholder = useMemo(() => {
    if (selectedSocialNetworkId && socialNetworks.length) {
      const selectedSocialNetwork = socialNetworks.find((c) =>
        compareIds(c.id, selectedSocialNetworkId),
      );

      return (
        <SocialNetworkLabel
          key={selectedSocialNetwork!.id}
          image={selectedSocialNetwork!.picture.x3}
          label={toString(selectedSocialNetwork!.translations[defaultLocale].name)}
        />
      );
    }

    return t('content.chooseIcon');
  }, [selectedSocialNetworkId, socialNetworks]);

  return (
    <FormSelect
      control={control}
      fieldName={fieldName}
      isMulti={false}
      label={t('content.linkIcon')}
      options={socialNetworkOptions}
      placeholder={socialNetworkPlaceholder}
      required
    />
  );
};

import { useFetchAdSlots } from 'entities/ad/slots/model/queries';
import { useEditAdBanner } from 'pages/adBanners/edit/hooks/useEditAdBanner';
import { useGetAdBannerFormValues } from 'pages/adBanners/edit/hooks/useGetAdBannerFormValues';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { toString } from 'shared/lib/toString';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';
import { AdBannerForm } from 'widgets/adBannerForm/ui/AdBannerForm/AdBannerForm';

export const EditAdBannerPage = () => {
  const { bannerId } = useParams<{ bannerId: string }>();

  const {
    isLoading: isLoadingAdBanner,
    values,
    isError,
    error,
  } = useGetAdBannerFormValues();

  const { data: { data: slots = [] } = {}, isLoading: isLoadingAdSlots } =
    useFetchAdSlots(
      {
        pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
      },
      { cacheTime: 0 },
    );

  const { isEditingBanner, editAdBanner } = useEditAdBanner();

  const onConfirmHandler = (
    fields: TAdBannerFormFields,
    dirtyFields: FieldNamesMarkedBoolean<TAdBannerFormFields>,
  ) =>
    editAdBanner({
      adBannerId: toString(bannerId),
      fields,
      initialValues: values,
      dirtyFields,
    });

  if (isError) {
    throw error;
  }

  if (isLoadingAdSlots || isLoadingAdBanner) {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  return (
    <AdBannerForm
      isEdit
      slots={slots}
      values={values}
      confirmButtonProps={{
        isLoading: isEditingBanner,
      }}
      onSubmit={onConfirmHandler}
    />
  );
};

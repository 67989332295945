import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import { labelsApi } from 'entities/labels/api';
import { labelsQueryKeys } from 'entities/labels/consts';
import { TLabelDTO } from 'entities/labels/types';
import { TEntityId } from 'shared/types/common';

export const useCreateLabelsMutation = () => {
  return useMutation({
    mutationFn: ({ data }: { data: TLabelDTO }) =>
      labelsApi.createEntity({ label: data }),
    onSuccess: () => {
      return queryClient.invalidateQueries([labelsQueryKeys.labels]);
    },
  });
};

export const useEditLabelsMutation = () => {
  return useMutation({
    mutationFn: (data: { id: TEntityId; data: TLabelDTO }) =>
      labelsApi.editEntity(data),
    onSuccess: () => {
      return queryClient.invalidateQueries([labelsQueryKeys.labels]);
    },
  });
};

export const useDeleteLabelsMutation = () => {
  return useMutation({
    mutationFn: labelsApi.deleteEntity,
    onSuccess: () => {
      return queryClient.invalidateQueries([labelsQueryKeys.labels]);
    },
  });
};

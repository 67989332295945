import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/components/Button/Button';
import { Icon } from 'shared/components/Icon/Icon';
import { Typography } from 'shared/components/Typography/Typography';
import { useFloat } from 'shared/hooks/useFloat';
import styles from 'widgets/navigation/NavigationLink/NavigationLink.module.css';
import { TLinkWithChildren } from 'widgets/navigation/types';

type TProps = {
  link: TLinkWithChildren;
};

export const NavigationLinkWithMenu = ({ link }: TProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const {
    trigger: { triggerRef, ...trigger },
    floating,
  } = useFloat({
    isOpen: openMenu,
    offset: -8,
    onChange: setOpenMenu,
    placement: 'bottom',
  });

  const currentMenuIsActive = link.children.some((link) =>
    location.pathname.includes(link.route),
  );

  return (
    <>
      <div
        ref={triggerRef}
        className={cn(styles.linkWrapper, {
          [styles.activeLinkWrapper]: openMenu,
          [styles.selectedLinkWrapper]: currentMenuIsActive,
        })}
        {...trigger}
      >
        <Typography className={cn(styles.linkLabel)} size={14}>
          {link.name}
        </Typography>
        <Icon className={styles.icon} kind="chevron-down" size="s" />
      </div>
      {openMenu && (
        <FloatingPortal>
          <div className={styles.children} {...floating}>
            {link.children.map((child) => (
              <NavLink
                key={child.name}
                to={child.route}
                onClick={() => {
                  setOpenMenu(false);
                }}
              >
                <Button color="blue" kind="ghost" size="s">
                  {child.name}
                </Button>
              </NavLink>
            ))}
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  UseDismissProps,
  useFloating,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react';
import React from 'react';

type TProps = {
  isOpen: boolean;
  offset?: number;
  onChange: (isOpen: boolean) => void;
  placement: Placement;
  customOutsidePress?: UseDismissProps['outsidePress'];
};

const defaultOutsidePressHandler = (event: MouseEvent) =>
  !(event.target as Element)?.closest('[role="dialog"]');

export const useFloat = ({
  placement,
  offset: offsetPadding,
  onChange,
  isOpen,
  customOutsidePress = defaultOutsidePressHandler,
}: TProps) => {
  const { refs, context, floatingStyles, ...other } = useFloating({
    open: isOpen,
    placement,
    onOpenChange: onChange,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetPadding),
      flip({ padding: offsetPadding }),
      shift({ padding: offsetPadding }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    escapeKey: false,
    outsidePress: customOutsidePress,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);
  const referenceProps = getReferenceProps() as {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
  } & Record<string, unknown>;

  const triggerRef = useMergeRefs([refs.setReference]);
  const dropdownRef = useMergeRefs([refs.setFloating]);

  return {
    trigger: {
      triggerRef,
      ...referenceProps,
    },
    floating: {
      ref: dropdownRef,
      style: floatingStyles,
      ...getFloatingProps(),
    },
    ...other,
  };
};

import cn from 'classnames';
import { components, MenuListProps } from 'react-select';
import { TSelectOption } from 'shared/components/Form/Select/types';

import styles from '../Select.module.css';

type TProps = MenuListProps<TSelectOption>;

export const SelectMenuList = (props: TProps) => {
  const { children, getClassNames, ...restProps } = props;

  return (
    <components.MenuList
      {...restProps}
      className={cn(styles.optionsContainer, getClassNames('menuList', props))}
      getClassNames={getClassNames}
    >
      {children}
    </components.MenuList>
  );
};

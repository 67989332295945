import {
  EEditorElementType,
  TEditor,
  TMatchTEditorElement,
} from 'shared/components/Editor/types';
import { toBoolean } from 'shared/lib/toBoolean';
import { Editor, Element, Location, Range, Transforms } from 'slate';

export const editorController = {
  isActiveElement(editor: TEditor, type: EEditorElementType) {
    const { selection } = editor;
    if (!selection) {
      return false;
    }

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === type,
      }),
    );

    return toBoolean(match);
  },

  unwrapBlockElements(editor: TEditor) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        n.type === EEditorElementType.BulletedList,
      split: true,
    });
  },

  isEmptySelection(editor: TEditor) {
    const { selection } = editor;
    return (
      selection?.focus.offset === 0 &&
      selection?.anchor.offset === 0 &&
      Range.isCollapsed(selection)
    );
  },

  getNode(editor: TEditor, type?: EEditorElementType, location?: Location) {
    return Editor.nodes(editor, {
      match: (n) => {
        if (type) {
          return Element.isElement(n) && n.type === type;
        }

        return Element.isElement(n);
      },
      at: location,
    });
  },

  getElement<T extends EEditorElementType>(
    editor: TEditor,
    type: T,
    location?: Location,
  ) {
    const [node] = this.getNode(editor, type, location);

    return node?.[0] as TMatchTEditorElement<T> | undefined;
  },
};

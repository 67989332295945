import { fontStyleMap } from 'pages/stories/edit/EditStory/components/forms/constants';
import { TElementProps } from 'pages/stories/edit/EditStory/types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './VotingElement.module.css';

export const VotingElement = ({ settings }: TElementProps<'voting'>) => {
  const { t } = useTranslation();

  const { title, fontStyle, fontFamily, fill, background } = settings;
  const fontStyles = fontStyleMap[fontStyle];

  return (
    <div className={styles.root}>
      <div
        className={styles.question}
        style={{
          backgroundColor: background,
        }}
      >
        <Typography
          size={16}
          style={{
            fontFamily,
            color: fill,
            ...fontStyles,
          }}
        >
          {title}
        </Typography>
      </div>
      <div className={styles.answers}>
        <Typography size={24} weight="Bold">
          {t('content.yes')}
        </Typography>
        <Typography size={24} weight="Bold">
          {t('content.no')}
        </Typography>
      </div>
    </div>
  );
};

import { FloatingPortal, useMergeRefs } from '@floating-ui/react';
import cn from 'classnames';
import React, { HTMLAttributes, ReactNode, useRef } from 'react';
import { DROPDOWN_OFFSET } from 'shared/components/constants';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { Icon } from 'shared/components/Icon/Icon';
import { TCell } from 'shared/components/SimpleTable/components/TCell';
import { useFloat } from 'shared/hooks/useFloat';
import { TSortOptions } from 'shared/types/table';

import { useNextSortDirection } from './hooks/useNextSortDirection';
import styles from './THeadCell.module.css';

export type TCommonCellProps = HTMLAttributes<HTMLDivElement>;

export type TSortableCellProps<SortBy extends string> = {
  sort?: {
    sortParam?: SortBy;
    sortOptions?: TSortOptions<SortBy>;
    onSort?: (sortOptions: TSortOptions<SortBy>) => void;
  };
  filter?: never;
};

export type TFilterableProps = {
  sort?: never;
  filter?: {
    isFilterOpen?: boolean;
    isFiltered?: boolean;
    filterWidth?: number;
    setFilterOpen?: (open: boolean) => void;
    renderFilter?: ReactNode;
  };
};

export type TTableHeadCellProps<SortBy extends string> = TCommonCellProps &
  (TSortableCellProps<SortBy> | TFilterableProps);

export const THeadCell = <SortBy extends string>({
  children,
  className,
  sort,
  filter,
  ...other
}: TTableHeadCellProps<SortBy>) => {
  const { sortParam, sortOptions, onSort } = sort ?? {};
  const {
    isFilterOpen = false,
    isFiltered,
    filterWidth = 204,
    setFilterOpen,
    renderFilter,
  } = filter ?? {};
  const iconRef = useRef<HTMLButtonElement | null>(null);
  const nextSortDirection = useNextSortDirection({
    sortDirection: sortOptions?.direction,
  });
  const handleSort = () => {
    if (iconRef.current) {
      iconRef.current?.blur();
    }
    if (onSort && sort && sortParam) {
      onSort({ sortBy: sortParam, direction: nextSortDirection });
    }
  };
  const isSortParamsEqualsToOptions = sortOptions?.sortBy === sortParam;

  const handleFilterClick = (isOpen: boolean) => {
    if (iconRef.current) {
      iconRef.current?.blur();
    }

    if (!setFilterOpen) {
      return;
    }

    return setFilterOpen(isOpen);
  };

  const {
    trigger: { triggerRef, onClick, ...trigger },
    floating,
  } = useFloat({
    isOpen: isFilterOpen,
    offset: DROPDOWN_OFFSET,
    onChange: handleFilterClick,
    placement: 'bottom',
  });

  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    onClick(e);
    handleSort();
  };

  const ref = useMergeRefs([triggerRef, iconRef]);

  return (
    <>
      <TCell
        {...other}
        className={cn(
          styles.cell,
          {
            [styles.active]:
              isFilterOpen || isFiltered || (sort && isSortParamsEqualsToOptions),
          },
          className,
        )}
      >
        <div
          {...trigger}
          className={styles.cellWrapper}
          ref={ref}
          onClick={onClickHandler}
        >
          {children}
          {sort && (
            <Icon
              ref={iconRef}
              size="xs"
              className={cn(styles.icon, {
                [styles.active]: isSortParamsEqualsToOptions,
              })}
              kind={
                isSortParamsEqualsToOptions && sortOptions?.direction === 'desc'
                  ? 'chevron-up'
                  : 'chevron-down'
              }
            />
          )}
          {filter && (
            <Icon
              kind="filter-lines"
              size="xs"
              className={cn(styles.icon, {
                [styles.active]: isFilterOpen,
              })}
            />
          )}
        </div>
      </TCell>
      {isFilterOpen && (
        <FloatingPortal>
          <Dropdown width={filterWidth} {...floating}>
            {renderFilter}
          </Dropdown>
        </FloatingPortal>
      )}
    </>
  );
};

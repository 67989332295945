import { TMatchPlayer, TMatchPlayerDTO } from 'entities/matchPlayer/type';
import { TMatchOpponentPlayerDTO } from 'entities/opponentMatchPlayer/type';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';

export const mapEditMatchPlayerToDTO = (
  matchId: TEntityId,
  matchPlayer: TMatchPlayer,
): TMatchPlayerDTO | TMatchOpponentPlayerDTO => {
  return {
    matchId: toString(matchId),
    row: matchPlayer.row,
    col: matchPlayer.col,
    playerId: matchPlayer.playerId,
    captain: matchPlayer.captain ?? false,
    reserve: matchPlayer.reserve ?? false,
    goalkeeper: matchPlayer.goalkeeper ?? false,
  };
};

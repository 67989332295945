import { EAdBannerType, TAdBanner } from 'entities/ad/banners/model/types';
import { useLocales } from 'entities/config/lib/useLocales';
import { useDeleteAdBanner } from 'pages/adBanners/list/hooks/useDeleteAdBanner';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { ImageModal } from 'shared/components/ImageModal/ImageModal';
import { useModal } from 'shared/hooks/useModal';
import { routes } from 'shared/routes';

type TProps = {
  adBanner: TAdBanner;
};

export const AdBannerCard = ({ adBanner }: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const navigate = useNavigate();
  const { openedPreviewModal, closePreviewModal, openPreviewModal } =
    useModal('Preview');
  const {
    deleteAdBanner,
    isDeletingBanner,
    openedDeleteModal,
    openDeleteModal,
    closeDeleteModal,
  } = useDeleteAdBanner(adBanner.id);

  const onPreviewClick = () => {
    openPreviewModal();
  };

  const onEditClick = () => {
    navigate(routes.editAdBanner.as(adBanner.id));
  };

  return (
    <>
      <Card
        key={adBanner.id}
        badge={adBanner.slot.translations[defaultLocale].name}
        type={ECardType.Banner}
        entity={{
          name: adBanner.translations[defaultLocale].name,
          picture: adBanner.content.x3,
        }}
        onDelete={openDeleteModal}
        onEdit={onEditClick}
        onPreview={
          adBanner.type === EAdBannerType.Image ? onPreviewClick : undefined
        }
      />
      <ImageModal
        imageUrl={adBanner.content.x3}
        isShow={openedPreviewModal}
        onClose={closePreviewModal}
      />
      <ConfirmationModal
        description={t('shared.warningDelete')}
        isLoading={isDeletingBanner}
        isOpened={openedDeleteModal}
        title={t('content.adBanners.page.deleteBannerTitle')}
        onClose={closeDeleteModal}
        onConfirm={deleteAdBanner}
      />
    </>
  );
};

import cn from 'classnames';
import { CSSProperties, MouseEvent, useCallback, useMemo } from 'react';
import { CardInformation } from 'shared/components/Card/CardInformationPart/CardInformation';
import { ECardType, TCardProps } from 'shared/components/Card/types';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Pic } from 'shared/components/Pic/Pic';
import { TPicPlaceholder } from 'shared/components/Pic/type';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './Card.module.css';

export const CARD_WIDTH = 330;

const PIC_OBJECT_FIT_BY_CARD_TYPE_MAP: Record<
  ECardType,
  CSSProperties['objectFit']
> = {
  [ECardType.Team]: 'contain',
  [ECardType.Banner]: 'contain',
  [ECardType.Player]: 'contain',
  [ECardType.OpponentPlayer]: 'contain',
  [ECardType.Manager]: 'contain',
};

export const Card = ({
  onEdit,
  onClick,
  onDelete,
  onPreview,
  badge,
  ...props
}: TCardProps) => {
  const onDeleteHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onDelete?.();
    },
    [onDelete],
  );

  const onEditHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onEdit?.();
    },
    [onEdit],
  );

  const onPreviewHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onPreview?.();
    },
    [onPreview],
  );

  const isPlayerPlaceholder =
    props.type === ECardType.Player ||
    props.type === ECardType.Manager ||
    props.type === ECardType.OpponentPlayer;

  const imagePlaceholder = useMemo<TPicPlaceholder>(() => {
    if (props.type === ECardType.Banner) {
      return { type: 'image', size: 'l' };
    }
    if (isPlayerPlaceholder) {
      return { type: 'player', size: 'l' };
    }
    return { type: 'soccerTeam', size: 120 };
  }, [props.type, isPlayerPlaceholder]);

  return (
    <div
      className={styles.cardWrapper}
      style={{ width: `${CARD_WIDTH}px` }}
      onClick={onClick}
    >
      <div className={styles.imageBlock}>
        {badge && (
          <div className={styles.badge}>
            <Typography color="var(--dark-blue-250)" size={12} weight="SemiBold">
              {badge}
            </Typography>
          </div>
        )}
        <div className={styles.buttonsWrapper}>
          {onPreview && (
            <IconButton
              className={styles.button}
              icon="eye"
              iconSize="s"
              onClick={onPreviewHandler}
            />
          )}
          {onEdit && (
            <IconButton
              className={styles.button}
              icon="edit-02"
              iconSize="s"
              onClick={onEditHandler}
            />
          )}
          {onDelete && (
            <IconButton
              className={styles.button}
              icon="trash"
              iconSize="s"
              onClick={onDeleteHandler}
            />
          )}
        </div>
        <Pic
          alt={props.entity.name}
          objectFit={PIC_OBJECT_FIT_BY_CARD_TYPE_MAP[props.type] || 'cover'}
          placeholder={imagePlaceholder}
          src={props.entity.picture}
          className={cn(styles.image, {
            [styles.teamImage]: props.type === ECardType.Team,
          })}
          placeholderBackgroundColor={
            props.type === ECardType.Team ? 'transparent' : undefined
          }
        />
      </div>
      <CardInformation {...props} />
    </div>
  );
};

import { useLocales } from 'entities/config/lib/useLocales';
import { TPlayerSocialNetworkItem } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/types';
import { useTranslation } from 'react-i18next';
import { Pic } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './PlayerSocialNetworksPage.module.css';

export const usePlayerSocialNetworksColumnsConfig: TConfigCreator<
  TPlayerSocialNetworkItem
> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();

  return [
    {
      title: t('content.title'),
      render: (item) => (
        <>
          <div className={styles.icon}>
            <Pic
              alt={`social network ${item.socialNetwork.translations[defaultLocale].name}`}
              src={item.socialNetwork.picture.x3}
              placeholder={{
                type: 'image',
                size: 'xs',
              }}
            />
          </div>
          <Typography className={styles.text} color="var(--gray-500)">
            {item.socialNetwork.translations[defaultLocale].name}
          </Typography>
        </>
      ),
    },
  ];
};

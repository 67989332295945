import { initial } from 'lodash';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { BaseButton } from 'shared/components/BaseButton';
import { Swatch } from 'shared/components/ColorPicker/components/SavedColors/Swatch';
import { INITIAL_SWATCHES } from 'shared/components/ColorPicker/constants';
import { Typography } from 'shared/components/Typography/Typography';
import { ELocalStorageKeys } from 'shared/constants';

import styles from './SavedColors.module.css';

type TProps = {
  selectedColor: string;
  onColorSelect: (color: string) => void;
};

export const SavedColors = ({ selectedColor, onColorSelect }: TProps) => {
  const { t } = useTranslation();
  const [swatches, setSwatches] = useLocalStorage<string[]>(
    ELocalStorageKeys.swatches,
  );
  const addButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!swatches) {
      setSwatches(INITIAL_SWATCHES);
    }
  }, []);

  const handleAddColor = () => {
    if (addButtonRef && addButtonRef.current) {
      addButtonRef.current?.blur();
    }
    const withoutLastColor = initial(swatches);
    setSwatches([selectedColor, ...withoutLastColor]);
  };

  if (!swatches) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography className={styles.title} weight="Bold">
          {t('shared.ColorPicker.SavedColors.title')}:
        </Typography>
        {/*TODO: Add this variant to button component: [WEB-1009] */}
        <BaseButton
          className={styles.addButton}
          ref={addButtonRef}
          onClick={handleAddColor}
        >
          <Typography weight="Bold">+ {t('shared.add')}:</Typography>
        </BaseButton>
      </div>
      <div className={styles.swatchesContainer}>
        {swatches.map((swatch, index) => (
          <Swatch
            key={`${swatch}-${index}`}
            color={swatch}
            isSelected={swatch === selectedColor}
            onClick={onColorSelect}
          />
        ))}
      </div>
    </div>
  );
};

import { SeasonsPage } from 'pages/settings/seasons/SeasonsPage/SeasonsPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const SettingsSeasonsRoute = {
  element: <SeasonsPage />,
  path: routes.seasons,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

import { useController } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { useModal } from 'shared/hooks/useModal';

type TUseNewsWorkspacePanelUnPublish = Partial<
  UseFormReturn<TEditNewsPostFields>
> & {
  isEdit: boolean;
  isPublish: boolean;
  editNewsPost: (data: TEditNewsPostFields) => Promise<void>;
};

export const useNewsWorkspacePanelUnPublish = ({
  control,
  handleSubmit,
  editNewsPost,
  isEdit,
  isPublish,
}: TUseNewsWorkspacePanelUnPublish) => {
  const {
    openConfirmUnpublishModal,
    closeConfirmUnpublishModal,
    openedConfirmUnpublishModal,
  } = useModal('ConfirmUnpublish');

  const { field } = useController({
    control,
    name: 'publishDate',
  });

  if (!isEdit || !isPublish) {
    return null;
  }

  const onConfirmUnpublish = () => {
    closeConfirmUnpublishModal();
    field.onChange(undefined);
    handleSubmit?.(editNewsPost)();
  };

  return {
    isOpenConfirmUnpublishModal: openedConfirmUnpublishModal,
    onClickUnpublishBtn: openConfirmUnpublishModal,
    onCloseUnpublish: closeConfirmUnpublishModal,
    onConfirmUnpublish,
  };
};

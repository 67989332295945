import { Alert } from 'shared/components/Alert/Alert';
import { Typography } from 'shared/components/Typography/Typography';

export const AlertExample = () => {
  return (
    <div>
      <Typography size={24} tag="h2">
        Alert
      </Typography>
      <Alert
        icon="alert-triangle"
        message="this is a messsage"
        title="Hello"
        variant="green"
      />
    </div>
  );
};

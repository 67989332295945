import { ReactNode } from 'react';
import { Pic } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './SocialNetworkLabel.module.css';

type TProps = {
  label: string | ReactNode;
  image: string;
};

export const SocialNetworkLabel = ({ image, label }: TProps) => (
  <div className={styles.optionLabel}>
    <div className={styles.icon}>
      <Pic
        alt={`social network ${label}`}
        src={image}
        placeholder={{
          type: 'image',
        }}
      />
    </div>
    <Typography className={styles.text} color="var(--gray-500)" size={12}>
      {label}
    </Typography>
  </div>
);

import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import {
  createTournament,
  deleteTournament,
  editTournament,
} from 'entities/tournaments/api';
import { tournamentQueryKeys } from 'entities/tournaments/consts';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';

export const useCreateTournamentMutation = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: createTournament,
    onSuccess: () => {
      return queryClient.invalidateQueries([tournamentQueryKeys.tournaments]);
    },
    onError: () => {
      notify(t('content.settings.notifyTournament'), { type: 'error' });
    },
  });
};

export const useEditTournamentMutation = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: editTournament,
    onError: () => {
      notify(t('content.settings.notifyEditTournament'), { type: 'error' });
    },
  });
};

export const useDeleteTournamentMutation = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteTournament,
    onError: () => {
      notify(t('content.settings.notifyDeleteTournament'), { type: 'error' });
    },
  });
};

import { useLocales } from 'entities/config/lib/useLocales';
import { EEditorElementType } from 'shared/components/Editor/types';
import { NewsPostPreviewSlider } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/NewsPostPreviewSlider/NewsPostPreviewSlider';
import { Pic } from 'shared/components/Pic/Pic';
import { TWorkspaceElement } from 'shared/components/Workspace/types';
import { TContentLang } from 'shared/types/common';

import styles from '../AddNewsPostPreview.module.css';

export const AddNewsPostPreviewSliderElement = ({
  element,
  lang,
}: {
  element: TWorkspaceElement;
  lang: TContentLang;
}) => {
  const { defaultLocale } = useLocales();

  if (element.type !== EEditorElementType.Slider) {
    return null;
  }

  const defaultLangUrls = element.translations[defaultLocale] ?? [];
  const urls = element.translations[lang] ?? defaultLangUrls;

  return (
    <NewsPostPreviewSlider
      nodes={urls.map(({ url }, nodeIndex) => (
        <Pic
          key={nodeIndex}
          alt=""
          className={styles.image}
          src={url}
          placeholder={{
            type: 'image',
            size: 'm',
          }}
        />
      ))}
    />
  );
};

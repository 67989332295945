import { EditMatchPage } from 'pages/matches/edit/EditMatchPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const EditMatchRoute = {
  path: routes.editMatch.url,
  element: <EditMatchPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

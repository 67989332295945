import { clubSocialNetworkApi } from 'entities/club-social-network/api';
import { clubSocialNetworksQueryKeys } from 'entities/club-social-network/consts';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export const useFetchClubSocialNetworksQuery = () => {
  return usePaginationQuery((pagination) => ({
    queryKey: [clubSocialNetworksQueryKeys.clubSocialNetworks, pagination],
    queryFn: () => clubSocialNetworkApi.fetchEntities({ params: pagination }),
    cacheTime: 0,
  }));
};

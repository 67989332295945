import { noop } from 'lodash';
import { EyeDropper } from 'shared/components/ColorPicker/components/EyeDropper';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Typography } from 'shared/components/Typography/Typography';

import styles from '../UIKit.module.css';

export const IconButtonExample = () => {
  return (
    <>
      <Typography size={24} tag="h2">
        Icon Button
      </Typography>
      <div className={styles.row}>
        <IconButton icon="dots-vertical" iconSize="l" onClick={noop} />
        <IconButton icon="dots-vertical" iconSize="m" onClick={noop} />
        <IconButton icon="dots-vertical" iconSize="s" onClick={noop} />
      </div>
      <div className={styles.row}>
        <Typography>I am not a global component</Typography>
        <EyeDropper setIsEyeDropActive={() => {}} onColorPick={noop} />
      </div>
    </>
  );
};

import { CardBannerInformation } from 'shared/components/Card/CardInformationPart/CardBannerInformation';
import { CardManagerInformation } from 'shared/components/Card/CardInformationPart/CardManagerInformation';
import { CardOpponentPlayerInformation } from 'shared/components/Card/CardInformationPart/CardOpponentPlayerInformation';
import { CardPlayerInformation } from 'shared/components/Card/CardInformationPart/CardPlayerInformation';
import { CardTeamInformation } from 'shared/components/Card/CardInformationPart/CardTeamInformation';
import { ECardType, TCardInformation } from 'shared/components/Card/types';

const elementMapper = {
  [ECardType.Player]: CardPlayerInformation,
  [ECardType.OpponentPlayer]: CardOpponentPlayerInformation,
  [ECardType.Manager]: CardManagerInformation,
  [ECardType.Team]: CardTeamInformation,
  [ECardType.Banner]: CardBannerInformation,
};

export const CardInformation = (props: TCardInformation) =>
  elementMapper[props.type](props);

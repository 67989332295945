import { useCreateAdBannerMutation } from 'entities/ad/banners/model/mutations';
import { useLocales } from 'entities/config/lib/useLocales';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notify } from 'shared/components/Notification';
import { routes } from 'shared/routes';
import { mapAdBannerFormToCreateDTO } from 'widgets/adBannerForm/mapAdBannerFormToCreateDTO';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';

export const useCreateAdBanner = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const navigate = useNavigate();

  const { mutate, isLoading: isCreatingBanner } = useCreateAdBannerMutation();

  const createAdBanner = async (fields: TAdBannerFormFields) => {
    mutate(await mapAdBannerFormToCreateDTO(locales, fields), {
      onSuccess: () => {
        navigate(routes.adBanners);
      },
      onError: () => {
        notify(t('content.adBanners.form.createBannerError'), { type: 'error' });
      },
    });
  };

  return {
    isCreatingBanner,
    createAdBanner,
  };
};

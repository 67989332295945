import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useForm } from 'shared/hooks/useReactHookForm';
import { clubFormValidationSchema } from 'widgets/ClubForm/hooks/validationSchema';
import { TClubFormFields } from 'widgets/ClubForm/types';

export type TClubProps = {
  values: TClubFormFields;
};

export const useClubForm = ({ values }: TClubProps) => {
  const { t } = useTranslation();

  return useForm<TClubFormFields>({
    defaultValues: values,
    mode: 'onChange',
    resolver: zodResolver(clubFormValidationSchema(t)),
  });
};

import { useChangeAppLocale } from 'pages/settings/language/useChangeAppLocale';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Typography } from 'shared/components/Typography/Typography';
import { EAppLanguages } from 'shared/types/common';

import styles from './LanguageSettingPage.module.css';

export const LanguageSettingPage = () => {
  const { i18n, t } = useTranslation();
  const changeAppLocale = useChangeAppLocale();

  return (
    <div className={styles.root}>
      <Typography size={20} tag="h1" weight="Bold">
        {t('content.options.language')}
      </Typography>
      <div className={styles.wrapper}>
        <RadioButton
          checked={i18n.language === EAppLanguages.ru}
          label={t('content.onlyRussian')}
          name="language"
          value={EAppLanguages.ru}
          onChange={() => changeAppLocale(EAppLanguages.ru)}
        />
        <RadioButton
          checked={i18n.language === EAppLanguages.en}
          label={t('content.onlyEnglish')}
          name="language"
          value={EAppLanguages.en}
          onChange={() => changeAppLocale(EAppLanguages.en)}
        />
      </div>
    </div>
  );
};

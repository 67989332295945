import { useFetchNewsQuery } from 'entities/news/queries';
import { useNewsColumnsConfig } from 'pages/news/NewsPage/columnsConfig';
import { NewsPostActions } from 'pages/news/NewsPage/NewsPostActions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routes } from 'shared/routes';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import tableStyles from './NewsTable.module.css';

export const NewsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    data: { data: news = [], meta: { page = 1, total = 0 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchNewsQuery();

  return (
    <CommonList
      configCreator={useNewsColumnsConfig}
      emptyComponentDescription={t('content.news.createFirstNews')}
      error={error}
      isLoading={isLoading}
      title={t('content.news')}
      createButtonProps={{
        content: t('shared.create'),
        onClick: () => navigate(routes.createNewsPost),
      }}
      paginationProps={{
        selectedPage: page,
        totalItems: total,
        onPageChange: changePage,
      }}
      tableProps={{
        data: news,
        rowClassName: tableStyles.rowGridTemplate,
        ActionComponent: NewsPostActions,
        actionsCellClassName: tableStyles.actionsColumn,
      }}
    />
  );
};

import { TFetchTournamentsParams } from 'entities/tournaments/types';
import { TEntityId } from 'shared/types/common';

export const tournamentQueryKeys = {
  tournaments: ({ include }: TFetchTournamentsParams = {}) =>
    'tournaments ' + (include ? `with ${include}` : ''),
  tournament: (tournamentId: TEntityId) => `tournaments/${tournamentId}`,
};

const tournamentBaseUrl = 'tournaments';

export const tournamentApiKeys = {
  createTournament: tournamentBaseUrl,
  getTournaments: tournamentBaseUrl,
  getTournament: (id: TEntityId) => `${tournamentBaseUrl}/${id}`,
  editTournament: (id: TEntityId) => `${tournamentBaseUrl}/${id}`,
  deleteTournament: (id: TEntityId) => `${tournamentBaseUrl}/${id}`,
};

import {
  TWorkspacePanelBackButtonProps,
  WorkspacePanelBackButton,
} from 'feature/workspacePanel/ui/WorkspacePanelBackButton/WorkspacePanelBackButton';
import {
  LocalesControl,
  TLocalesControlProps,
} from 'shared/components/LocalesControl/LocalesControl';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './WorkspacePanel.module.css';

type TProps = {
  localesControlProps?: TLocalesControlProps;
  backButtonProps: TWorkspacePanelBackButtonProps;
  renderActions?: () => JSX.Element;
  title?: string;
};

export const WorkspacePanel = ({
  localesControlProps,
  backButtonProps,
  renderActions,
  title,
}: TProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <WorkspacePanelBackButton {...backButtonProps} />
        {title && (
          <Typography color="var(--gray-450)" size={20} weight="Bold">
            {title}
          </Typography>
        )}
      </div>

      {localesControlProps && (
        <LocalesControl className={styles.localesControl} {...localesControlProps} />
      )}
      {renderActions && <div className={styles.control}>{renderActions()}</div>}
    </div>
  );
};

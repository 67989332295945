import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import { matchPlayerApi, matchPlayerButchesApi } from 'entities/matchPlayer/api';
import { matchPlayerQueryKeys } from 'entities/matchPlayer/consts';
import { TMatchPlayerDTO } from 'entities/matchPlayer/type';
import { TEntityId } from 'shared/types/common';

export const useCreateMatchPlayerMutation = () => {
  return useMutation({
    mutationFn: (data: TMatchPlayerDTO[]) =>
      matchPlayerButchesApi.createEntity({ data }),
    onSuccess: () => {
      return queryClient.invalidateQueries([matchPlayerQueryKeys.matchPlayer]);
    },
  });
};

export const useEditMatchPlayerMutation = () => {
  return useMutation({
    mutationFn: (data: { id: TEntityId; data: TMatchPlayerDTO }) =>
      matchPlayerApi.editEntity(data),
    onSuccess: () => {
      return queryClient.invalidateQueries([matchPlayerQueryKeys.matchPlayer]);
    },
  });
};

export const useDeleteMatchPlayerMutation = () => {
  return useMutation({
    mutationFn: matchPlayerApi.deleteEntity,
    onSuccess: () => {
      return queryClient.invalidateQueries([matchPlayerQueryKeys.matchPlayer]);
    },
  });
};

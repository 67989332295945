import {
  fontFamilyOptions,
  fontStyleOptions,
} from 'pages/stories/edit/EditStory/components/forms/constants';
import styles from 'pages/stories/edit/EditStory/components/forms/ElementForm.module.css';
import { Control, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'shared/components/Form/FormSelect/FormSelect';
import { Typography } from 'shared/components/Typography/Typography';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fontFamilyName: Path<T>;
  fontStyleName: Path<T>;
  withTitle?: boolean;
};
export const FontSettings = <T extends FieldValues>({
  control,
  fontFamilyName,
  fontStyleName,
  withTitle,
}: TProps<T>) => {
  const { t } = useTranslation();
  return (
    <>
      {withTitle && (
        <Typography className={styles.title} size={14} tag="h2">
          {t('content.story.optionFonts')}
        </Typography>
      )}
      <FormSelect
        control={control}
        fieldName={fontFamilyName}
        inline
        inlineInputClassName={styles.formField}
        isSearchable={false}
        label={t('content.story.fonts')}
        menuWidth={116}
        options={fontFamilyOptions}
        placeholder="Select"
        selectWidth={116}
      />
      <FormSelect
        control={control}
        fieldName={fontStyleName}
        inline
        inlineInputClassName={styles.formField}
        isSearchable={false}
        label={t('content.story.typeface')}
        menuWidth={116}
        options={fontStyleOptions}
        placeholder="Select"
        selectWidth={116}
      />
    </>
  );
};

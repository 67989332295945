import { useFetchAdSlots } from 'entities/ad/slots/model/queries';
import { useCreateAdBanner } from 'pages/adBanners/create/hooks/useCreateAdBanner';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { AdBannerForm } from 'widgets/adBannerForm/ui/AdBannerForm/AdBannerForm';

export const CreateAdBannerPage = () => {
  const { data: { data: slots = [] } = {}, isLoading: isLoadingAdSlots } =
    useFetchAdSlots(
      {
        pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
      },
      { cacheTime: 0 },
    );

  const { isCreatingBanner, createAdBanner } = useCreateAdBanner();

  if (isLoadingAdSlots) {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  return (
    <AdBannerForm
      slots={slots}
      confirmButtonProps={{
        isLoading: isCreatingBanner,
      }}
      onSubmit={createAdBanner}
    />
  );
};

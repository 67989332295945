import { useLocales } from 'entities/config/lib/useLocales';
import { EEditorElementType } from 'shared/components/Editor/types';
import { Pic } from 'shared/components/Pic/Pic';
import { TWorkspaceElement } from 'shared/components/Workspace/types';
import { TContentLang } from 'shared/types/common';

import styles from '../AddNewsPostPreview.module.css';

export const AddNewsPostPreviewImageElement = ({
  element,
  lang,
}: {
  element: TWorkspaceElement;
  lang: TContentLang;
}) => {
  const { defaultLocale } = useLocales();
  if (element.type !== EEditorElementType.Image) {
    return null;
  }

  const defaultLangUrl = element.translations[defaultLocale]?.url ?? '';
  const url = element.translations[lang]?.url ?? defaultLangUrl;

  return (
    <Pic
      alt=""
      className={styles.image}
      src={url}
      placeholder={{
        type: 'image',
        size: 'm',
      }}
    />
  );
};

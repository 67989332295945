import alertCircle from 'shared/assets/icons/alertCircle.svg';
import alertTriangle from 'shared/assets/icons/alertTriangle.svg';
import alignCenter from 'shared/assets/icons/alignCenter.svg';
import alignLeft from 'shared/assets/icons/alignLeft.svg';
import alignRight from 'shared/assets/icons/alignRight.svg';
import bold01 from 'shared/assets/icons/bold-01.svg';
import button from 'shared/assets/icons/button.svg';
import calendar from 'shared/assets/icons/calendar.svg';
import checkCircle from 'shared/assets/icons/check-circle.svg';
import checkmark from 'shared/assets/icons/checkmark.svg';
import chevronDown from 'shared/assets/icons/chevronDown.svg';
import chevronLeft from 'shared/assets/icons/chevronLeft.svg';
import chevronRight from 'shared/assets/icons/chevronRight.svg';
import chevronSelectorVertical from 'shared/assets/icons/chevronSelectorVertical.svg';
import chevronUp from 'shared/assets/icons/chevronUp.svg';
import clock from 'shared/assets/icons/clock.svg';
import copy from 'shared/assets/icons/copy.svg';
import crop01 from 'shared/assets/icons/crop-01.svg';
import cup from 'shared/assets/icons/cup.svg';
import dotpoints01 from 'shared/assets/icons/dotpoints-01.svg';
import dotsGrag from 'shared/assets/icons/dotsGrag.svg';
import dotsHorizontal from 'shared/assets/icons/dotsHorizontal.svg';
import dotsVertical from 'shared/assets/icons/dotsVertical.svg';
import dropper from 'shared/assets/icons/dropper.svg';
import edit02 from 'shared/assets/icons/edit-02.svg';
import empty from 'shared/assets/icons/empty.svg';
import eye from 'shared/assets/icons/eye.svg';
import feedback from 'shared/assets/icons/feedback.svg';
import fileAttachment from 'shared/assets/icons/fileAttachment.svg';
import fileDownload03 from 'shared/assets/icons/fileDownload-03.svg';
import filePlus from 'shared/assets/icons/filePlus.svg';
import filterLines from 'shared/assets/icons/filterLines.svg';
import image01 from 'shared/assets/icons/image-01.svg';
import image03 from 'shared/assets/icons/image-03.svg';
import imagePlus from 'shared/assets/icons/imagePlus.svg';
import imageX from 'shared/assets/icons/imageX.svg';
import info from 'shared/assets/icons/info.svg';
import italic01 from 'shared/assets/icons/italic-01.svg';
import link01 from 'shared/assets/icons/link-01.svg';
import linkBroken02 from 'shared/assets/icons/linkBroken-02.svg';
import logout from 'shared/assets/icons/logout.svg';
import map01 from 'shared/assets/icons/map-01.svg';
import monitor from 'shared/assets/icons/monitor-04.svg';
import move from 'shared/assets/icons/move.svg';
import noEyes from 'shared/assets/icons/noEyes.svg';
import notFoundPerson from 'shared/assets/icons/notFoundPerson.svg';
import notFoundTeam from 'shared/assets/icons/notFoundTeam.svg';
import paperclip from 'shared/assets/icons/paperclip.svg';
import picture from 'shared/assets/icons/picture.svg';
import play from 'shared/assets/icons/play.svg';
import playCircle from 'shared/assets/icons/playCircle.svg';
import player02 from 'shared/assets/icons/player-02.svg';
import plus from 'shared/assets/icons/plus.svg';
import quiz from 'shared/assets/icons/quiz.svg';
import refreshCcw01 from 'shared/assets/icons/refreshCcw-01.svg';
import refreshCcw02 from 'shared/assets/icons/refreshCcw-02.svg';
import refreshCw01 from 'shared/assets/icons/refreshCw-01.svg';
import resizer from 'shared/assets/icons/resizer.svg';
import reverseCountdown from 'shared/assets/icons/reverseCountdown.svg';
import searchMd from 'shared/assets/icons/searchMd.svg';
import settings01 from 'shared/assets/icons/settings-01.svg';
import settings02 from 'shared/assets/icons/settings-02.svg';
import simpleCircle from 'shared/assets/icons/simpleCircle.svg';
import slidersImage from 'shared/assets/icons/slidersImage.svg';
import soccerTeamPlaceholder from 'shared/assets/icons/soccerTeamPlaceholder.svg';
import spinner from 'shared/assets/icons/spinner.svg';
import strikethrough01 from 'shared/assets/icons/strikethrough-01.svg';
import swipeUp from 'shared/assets/icons/swipeUp.svg';
import templates from 'shared/assets/icons/templates.svg';
import text from 'shared/assets/icons/text.svg';
import trash from 'shared/assets/icons/trash.svg';
import underline01 from 'shared/assets/icons/underline-01.svg';
import video from 'shared/assets/icons/video.svg';
import voting from 'shared/assets/icons/voting.svg';
import x from 'shared/assets/icons/x.svg';
import xCircle from 'shared/assets/icons/xCircle.svg';
import zoomIn from 'shared/assets/icons/zoomIn.svg';
import zoomOut from 'shared/assets/icons/zoomOut.svg';

import { IconFactory } from './IconFactory';

export const componentsMap = {
  'alert-circle': IconFactory(alertCircle),
  'alert-triangle': IconFactory(alertTriangle),
  'align-center': IconFactory(alignCenter),
  'align-left': IconFactory(alignLeft),
  'align-right': IconFactory(alignRight),
  'bold-01': IconFactory(bold01),
  button: IconFactory(button),
  calendar: IconFactory(calendar),
  'check-circle': IconFactory(checkCircle),
  checkmark: IconFactory(checkmark),
  'chevron-down': IconFactory(chevronDown),
  'chevron-left': IconFactory(chevronLeft),
  'chevron-right': IconFactory(chevronRight),
  'chevron-selector-vertical': IconFactory(chevronSelectorVertical),
  'chevron-up': IconFactory(chevronUp),
  clock: IconFactory(clock),
  copy: IconFactory(copy),
  'crop-01': IconFactory(crop01),
  cup: IconFactory(cup),
  'dotpoints-01': IconFactory(dotpoints01),
  'dots-grag': IconFactory(dotsGrag),
  'dots-vertical': IconFactory(dotsVertical),
  dotsHorizontal: IconFactory(dotsHorizontal),
  dropper: IconFactory(dropper),
  'edit-02': IconFactory(edit02),
  empty: IconFactory(empty),
  eye: IconFactory(eye),
  feedback: IconFactory(feedback),
  'file-attachment-04': IconFactory(fileAttachment),
  'file-download-03': IconFactory(fileDownload03),
  filePlus: IconFactory(filePlus),
  'filter-lines': IconFactory(filterLines),
  'image-01': IconFactory(image01),
  'image-03': IconFactory(image03),
  'image-plus': IconFactory(imagePlus),
  'image-x': IconFactory(imageX),
  info: IconFactory(info),
  'italic-01': IconFactory(italic01),
  'link-01': IconFactory(link01),
  'link-broken-02': IconFactory(linkBroken02),
  logout: IconFactory(logout),
  'map-01': IconFactory(map01),
  monitor: IconFactory(monitor),
  move: IconFactory(move),
  'no-eyes': IconFactory(noEyes),
  'not-found-person': IconFactory(notFoundPerson),
  'not-found-team': IconFactory(notFoundTeam),
  paperclip: IconFactory(paperclip),
  picture: IconFactory(picture),
  play: IconFactory(play),
  'play-circle': IconFactory(playCircle),
  'player-02': IconFactory(player02),
  plus: IconFactory(plus),
  quiz: IconFactory(quiz),
  'refresh-ccw-01': IconFactory(refreshCcw01),
  'refresh-ccw-02': IconFactory(refreshCcw02),
  'refresh-cw-01': IconFactory(refreshCw01),
  resizer: IconFactory(resizer),
  'reverse-countdown': IconFactory(reverseCountdown),
  'search-md': IconFactory(searchMd),
  'settings-01': IconFactory(settings01),
  'settings-02': IconFactory(settings02),
  'simple-circle': IconFactory(simpleCircle),
  'sliders-image': IconFactory(slidersImage),
  'soccer-team-placeholder': IconFactory(soccerTeamPlaceholder),
  spinner: IconFactory(spinner),
  'strikethrough-01': IconFactory(strikethrough01),
  'swipe-up': IconFactory(swipeUp),
  templates: IconFactory(templates),
  text: IconFactory(text),
  trash: IconFactory(trash),
  'underline-01': IconFactory(underline01),
  video: IconFactory(video),
  voting: IconFactory(voting),
  x: IconFactory(x),
  'x-circle': IconFactory(xCircle),
  'zoom-in': IconFactory(zoomIn),
  'zoom-out': IconFactory(zoomOut),
} as const;

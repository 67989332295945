import { useFetchOpponentPlayersQuery } from 'entities/opponentPlayers/queries';
import { OPPONENT_TEAM_PLAYERS_PAGE_GAP } from 'pages/opponentTeam/teamPage/OpponentTeamPlayers';
import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { CARD_WIDTH } from 'shared/components/Card/Card';
import { notify } from 'shared/components/Notification';
import { useCalcDynamicPagination } from 'shared/hooks/useCalcDynamicPagination';
import { getApiFilters } from 'shared/lib/apiFilters';

const CREATE_BUTTON_COUNT = 1;

export const useFetchOpponentPlayers = (
  wrapperRef: RefObject<HTMLDivElement>,
  teamId?: string,
) => {
  const { t } = useTranslation();

  const { isCalculateItems, dynamicItems } = useCalcDynamicPagination({
    parent: wrapperRef,
    itemWidth: CARD_WIDTH,
    wrapperGap: OPPONENT_TEAM_PLAYERS_PAGE_GAP,
  });

  const {
    data: {
      data: players = [],
      meta: { total = 0, pageSize = 0, page = 1 } = {},
    } = {},
    isLoading,
    changePage,
  } = useFetchOpponentPlayersQuery(
    {
      pageSize: dynamicItems - CREATE_BUTTON_COUNT,
      filter: getApiFilters({
        name: 'team_id',
        type: 'eq',
        value: teamId,
      }),
    },
    {
      cacheTime: 0,
      enabled: isCalculateItems,
      onError: () => notify(t('content.validation.notifyGetListPlayers')),
    },
  );

  const isShowPagination = total > 0 && total > pageSize && !isLoading;

  return {
    players,
    total,
    pageSize,
    page,
    isShowPagination,
    changePage,
    isLoading,
  };
};

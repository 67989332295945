import { useCallback, useState } from 'react';

export const useModal = <T extends string>(name: T = '' as T) => {
  const [open, setOpen] = useState(false);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  return {
    [`opened${name}Modal`]: open,
    [`close${name}Modal`]: closeModal,
    [`open${name}Modal`]: openModal,
  } as {
    [key in `opened${T}Modal`]: boolean;
  } & {
    [key in `close${T}Modal`]: () => void;
  } & {
    [key in `open${T}Modal`]: () => void;
  };
};

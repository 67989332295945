import { EEditorElementType } from 'shared/components/Editor/types';
import { TEditorDisableStructureChange } from 'shared/components/Editor/utils/structureChange/types';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { VideoPlayer } from 'shared/components/VideoPlayer/VideoPlayer';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';

import styles from './EditorVideoElement.module.css';

export type TEditorVideoElementProps = {
  changeVideo: (path: number[]) => void;
};

export const EditorVideoElement = ({
  attributes,
  element,
  children,
  disabledStructureChange,
  changeVideo,
}: RenderElementProps &
  TEditorVideoElementProps &
  TEditorDisableStructureChange) => {
  if (element.type !== EEditorElementType.Video) {
    return null;
  }
  const editor = useSlateStatic();

  const deleteVideo = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
  };

  const changeVideoHandler = () => {
    const path = ReactEditor.findPath(editor, element);

    changeVideo(path);
  };

  return (
    <div {...attributes}>
      {children}
      <div className={styles.videoWrapper}>
        <VideoPlayer {...element.file} />
        <div className={styles.buttonWrapper}>
          <IconButton
            className={styles.button}
            icon="edit-02"
            iconSize="s"
            onClick={changeVideoHandler}
          />
          {!disabledStructureChange && (
            <IconButton
              className={styles.button}
              icon="trash"
              iconSize="s"
              onClick={deleteVideo}
            />
          )}
        </div>
      </div>
    </div>
  );
};

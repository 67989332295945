import { LanguageSettingPage } from 'pages/settings/language/LanguageSettingPage/LanguageSettingPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const SettingsLanguageRoute = {
  element: <LanguageSettingPage />,
  path: routes.settingsLanguages,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

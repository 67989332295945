import { components, SingleValueProps } from 'react-select';
import { TSelectOption } from 'shared/components/Form/Select/types';

export const SelectSingleValue = (props: SingleValueProps<TSelectOption>) => {
  const { data, children } = props;
  const { renderLabel, label } = data || {};

  return (
    <components.SingleValue {...props}>
      {renderLabel?.(label) ?? children}
    </components.SingleValue>
  );
};

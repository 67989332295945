import { TClubDTO } from 'entities/club/types';
import { isNull } from 'lodash';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import { mapFileToUpload } from 'shared/utils/image';
import { TClubFormFields } from 'widgets/ClubForm/types';

const getNewImage = async (image?: File | null) => {
  if (image) {
    return await mapFileToUpload(image);
  }

  if (isNull(image)) {
    return null;
  }

  return undefined;
};

export const mapClubFormFieldsToDTO = async (
  values: TClubFormFields,
  locales: TContentLang[],
): Promise<TClubDTO> => {
  return {
    whiteImage: await getNewImage(values.whiteImage),
    darkImage: await getNewImage(values.darkImage),
    translations: reduceTranslationObject(locales, (locale) => ({
      description: values.description[locale],
    })),
  };
};

import { useMatchLayout } from 'feature/match-layout/useMatchLayout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TButtonProps } from 'shared/components/Button/Button';
import { Centered } from 'shared/components/Centered/Centered';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { Loader } from 'shared/components/Loader/Loader';
import { useModal } from 'shared/hooks/useModal';
import { TWithOptionalChildren } from 'shared/types/common';
import { SecondLayout } from 'shared/ui/layouts/secondLayout/SecondLayout';

type TProps = {
  confirmButtonProps?: TButtonProps;
  title: string;
  isEdit?: boolean;
};

export const MatchLayout = ({
  children,
  title,
  confirmButtonProps = {},
}: TProps & TWithOptionalChildren) => {
  const { t } = useTranslation();
  const { openClosingModal, closeClosingModal, openedClosingModal } =
    useModal('Closing');
  const { matchId } = useParams<{
    matchId: string;
  }>();

  const { matchNavigationLinks, isLoading, error, backButtonClickHandler } =
    useMatchLayout({
      matchId,
    });

  if (error) {
    throw error;
  }

  if (isLoading) {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  return (
    <>
      <SecondLayout
        confirmButtonProps={confirmButtonProps}
        navigationLinks={matchNavigationLinks}
        title={title}
        backButtonProps={{
          onClick: openClosingModal,
        }}
      >
        {children}
      </SecondLayout>
      <ConfirmationModal
        description={t('shared.BackButton.description')}
        isOpened={openedClosingModal}
        title={t('shared.backToRoot.confirmationModal.titleWithMark')}
        onClose={closeClosingModal}
        onConfirm={backButtonClickHandler}
      />
    </>
  );
};

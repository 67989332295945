import { TAPIError } from 'shared/api/types';
import { notify } from 'shared/components/Notification';

export const isApiError = (error: unknown): error is TAPIError => {
  if (
    error !== null &&
    typeof error === 'object' &&
    'response' in error &&
    error.response !== undefined &&
    typeof error.response === 'object'
  ) {
    const response = (error as TAPIError).response;
    if (
      response &&
      response.data &&
      typeof response.data === 'object' &&
      'errors' in response.data &&
      Array.isArray(response.data.errors)
    ) {
      return true;
    }
  }
  return false;
};

export const defaultErrorHandler = (reason: TAPIError) => {
  const message = reason.response?.data.errors
    ? reason.response?.data.errors
        .map((error) => {
          let title = error.title;
          if (error.details) {
            title += `: ${error.attribute} ${error.details}`;
          }
          return title;
        })
        .join(', ')
    : reason.message;
  notify(message, { type: 'error' });
};

export const handleValidationError = (
  error: unknown,
  fallbackErrorHandler: VoidFunction,
) => {
  if (isApiError(error) && error.status === 422) {
    defaultErrorHandler(error);
    return;
  }
  fallbackErrorHandler();
};

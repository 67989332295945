import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useToken } from 'shared/api/token';
import { ELocalStorageKeys } from 'shared/constants';
import { TWithChildren } from 'shared/types/common';

export const CleanupQueries = ({ children }: TWithChildren) => {
  const queryClient = useQueryClient();
  const token = useToken();

  useEffect(() => {
    if (!token) {
      queryClient.resetQueries();
      localStorage.removeItem(ELocalStorageKeys.backendSandbox);
    }
  }, [token]);

  return <>{children}</>;
};

import cn from 'classnames';
import { ReactNode } from 'react';
import { Icon, TIconProps } from 'shared/components/Icon/Icon';
import { Pic } from 'shared/components/Pic/Pic';
import {
  TTypographyProps,
  Typography,
} from 'shared/components/Typography/Typography';
import { toBoolean } from 'shared/lib/toBoolean';

import styles from './Badge.module.css';

type TProps = {
  type?: 'white' | 'outlined' | 'soft' | 'solid';
  size?: 's' | 'm' | 'l';
  shape?: 'rounded' | 'pilled';
  color?: 'green' | 'red' | 'light' | 'dark' | 'gray';
  textProps?: Omit<TTypographyProps, 'size' | 'children'>;
  content: ReactNode;
  imageUrl?: string | null;
  className?: string;
  closeIconProps?: Omit<TIconProps, 'kind'>;
};

export const Badge = ({
  content,
  size = 'm',
  color = 'light',
  shape = 'rounded',
  type = 'white',
  textProps = {},
  imageUrl,
  className,
  closeIconProps,
}: TProps) => {
  const haveCloseButton = toBoolean(closeIconProps);

  return (
    <div
      className={cn(
        styles.base,
        styles[size],
        styles[color],
        styles[shape],
        styles[type],
        { [styles.closeButton]: haveCloseButton },
        className,
      )}
    >
      {imageUrl && (
        <div className={styles.imageWrapper}>
          <Pic
            alt=""
            src={imageUrl}
            placeholder={{
              type: 'image',
            }}
          />
        </div>
      )}
      {typeof content === 'string' ? (
        <Typography className={styles.text} weight="SemiBold" {...textProps}>
          {content}
        </Typography>
      ) : (
        content
      )}
      {haveCloseButton && (
        <Icon
          {...closeIconProps}
          className={cn(styles.closeIcon, closeIconProps!.className)}
          kind="x"
        />
      )}
    </div>
  );
};

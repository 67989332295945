import { EMatchType, TMatch } from 'entities/matches/types';
import { useFetchMatchPlayersQuery } from 'entities/matchPlayer/query';
import { TMatchPlayer } from 'entities/matchPlayer/type';
import { useFetchOpponentMatchPlayersQuery } from 'entities/opponentMatchPlayer/query';
import { TMatchOpponentPlayer } from 'entities/opponentMatchPlayer/type';
import { TTacticErrorModalType } from 'pages/matches/tactic/TacticErrorModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { getApiFilters } from 'shared/lib/apiFilters';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { routes } from 'shared/routes';

const PLAYERS_ON_FIELD = 11;
const MIN_RESERVE_PLAYERS = 3;

export const useCheckMatchTactic = (match?: TMatch<'opponentTeam' | 'team'>) => {
  const navigate = useNavigate();
  const [errorModalState, setErrorModalState] = useState<TTacticErrorModalType>('');

  const { data: { data: matchPlayers } = {}, isLoading: isLoadingPlayers } =
    useFetchMatchPlayersQuery(
      {
        defaultPagination: { pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE },
        filter: getApiFilters({
          name: 'match_id',
          type: 'eq',
          value: toString(match?.id),
        }),
      },
      { enabled: toBoolean(match) },
    );

  const {
    data: { data: matchOpponentPlayers } = {},
    isLoading: isLoadingOpponentPlayers,
  } = useFetchOpponentMatchPlayersQuery(
    {
      defaultPagination: { pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE },
      filter: getApiFilters({
        name: 'match_id',
        type: 'eq',
        value: toString(match?.id),
      }),
    },
    { enabled: toBoolean(match) },
  );

  const checkMatchTactic = () => {
    if (matchPlayers && matchOpponentPlayers) {
      const isHome = match?.matchType === EMatchType.Home;

      const homePlayers = isHome ? matchPlayers : matchOpponentPlayers;
      const guestPlayers = isHome ? matchOpponentPlayers : matchPlayers;

      const {
        fullFieldPlayers: fullHomeFieldPlayers,
        haveReservePlayers: haveHomeReservePlayers,
        haveCaptain: haveHomeCaptain,
      } = isFilledTactic(homePlayers);
      const {
        haveCaptain: haveGuestCaptain,
        haveReservePlayers: haveGuestReservePlayers,
        fullFieldPlayers: fullGuestFieldPlayers,
      } = isFilledTactic(guestPlayers);

      if (!fullHomeFieldPlayers) {
        setErrorModalState('homeField');
      } else if (!fullGuestFieldPlayers) {
        setErrorModalState('guestField');
      } else if (!haveHomeCaptain) {
        setErrorModalState('homeCaptain');
      } else if (!haveGuestCaptain) {
        setErrorModalState('guestCaptain');
      } else if (!haveHomeReservePlayers) {
        setErrorModalState('homeReserve');
      } else if (!haveGuestReservePlayers) {
        setErrorModalState('guestReserve');
      } else {
        navigate(routes.matches);
      }
    }
  };

  return {
    checkMatchTactic,
    isLoading: isLoadingOpponentPlayers || isLoadingPlayers,
    errorModalState,
    closeErrorModal: () => setErrorModalState(''),
  };
};

function isFilledTactic(matchPlayers: (TMatchPlayer | TMatchOpponentPlayer)[]) {
  const { haveCaptain, reservePlayers, playersOnField } =
    calculatePlayers(matchPlayers);

  return {
    fullFieldPlayers: playersOnField >= PLAYERS_ON_FIELD,
    haveCaptain,
    haveReservePlayers: reservePlayers >= MIN_RESERVE_PLAYERS,
  };
}

function calculatePlayers(matchPlayers: (TMatchPlayer | TMatchOpponentPlayer)[]) {
  return matchPlayers.reduce(
    (acc, player) => {
      if (player.captain) {
        acc.haveCaptain = true;
      }

      if (player.reserve) {
        acc.reservePlayers += 1;
      } else {
        acc.playersOnField += 1;
      }

      return acc;
    },
    { haveCaptain: false, reservePlayers: 0, playersOnField: 0 },
  );
}

import { useQueryClient } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { opponentTeamsQueryKeys } from 'entities/opponentTeams/consts';
import { editOpponentTeamMutation } from 'entities/opponentTeams/mutations';
import { TOpponentTeam } from 'entities/opponentTeams/types';
import { mapOpponentTeamModalFieldsToDTO } from 'feature/opponentTeamModal/mapper/mapOpponentTeamModalFieldsToDTO';
import { mapOpponentTeamToTeamModalFields } from 'feature/opponentTeamModal/mapper/mapOpponentTeamToTeamModalFields';
import { TOpponentTeamModalFields } from 'feature/opponentTeamModal/OpponentTeamModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';

type TParams = {
  team: TOpponentTeam;
};

export const useEditOpponentTeam = ({ team }: TParams) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { openModal, closeModal, openedModal } = useModal();

  const [initialValues, setInitialValues] = useState<
    TOpponentTeamModalFields | undefined
  >(undefined);
  const { mutateAsync, isLoading } = editOpponentTeamMutation();
  const { locales } = useLocales();

  const editTeam = async (editTeamModalFields: TOpponentTeamModalFields) => {
    const dto = await mapOpponentTeamModalFieldsToDTO(editTeamModalFields, locales);

    await mutateAsync(
      { ...dto, teamId: team.id },
      {
        onSuccess: () => {
          queryClient.resetQueries([opponentTeamsQueryKeys.opponentTeams]);
        },
        onError: () => {
          notify(t('content.validation.notifyEditTeamOpponent'), {
            type: 'error',
          });
        },
        onSettled: closeModal,
      },
    );
  };

  useEffect(() => {
    mapOpponentTeamToTeamModalFields(team, locales).then(setInitialValues);
  }, [team]);

  return {
    isLoading,
    editTeam,
    openModal,
    closeModal,
    isOpenModal: openedModal,
    initialValues,
  };
};

import { useQueryClient } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { teamsQueryKeys } from 'entities/teams/consts';
import { editTeamMutation } from 'entities/teams/mutations';
import { TTeam } from 'entities/teams/types';
import { mapTeamModalFieldsToDTO } from 'feature/teamModal/mapper/mapTeamModalFieldsToDTO';
import { mapTeamToTeamModalFields } from 'feature/teamModal/mapper/mapTeamToTeamModalFields';
import { TTeamModalFields } from 'feature/teamModal/TeamModal';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';

type TParams = {
  team: TTeam;
};

export const useEditTeam = ({ team }: TParams) => {
  const queryClient = useQueryClient();
  const { openModal, closeModal, openedModal } = useModal();
  const [initialValues, setInitialValues] = useState<TTeamModalFields | undefined>(
    undefined,
  );
  const { mutateAsync, isLoading } = editTeamMutation();
  const { locales } = useLocales();

  const editTeam = async (editTeamModalFields: TTeamModalFields) => {
    const dto = await mapTeamModalFieldsToDTO(editTeamModalFields, locales);

    await mutateAsync(
      { ...dto, teamId: team.id },
      {
        onSuccess: () => {
          queryClient.resetQueries([teamsQueryKeys.teams]);
        },
        onError: () => {
          notify(i18n.t('content.teams.notifyNotEditTeam'), { type: 'error' });
        },
        onSettled: closeModal,
      },
    );
  };

  useEffect(() => {
    mapTeamToTeamModalFields(team, locales).then(setInitialValues);
  }, [team]);

  return {
    isLoading,
    editTeam,
    openModal,
    closeModal,
    isOpenModal: openedModal,
    initialValues,
  };
};

import escapeHtml from 'escape-html';
import {
  EEditorElementType,
  TEditorDefaultText,
  TEditorElement,
} from 'shared/components/Editor/types';
import { Text } from 'slate';

export const brTag = '<br />';

export const serializeEditorElementsToHTML = (
  element: TEditorElement | TEditorDefaultText,
): string => {
  if (Text.isText(element)) {
    let string = escapeHtml(element.text);
    if (element.bold) {
      string = `<strong>${string}</strong>`;
    }

    if (element.italic) {
      string = `<em>${string}</em>`;
    }

    if (element.deleted) {
      string = `<del>${string}</del>`;
    }

    if (element.underline) {
      string = `<u>${string}</u>`;
    }

    string = string.replace(/\n/g, '<br />');

    return string;
  }

  if (element.type === EEditorElementType.Paragraph) {
    return element.children.map(serializeEditorElementsToHTML).join('') || brTag;
  }

  if (element.type === EEditorElementType.BulletedList) {
    const children = element.children.map(serializeEditorElementsToHTML).join('');

    return `<ul>${children}</ul>`;
  }

  if (element.type === EEditorElementType.ListItem) {
    const children = element.children.map(serializeEditorElementsToHTML).join('');

    return `<li>${children}</li>`;
  }

  if (element.type === EEditorElementType.HeadingTwo) {
    const children = element.children.map(serializeEditorElementsToHTML).join('');

    return `<h2>${children}</h2>`;
  }

  if (element.type === EEditorElementType.Link) {
    const children = element.children.map(serializeEditorElementsToHTML).join('');

    return `<a href="${element.url}">${children}</a>`;
  }

  return '';
};

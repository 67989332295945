import { UseQueryOptions } from '@tanstack/react-query';
import { opponentMatchPlayerApi } from 'entities/opponentMatchPlayer/api';
import { opponentMatchPlayerQueryKeys } from 'entities/opponentMatchPlayer/consts';
import { mapApiMatchOpponentPlayersToDTO } from 'entities/opponentMatchPlayer/mapper';
import { TMatchOpponentPlayer } from 'entities/opponentMatchPlayer/type';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchOpponentMatchPlayers = {
  defaultPagination?: TPaginatedParameters;
  filter?: Record<string, string>;
};

export const useFetchOpponentMatchPlayersQuery = (
  { defaultPagination = {}, filter }: TFetchOpponentMatchPlayers,
  options: UseQueryOptions<
    TResponseData<TMatchOpponentPlayer[], TPaginatedMeta>
  > = {},
) => {
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [
        opponentMatchPlayerQueryKeys.opponentMatchPlayer,
        filter,
        pagination,
      ],
      queryFn: () =>
        opponentMatchPlayerApi
          .fetchEntities({
            params: { ...filter, ...pagination },
          })
          .then((res) => ({
            ...res,
            data: mapApiMatchOpponentPlayersToDTO(res.data),
          })),
      cacheTime: 0,
      ...options,
    }),
    defaultPagination,
  );
};

import { TMatch } from 'entities/matches/types';
import { useEditMatchTactic } from 'pages/matches/tactic/hooks/useEditMatchTactic';
import { useRef } from 'react';
import { autoSaveNotify } from 'shared/components/Notification/AutoSaveNotification/AutoSaveNotification';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';

type TProps = {
  haveMatchPlayers: boolean;
  match: TMatch<'opponentTeam' | 'team'>;
  changeTacticField: 'opponentTacticId' | 'tacticId';
};

export const useChangeTacticId = ({
  haveMatchPlayers,
  match,
  changeTacticField,
}: TProps) => {
  const {
    openedConfirmChangeTacticModal,
    closeConfirmChangeTacticModal,
    openConfirmChangeTacticModal,
  } = useModal('ConfirmChangeTactic');
  const changedTacticId = useRef<TEntityId>();

  const { editMatch, isLoading: isLoadingEditMatch } = useEditMatchTactic();

  const onChangeTactic = async ({ tacticId }: { tacticId: TEntityId }) => {
    if (haveMatchPlayers) {
      changedTacticId.current = tacticId;
      openConfirmChangeTacticModal();
    } else {
      await editMatch({ match, [changeTacticField]: tacticId });
      autoSaveNotify();
    }
  };

  const confirmChangeTactic = async () => {
    await editMatch({ match, [changeTacticField]: changedTacticId.current });
    changedTacticId.current = undefined;
    closeConfirmChangeTacticModal();
    autoSaveNotify();
  };

  return {
    openedConfirmChangeTacticModal,
    onChangeTactic,
    isLoadingEditMatch,
    confirmChangeTactic,
    closeConfirmChangeTacticModal,
  };
};

import { RefObject, useEffect, useState } from 'react';

type TProps = {
  parent: RefObject<HTMLDivElement>;
  itemWidth: number;
  wrapperGap: number;
};

const DEFAULT_ROWS = 2;
const DEFAULT_ITEMS = 0;

export const useCalcDynamicPagination = ({
  parent,
  itemWidth,
  wrapperGap,
}: TProps) => {
  const [dynamicItems, setDynamicItems] = useState(DEFAULT_ITEMS);

  useEffect(() => {
    const calculateItemsCount = () => {
      if (parent.current) {
        const itemsInRow =
          (parent.current.clientWidth + wrapperGap) / (itemWidth + wrapperGap);
        const itemsCount = Math.floor(itemsInRow) * DEFAULT_ROWS;
        setDynamicItems(Math.max(DEFAULT_ROWS, itemsCount));
      }
    };

    calculateItemsCount();
    window.addEventListener('resize', calculateItemsCount);

    return () => {
      window.removeEventListener('resize', calculateItemsCount);
    };
  }, [parent]);

  return { dynamicItems, isCalculateItems: DEFAULT_ITEMS !== dynamicItems };
};

import { useLocales } from 'entities/config/lib/useLocales';
import { TMatchPlayer } from 'entities/matchPlayer/type';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { Field } from 'shared/components/Form';
import { Select } from 'shared/components/Form/Select/Select';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { Typography } from 'shared/components/Typography/Typography';
import { toString } from 'shared/lib/toString';
import { compareIds } from 'shared/utils/entityIds';
import { TMatchPlacementPlayer } from 'widgets/matchPlacement/types';

import styles from './MatchPlacementCaptain.module.css';

type TProps = {
  teamName?: string;
  players: TMatchPlacementPlayer[];
  matchPlayers: TMatchPlayer[];
  onEditPlayer: (matchPlayer: TMatchPlayer) => void;
};

export const MatchPlacementCaptain = ({
  teamName,
  players,
  matchPlayers,
  onEditPlayer,
}: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();

  const matchPlayersWithPlayers = useMemo(() => {
    const playersMap = new Map(
      players.map((player) => [toString(player?.id), player]),
    );

    return matchPlayers.map((matchPlayer) => {
      const currentPlayer = playersMap.get(toString(matchPlayer.playerId));
      return { ...matchPlayer, player: currentPlayer };
    });
  }, [matchPlayers, players]);

  const currentCaptain = useMemo(() => {
    return matchPlayersWithPlayers.find((player) => player.captain);
  }, [matchPlayersWithPlayers]);

  const captainOptions = useMemo(() => {
    return matchPlayersWithPlayers
      .filter((player) => !player.reserve)
      .map((matchPlayer) => ({
        value: toString(matchPlayer.playerId),
        label: matchPlayer?.player
          ? `${matchPlayer?.player?.translations?.[defaultLocale]?.firstName}
    ${matchPlayer?.player?.translations?.[defaultLocale]?.lastName}`
          : '',
      }));
  }, [defaultLocale, matchPlayersWithPlayers]);

  const onSelectCaptain = (option: SingleValue<TSelectOption>) => {
    const currentPlayer = matchPlayersWithPlayers.find((matchPlayer) =>
      compareIds(matchPlayer.playerId, option?.value),
    );

    if (currentCaptain) {
      onEditPlayer({
        ...currentCaptain,
        captain: false,
      });
    }

    if (currentPlayer) {
      onEditPlayer({
        ...currentPlayer,
        captain: true,
      });
    }
  };

  return (
    <>
      <Typography color="var(--gray-500)" size={16} weight="Bold">
        {t('content.tactics.teamCompositionLabel')} {teamName}
      </Typography>
      <Field
        className={styles.captainSelectWrapper}
        label={t('content.tactics.captain')}
        labelClassName={styles.label}
        required
      >
        <Select
          isSearchable
          options={captainOptions}
          placeholder={t('content.tactics.placeholderCaptain')}
          selectWidth="100%"
          size="small"
          value={
            captainOptions?.find((option) =>
              compareIds(option.value, currentCaptain?.playerId),
            ) ?? null
          }
          onChange={onSelectCaptain}
        />
      </Field>
    </>
  );
};

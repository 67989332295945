import cn from 'classnames';
import { createElement, forwardRef } from 'react';
import {
  TTextColor,
  TTypographySizes,
  TTypographyTagName,
  TTypographyWeight,
} from 'shared/components/Typography/types';
import { TWithOptionalChildren } from 'shared/types/common';

import styles from './Typography.module.css';

export type TTypographyProps = {
  className?: string;
  size?: TTypographySizes;
  weight?: TTypographyWeight;
  decoration?: 'underline' | 'lineThrough' | 'none';
  truncate?: boolean;
  tag?: TTypographyTagName;
  color?: TTextColor;
  style?: React.CSSProperties;
} & TWithOptionalChildren;

export const Typography = forwardRef<
  HTMLElementTagNameMap[TTypographyTagName],
  TTypographyProps
>(
  (
    {
      size = 12,
      weight = 'Regular',
      decoration = 'none',
      truncate = false,
      tag = 'span',
      className,
      children,
      color,
      style,
      ...props
    },
    ref,
  ) =>
    createElement(
      tag,
      {
        ref,
        className: cn(
          styles[`fontSize${size}`],
          styles[`fontWeight${weight}`],
          styles[decoration],
          className,
          {
            [styles.truncate]: truncate,
          },
        ),
        style: {
          color,
          ...style,
        },
        ...props,
      },
      children,
    ),
);

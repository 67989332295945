import { ControlProps } from 'react-select';
import { TSelectOption } from 'shared/components/Form/Select/types';

type TProps = ControlProps<TSelectOption>;

export const SelectControl = (props: TProps) => {
  const { innerProps, getClassNames, children, innerRef } = props;

  return (
    <div className={getClassNames('control', props)} {...innerProps} ref={innerRef}>
      {children}
    </div>
  );
};

import { EditManagerPage } from 'pages/manager/edit/EditManagerPage/EditManagerPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const EditManagerRoute = {
  path: routes.editManager.url,
  element: <EditManagerPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

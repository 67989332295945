import { Pic } from 'shared/components/Pic/Pic';
import { TPostFormFile } from 'shared/types/common';

import styles from './VideoFormPreview.module.css';

type TProps = {
  preview: TPostFormFile;
};

export const VideoFormPreviewImage = ({ preview }: TProps) => {
  return (
    <div className={styles.videoWrapper}>
      <Pic
        alt="video preview image"
        src={preview.data}
        placeholder={{
          size: 'm',
          type: 'image',
        }}
      />
    </div>
  );
};

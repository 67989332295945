import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { Field } from 'shared/components/Form';
import { TInputOwnProps } from 'shared/components/Form/types';
import { Icon } from 'shared/components/Icon/Icon';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';

import formCommonStyles from '../FormCommonStyles.module.css';
import styles from './FormInput.module.css';

export const Input = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  TInputOwnProps
>((props, ref) => {
  const {
    placeholder,
    icon,
    type = 'text',
    size = 'l',
    className,
    maxLength,
    inputClassName,
    label,
    inline,
    tip,
    inlineInputClassName,
    labelClassName,
    onBlur,
    showErrors = true,
    required,
    disabled,
    switchField,
    value,
    onChange,
    onClick,
    isInvalid,
    showMaxLength,
    error,
  } = props;
  const isTextarea = type === 'textarea';

  const cName = cn(
    formCommonStyles.formControl,
    formCommonStyles[size],
    {
      [formCommonStyles.invalid]: isInvalid,
      [styles.textarea]: isTextarea,
      [styles.withIcon]: !!icon,
    },
    inputClassName,
  );

  return (
    <Field
      className={className}
      error={error}
      inline={toBoolean(inline)}
      inlineInputClassName={inline ? inlineInputClassName : undefined}
      label={label}
      labelClassName={labelClassName}
      length={inline || !maxLength ? undefined : value?.length || 0}
      maxLength={inline ? undefined : maxLength}
      required={required}
      showErrors={showErrors}
      showMaxLength={showMaxLength}
      switchField={switchField}
      tip={tip}
    >
      <div className={styles.inputWrapper}>
        {isTextarea ? (
          <>
            <textarea
              className={cName}
              disabled={disabled}
              maxLength={maxLength}
              placeholder={placeholder}
              ref={ref as ForwardedRef<HTMLTextAreaElement>}
              required={required}
              value={toString(value || '')}
              onChange={(e) => onChange(e.target.value)}
              onClick={onClick}
              onBlur={(e) => {
                onChange(e.target.value.trim());
                if (onBlur) {
                  onBlur(e);
                }
              }}
            />
            <div className={styles.customResizer}>
              <Icon
                aria-orientation="vertical"
                className={styles.resizerIcon}
                kind="resizer"
                role="separator"
                size="s"
              />
            </div>
          </>
        ) : (
          <input
            className={cName}
            disabled={disabled}
            maxLength={maxLength}
            placeholder={placeholder}
            ref={ref as ForwardedRef<HTMLInputElement>}
            required={required}
            type={type === 'number' ? 'string' : type}
            value={toString(value || '')}
            onClick={onClick}
            onBlur={(e) => {
              onChange(e.target.value.trim());
              if (onBlur) {
                onBlur(e);
              }
            }}
            onChange={(e) => {
              const newValue =
                type === 'number' && maxLength && e.target.value.length >= maxLength
                  ? e.target.value.slice(0, maxLength)
                  : e.target.value;

              if (type === 'number') {
                if (/^\d*$/.test(newValue)) {
                  onChange(newValue);
                }
              } else {
                onChange(newValue);
              }
            }}
          />
        )}
        {icon && !value && <Icon kind={icon} size="s" />}
      </div>
    </Field>
  );
});

import { useFetchClubSocialNetworksQuery } from 'entities/club-social-network/queries';
import { combineEntitySocialNetworks } from 'entities/socialNetworks/combineEntitySocialNetworks';
import { useFetchSocialNetworksQuery } from 'entities/socialNetworks/queries';
import { useMemo } from 'react';

export const useClubSocialNetworks = () => {
  const { data: { data: clubSocialNetworks = [] } = {} } =
    useFetchClubSocialNetworksQuery();
  const { data: { data: socialNetworks } = {} } = useFetchSocialNetworksQuery();

  const combinedSocialNetworks = useMemo(() => {
    if (!clubSocialNetworks || !socialNetworks) {
      return [];
    }

    return combineEntitySocialNetworks({
      entitySocialLinks: clubSocialNetworks,
      socialNetworks,
    });
  }, [clubSocialNetworks, socialNetworks]);

  return {
    socialLinks: combinedSocialNetworks,
  };
};

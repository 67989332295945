import { parseISO } from 'date-fns';
import { ETableElementStatus } from 'entities/common/types';
import { TStoryGroupData } from 'entities/stories/types';
import { getStatusByPublicationDate } from 'feature/story/getStatusByPublicationDate';
import { useStoryGroupActions } from 'pages/stories/StoriesPage/hooks/useStoryGroupActions';
import { TStoriesPageModal } from 'pages/stories/StoriesPage/types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { useModal } from 'shared/hooks/useModal';
import { TDefaultActions, TGroupAction } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';

export const StoriesActions = ({
  isActive,
  onIconClick,
  item: storyGroup,
  closeActiveItemId,
}: TTableActions<TStoryGroupData>) => {
  const { t } = useTranslation();

  const { openWithdrawModal, closeWithdrawModal, openedWithdrawModal } =
    useModal('Withdraw');

  const { id, published } = storyGroup;
  const status = getStatusByPublicationDate(published);
  const {
    editStoryGroup,
    changePublicationDate,
    publishNow,
    unpublish,
    deleteStoryGroup,
    isLoading,
  } = useStoryGroupActions();
  const [activeModal, setActiveModal] = useState<TStoriesPageModal | null>(null);
  const defaultStoryGroupActions: Record<TDefaultActions, TGroupAction> = {
    edit: {
      title: t('content.edit'),
      icon: 'edit-02',
      action: editStoryGroup,
    },
    delete: {
      title: t('content.delete'),
      icon: 'trash',
      action: () => setActiveModal('confirmDelete'),
    },
  };
  const actions = useMemo<TGroupAction[]>(() => {
    if (status === ETableElementStatus.Draft) {
      return [defaultStoryGroupActions.edit, defaultStoryGroupActions.delete];
    }
    if (status === ETableElementStatus.Published) {
      return [
        defaultStoryGroupActions.edit,
        {
          title: t('content.deleteFromPublication'),
          icon: 'no-eyes',
          action: openWithdrawModal,
        },
        defaultStoryGroupActions.delete,
      ];
    }
    if (status === ETableElementStatus.Postponed) {
      return [
        {
          title: t('content.publicNow'),
          icon: 'plus',
          action: () => setActiveModal('confirmPublishNow'),
        },
        defaultStoryGroupActions.edit,
        {
          title: t('content.changeTimePublic'),
          icon: 'clock',
          action: () => setActiveModal('changePublishDate'),
        },
        defaultStoryGroupActions.delete,
      ];
    }
    return [];
  }, [status]);
  const closeModal = () => {
    setActiveModal(null);
    closeActiveItemId();
  };
  const handleDateChange = (date: Date | undefined) => {
    changePublicationDate(storyGroup, date);
    closeModal();
  };
  const handleConfirm = () => {
    if (activeModal === 'confirmPublishNow') {
      publishNow(storyGroup);
    }
    if (activeModal === 'confirmDelete') {
      deleteStoryGroup(storyGroup);
    }
    closeModal();
  };
  const handleUnpublishStory = () => {
    unpublish(storyGroup);
    closeActiveItemId();
    closeWithdrawModal();
  };
  return (
    <>
      <Actions
        actions={actions}
        isLoading={isLoading}
        isOpen={isActive}
        itemId={id}
        onIconClick={onIconClick}
      />
      <DatePicker
        isOpened={activeModal === 'changePublishDate'}
        date={
          (isActive && storyGroup.published && parseISO(storyGroup.published)) ||
          undefined
        }
        onClose={closeModal}
        onDateChange={handleDateChange}
      />
      <ConfirmationModal
        description={t('content.story.publicStories')}
        isOpened={activeModal === 'confirmPublishNow'}
        title={t('content.confirm')}
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
      <ConfirmationModal
        description={t('shared.warningDelete')}
        isOpened={activeModal === 'confirmDelete'}
        title={t('shared.deleteTitle')}
        onClose={closeModal}
        onConfirm={handleConfirm}
      />
      <ConfirmationModal
        description={t('shared.NewsPostForm.AddNewsPostUnPublishButton.description')}
        isOpened={openedWithdrawModal}
        title={t('shared.NewsPostForm.AddNewsPostUnPublishButton.title')}
        onClose={closeWithdrawModal}
        onConfirm={handleUnpublishStory}
      />
    </>
  );
};

import {
  matchPlayerBaseUrl,
  matchPlayerButchesBaseUrl,
} from 'entities/matchPlayer/consts';
import { TApiMatchPlayer, TMatchPlayerDTO } from 'entities/matchPlayer/type';
import { apiRequestGenerator } from 'shared/api/apiRequestGenerator';

export const matchPlayerButchesApi = apiRequestGenerator<TMatchPlayerDTO>({
  baseUrl: matchPlayerButchesBaseUrl,
});

export const matchPlayerApi = apiRequestGenerator<TApiMatchPlayer>({
  baseUrl: matchPlayerBaseUrl,
});

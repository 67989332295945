import { queryClient } from 'app/Providers';
import { useLocales } from 'entities/config/lib/useLocales';
import { stadiumsQueryKeys } from 'entities/stadiums/consts';
import { useCreateStadiumMutation } from 'entities/stadiums/mutations';
import { useFetchStadiumsQuery } from 'entities/stadiums/queries';
import { useMemo } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { OldFormSelect } from 'shared/components/Form/OldSelect/OldFormSelect';
import { TSelectOption } from 'shared/components/Form/OldSelect/types';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { useModal } from 'shared/hooks/useModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toString } from 'shared/lib/toString';
import { TTranslations } from 'shared/types/common';
import { compareIds } from 'shared/utils/entityIds';

import styles from './StadiumsSelect.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  className?: string;
  required?: boolean;
};

export const StadiumsSelect = <T extends FieldValues>({
  control,
  fieldName,
  className,
  required,
}: TProps<T>) => {
  const { t } = useTranslation();
  const { locales, defaultLocale } = useLocales();

  const {
    field: { onChange: changeSelectedStadium, value: selectedStadium },
  } = useController({
    control,
    name: fieldName,
  });

  const { openedModal, closeModal, openModal } = useModal();

  const createStadiumMutation = useCreateStadiumMutation();

  const createStadium = (stadiumName: TTranslations<string>) => {
    createStadiumMutation.mutate(
      {
        translations: reduceTranslationObject(locales, (locale) => ({
          name: stadiumName[locale],
        })),
      },
      {
        onSuccess: (stadium) => {
          changeSelectedStadium(toString(stadium.id));
          closeModal();
          return queryClient.invalidateQueries([stadiumsQueryKeys.stadiums]);
        },
      },
    );
  };

  const { data: { data: stadiums = [] } = {} } = useFetchStadiumsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const stadiumOptions = useMemo(
    () =>
      stadiums.map<TSelectOption>((stadium) => ({
        value: toString(stadium.id),
        label: stadium.translations[defaultLocale].name,
        size: 's',
      })),
    [stadiums],
  );

  const stadiumPlaceholder = useMemo(() => {
    if (selectedStadium && stadiumOptions.length) {
      return stadiumOptions.find((stadium) =>
        compareIds(stadium.value, selectedStadium),
      )?.label;
    }
    return t('content.stadiums.chooseStadium');
  }, [selectedStadium, stadiumOptions]);

  return (
    <>
      <OldFormSelect
        className={className}
        control={control}
        fieldName={fieldName}
        label={t('content.stadiums.stadium')}
        multiple={false}
        options={stadiumOptions}
        optionsContainerClassName={styles.optionsContainer}
        placeholder={stadiumPlaceholder}
        required={required}
        search
        downButton={{
          onClick: openModal,
          text: t('content.stadiums.createNewStadium'),
        }}
      />
      <LocalizationModal
        actionSubmit={createStadium}
        closeModal={closeModal}
        isOpened={openedModal}
        title={t('content.matches.createStadium')}
        inputProps={{
          placeholder: t('content.inputPlaceholder'),
          required: true,
          size: 's',
        }}
      />
    </>
  );
};

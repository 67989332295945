import cn from 'classnames';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Pic } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './ActionButtonADS.module.css';

type TActionButtonADSProps = {
  imageSrc: string;
  title: string;
  description: string;
  active: boolean;
  disabled?: boolean;
  onClick: VoidFunction;
};

export const ActionButtonADS = ({
  imageSrc,
  title,
  description,
  active,
  disabled,
  onClick,
}: TActionButtonADSProps) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={cn(styles.button, {
        [styles.active]: active,
        [styles.disabled]: disabled,
      })}
      onClick={!disabled ? onClick : undefined}
    >
      <div className={styles.imageRadioWrapper}>
        <Pic
          alt={title}
          className={styles.image}
          src={imageSrc}
          placeholder={{
            type: 'image',
            size: 'm',
          }}
        />
        <RadioButton checked={active} className={styles.radio} />
      </div>
      <div className={styles.content}>
        <Typography
          className={styles.title}
          color="var(--gray-500)"
          size={14}
          tag="h3"
          weight="SemiBold"
        >
          {title}
        </Typography>
        <Typography
          className={styles.description}
          color="var(--gray-400)"
          size={12}
          tag="p"
          weight="Regular"
        >
          {description}
        </Typography>
      </div>
    </button>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { TFormInputProps } from 'shared/components/Form/types';
import { localizationModalValidationSchema } from 'shared/components/LocalizationModal/validationSchema';
import { Typography } from 'shared/components/Typography/Typography';
import { getLangNameMap } from 'shared/consts';
import { useForm } from 'shared/hooks/useReactHookForm';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang, TTranslations } from 'shared/types/common';

import styles from './LocalizationModal.module.css';

export type TLocalizationModalProps = {
  title: string;
  subtitle?: string;
  closeModal: () => void;
  actionSubmit: (data: TTranslations<string>) => void;
  createInitialValue?: (locale: TContentLang) => string;
  isOpened: boolean;
  inputProps?: Omit<TFormInputProps<TTranslations<string>>, 'control' | 'fieldName'>;
};

export function LocalizationModal({
  title,
  subtitle,
  closeModal,
  actionSubmit,
  createInitialValue,
  isOpened,
  inputProps,
}: TLocalizationModalProps) {
  const { locales } = useLocales();

  const {
    control,
    submit,
    formState: { isValid },
  } = useForm<TTranslations<string>>({
    values: reduceTranslationObject(locales, createInitialValue ?? (() => '')),
    resolver: zodResolver(
      localizationModalValidationSchema(locales, inputProps?.required ?? false),
    ),
    actionSubmit: (data) => {
      actionSubmit(data);
      closeModal();
    },
    needReset: !isOpened,
    needResetAfterSubmit: true,
  });

  return (
    <ActionModal
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValid,
        onClick: submit,
      }}
      onClose={closeModal}
    >
      {subtitle && (
        <Typography size={14} tag="h3" weight="SemiBold">
          {subtitle}
        </Typography>
      )}
      <div className={styles.form}>
        {locales?.map((locale) => (
          <FormInput
            key={locale}
            className={inputProps?.className}
            control={control}
            fieldName={locale}
            inputClassName={styles.input}
            label={getLangNameMap()[locale]}
            {...(inputProps ?? {})}
          />
        ))}
      </div>
    </ActionModal>
  );
}

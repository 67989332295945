import { useCanUser } from 'entities/user/lib/useCanUser';
import { TProtectedRoutes } from 'shared/types/router';

export const useProtectedRoutes = (routes: TProtectedRoutes) => {
  const canUser = useCanUser();

  const filterRoutes = (routes: TProtectedRoutes): TProtectedRoutes =>
    routes.reduce<TProtectedRoutes>((acc, route) => {
      const hasPermission = route.roles && canUser(route.roles);

      if (!hasPermission) {
        return acc;
      }

      if (route.children) {
        return [...acc, { ...route, children: filterRoutes(route.children) }];
      }
      return [...acc, route];
    }, []);

  return filterRoutes(routes);
};

import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { Icon } from 'shared/components/Icon/Icon';
import { Pic } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';
import { TContentLang } from 'shared/types/common';

import styles from './PreviewImage.module.css';

type TProps = {
  selectedLocale: TContentLang;
  previewImage: string;
  name: string;
  onSettingsClick: () => void;
};

export const PreviewImage = ({
  selectedLocale,
  previewImage,
  name,
  onSettingsClick,
}: TProps) => {
  const { defaultLocale } = useLocales();

  return (
    <div className={styles.previewImageWrapper}>
      <Pic
        alt=""
        src={previewImage}
        placeholder={{
          type: 'image',
          size: 'm',
        }}
      />
      <div
        role="button"
        className={cn(styles.settings, {
          [styles.settingsDisabled]: selectedLocale !== defaultLocale,
        })}
        onClick={onSettingsClick}
      >
        <Icon kind="settings-02" size="s" />
      </div>
      {name.length > 0 && (
        <Typography className={styles.storyTitle} weight="SemiBold">
          {name}
        </Typography>
      )}
    </div>
  );
};

import {
  EEditorElementType,
  EEditorMarks,
  TEditorBulletedListElement,
  TEditorDefaultText,
  TEditorHeadingTwoElement,
  TEditorLinkElement,
  TEditorParagraphElement,
} from 'shared/components/Editor/types';
import { TWorkspaceParagraphElement } from 'shared/components/Workspace/types';
import { TContentLang } from 'shared/types/common';
import { Element, Text } from 'slate';
import { jsx } from 'slate-hyperscript';

type TProps = {
  el: HTMLElement;
  markAttributes?: Record<EEditorMarks, boolean>;
};

export const deserializeWorkspaceElements = (
  element: TWorkspaceParagraphElement,
  lang: TContentLang,
) => {
  const textElement = element.translations[lang]!.text;
  const parse = new DOMParser().parseFromString(textElement, 'text/html');
  const parsedEditorElement = deserialize({
    el: parse.body,
  }) as (
    | TEditorDefaultText
    | TEditorHeadingTwoElement
    | TEditorBulletedListElement
  )[];

  const isEditorElement =
    parsedEditorElement.length === 1 && Element.isElement(parsedEditorElement[0]);

  if (isEditorElement) {
    const editorElement = parsedEditorElement[0] as
      | TEditorHeadingTwoElement
      | TEditorBulletedListElement;

    return {
      id: element.id,
      ...editorElement,
      translations: {
        ...element.translations,
        ...editorElement.translations,
      },
    };
  }

  const paragraphElement = jsx(
    'element',
    { type: EEditorElementType.Paragraph },
    parsedEditorElement,
  ) as TEditorParagraphElement;

  const isEmptyParagraph =
    paragraphElement.children.length === 1 &&
    Text.isText(paragraphElement.children[0]) &&
    paragraphElement.children[0].text === '\n';

  return {
    id: element.id,
    ...paragraphElement,
    children: isEmptyParagraph ? [{ text: '' }] : paragraphElement.children,
    translations: {
      ...element.translations,
      ...paragraphElement.translations,
    },
  };
};

const deserialize = ({
  el,
  markAttributes = {} as Record<EEditorMarks, boolean>,
}: TProps) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  }

  const nodeAttributes = { ...markAttributes };

  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'DEL':
      nodeAttributes.deleted = true;
      break;
    case 'EM':
      nodeAttributes.italic = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
      break;
  }

  const children = Array.from(el.childNodes)
    .map((node) =>
      deserialize({
        markAttributes: nodeAttributes,
        el: node as HTMLElement,
      }),
    )
    .flat() as (TEditorDefaultText | TEditorLinkElement)[];

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'H2':
      return jsx('element', { type: EEditorElementType.HeadingTwo }, children);
    case 'UL':
      return jsx('element', { type: EEditorElementType.BulletedList }, children);
    case 'LI':
      return jsx('element', { type: EEditorElementType.ListItem }, children);
    case 'A':
      return jsx(
        'element',
        {
          type: EEditorElementType.Link,
          url: (el as HTMLElement).getAttribute('href'),
        },
        children,
      );
    default:
      return children;
  }
};

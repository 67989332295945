import cn from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';
import React from 'react';
import { TCheckboxSize } from 'shared/components/Form/types';

import styles from './RadioButton.module.css';
import type { TChangeRadioButtonHandler } from './types';

type TProps<VALUE extends string | number> = {
  name?: string;
  className?: string;
  value?: VALUE;
  checked?: boolean;
  disabled?: boolean;
  label?: ReactNode;
  size?: TCheckboxSize;
  onChange?: TChangeRadioButtonHandler<VALUE>;
  onClick?: MouseEventHandler<HTMLInputElement>;
};

export const RadioButton = <VALUE extends string | number = string | number>({
  checked,
  disabled,
  label,
  className,
  size = 'm',
  name,
  value,
  onChange,
  onClick,
}: TProps<VALUE>) => {
  const handleChange = () => {
    if (disabled) {
      return;
    }
    if (name && (value || value === 0) && onChange) {
      onChange(name, value);
    }
  };

  return (
    <label
      className={cn(styles.root, styles[size], className, {
        [styles.disabled]: disabled,
      })}
    >
      <input
        checked={checked}
        name={name}
        type="radio"
        value={value}
        onChange={handleChange}
        onClick={onClick}
      />
      <span onClick={onClick}>{label}</span>
    </label>
  );
};

import cn from 'classnames';
import { Icon } from 'shared/components/Icon/Icon';
import { TPicPlaceholderSoccerTeamSize } from 'shared/components/Pic/type';

import styles from './PicPlaceholderSoccerTeam.module.css';

type TProps = {
  size?: TPicPlaceholderSoccerTeamSize;
};

export const PicPlaceholderSoccerTeam = ({ size = 32 }: TProps) => {
  return (
    <Icon
      className={cn(styles.icon, styles[`size${size}`])}
      customSize
      kind="soccer-team-placeholder"
    />
  );
};

import { EditNewsPost } from 'pages/news/edit/EditNewsPost/EditNewsPost';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const EditNewsRoute = {
  path: routes.editNewsPost.url,
  element: <EditNewsPost />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

import { EEditorMarks, TEditor } from 'shared/components/Editor/types';
import { editorMarkController } from 'shared/components/Editor/utils/mark/editorMarkController';

export const useWorkspacePanelMarksActions = (editor: TEditor) => {
  const isBold = () => {
    return editorMarkController.isMarkActive(editor, EEditorMarks.Bold);
  };

  const toggleBold = () => {
    editorMarkController.toggleMark(editor, EEditorMarks.Bold);
  };

  const isItalic = () => {
    return editorMarkController.isMarkActive(editor, EEditorMarks.Italic);
  };

  const toggleItalic = () => {
    return editorMarkController.toggleMark(editor, EEditorMarks.Italic);
  };

  const isUnderline = () => {
    return editorMarkController.isMarkActive(editor, EEditorMarks.Underline);
  };

  const toggleUnderline = () => {
    editorMarkController.toggleMark(editor, EEditorMarks.Underline);
  };

  const isDeleted = () => {
    return editorMarkController.isMarkActive(editor, EEditorMarks.Deleted);
  };

  const toggleDeleted = () => {
    editorMarkController.toggleMark(editor, EEditorMarks.Deleted);
  };

  return {
    isBold,
    toggleBold,
    isItalic,
    toggleItalic,
    isUnderline,
    toggleUnderline,
    isDeleted,
    toggleDeleted,
  };
};

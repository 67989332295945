import { useQueryClient } from '@tanstack/react-query';
import { useLocales } from 'entities/config/lib/useLocales';
import { opponentPlayersQueryKeys } from 'entities/opponentPlayers/consts';
import { useCreateOpponentPlayerMutation } from 'entities/opponentPlayers/mutations';
import { mapOpponentPlayerFieldsToDTO } from 'feature/opponentPlayerModal/mapper/mapOpponentPlayerFieldsToDTO';
import { TOpponentPlayerModalFields } from 'feature/opponentPlayerModal/types';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';

export const useCreateOpponentPlayer = (teamId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { openModal, closeModal, openedModal } = useModal();
  const { mutateAsync, isLoading } = useCreateOpponentPlayerMutation();
  const { locales } = useLocales();
  const createOpponentPlayer = async (
    createOpponentPlayerModalFields: TOpponentPlayerModalFields,
  ) => {
    const dto = await mapOpponentPlayerFieldsToDTO(
      createOpponentPlayerModalFields,
      teamId,
      locales,
    );
    await mutateAsync(dto, {
      onSuccess: () => {
        queryClient.resetQueries([opponentPlayersQueryKeys.opponentPlayers]);
      },
      onError: () => {
        notify(t('content.validation.notifyCreatePlayerOpponent'), {
          type: 'error',
        });
      },
      onSettled: closeModal,
    });
  };
  return {
    isLoading,
    createOpponentPlayer,
    openModal,
    closeModal,
    openedModal,
  };
};

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  fetchTournamentSeasons,
  fetchTournamentSeasonsItem,
} from 'entities/tournament-season/api';
import { tournamentSeasonQueryKeys } from 'entities/tournament-season/consts';
import {
  TTournamentSeasons,
  TTournamentSeasonsInclude,
} from 'entities/tournament-season/types';
import { TFetchTournamentSeasonsParams } from 'entities/tournament-season/utils';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { TEntityId } from 'shared/types/common';
import { mapTournamentSeasonFormFields } from 'widgets/tournamentSeasonsForm/mapper/mapTournamentSeasonFormFields';
import { TTournamentSeasonsFields } from 'widgets/tournamentSeasonsForm/ui/TournamentSeasonsForm/types';

export const useFetchTournamentSeasonsQuery = <
  Include extends TTournamentSeasonsInclude = never,
>({
  params,
  options = {},
}: {
  params: TFetchTournamentSeasonsParams;
  options?: UseQueryOptions<
    TResponseData<TTournamentSeasons<Include>[], TPaginatedMeta>
  >;
}) => {
  const { include, filter, sort, ...defaultPagination } = params;

  return usePaginationQuery(
    (pagination) => ({
      queryKey: [
        tournamentSeasonQueryKeys.tournamentSeason,
        pagination,
        filter,
        sort,
      ],
      queryFn: () =>
        fetchTournamentSeasons<Include>({
          params: {
            ...pagination,
            ...filter,
            sort,
            include: include?.join(','),
          },
        }),
      ...options,
    }),
    defaultPagination,
  );
};

export const useFetchTournamentSeasonsItemQuery = <
  Include extends TTournamentSeasonsInclude = never,
>({
  id = '',
  params = {},
  options,
}: {
  id: TEntityId;
  params?: TFetchTournamentSeasonsParams;
  options?: UseQueryOptions<TTournamentSeasonsFields>;
}) => {
  const { include } = params;

  return useQuery<TTournamentSeasonsFields>(
    [tournamentSeasonQueryKeys.tournamentSeasonId(id)],
    async () =>
      await mapTournamentSeasonFormFields(
        await fetchTournamentSeasonsItem<Include>(id, {
          params: {
            ...params,
            include: include?.join(','),
          },
        }),
      ),
    options,
  );
};

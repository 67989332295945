import { TApiMatchPlayer, TMatchPlayer } from 'entities/matchPlayer/type';

export const mapApiMatchPlayersToDTO = (
  apiMatchPlayers: TApiMatchPlayer[],
): TMatchPlayer[] => {
  return apiMatchPlayers?.map((player) => ({
    ...player,
    matchId: player?.match,
    playerId: player?.player,
  }));
};

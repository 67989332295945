import commonElementFormStyles from 'pages/stories/edit/EditStory/components/forms/ElementForm.module.css';
import { AnswerInput } from 'pages/stories/edit/EditStory/components/forms/QuizElementForm/components/AnswerInputs/AnswerInput';
import {
  TGetSwitchFieldFunction,
  TQuizAnswersArray,
  TQuizControl,
} from 'pages/stories/edit/EditStory/components/forms/QuizElementForm/types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';

type TProps = {
  control: TQuizControl;
  fields: TQuizAnswersArray;
  getSwitchField: TGetSwitchFieldFunction;
};

export const AnswerInputs = memo(({ control, fields, getSwitchField }: TProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        className={commonElementFormStyles.subtitle}
        size={14}
        tag="h2"
        weight="Regular"
      >
        {t('content.story.optionAnswer')}
      </Typography>
      {fields.map((field, index) => (
        <AnswerInput
          key={field.id}
          control={control}
          field={field}
          index={index}
          switchField={getSwitchField({ index, field })}
        />
      ))}
    </>
  );
});

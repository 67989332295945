import { useLocales } from 'entities/config/lib/useLocales';
import { TPlayerCareer } from 'entities/playerCareers/types';
import { useTranslation } from 'react-i18next';
import { ImageCell } from 'shared/components/SimpleTable/components/ImageCell/ImageCell';
import { Typography } from 'shared/components/Typography/Typography';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './PlayerCareersPage.module.css';

export const usePlayerCareersColumnsConfig: TConfigCreator<TPlayerCareer> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();

  return [
    {
      title: t('content.club'),
      render: (item) => (
        <ImageCell
          picProps={{ src: item.teamPicture.x1 }}
          title={item?.translations[defaultLocale]?.teamName}
        />
      ),
    },
    {
      title: t('content.season'),
      render: (item) => (
        <Typography className={styles.season}>{item.season}</Typography>
      ),
    },
    {
      title: t('content.player.championshipGames'),
      render: (item) => <Typography>{item.championshipCount}</Typography>,
    },
    {
      title: t('content.player.cupGames'),
      render: (item) => <Typography>{item.cupsCount}</Typography>,
    },
    {
      title: t('content.player.euroCupGames'),
      render: (item) => <Typography>{item.eurocupsCount}</Typography>,
    },
    {
      title: t('content.player.games'),
      render: (item) => <Typography>{item.gamesCount || '-'}</Typography>,
    },
    {
      title: 'ID',
      render: (item) => <Typography>{item.id}</Typography>,
    },
  ];
};

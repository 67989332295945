import { queryClient } from 'app/Providers';
import { useLocales } from 'entities/config/lib/useLocales';
import { tournamentQueryKeys } from 'entities/tournaments/consts';
import {
  useDeleteTournamentMutation,
  useEditTournamentMutation,
} from 'entities/tournaments/mutations';
import { TBaseTournament } from 'entities/tournaments/types';
import { MAX_TOURNAMENT_INPUT_LENGTH } from 'pages/settings/tournaments/TournamentsPage/const';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { useModal } from 'shared/hooks/useModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TGroupAction, TTranslations } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';

export const TournamentsActions = ({
  isActive,
  onIconClick,
  item: tournament,
  closeActiveItemId,
}: TTableActions<TBaseTournament>) => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const { openedEditModal, closeEditModal, openEditModal } = useModal('Edit');
  const { openDeleteModal, closeDeleteModal, openedDeleteModal } =
    useModal('Delete');

  const { mutateAsync: editTournament, isLoading: isLoadingEdit } =
    useEditTournamentMutation();

  const { mutateAsync: deleteTournament, isLoading: isLoadingDelete } =
    useDeleteTournamentMutation();

  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditModal,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: openDeleteModal,
    },
  ];

  const closeEditModalHandler = () => {
    closeActiveItemId();
    closeEditModal();
  };

  const editTournamentData = async (data: TTranslations<string>) => {
    closeEditModal();
    await editTournament(
      {
        translations: reduceTranslationObject(locales, (locale) => ({
          name: data[locale],
        })),
        id: tournament.id,
      },
      {
        onSuccess: () => {
          return queryClient.invalidateQueries([tournamentQueryKeys.tournaments]);
        },
      },
    );
    closeActiveItemId();
  };

  const deleteTournamentData = async () => {
    closeModalHandler();
    await deleteTournament(tournament.id, {
      onSuccess: () => {
        return queryClient.invalidateQueries([tournamentQueryKeys.tournaments]);
      },
    });
  };

  const closeModalHandler = () => {
    closeDeleteModal();
    closeActiveItemId();
  };

  return (
    <>
      <Actions
        actions={actions}
        isLoading={isLoadingEdit || isLoadingDelete}
        isOpen={isActive}
        itemId={tournament.id}
        onIconClick={onIconClick}
      />
      <ConfirmationModal
        isOpened={openedDeleteModal}
        title={t('content.settings.deleteTournament')}
        onClose={closeModalHandler}
        onConfirm={deleteTournamentData}
      />
      <LocalizationModal
        actionSubmit={editTournamentData}
        closeModal={closeEditModalHandler}
        createInitialValue={(locale) => tournament?.translations[locale].name ?? ''}
        isOpened={openedEditModal}
        subtitle={t('content.title')}
        title={t('content.settings.editTournament')}
        inputProps={{
          maxLength: MAX_TOURNAMENT_INPUT_LENGTH,
          placeholder: t('content.inputPlaceholderText'),
          required: true,
          size: 's',
        }}
      />
    </>
  );
};

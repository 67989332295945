import { CreateAdBannerPage } from 'pages/adBanners/create/CreateAdBannerPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const CreateAdBannerRoute = {
  path: routes.createAdBanner,
  element: <CreateAdBannerPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

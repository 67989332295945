import { EditAdBannerPage } from 'pages/adBanners/edit/EditAdBannerPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const EditAdBannerRoute = {
  path: routes.editAdBanner.url,
  element: <EditAdBannerPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

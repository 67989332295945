import { useLocales } from 'entities/config/lib/useLocales';
import { TLabel } from 'entities/labels/types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './LabelsPage.module.css';

export const useLabelsColumnsConfig: TConfigCreator<TLabel> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();
  return [
    {
      title: t('content.title'),
      cellClassName: styles.publicationNameColumn,
      render: (item) => (
        <Typography>{item?.translations[defaultLocale]?.name}</Typography>
      ),
    },
  ];
};

import { TTableSubComponents, TTableWithRef } from 'shared/components/SimpleTable';
import { TCommonCellProps } from 'shared/components/SimpleTable/components/THeadCell/THeadCell';
import { Typography } from 'shared/components/Typography/Typography';
import { TColumnConfig, TDataItem } from 'widgets/elementsList/types';

type TProps<T extends TDataItem, SortBy extends string> = {
  tableComponent: TTableWithRef & TTableSubComponents<SortBy>;
} & Omit<TColumnConfig<T, SortBy>, 'headCellProps'> & {
    headCellProps: TCommonCellProps;
  };
export const CommonListTableHeadCell = <T extends TDataItem, SortBy extends string>({
  title,
  tableComponent: Table,
  headCellProps = {},
}: TProps<T, SortBy>) => {
  return (
    <Table.HeadCell {...headCellProps}>
      <Typography>{title}</Typography>
    </Table.HeadCell>
  );
};

import { useQuery } from '@tanstack/react-query';
import { fetchClub } from 'entities/club/api';
import { clubQueryKeys } from 'entities/club/consts';
import { useLocales } from 'entities/config/lib/useLocales';
import { mapClubToClubFormFields } from 'pages/aboutClubPage/mapper';

export const useGetClubFormFields = () => {
  const { locales, defaultLocale } = useLocales();

  return useQuery({
    queryKey: [clubQueryKeys.club],
    queryFn: () =>
      fetchClub().then(async (club) => ({
        club,
        clubFormFields: await mapClubToClubFormFields(club, locales, defaultLocale),
      })),
    cacheTime: 0,
  });
};

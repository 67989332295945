import cn from 'classnames';
import { noop } from 'lodash';
import { forwardRef, memo } from 'react';
import { BaseButton } from 'shared/components/BaseButton';
import { Icon } from 'shared/components/Icon/Icon';
import { TIconType } from 'shared/components/Icon/types';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './ToolButton.module.css';

type TProps = {
  className?: string;
  onClick(): void;
  title: string;
  icon: TIconType;
  disabled?: boolean;
  isLoading?: boolean;
};

export const ToolButton = memo(
  forwardRef<HTMLButtonElement, TProps>(
    ({ onClick, title, icon, disabled, className, isLoading }, ref) => {
      return (
        <BaseButton
          className={cn(styles.button, className)}
          htmlType="button"
          isDisabled={disabled}
          isLoading={isLoading}
          isSingleLineTitle={false}
          loaderSize="s"
          ref={ref}
          onClick={isLoading ? noop : onClick}
        >
          {!isLoading && (
            <>
              <Icon kind={icon} />
              <Typography size={12}>{title}</Typography>
            </>
          )}
        </BaseButton>
      );
    },
  ),
);

ToolButton.displayName = 'ToolButton';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { tacticsQueryKeys } from 'entities/tactics/constants';
import { TFetchPlayersPositionsParams, TTactic } from 'entities/tactics/types';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchTactic, fetchTactics } from './api';

export const useFetchTacticsQuery = () =>
  useQuery({
    queryKey: [tacticsQueryKeys.tactics],
    queryFn: fetchTactics,
  });

export const useFetchTacticQuery = ({
  id = '',
  params = {},
  options = {},
}: {
  id: TEntityId;
  params?: TFetchPlayersPositionsParams;
  options?: UseQueryOptions<TTactic, unknown, TTactic>;
}) => {
  const { include } = params;

  return useQuery({
    queryKey: [tacticsQueryKeys.tactics, id],
    queryFn: () =>
      fetchTactic(id, {
        params: {
          ...params,
          include: include?.join(','),
        },
      }),
    enabled: toBoolean(id),
    cacheTime: 0,
    ...options,
  });
};

import cn from 'classnames';
import { useFetchAdBanners } from 'entities/ad/banners/model/queries';
import { AdBannerCard } from 'pages/adBanners/list/AdBannerCard';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CARD_WIDTH } from 'shared/components/Card/Card';
import { CardButton } from 'shared/components/CardButton/CardButton';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { Typography } from 'shared/components/Typography/Typography';
import { useCalcDynamicPagination } from 'shared/hooks/useCalcDynamicPagination';
import { routes } from 'shared/routes';
import { EmptyListPage } from 'widgets/elementsList/ui/EmptyList/EmptyListPage';

import styles from './AdBannersPage.module.css';

const PAGE_GAP = 24;
const CREATE_BUTTON_COUNT = 1;

export const AdBannersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { dynamicItems, isCalculateItems } = useCalcDynamicPagination({
    parent: wrapperRef,
    itemWidth: CARD_WIDTH,
    wrapperGap: PAGE_GAP,
  });

  const {
    data: {
      data: adBanners = [],
      meta: { total = 0, pageSize = 0, page = 1 } = {},
    } = {},
    error,
    isLoading,
    changePage,
  } = useFetchAdBanners(
    { pageSize: dynamicItems - CREATE_BUTTON_COUNT },
    { cacheTime: 0, enabled: isCalculateItems },
  );

  if (error) {
    throw error;
  }

  const onCreateButtonClick = () => {
    navigate(routes.createAdBanner);
  };

  const showEmptyPage = total === 0 && !isLoading;
  const showPagination = total > 0 && total > pageSize;

  if (isLoading) {
    return (
      <div className={cn(styles.root, styles.loading)} ref={wrapperRef}>
        <Centered>
          <Loader showLabel />
        </Centered>
      </div>
    );
  }

  if (showEmptyPage) {
    return (
      <div className={styles.root} ref={wrapperRef}>
        <EmptyListPage
          createButtonContent={t('shared.create')}
          message={t('content.adBanners.page.emptyTitle')}
          onCreateButtonClick={onCreateButtonClick}
        />
      </div>
    );
  }

  return (
    <div className={styles.root} ref={wrapperRef}>
      <Typography size={24} tag="h1" weight="Bold">
        {t('content.adBanners.page.title')}
      </Typography>
      <div className={styles.listWrapper} style={{ gap: `${PAGE_GAP}px` }}>
        <CardButton title={t('shared.create')} onClick={onCreateButtonClick} />
        {adBanners.map((item) => {
          return <AdBannerCard key={item.id} adBanner={item} />;
        })}
      </div>
      {showPagination && (
        <Pagination
          className={styles.pagination}
          pageSize={pageSize}
          selectedPage={page}
          totalItems={total}
          onPageChange={changePage}
        />
      )}
    </div>
  );
};

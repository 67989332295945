import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import iphone13mockup from 'shared/assets/images/iphone-13Mockup.png';
import defaultElementsStyles from 'shared/components/Editor/elements/editorElementsStyle.module.css';
import { EEditorElementType } from 'shared/components/Editor/types';
import { AddNewPostPreviewVideoElement } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewElements/AddNewPostPreviewVideoElement';
import { AddNewsPostPreviewImageElement } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewElements/AddNewsPostPreviewImageElement';
import { AddNewsPostPreviewParagraphElement } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewElements/AddNewsPostPreviewParagraphElement';
import { AddNewsPostPreviewSliderElement } from 'shared/components/NewsPostForm/components/AddNewsPostPreview/AddNewsPostPreviewElements/AddNewsPostPreviewSliderElement';
import { TEditNewsPostFields } from 'shared/components/NewsPostForm/types';
import { Pic } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './AddNewsPostPreview.module.css';

const elementMapper = {
  [EEditorElementType.Paragraph]: AddNewsPostPreviewParagraphElement,
  [EEditorElementType.Image]: AddNewsPostPreviewImageElement,
  [EEditorElementType.Slider]: AddNewsPostPreviewSliderElement,
  [EEditorElementType.Video]: AddNewPostPreviewVideoElement,
};
export const AddNewsPostPreviewContent = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<TEditNewsPostFields>();
  const {
    content: { preview, title, body },
    locale: { selected: selectedLocale },
  } = getValues();

  const filePreview = preview.translations[selectedLocale].data;

  return (
    <>
      <img alt="iphone 13 mockup" className={styles.mockup} src={iphone13mockup} />
      <div className={styles.content}>
        <div className={styles.previewImageWrapper}>
          <Pic
            alt="news preview image"
            placeholderClassName={styles.imagePlaceholder}
            src={filePreview}
            placeholder={{
              size: 'm',
              type: 'image',
            }}
          />
        </div>
        <div className={cn(styles.body, defaultElementsStyles.root)}>
          <Typography className={styles.title} size={12} weight="SemiBold">
            {title.translations[selectedLocale].title ||
              t('shared.NewsPostForm.AddPostPreviewContent.title')}
          </Typography>
          {body.map((element, index) => {
            const Element = elementMapper[element.type];
            return <Element key={index} element={element} lang={selectedLocale} />;
          })}
        </div>
      </div>
    </>
  );
};

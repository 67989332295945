import { TSettingsNavigationProps } from 'feature/settingsNavigation/types';
import { SettingsNavigationItem } from 'feature/settingsNavigation/ui/SettingsNavigationItem/SettingsNavigationItem';
import { SettingsNavigationLink } from 'feature/settingsNavigation/ui/SettingsNavigationLink/SettingsNavigationLink';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';
import { routes } from 'shared/routes';

import styles from './SettingsNavigation.module.css';

export const SettingsNavigation = ({ links }: TSettingsNavigationProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Typography color="var(--dark-blue-400)" size={20} tag="h2" weight="Bold">
        {t('content.settings.general')}
      </Typography>
      <SettingsNavigationLink
        key="language"
        className={styles.link}
        link={{
          name: t('content.options.language'),
          route: routes.settingsLanguages,
        }}
      />
      <Typography color="var(--dark-blue-400)" size={20} tag="h2" weight="Bold">
        {t('content.options')}
      </Typography>
      {links.map((navigationItem) => (
        <SettingsNavigationItem
          key={navigationItem.name}
          navigationItem={navigationItem}
        />
      ))}
    </div>
  );
};

import axios from 'axios';
import { APIS, DEFAULT_SANDBOX } from 'shared/api/constants';
import {
  handleApiOnRejection,
  handleApiOnRequest,
  handleApiOnResponse,
} from 'shared/api/interceptors';
import { TAPIName } from 'shared/api/types';
import { ELocalStorageKeys, isProduction } from 'shared/constants';
import { apiFilterArrayParamsSerializer } from 'shared/lib/apiFilterArrayParamsSerializer';

const savedSandbox = localStorage.getItem(
  ELocalStorageKeys.backendSandbox,
) as TAPIName;

const getApiBaseURL = () => {
  if (isProduction) {
    return process.env.API_URL;
  }

  if (savedSandbox) {
    return APIS[savedSandbox];
  }

  return APIS[DEFAULT_SANDBOX];
};

export const apiClient = axios.create({
  baseURL: getApiBaseURL(),
  paramsSerializer: apiFilterArrayParamsSerializer,
});

apiClient.interceptors.response.use(handleApiOnResponse, handleApiOnRejection);
apiClient.interceptors.request.use(handleApiOnRequest);

export const setApiBaseURL = (value: TAPIName) => {
  apiClient.defaults.baseURL = APIS[value];
};

import { zodResolver } from '@hookform/resolvers/zod';
import { useConfig } from 'entities/config/lib/useConfig';
import { useTranslation } from 'react-i18next';
import { useForm } from 'shared/hooks/useReactHookForm';
import { TEntityId } from 'shared/types/common';
import { generateAdBannerFormValues } from 'widgets/adBannerForm/generateAdBannerFormValues';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';
import { adBannerFormValidationSchema } from 'widgets/adBannerForm/ui/AdBannerForm/validationSchema';

export type TAdBannerFormProps = {
  defaultSlotId: TEntityId;
  values?: TAdBannerFormFields;
};

export const useAdBannerForm = ({ values, defaultSlotId }: TAdBannerFormProps) => {
  const { config } = useConfig();
  const { t } = useTranslation();

  return useForm<TAdBannerFormFields>({
    defaultValues: generateAdBannerFormValues(defaultSlotId),
    values,
    mode: 'onBlur',
    resolver: zodResolver(
      adBannerFormValidationSchema(t, config?.whitelistDomains ?? []),
    ),
    resetOptions: {
      keepDefaultValues: true,
    },
  });
};

import { TSettingsNavigationLink } from 'feature/settingsNavigation/types';
import { SettingsNavigationLink } from 'feature/settingsNavigation/ui/SettingsNavigationLink/SettingsNavigationLink';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './SettingsNavigationItem.module.css';

export type TProps = {
  navigationItem: TSettingsNavigationLink;
};

export const SettingsNavigationItem = ({ navigationItem }: TProps) => (
  <div className={styles.wrapper}>
    <Typography className={styles.categoryTitle} size={12} tag="p">
      {navigationItem.name}
    </Typography>

    {navigationItem.subLinks.map((link) => (
      <SettingsNavigationLink key={link.name} className={styles.link} link={link} />
    ))}
  </div>
);

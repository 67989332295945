import { TEntityId } from 'shared/types/common';

export const tournamentSeasonQueryKeys = {
  tournamentSeason: 'tournamentSeason',
  tournamentSeasonId: (id: TEntityId) => `tournamentSeason/${id}`,
};

const tournamentSeasonBaseUrl = 'tournament_seasons';

export const tournamentSeasonApiKeys = {
  getTournamentSeasons: tournamentSeasonBaseUrl,
  createTournamentSeasons: tournamentSeasonBaseUrl,
  getTournamentSeasonItem: (id: TEntityId) => `${tournamentSeasonBaseUrl}/${id}`,
  editTournamentSeasons: (id: TEntityId) => `${tournamentSeasonBaseUrl}/${id}`,
  deleteTournamentSeasons: (id: TEntityId) => `${tournamentSeasonBaseUrl}/${id}`,
};

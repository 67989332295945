import { useController } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useModal } from 'shared/hooks/useModal';
import { TVideoPostFields } from 'widgets/videoForm/types';

type TUseVideoFormUnpublishButtonArgs = Partial<UseFormReturn<TVideoPostFields>> & {
  editVideoPost: (data: TVideoPostFields) => Promise<void>;
};

export const useVideoFormUnpublishButton = ({
  editVideoPost,
  control,
  handleSubmit,
}: TUseVideoFormUnpublishButtonArgs) => {
  const {
    openConfirmUnpublishModal,
    closeConfirmUnpublishModal,
    openedConfirmUnpublishModal,
  } = useModal('ConfirmUnpublish');

  const { field } = useController({
    control,
    name: 'publishDate',
  });

  const onConfirmUnpublish = () => {
    closeConfirmUnpublishModal();
    field.onChange(null);
    handleSubmit?.(editVideoPost)();
  };

  return {
    isOpenConfirmUnpublishModal: openedConfirmUnpublishModal,
    onClickUnpublishBtn: openConfirmUnpublishModal,
    onCloseUnpublish: closeConfirmUnpublishModal,
    onConfirmUnpublish,
  };
};

import { tournamentApiKeys } from 'entities/tournaments/consts';
import {
  TBaseTournament,
  TTournament,
  TTournamentDTO,
  TTournamentInclude,
} from 'entities/tournaments/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchTournaments = <Include extends TTournamentInclude = never>({
  params,
}: TFetchParams) =>
  get<TTournament<Include>[], TPaginatedMeta>(tournamentApiKeys.getTournaments, {
    params,
  }).then((response) => response.data);

export const fetchTournament = (id: TEntityId) =>
  get<TBaseTournament>(tournamentApiKeys.getTournament(id)).then(
    (response) => response.data.data,
  );

export const createTournament = (data: TTournamentDTO) =>
  post<TBaseTournament>(tournamentApiKeys.createTournament, data).then(
    (response) => response.data.data,
  );

export const editTournament = (data: TTournament) =>
  patch<TBaseTournament>(tournamentApiKeys.editTournament(data.id), data).then(
    (response) => response.data.data,
  );

export const deleteTournament = (id: TEntityId) =>
  del(tournamentApiKeys.deleteTournament(id)).then((response) => response.data.data);

import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { TMatchPlayer } from 'entities/matchPlayer/type';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { BaseCheckbox } from 'shared/components/Form/Checkbox/BaseCheckbox';
import { Icon } from 'shared/components/Icon/Icon';
import { Typography } from 'shared/components/Typography/Typography';
import { useFloat } from 'shared/hooks/useFloat';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';
import {
  TMatchPlacementAddPlayer,
  TMatchPlacementPlayer,
} from 'widgets/matchPlacement/types';

import styles from './MatchPlacementReservation.module.css';

type TProps = {
  players: TMatchPlacementPlayer[];
  matchPlayers: TMatchPlayer[];
  onAddPlayer: (data: TMatchPlacementAddPlayer[]) => void;
  onDeletePlayer: (id: TEntityId) => void;
};

export const MatchPlacementReservation = ({
  players,
  matchPlayers,
  onAddPlayer,
  onDeletePlayer,
}: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();

  const matchPlayersWithPlayers = useMemo(() => {
    const playersMap = new Map(
      players.map((player) => [toString(player.id), player]),
    );

    return matchPlayers.map((matchPlayer) => {
      const currentPlayer = playersMap.get(toString(matchPlayer.playerId));
      return { ...matchPlayer, player: currentPlayer };
    });
  }, [matchPlayers, players]);

  const onChangeFloatHandler = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const [isOpen, setOpen] = useState<boolean>(false);

  const {
    trigger: { triggerRef, ...trigger },
    floating,
  } = useFloat({
    isOpen,
    offset: 8,
    placement: 'bottom-end',
    onChange: onChangeFloatHandler,
    customOutsidePress: true,
  });

  const [selectedPlayers, setSelectedPlayers] = useState<TEntityId[]>([]);

  const remainingPlayers = useMemo(() => {
    const matchPlayerIds = new Set(
      matchPlayersWithPlayers.map(({ player }) => player?.id).filter(toBoolean),
    );

    const selectedPlayerIds = new Set(selectedPlayers);

    return players
      ?.filter(({ id }) => !matchPlayerIds.has(id))
      .map((player) => ({
        ...player,
        checked: selectedPlayerIds.has(player.id),
      }));
  }, [players, matchPlayersWithPlayers, selectedPlayers]);

  const addSelectedPlayer = (playerId: TEntityId, checked: boolean) => {
    setSelectedPlayers((prev) => {
      if (checked) {
        return [...prev, playerId];
      }

      return prev.filter((id) => id !== playerId);
    });
  };

  const handleClickReservation = () => {
    const matchReservation: TMatchPlacementAddPlayer[] = remainingPlayers
      ?.filter((player) => player.checked)
      .map(({ checked: _checked, ...rest }) => ({
        player: rest,
        position: { col: null, row: null },
        goalkeeper: false,
        reserve: true,
        captain: false,
      }));

    onAddPlayer(matchReservation);
    setOpen(false);
    setSelectedPlayers([]);
  };

  const handleDeleteReservation = (matchPlayerId: TEntityId) => {
    onDeletePlayer(matchPlayerId);
  };

  const reservationPlayers = useMemo(() => {
    return matchPlayersWithPlayers.filter((player) => player.reserve);
  }, [matchPlayersWithPlayers]);

  return (
    <>
      <div
        className={cn(styles.reservationWrapper, {
          [styles.isReservation]: reservationPlayers.length > 0,
        })}
      >
        <Typography color="var(--gray-450)" size={14}>
          {t('content.tactics.substitutePlayers')}
        </Typography>
        <div className={styles.addButton} ref={triggerRef} {...trigger}>
          <Icon
            className={isOpen ? styles.green : styles.gray}
            kind="plus"
            size="xs"
          />
          <Typography
            color={isOpen ? 'var(--green-400)' : 'var(--gray-450)'}
            size={12}
            weight="Bold"
          >
            {t('shared.add')}
          </Typography>
        </div>
        {isOpen && (
          <FloatingPortal>
            <Dropdown className={styles.playerPositionDropdown} {...floating}>
              <div className={styles.scrollBlock}>
                <Typography color="var(--gray-400)" size={12} tag="span">
                  {t('content.tactics.selectPlayers')}
                </Typography>
                {remainingPlayers?.map((player) => (
                  <BaseCheckbox
                    key={player.id}
                    checked={player.checked}
                    className={styles.playerItem}
                    label={
                      <Typography className={styles.playerItemText}>
                        {player.translations[defaultLocale].firstName}{' '}
                        {player.translations[defaultLocale].lastName}
                      </Typography>
                    }
                    onChange={(checked) => addSelectedPlayer(player.id, checked)}
                  />
                ))}
              </div>
              <Button className={styles.button} onClick={handleClickReservation}>
                {t('shared.confirm')}
              </Button>
            </Dropdown>
          </FloatingPortal>
        )}
      </div>
      {reservationPlayers.map((reservationPlayer) => (
        <div key={reservationPlayer.playerId} className={styles.matchReservation}>
          <Typography color="var(--gray-500)" size={12}>
            {reservationPlayer?.player?.translations?.[defaultLocale]?.firstName}{' '}
            {reservationPlayer?.player?.translations?.[defaultLocale]?.lastName}
          </Typography>

          <Icon
            className={styles.closeIcon}
            customSize={true}
            kind="x"
            size="s"
            onClick={() => handleDeleteReservation(reservationPlayer.id)}
          />
        </div>
      ))}
    </>
  );
};

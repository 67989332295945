import cn from 'classnames';
import { StadiumsSelect } from 'entities/stadiums/StadiumSelect/StadiumsSelect';
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/Form';
import { FormSelect } from 'shared/components/Form/FormSelect/FormSelect';
import { InputDatePicker } from 'shared/components/InputDatePicker/InputDatePicker';
import { Typography } from 'shared/components/Typography/Typography';
import { toBoolean } from 'shared/lib/toBoolean';
import { TMatchFormFields } from 'widgets/match-form/types/types';
import { useTeamSelectorsMatch } from 'widgets/match-form/ui/MatchForm/useTeamSelectorsMatch';
import { useTournamentSelectorsMatch } from 'widgets/match-form/ui/MatchForm/useTournamentSelectorsMatch';
import { useTourSelectorsMatch } from 'widgets/match-form/ui/MatchForm/useTourSelectorsMatch';

import styles from './MatchForm.module.css';

type TProps = {
  control: Control<TMatchFormFields>;
};

export const MatchForm = ({ control }: TProps) => {
  const { t } = useTranslation();
  const { homeTeams, guestTeams } = useTeamSelectorsMatch(control);

  const { tours } = useTourSelectorsMatch();

  const { tournament, season } = useTournamentSelectorsMatch({
    control,
  });

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <Typography color="var(--gray-500)" size={20} weight="SemiBold">
          {t('content.baseData')}
        </Typography>
        <FormSelect
          control={control}
          fieldName="tournament"
          isMulti={false}
          isSearchable
          label={t('content.tournamentLabel')}
          options={tournament.options}
          placeholder={tournament.placeholder}
          required
          size="small"
          onChange={(option) => option && tournament.onChange(option.value)}
        />
        <FormSelect
          control={control}
          fieldName="tournamentSeason"
          isDisabled={!toBoolean(tournament.selected)}
          isMulti={false}
          isSearchable
          label={t('content.season')}
          options={season.options}
          placeholder={season.placeholder}
          required
          size="small"
        />
        <FormSelect
          control={control}
          fieldName="tourId"
          isMulti={false}
          isSearchable
          label={t('content.matches.tour')}
          options={tours.options}
          placeholder={tours.placeholder}
          size="small"
        />
        <InputDatePicker
          control={control}
          fieldName="startAt"
          fromDate={new Date('1970-01-01')}
          inputClassName={cn(styles.input)}
          isShowTimePicker
          label={t('content.matches.dateAndTime')}
          placeholder={t('content.matches.chooseAndTime')}
          required
        />
        <StadiumsSelect control={control} fieldName="stadiumId" required />
        <FormSelect
          control={control}
          fieldName="homeTeam"
          isMulti={false}
          isSearchable
          label={t('content.matches.homeTeam')}
          options={homeTeams.options}
          placeholder={homeTeams.placeholder}
          required
          size="small"
        />
        <FormSelect
          control={control}
          fieldName="guestTeam"
          isMulti={false}
          isSearchable
          label={t('content.matches.guestTeam')}
          options={guestTeams.options}
          placeholder={guestTeams.placeholder}
          required
          size="small"
        />
        <FormInput
          control={control}
          fieldName="ticketsUrl"
          inputClassName={styles.formInput}
          label={t('content.matches.matchTickets')}
          placeholder={t('shared.addLink')}
        />
      </div>
    </div>
  );
};

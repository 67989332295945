import { zodResolver } from '@hookform/resolvers/zod';
import { useConfig } from 'entities/config/lib/useConfig';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useForm } from 'shared/hooks/useReactHookForm';
import { TVideoPostFields } from 'widgets/videoForm/types';
import { VideoFormWorkspacePanel } from 'widgets/videoForm/ui/VideoFormWorkspacePanel/VideoFormWorkspacePanel';
import { VideoRightPanel } from 'widgets/videoForm/ui/VideoRightPanel/VideoRightPanel';
import { VideoWorkspace } from 'widgets/videoForm/ui/VideoWorkspace/VideoWorkspace';
import { videoFormValidationSchema } from 'widgets/videoForm/validationSchema';

import styles from './VideoForm.module.css';

type TProps = {
  values: TVideoPostFields;
};

export const VideoForm = ({ values }: TProps) => {
  const { config } = useConfig();
  const { t } = useTranslation();
  const methods = useForm<TVideoPostFields>({
    mode: 'onChange',
    values,
    resolver: zodResolver(videoFormValidationSchema(t, config)),
  });

  return (
    <FormProvider {...methods}>
      <form className={styles.root}>
        <VideoFormWorkspacePanel />
        <div className={styles.content}>
          <div className={styles.editWrapper}>
            <VideoWorkspace />
          </div>
          <VideoRightPanel />
        </div>
      </form>
    </FormProvider>
  );
};

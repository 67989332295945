import { ComponentProps, forwardRef } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { Field } from 'shared/components/Form';
import {
  TFormModeSelectProps,
  TModeSelectOwnProps,
  TRenderInputProps,
} from 'shared/components/Form/types';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './ModeSelect.module.css';

type TProps = TModeSelectOwnProps & ComponentProps<'input'>;

export const ModeSelect = forwardRef<HTMLDivElement, TProps>(
  ({ options, value, ...rest }, ref) => {
    return (
      <div className={styles.container} ref={ref}>
        {options.map((option) => (
          <label key={option.value}>
            <input
              {...rest}
              checked={value === option.value}
              type="radio"
              value={option.value}
            />
            <div className={styles.label}>
              {typeof option.label === 'string' ? (
                <Typography size={12}>{option.label}</Typography>
              ) : (
                option.label
              )}
            </div>
          </label>
        ))}
      </div>
    );
  },
);

export const ModeSelectField = <T extends FieldValues>(
  props: TFormModeSelectProps<T>,
) => {
  const {
    control,
    fieldName,
    className,
    label,
    options,
    inlineInputClassName,
    inline,
  } = props;

  const renderInput = (renderProps: TRenderInputProps<T>) => {
    const { field } = renderProps;
    return (
      <Field
        className={className}
        inline={inline}
        inlineInputClassName={inlineInputClassName}
        label={label}
      >
        <ModeSelect {...field} options={options} />
      </Field>
    );
  };

  return <Controller control={control} name={fieldName} render={renderInput} />;
};

ModeSelect.displayName = 'ModeSelect';

import { BackButton } from 'pages/stories/edit/EditStory/components/forms/components/BackButton/BackButton';
import { FontSettings } from 'pages/stories/edit/EditStory/components/forms/components/FontSettings/FontSettings';
import {
  TCommonElementFormProps,
  TVotingElementFormValues,
} from 'pages/stories/edit/EditStory/components/forms/types';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { useTranslation } from 'react-i18next';
import { Divider } from 'shared/components/Divider';
import { Form, FormInput } from 'shared/components/Form';
import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';
import { Typography } from 'shared/components/Typography/Typography';
import { useForm } from 'shared/hooks/useReactHookForm';

import styles from '../ElementForm.module.css';

export const VotingElementForm = ({
  selectedElement,
}: TCommonElementFormProps<'voting'>) => {
  const { t } = useTranslation();
  const { selectedLocale } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();
  const actionSubmit = (data: TVotingElementFormValues) => {
    dispatch({
      type: 'updateSelectedStoryElementSettings',
      payload: data,
    });
  };
  const { submit, control } = useForm<TVotingElementFormValues>({
    values: selectedElement?.translations[selectedLocale].settings,
    submitOnChange: true,
    actionSubmit,
  });

  return (
    <div className={styles.root}>
      <Form action={submit} className={styles.formComponent}>
        <FormInput
          control={control}
          fieldName="title"
          inputClassName={styles.input}
          label={t('content.heading')}
          maxLength={20}
          placeholder={t('content.inputPlaceholder')}
        />
        <Divider />
        <FontSettings
          control={control}
          fontFamilyName="fontFamily"
          fontStyleName="fontStyle"
        />
        <Divider />
        <Typography className={styles.subtitle} size={14} tag="h2" weight="Regular">
          {t('content.story.colorHeading')}
        </Typography>
        <ColorInput
          control={control}
          fieldName="fill"
          inline
          inlineInputClassName={styles.formField}
          label={t('content.story.fill')}
          placeholder=""
        />
        <Divider />
        <ColorInput
          control={control}
          fieldName="background"
          inline
          inlineInputClassName={styles.formField}
          label={t('content.story.colorInputLabel')}
          placeholder=""
        />
      </Form>
      <BackButton />
    </div>
  );
};

import { UseQueryOptions } from '@tanstack/react-query';
import { TAdBanner } from 'entities/ad/banners/model/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

import { fetchAdBanners } from './api';
import { adBannerQueryKeys } from './consts';

export const useFetchAdBanners = (
  defaultPagination?: TPaginatedParameters,
  options: UseQueryOptions<TResponseData<TAdBanner[], TPaginatedMeta>> = {},
) => {
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [adBannerQueryKeys.adBanners, pagination],
      queryFn: () => fetchAdBanners({ params: pagination }),
      ...options,
    }),
    defaultPagination,
  );
};

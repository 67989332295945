// eslint-disable-next-line no-restricted-imports
import { FieldValues, useForm as useReactHookForm } from 'react-hook-form';
import { UseFormProps, UseFormReturn } from 'react-hook-form/dist/types';
import { isCustomProps } from 'shared/hooks/useReactHookForm/helpers';
import {
  TUseReactHookFormProps,
  TUseReactHookFormReturn,
} from 'shared/hooks/useReactHookForm/types';
import { useReactHookFormCustom } from 'shared/hooks/useReactHookForm/useReactHookFormCustom';

export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
>(
  props?:
    | TUseReactHookFormProps<TFieldValues, TContext>
    | UseFormProps<TFieldValues, TContext>,
): TUseReactHookFormReturn<typeof props, TFieldValues, TContext> =>
  props && isCustomProps(props)
    ? useReactHookFormCustom<TFieldValues, TContext>(
        props as TUseReactHookFormProps<TFieldValues, TContext>,
      )
    : (useReactHookForm<TFieldValues, TContext>(props) as UseFormReturn<
        TFieldValues,
        TContext
      > & {
        submit: VoidFunction;
      });

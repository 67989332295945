import cn from 'classnames';
import { ReactNode } from 'react';
import formCommonStyles from 'shared/components/Form/FormCommonStyles.module.css';
import { TCheckboxSize } from 'shared/components/Form/types';
import { Icon } from 'shared/components/Icon/Icon';

import styles from './Checkbox.module.css';

type TProps = {
  label: ReactNode;
  checked?: boolean;
  invalid?: boolean;
  onChange: (checked: boolean) => void;
  labelClassName?: string;
  className?: string;
  size?: TCheckboxSize;
};

export const BaseCheckbox = ({
  label,
  checked,
  onChange,
  invalid = false,
  labelClassName,
  className,
  size = 's',
}: TProps) => {
  const inputClass = cn(formCommonStyles.formControl, styles.checkbox, {
    [formCommonStyles.invalid]: invalid,
  });

  return (
    <label
      className={cn(
        styles.container,
        className,
        size === 's' ? styles.small : styles.medium,
      )}
    >
      <input
        checked={checked}
        className={styles.input}
        type="checkbox"
        onChange={(e) => onChange(e.target.checked)}
      />
      <div className={inputClass}>
        {checked && <Icon kind="checkmark" size="s" />}
      </div>
      <span
        className={cn(styles.label, labelClassName)}
        onClick={() => onChange(!checked)}
      >
        {label}
      </span>
    </label>
  );
};

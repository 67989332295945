import { useEffect } from 'react';
import { SingleValue } from 'react-select';
import { useLocalStorage } from 'react-use';
import { setApiBaseURL } from 'shared/api/config';
import { DEFAULT_SANDBOX } from 'shared/api/constants';
import { TAPIName } from 'shared/api/types';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { ELocalStorageKeys, isProduction } from 'shared/constants';

export const useApiSelection = () => {
  const [selectedApi, setSelectedApi] = useLocalStorage<TAPIName>(
    ELocalStorageKeys.backendSandbox,
    DEFAULT_SANDBOX,
    { raw: true },
  );

  useEffect(() => {
    // После разавторизации необходимо менять песок
    if (selectedApi && !isProduction) {
      setApiBaseURL(selectedApi);
    }
  }, [selectedApi]);

  return {
    selectedApi: {
      value: selectedApi ?? DEFAULT_SANDBOX,
      label: selectedApi ?? DEFAULT_SANDBOX,
    },
    onChangeApi: (api: SingleValue<TSelectOption>) => {
      api && setSelectedApi(api.value as TAPIName);
    },
  };
};

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { routes } from 'shared/routes';

type TModalTypeVariant = 'field' | 'captain' | 'reserve';

export type TTacticErrorModalType =
  | ''
  | `home${Capitalize<TModalTypeVariant>}`
  | `guest${Capitalize<TModalTypeVariant>}`;

export type TTacticErrorModal = {
  type: TTacticErrorModalType;
};

type TProps = TTacticErrorModal & {
  onClose: () => void;
};

export const TacticErrorModal = ({ onClose, type }: TProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      description={getDescriptionByType(type, t)}
      isOpened
      title={t('content.tactics.errorTitle')}
      onClose={onClose}
      onConfirm={() => {
        navigate(routes.matches);
        onClose();
      }}
    />
  );
};

function getDescriptionByType(type: TTacticErrorModalType, t: TFunction) {
  switch (type) {
    case 'homeField':
      return t('content.tactics.notFilledFieldDescription');
    case 'guestField':
      return t('content.tactics.notFilledFieldDescription');
    case 'homeCaptain':
      return t('content.tactics.notFilledHomeCaptainDescription');
    case 'guestCaptain':
      return t('content.tactics.notFilledGuestCaptainDescription');
    case 'homeReserve':
      return t('content.tactics.notFilledHomeReserveDescription');
    case 'guestReserve':
      return t('content.tactics.notFilledGuestReserveDescription');
  }
}

import { TManager } from 'entities/managers/model/types';
import { TPlayer } from 'entities/players/model/types';
import { TTeam } from 'entities/teams/types';
import { TCommonOptionType } from 'feature/rightPanel/types';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';
import { TContentLang, TTranslationsObject } from 'shared/types/common';

type TNamedTranslations = TTranslationsObject<{
  firstName: string;
  lastName: string;
}>;

const generateName = <T extends TPlayer | TManager>(
  item: T & TNamedTranslations,
  locale: TContentLang,
) => {
  return `${item.translations?.[locale]?.firstName ?? ''} ${item.translations?.[locale]?.lastName ?? ''}`;
};

const getTranslationsName = <T extends TTeam>(item: T, locale: TContentLang) => {
  return `${item.translations?.[locale]?.name ?? ''}`;
};

export const generateOptionsArray = <T extends TCommonOptionType>(
  defaultLocale: TContentLang,
  data?: T[],
): TSelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    value: toString(item.id),
    label: item.translations?.[defaultLocale]?.name ?? '',
  }));
};

export const generateNameOptionsArray = <T extends TPlayer | TManager>(
  defaultLocale: TContentLang,
  data?: T[],
): TSelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    value: toString(item.id),
    label: generateName(item, defaultLocale),
  }));
};

export const generateTeamOptionsArray = <T extends TTeam>(
  defaultLocale: TContentLang,
  data?: T[],
): TSelectOption[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    value: toString(item.id),
    label: getTranslationsName(item, defaultLocale),
  }));
};

import { EMatchType, TMatchDTO } from 'entities/matches/types';
import { TMatchFormFields } from 'widgets/match-form/types/types';

export const mapMatchFieldsToDTO = (formData: TMatchFormFields): TMatchDTO => {
  return {
    exactTime: true,
    tourId: formData.tourId,
    ticketsUrl: formData.ticketsUrl || undefined,
    startAt: formData.startAt,
    stadiumId: formData.stadiumId,
    matchType: formData.matchType,
    teamId:
      formData.matchType === EMatchType.Guest
        ? formData.guestTeam
        : formData.homeTeam,
    opponentTeamId:
      formData.matchType === EMatchType.Home
        ? formData.guestTeam
        : formData.homeTeam,
    tournamentSeasonId: formData.tournamentSeason,
  };
};

import { useLocales } from 'entities/config/lib/useLocales';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { CopyButton } from 'shared/components/CopyButton/CopyButton';
import { Icon } from 'shared/components/Icon/Icon';
import { Typography } from 'shared/components/Typography/Typography';
import { getLangNameMap } from 'shared/consts';
import { toString } from 'shared/lib/toString';
import { useGetCreatedLocales } from 'widgets/videoForm/hooks/useGetCreatedLocales';
import { TVideoPostFields } from 'widgets/videoForm/types';

import styles from './VideoFormPublish.module.css';

type TProps = {
  onClose: () => void;
  onPublish: () => void;
  onClickChooseDate: () => void;
  isOpened: boolean;
};

export const VideoFormPublishModal = ({
  onClose,
  onPublish,
  onClickChooseDate,
  isOpened,
}: TProps) => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const { control, formState } = useFormContext<TVideoPostFields>();
  const { createdLocales } = useGetCreatedLocales(control);
  const {
    field: { value: videoPostId },
  } = useController({
    control,
    name: 'id',
  });

  const {
    field: { value: publishDate },
  } = useController({
    control,
    name: 'publishDate',
  });

  const filledLocales = createdLocales
    .map((locale) => getLangNameMap()[locale])
    .join(', ');

  const notFilledLocales = locales
    .filter((lang) => !createdLocales.includes(lang))
    .map((locale) => getLangNameMap()[locale])
    .join(', ');

  return (
    <ActionModal
      cancelText={t('shared.planningText')}
      confirmText={publishDate ? t('shared.confirm') : t('shared.publish')}
      contentClassName={styles.mainContent}
      opened={isOpened}
      title={t('shared.publish')}
      width={549}
      cancelButtonProps={{
        icon: 'calendar',
        iconPosition: 'before',
        isDisabled: formState.isSubmitting,
        onClick: onClickChooseDate,
      }}
      confirmButtonProps={{
        isLoading: formState.isSubmitting,
        kind: 'primary',
        onClick: onPublish,
      }}
      onClose={onClose}
    >
      {videoPostId && (
        <div className={styles.infoId}>
          <Typography>ID {t('content.video')}</Typography>
          <div className={styles.copyId}>
            <CopyButton iconSize="s" textToCopy={toString(videoPostId)} />
            <Typography className={styles.textMuted} size={14}>
              ID: {videoPostId}
            </Typography>
          </div>
        </div>
      )}
      <div className={styles.info}>
        <Typography>{t('shared.filledLocales')}</Typography>
        <Typography className={styles.textMuted} size={16}>
          {filledLocales}
        </Typography>
      </div>
      {notFilledLocales.length > 0 && (
        <div className={styles.info}>
          <Typography>{t('shared.notFilledLocales')}</Typography>
          <Typography className={styles.textMuted} size={16}>
            {notFilledLocales}
          </Typography>
          <div className={styles.alertMessage}>
            <Icon kind="alert-circle" size="s" />
            <Typography className={styles.textMuted}>
              {t('content.publishModal.warningUnpublishLocalization')}
            </Typography>
          </div>
        </div>
      )}
    </ActionModal>
  );
};

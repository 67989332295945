import { EditStoryRoute } from 'pages/stories/edit';
import { StoriesPage } from 'pages/stories/StoriesPage/StoriesPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRecord } from 'shared/types/router';

export const StoriesRoutes = {
  List: {
    path: routes.stories,
    element: <StoriesPage />,
    roles: DEFAULT_ROUTER_ROLES,
  },
  Edit: EditStoryRoute,
} satisfies TProtectedRecord;

import cn from 'classnames';
import { TeamSelect } from 'entities/teamSelect/TeamSelect';
import { CountrySelect } from 'feature/countrySelect/CountrySelect';
import { FormLocalesInput } from 'feature/localesInput/FormLocalesInput';
import { PlayerPositionSelect } from 'feature/playerPositionSelect/PlayerPositionSelect';
import { UploadImage } from 'feature/rightPanel/ui/UploadImage/UploadImage';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/Form';
import { InputDatePicker } from 'shared/components/InputDatePicker/InputDatePicker';
import { Typography } from 'shared/components/Typography/Typography';
import { getImageSettings, TODAY } from 'shared/constants';
import { TPlayerFormFields } from 'widgets/playerForm/types/types';
import {
  MAX_PLAYER_BIOGRAPHY_LENGTH,
  MAX_PLAYER_FIRST_NAME_LENGTH,
  MAX_PLAYER_LAST_NAME_LENGTH,
  MAX_PLAYER_NICKNAME_LENGTH,
  MAX_PLAYER_PUPIL_LENGTH,
} from 'widgets/playerForm/ui/PlayerForm/validationSchema';
import { PlayerFormDiscarded } from 'widgets/playerForm/ui/PlayerFormDiscarded/PlayerFormDiscarded';
import { PlayerFormPreview } from 'widgets/playerForm/ui/PlayerFormPreview/PlayerFormPreview';
import { PlayerFormStatus } from 'widgets/playerForm/ui/PlayerFormStatus/PlayerFormStatus';

import styles from './PlayerForm.module.css';

export const PLAYER_FORM_FROM_DATE = new Date(1970, 0);
type TProps = {
  control: Control<TPlayerFormFields>;
};
export const PlayerForm = ({ control }: TProps) => {
  const { t } = useTranslation();
  const { aspectRatio, hint } = getImageSettings(t).player.picture;
  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <Typography color="var(--gray-500)" size={20} weight="SemiBold">
          {t('content.baseData')}
        </Typography>
        <PlayerFormPreview control={control} />
        <FormLocalesInput
          control={control}
          fieldName="firstName"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.nameTitle')}
          maxLength={MAX_PLAYER_FIRST_NAME_LENGTH}
          placeholder={t('content.inputPlaceholder')}
          previewInputPlaceholder={t('content.notInputPreview')}
          required
          size="s"
          title={t('content.inputName')}
        />
        <FormLocalesInput
          control={control}
          fieldName="lastName"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.secondNameTitle')}
          maxLength={MAX_PLAYER_LAST_NAME_LENGTH}
          placeholder={t('content.inputPlaceholder')}
          previewInputPlaceholder={t('content.notInputPreview')}
          required
          size="s"
          title={t('content.inputSecondName')}
        />
        <FormInput
          control={control}
          fieldName="nickname"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.player.nickName')}
          maxLength={MAX_PLAYER_NICKNAME_LENGTH}
          placeholder={t('content.inputPlaceholder')}
          showMaxLength={false}
          size="s"
        />
        <InputDatePicker
          control={control}
          fieldName="birthDate"
          fromDate={PLAYER_FORM_FROM_DATE}
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.birthDay')}
          placeholder={t('content.choseDate')}
          toDate={TODAY}
        />
        <FormLocalesInput
          control={control}
          fieldName="biography"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.bio')}
          maxLength={MAX_PLAYER_BIOGRAPHY_LENGTH}
          placeholder={t('content.inputPlaceholder')}
          previewInputPlaceholder={t('content.notInputPreview')}
          size="s"
          title={t('content.inputBio')}
          type="textarea"
        />
        <CountrySelect
          className={cn(styles.input, styles.baseWidth)}
          control={control}
          fieldName="countryId"
        />
        <div className={cn(styles.playerParams, styles.baseWidth)}>
          <FormInput
            control={control}
            fieldName="weight"
            inputClassName={styles.input}
            label={t('content.player.weight')}
            placeholder={t('content.player.inputWeight')}
            size="s"
            type="number"
          />
          <FormInput
            control={control}
            fieldName="height"
            inputClassName={styles.input}
            label={t('content.player.height')}
            placeholder={t('content.player.inputHeight')}
            size="s"
            type="number"
          />
        </div>
        <PlayerFormStatus control={control} />
        <TeamSelect
          className={styles.baseWidth}
          control={control}
          fieldName="teamId"
        />
        <PlayerPositionSelect
          className={styles.baseWidth}
          control={control}
          fieldName="playerPositionId"
        />
        <FormInput
          control={control}
          fieldName="number"
          inputClassName={cn(styles.baseWidth, styles.input)}
          label={t('content.player.number')}
          placeholder={t('content.player.inputNumber')}
          required
          size="s"
          type="number"
        />
        <FormLocalesInput
          control={control}
          fieldName="pupil"
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.player.clubStudent')}
          maxLength={MAX_PLAYER_PUPIL_LENGTH}
          placeholder={t('content.inputPlaceholder')}
          previewInputPlaceholder={t('content.notInputPreview')}
          size="s"
          title={t('content.player.inputClubStudent')}
        />
        <InputDatePicker
          control={control}
          fieldName="debutDate"
          fromDate={PLAYER_FORM_FROM_DATE}
          inputClassName={cn(styles.input, styles.baseWidth)}
          label={t('content.debutDate')}
          placeholder={t('content.choseDate')}
          toDate={TODAY}
        />
        <UploadImage
          aspectRatio={aspectRatio}
          control={control}
          fieldName="picture"
          hint={hint}
          imageRequirementText={hint}
          label={t('content.player.imageList')}
          modalTitle={t('shared.imageSetting')}
          showIcon={false}
          wrapperClassName={styles.baseWidth}
        />
        <PlayerFormDiscarded control={control} />
      </div>
      <div className={styles.links}>
        <Typography color="var(--gray-500)" size={20} weight="SemiBold">
          {t('content.story.linkWithEndingBigLetter')}
        </Typography>
        <FormInput
          control={control}
          fieldName="shopLink"
          inputClassName={cn(styles.baseWidth, styles.input)}
          label={t('content.linkTShirt')}
          placeholder={t('shared.addLink')}
          size="s"
        />
        <FormInput
          control={control}
          fieldName="sharingLink"
          inputClassName={cn(styles.baseWidth, styles.input)}
          label={t('content.linkPage')}
          placeholder={t('shared.addLink')}
          size="s"
        />
      </div>
    </div>
  );
};

import { TMatchPlayerDTO } from 'entities/matchPlayer/type';
import { TMatchOpponentPlayerDTO } from 'entities/opponentMatchPlayer/type';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';
import { TMatchPlacementAddPlayer } from 'widgets/matchPlacement/types';

export const mapAddPlayerToDTO = <Opponent extends boolean = true>({
  player,
  matchId,
}: {
  player: TMatchPlacementAddPlayer;
  matchId: TEntityId;
}): Opponent extends true ? TMatchPlayerDTO : TMatchOpponentPlayerDTO => {
  return {
    matchId: toString(matchId) ?? '',
    row: player.position.row,
    col: player.position.col,
    playerId: player.player.id,
    captain: player.captain ?? false,
    reserve: player.reserve ?? false,
    goalkeeper: player.goalkeeper ?? false,
  };
};

import cn from 'classnames';
import { Control, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/Form';
import { Typography } from 'shared/components/Typography/Typography';
import { getLangNameMap } from 'shared/consts';
import { TContentLang, TWithClassName } from 'shared/types/common';

import styles from './TranslationInputs.module.css';

// TODO: вынести в компонент и переиспользоваться везде,
//  где есть конструкция " locales?.map((locale) => (<FormInput ... "
type TProps<T extends FieldValues> = {
  locales: TContentLang[];
  title?: string;
  placeholder?: string;
  control: Control<T>;
  maxLength: number;
  showMaxLength?: boolean;
  pathResolver: (locale: TContentLang) => Path<T>;
} & TWithClassName;

export const TranslationInputs = <T extends FieldValues>({
  title,
  className,
  locales,
  control,
  pathResolver,
  showMaxLength = true,
  placeholder,
  maxLength,
}: TProps<T>) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.root, className)}>
      {title && (
        <Typography size={14} tag="h3" weight="SemiBold">
          {title}
        </Typography>
      )}
      <div className={styles.inputWrapper}>
        {locales?.map((locale) => (
          <FormInput
            key={locale}
            control={control}
            fieldName={pathResolver(locale)}
            label={getLangNameMap()[locale]}
            maxLength={maxLength}
            placeholder={placeholder ?? t('content.inputPlaceholder')}
            required
            showMaxLength={showMaxLength}
            size="s"
          />
        ))}
      </div>
    </div>
  );
};

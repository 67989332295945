import { TConfigData } from 'entities/config/model/types';
import i18n from 'i18next';
import { secureProtocol } from 'shared/constants';
import { getTextMinLengthMessage } from 'shared/consts';
import { isValidUrl } from 'shared/lib/isValidUrl';
import { linkInWhitelist } from 'shared/lib/linkInWhitelist';
import { TContentLang } from 'shared/types/common';
import { isSecuredLink } from 'shared/utils/links';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import z from 'zod';

export const photoGalleryFormValidationSchema = (config?: TConfigData) =>
  z
    .object({
      locale: z.object({
        created: z.array(z.string()),
        selected: z.string(),
      }),
      preview: z.object({
        file: fileZodValidation({
          errorMessage: i18n.t('shared.validation.preview'),
        }),
        data: z.string(),
      }),
      totalImages: z
        .number()
        .min(1, 'В фотогалерее должно быть хотя бы одно изображение'),
      sharingLink: z
        .string()
        .optional()
        .refine(
          (link) => (link ? isValidUrl(link) : true),
          i18n.t('content.notAvailableLink'),
        )
        .refine(
          (link) => (link ? isSecuredLink(link) : true),
          `${i18n.t('content.startLink')} ${secureProtocol}`,
        )
        .refine(
          (link) =>
            link ? linkInWhitelist(link, config?.whitelistDomains ?? []) : true,
          i18n.t('content.notAvailableDomain'),
        ),
    })
    .passthrough()
    .superRefine((fields, context) => {
      const validatedContent = translationsValidation(
        fields.locale.selected as TContentLang,
      ).safeParse(fields);

      if (!validatedContent.success) {
        validatedContent.error.issues.forEach((issue) => {
          context.addIssue(issue);
        });
      }
    });

const translationsValidation = (selected: TContentLang) =>
  z.object({
    translations: z.object({
      [selected]: z.object({
        title: z.string().min(1, getTextMinLengthMessage()),
      }),
    }),
  });

import { useConfig } from 'entities/config/lib/useConfig';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { ELocalStorageKeys } from 'shared/constants';

export const useDefaultLocales = () => {
  const { config } = useConfig();
  const { i18n } = useTranslation();

  const browserLocale = navigator.language.split('-')[0];
  const [locale, setLocale] = useLocalStorage(ELocalStorageKeys.locale);

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale as string);
    } else if (config) {
      setLocale(config.defaultLocale);
      i18n.changeLanguage(config.defaultLocale);
    } else if (!config && (browserLocale === 'en' || browserLocale === 'ru')) {
      i18n.changeLanguage(browserLocale);
    }
  }, []);

  return { locale: i18n.language };
};

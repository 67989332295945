import { TClub } from 'entities/club/types';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import { TClubFormFields } from 'widgets/ClubForm/types';

export const mapClubToClubFormFields = async (
  club: TClub,
  locales: TContentLang[],
  defaultLocale: TContentLang,
): Promise<TClubFormFields> => ({
  locale: {
    created: locales,
    selected: defaultLocale,
  },
  whiteImage: club?.whiteImage?.x3
    ? await getFileFromUrl(club.whiteImage.x3)
    : undefined,
  darkImage: club?.darkImage?.x3
    ? await getFileFromUrl(club.darkImage.x3)
    : undefined,
  description: reduceTranslationObject(
    locales,
    (locale) => club.translations[locale].description ?? '',
  ),
  name: reduceTranslationObject(locales, (locale) => club.translations[locale].name),
});

import { useLocales } from 'entities/config/lib/useLocales';
import { TManagerCareer } from 'entities/managerCareers/model/types';
import { useTranslation } from 'react-i18next';
import { ImageCell } from 'shared/components/SimpleTable/components/ImageCell/ImageCell';
import { Typography } from 'shared/components/Typography/Typography';
import { TConfigCreator } from 'widgets/elementsList/types';

export const useManagerCareersColumnsConfig: TConfigCreator<TManagerCareer> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();
  return [
    {
      title: t('content.club'),
      render: (item) => (
        <ImageCell
          title={item?.translations[defaultLocale]?.teamName}
          picProps={{
            src: item.teamPicture.x1,
          }}
        />
      ),
    },
    {
      title: t('content.manager.jobTitle'),
      render: (item) => (
        <Typography>{item.translations[defaultLocale]?.jobTitle}</Typography>
      ),
    },
    {
      title: t('content.season'),
      render: (item) => <Typography>{item.season}</Typography>,
    },
    {
      title: 'ID',
      render: (item) => <Typography>{item.id}</Typography>,
    },
  ];
};

import { useEditAdBannerMutation } from 'entities/ad/banners/model/mutations';
import { useLocales } from 'entities/config/lib/useLocales';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notify } from 'shared/components/Notification';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';
import { mapAdBannerFormToEditDTO } from 'widgets/adBannerForm/mapAdBannerFormToCreateDTO';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';

type TEditAdBannerProps = {
  fields: TAdBannerFormFields;
  initialValues?: TAdBannerFormFields;
  adBannerId: TEntityId;
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<TAdBannerFormFields>>>;
};

export const useEditAdBanner = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const navigate = useNavigate();

  const { mutate, isLoading: isEditingBanner } = useEditAdBannerMutation();

  const editAdBanner = async ({
    adBannerId,
    dirtyFields,
    initialValues,
    fields,
  }: TEditAdBannerProps) => {
    const changedFields = deleteNotChangedFields(fields, dirtyFields, initialValues);

    const dto = await mapAdBannerFormToEditDTO(locales, changedFields);

    mutate(
      { id: adBannerId, ...dto },
      {
        onSuccess: () => {
          navigate(routes.adBanners);
        },
        onError: () => {
          notify(t('content.adBanners.form.editBannerError'), { type: 'error' });
        },
      },
    );
  };

  return {
    isEditingBanner,
    editAdBanner,
  };
};

const deleteNotChangedFields = (
  fields: TAdBannerFormFields,
  dirtyFields: FieldNamesMarkedBoolean<TAdBannerFormFields>,
  initialValues?: TAdBannerFormFields,
) => {
  const notChangedFields = Object.fromEntries(
    Object.entries(fields).filter(([fieldName, value]) => {
      const key = fieldName as keyof TAdBannerFormFields;

      if (value instanceof File) {
        const initialFile = initialValues?.[key];
        if (initialFile instanceof File) {
          return value !== initialFile;
        }
      }

      return true;
    }),
  );

  return notChangedFields satisfies Partial<TAdBannerFormFields>;
};

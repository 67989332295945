export const clubQueryKeys = {
  club: 'club',
};

export const baseUrl = 'club';

export const clubApiKeys = {
  getClub: baseUrl,
  editClub: baseUrl,
};

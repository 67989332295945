import { useLocales } from 'entities/config/lib/useLocales';
import { useCreateTournamentMutation } from 'entities/tournaments/mutations';
import { useFetchTournamentsQuery } from 'entities/tournaments/queries';
import { useTournamentsColumnsConfig } from 'pages/settings/tournaments/TournamentsPage/columnsConfig';
import { MAX_TOURNAMENT_INPUT_LENGTH } from 'pages/settings/tournaments/TournamentsPage/const';
import { TournamentsActions } from 'pages/settings/tournaments/TournamentsPage/TournamentsActions';
import { useTranslation } from 'react-i18next';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { useModal } from 'shared/hooks/useModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TTranslations } from 'shared/types/common';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './TournamentsPage.module.css';

export const TournamentsPage = () => {
  const { t } = useTranslation();
  const { locales } = useLocales();
  const { openModal, closeModal, openedModal } = useModal();

  const {
    data: { data: tournaments = [], meta: { page = 1, total = 0 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchTournamentsQuery({
    options: {
      cacheTime: 0,
    },
  });

  const createTournament = useCreateTournamentMutation();

  const onCreateTournament = (translations: TTranslations<string>) => {
    createTournament.mutate({
      translations: reduceTranslationObject(locales, (locale) => ({
        name: translations[locale],
      })),
    });
    closeModal();
  };

  return (
    <>
      <CommonList
        configCreator={useTournamentsColumnsConfig}
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.settings.tournament')}
        createButtonProps={{
          content: t('shared.create'),
          onClick: openModal,
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
        tableProps={{
          data: tournaments,
          rowClassName: styles.rowGridTemplate,
          ActionComponent: TournamentsActions,
        }}
      />
      <LocalizationModal
        actionSubmit={onCreateTournament}
        closeModal={closeModal}
        isOpened={openedModal}
        subtitle={t('content.title')}
        title={t('content.settings.createTournament')}
        inputProps={{
          maxLength: MAX_TOURNAMENT_INPUT_LENGTH,
          placeholder: t('content.inputPlaceholderText'),
          required: true,
          size: 's',
        }}
      />
    </>
  );
};

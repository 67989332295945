import { useLocales } from 'entities/config/lib/useLocales';
import { TBaseTournament } from 'entities/tournaments/types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';
import { TConfigCreator } from 'widgets/elementsList/types';

export const useTournamentsColumnsConfig: TConfigCreator<TBaseTournament> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();
  return [
    {
      title: t('content.title'),
      render: (item) => (
        <Typography>{item?.translations[defaultLocale]?.name}</Typography>
      ),
    },
    {
      title: 'ID',
      render: (item) => item.id,
    },
  ];
};

import { useNavigate, useSearchParams } from 'react-router-dom';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';

export const FROM_CREATE_MATCH_PARAM = 'fromCreateMatch';

/*
 * ATTENTION!!! Костыль. Возможны баги
 * Данная логика добавлена, так как нам необходимо показывать заголовок о создании матча,
 * если пользователь пришел со страницы создания матча.
 * Подробней в задаче https://dcsportsoft.atlassian.net/browse/CF-817
 * */
export const useUserFromCreateMatch = () => {
  const [searchParams] = useSearchParams();

  return toBoolean(searchParams.get(FROM_CREATE_MATCH_PARAM));
};

export const useNavigateToTacticFromCreateMatch = () => {
  const navigate = useNavigate();

  const params = new URLSearchParams({ [FROM_CREATE_MATCH_PARAM]: 'true' });

  return (matchId: TEntityId) => {
    navigate({
      search: toString(params),
      pathname: routes.tacticMatch.as(matchId),
    });
  };
};

import { useFetchMatchQuery } from 'entities/matches/queries';
import { getMatchNavigationLinks } from 'feature/match-layout/model/getMatchNavigationLinks';
import { useNavigate } from 'react-router-dom';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';

type TProps = {
  matchId?: TEntityId;
};

export const useMatchLayout = ({ matchId }: TProps) => {
  const navigate = useNavigate();

  const { isLoading: isLoadingMatch, error: matchError } = useFetchMatchQuery({
    id: toString(matchId),
    params: {
      include: ['team', 'opponent_team'],
    },
  });

  const matchNavigationLinks = getMatchNavigationLinks(matchId);

  const backButtonClickHandler = () => {
    navigate(routes.matches);
  };

  return {
    error: matchError,
    isLoading: isLoadingMatch && toBoolean(matchId),
    matchNavigationLinks,
    backButtonClickHandler,
  };
};

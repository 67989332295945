import { UploadImage } from 'feature/rightPanel/ui/UploadImage/UploadImage';
import { WorkspacePanel } from 'feature/workspacePanel/ui/WorkspacePanel';
import React from 'react';
import { FormProvider, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormInput } from 'shared/components/Form';
import { Typography } from 'shared/components/Typography/Typography';
import { getImageSettings } from 'shared/constants';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';
import { useClubForm } from 'widgets/ClubForm/hooks/useClubForm';
import { useDebounceEditClub } from 'widgets/ClubForm/hooks/useDebounceEditClub';
import { useEditClubTranslation } from 'widgets/ClubForm/hooks/useEditClubTranslation';
import { TClubFormFields } from 'widgets/ClubForm/types';
import { ClubContacts } from 'widgets/ClubForm/ui/ClubContacts/ClubContacts';
import { ClubFormTitle } from 'widgets/ClubForm/ui/ClubFormTitle';
import { ClubSocialLinks } from 'widgets/ClubForm/ui/ClubSocialLinks/ClubSocialLinks';

import styles from './ClubForm.module.css';

export const ClubForm = ({
  clubFormFields,
  clubId,
}: {
  clubFormFields: TClubFormFields;
  clubId: TEntityId;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useClubForm({
    values: clubFormFields,
  });
  const { control } = methods;

  const {
    field: { value: currentLocal },
  } = useController({
    control,
    name: 'locale.selected',
  });

  const {
    selectedLocale,
    createdLocales,
    handleAddLocale,
    handleSelectLocale,
    handleDeleteLocale,
  } = useEditClubTranslation(control);

  useDebounceEditClub(methods);

  return (
    <FormProvider {...methods}>
      <div className={styles.root}>
        <WorkspacePanel
          backButtonProps={{ onClick: () => navigate(routes.root) }}
          title={t('content.club.about')}
          localesControlProps={{
            createdLocales,
            selectedLocale,
            onLocaleAdd: handleAddLocale,
            onLocaleDelete: handleDeleteLocale,
            onLocaleSelect: handleSelectLocale,
          }}
        />
        <div className={styles.wrapper}>
          <ClubFormTitle />
          <div className={styles.mainBlock}>
            <Typography color="var(--gray-500)" size={20} weight="Bold">
              {t('content.baseData')}
            </Typography>
            <div className={styles.secondBlock}>
              <Typography color="var(--gray-500)" size={16} weight="Bold">
                {t('content.tournamentSeasons.logo')}
              </Typography>
              <UploadImage
                aspectRatio={getImageSettings(t).club.lightTheme.hint}
                control={control}
                fieldName="whiteImage"
                hint={getImageSettings(t).club.lightTheme.hint}
                label={t('content.club.lightTheme')}
                modalTitle={t('content.club.uploadFile')}
                showIcon={false}
                imageRequirementText={t(
                  'shared.imageRequirementText.warningUpload.transparentBackgroundSmallFormatWithoutRatio',
                )}
              />
              <UploadImage
                aspectRatio={getImageSettings(t).club.lightTheme.hint}
                control={control}
                fieldName="darkImage"
                hint={getImageSettings(t).club.darkTheme.hint}
                label={t('content.club.darkTheme')}
                modalTitle={t('content.club.uploadFile')}
                showIcon={false}
                imageRequirementText={t(
                  'shared.imageRequirementText.warningUpload.transparentMonochromeBackgroundWithoutRatio',
                )}
              />
            </div>
            <div className={styles.secondBlock}>
              <Typography color="var(--gray-500)" size={16} weight="Bold">
                {t('content.club.description')}
              </Typography>
              <FormInput
                className={styles.baseWidth}
                control={control}
                fieldName={`description.${currentLocal}`}
                placeholder={t('content.inputPlaceholderText')}
                size="s"
                type="textarea"
              />
            </div>
          </div>
          <ClubSocialLinks clubId={clubId} />
          <ClubContacts clubId={clubId} />
        </div>
      </div>
    </FormProvider>
  );
};

import { WorkspacePanel } from 'feature/workspacePanel/ui/WorkspacePanel';
import { getRenderActions } from 'feature/workspacePanel/utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TButtonProps } from 'shared/components/Button/Button';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { useAdBannerBackButton } from 'widgets/adBannerForm/hooks/useAdBannerBackButton';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';

type TProps = {
  confirmButtonProps?: TButtonProps;
  isEdit: boolean;
};

export const AdBannerFormHeader = ({ confirmButtonProps, isEdit }: TProps) => {
  const { t } = useTranslation();
  const {
    formState: { isValid },
  } = useFormContext<TAdBannerFormFields>();
  const { onClickBackButton, openedClosingModal, onConfirmBack, closeClosingModal } =
    useAdBannerBackButton();

  return (
    <>
      <WorkspacePanel
        backButtonProps={{
          onClick: onClickBackButton,
        }}
        renderActions={getRenderActions({
          publishButtonProps: {
            children: t('shared.confirm'),
            isDisabled: !isValid,
            htmlType: 'submit',
            ...confirmButtonProps,
          },
        })}
        title={
          isEdit
            ? t('content.adBanners.form.editAdTitle')
            : t('content.adBanners.form.createAdTitle')
        }
      />
      <ConfirmationModal
        description={t('content.video.comeBackPageWarning')}
        isOpened={openedClosingModal}
        title={t('shared.backToRoot.confirmationModal.titleWithMark')}
        onClose={closeClosingModal}
        onConfirm={onConfirmBack}
      />
    </>
  );
};

import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import { createSeason, deleteSeason, editSeason } from 'entities/seasons/api';
import { seasonsQueryKeys } from 'entities/seasons/consts';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';

export const useCreateSeasonMutation = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: createSeason,
    onSuccess: () => {
      return queryClient.invalidateQueries([seasonsQueryKeys.seasons]);
    },
    onError: () => {
      notify(t('content.settings.notifySeason'), { type: 'error' });
    },
  });
};

export const useEditSeasonMutation = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: editSeason,
    onError: () => {
      notify(t('content.settings.notifyEditSeason'), { type: 'error' });
    },
  });
};

export const useDeleteSeasonMutation = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteSeason,
    onError: () => {
      notify(t('content.settings.notifyDeleteSeason'), { type: 'error' });
    },
  });
};

import { getSuccessStoryPublicationNotify } from 'entities/stories/helpers';
import {
  useDeleteStoryGroupMutation,
  useUpdateStoryGroupMutation,
} from 'entities/stories/mutations';
import { TStoryGroupData } from 'entities/stories/types';
import { useNavigate } from 'react-router-dom';
import { toString } from 'shared/lib/toString';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';

export const useStoryGroupActions = () => {
  const navigate = useNavigate();
  const {
    mutateAsync: updateStoryGroupMutation,
    isLoading: isUpdateStoryGroupMutation,
  } = useUpdateStoryGroupMutation();
  const { mutate: deleteStoryGroupMutation, isLoading: isStoryGroupDeleting } =
    useDeleteStoryGroupMutation();

  const editStoryGroup = (id: TEntityId) => {
    navigate(routes.editStory.replace(':storyGroupId', toString(id)));
  };

  const changePublicationDate = (
    storyGroup: TStoryGroupData,
    date: Date | undefined,
  ) => {
    if (date) {
      updateStoryGroupMutation({
        id: storyGroup.id,
        availableLocales: storyGroup.availableLocales,
        published: date.toString(),
      }).then(getSuccessStoryPublicationNotify);
    }
  };

  const publishNow = (storyGroup: TStoryGroupData) => {
    const now = new Date();
    updateStoryGroupMutation({
      id: storyGroup.id,
      availableLocales: storyGroup.availableLocales,
      published: now.toString(),
    }).then(getSuccessStoryPublicationNotify);
  };

  const unpublish = async (storyGroup: TStoryGroupData) => {
    await updateStoryGroupMutation({
      id: storyGroup.id,
      availableLocales: storyGroup.availableLocales,
      published: null,
    });
  };

  const deleteStoryGroup = (storyGroup: TStoryGroupData) => {
    deleteStoryGroupMutation(storyGroup.id);
  };

  return {
    editStoryGroup,
    changePublicationDate,
    publishNow,
    unpublish,
    deleteStoryGroup,
    isLoading: isStoryGroupDeleting || isUpdateStoryGroupMutation,
  };
};

import i18n from 'i18next';
import { MAX_FILE_SIZE } from 'shared/consts';
import z from 'zod';

type TFileValidation = {
  errorMessage: string;
  maxFileSize?: number;
  maxSizeErrorMessage?: string;
  isFileRequired?: boolean;
};

export const fileZodValidation = ({
  errorMessage = i18n.t('shared.validation.preview'),
  maxFileSize = MAX_FILE_SIZE,
  maxSizeErrorMessage = i18n.t('shared.maxSizeFile'),
  isFileRequired = true,
}: TFileValidation) =>
  z
    .any()
    .refine((file?: File) => {
      if (!isFileRequired && !file) {
        return true;
      }

      return file;
    }, errorMessage)
    .refine((file?: File) => {
      if (!isFileRequired && !file) {
        return true;
      }

      return file && file.size < maxFileSize;
    }, maxSizeErrorMessage);

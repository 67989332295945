import { tournamentSeasonApiKeys } from 'entities/tournament-season/consts';
import {
  TTournamentSeasons,
  TTournamentSeasonsDTO,
  TTournamentSeasonsInclude,
} from 'entities/tournament-season/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchTournamentSeasons = <
  Include extends TTournamentSeasonsInclude = never,
>({
  params,
}: TFetchParams) =>
  get<TTournamentSeasons<Include>[], TPaginatedMeta>(
    tournamentSeasonApiKeys.getTournamentSeasons,
    {
      params,
    },
  ).then((response) => response.data);

export const editTournamentSeasons = ({
  id,
  data,
}: {
  id: TEntityId;
  data: TTournamentSeasonsDTO;
}) =>
  patch<TTournamentSeasons>(
    tournamentSeasonApiKeys.editTournamentSeasons(id),
    data,
  ).then((response) => response.data.data);

export const createTournamentSeasons = (dto: TTournamentSeasonsDTO) =>
  post(tournamentSeasonApiKeys.createTournamentSeasons, dto);

export const fetchTournamentSeasonsItem = <
  Include extends TTournamentSeasonsInclude = never,
>(
  id: TEntityId,
  { params }: TFetchParams,
) =>
  get<TTournamentSeasons<Include>>(
    tournamentSeasonApiKeys.getTournamentSeasonItem(id),
    {
      params,
    },
  ).then((response) => response.data.data);

export const deleteTournamentSeasons = (id: TEntityId) =>
  del(tournamentSeasonApiKeys.deleteTournamentSeasons(id));

import { ContactModal } from 'feature/contact-modal/ui/ContactModal';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/Button/Button';
import { Typography } from 'shared/components/Typography/Typography';
import { TEntityId } from 'shared/types/common';
import { useCreateClubContact } from 'widgets/ClubForm/hooks/useCreateClubContact';
import { ClubContactsTable } from 'widgets/ClubForm/ui/ClubContactsTable';

import styles from './ClubContacts.module.css';

type TProps = {
  clubId: TEntityId;
};

export const ClubContacts = ({ clubId }: TProps) => {
  const { t } = useTranslation();
  const { openCreateClubContactModal, createContactModalProps } =
    useCreateClubContact({
      clubId,
    });

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <Typography color="var(--gray-500)" size={20} weight="Bold">
          {t('content.club.contactsLabel')}
        </Typography>
        <Button
          color="blue"
          icon="plus"
          iconPosition="before"
          iconSize="xs"
          kind="ghost"
          size="s"
          onClick={openCreateClubContactModal}
        >
          {t('shared.add')}
        </Button>
      </div>
      <ClubContactsTable clubId={clubId} />
      {createContactModalProps.isOpened && (
        <ContactModal {...createContactModalProps} />
      )}
    </div>
  );
};

import { useLocales } from 'entities/config/lib/useLocales';
import { EMatchType, TMatch } from 'entities/matches/types';
import { TMatchPlayer } from 'entities/matchPlayer/type';
import {
  useCreateOpponentMatchPlayerMutation,
  useDeleteOpponentMatchPlayerMutation,
  useEditOpponentMatchPlayerMutation,
} from 'entities/opponentMatchPlayer/mutations';
import { useChangeTacticId } from 'pages/matches/tactic/hooks/useChangeTacticId';
import { useFetchOpponentMatchPlacementPlayers } from 'pages/matches/tactic/hooks/useFetchOpponentMatchPlacementPlayers';
import { mapAddPlayerToDTO } from 'pages/matches/tactic/mapper/mapAddPlayerToDTO';
import { mapEditMatchPlayerToDTO } from 'pages/matches/tactic/mapper/mapEditMatchPlayerToDTO';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { autoSaveNotify } from 'shared/components/Notification/AutoSaveNotification/AutoSaveNotification';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';
import { TMatchPlacementAddPlayer } from 'widgets/matchPlacement/types';
import { MatchPlacement } from 'widgets/matchPlacement/ui/MatchPlacement/MatchPlacement';

export const OpponentTeamMatchPlacement = ({
  match,
}: {
  match: TMatch<'team' | 'opponentTeam'>;
}) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();

  const {
    mutateAsync: createMatchPlayerMutation,
    isLoading: isLoadingCreateMatchPlayer,
  } = useCreateOpponentMatchPlayerMutation();
  const {
    mutateAsync: editMatchPlayerMutation,
    isLoading: isLoadingEditMatchPlayer,
  } = useEditOpponentMatchPlayerMutation();
  const {
    mutateAsync: deleteMatchPlayerMutation,
    isLoading: isLoadingDeleteMatchPlayer,
  } = useDeleteOpponentMatchPlayerMutation();

  const { matchPlayers, players } = useFetchOpponentMatchPlacementPlayers({
    matchId: match.id,
    teamOpponentId: match.opponentTeam.id,
  });

  const haveMatchPlayers = useMemo(
    () => matchPlayers.some((player) => player.reserve === false),
    [matchPlayers],
  );

  const {
    openedConfirmChangeTacticModal,
    confirmChangeTactic,
    onChangeTactic,
    isLoadingEditMatch,
    closeConfirmChangeTacticModal,
  } = useChangeTacticId({
    match,
    haveMatchPlayers,
    changeTacticField: 'opponentTacticId',
  });

  const onAddPlayerHandler = async (player: TMatchPlacementAddPlayer[]) => {
    const newPlayers = player.map((player) =>
      mapAddPlayerToDTO<false>({ player, matchId: match.id }),
    );

    await createMatchPlayerMutation(newPlayers);
    autoSaveNotify();
  };

  const onDeletePlayerHandler = async (matchPlayerId: TEntityId) => {
    await deleteMatchPlayerMutation(matchPlayerId);
    autoSaveNotify();
  };

  const onEditHandler = async (matchPlayer: TMatchPlayer) => {
    await editMatchPlayerMutation({
      id: toString(matchPlayer.id),
      data: mapEditMatchPlayerToDTO(match.id, matchPlayer),
    });
    autoSaveNotify();
  };

  return (
    <>
      <MatchPlacement
        matchPlayers={matchPlayers}
        players={players}
        tacticId={match.opponentTactic}
        teamName={match.opponentTeam.translations[defaultLocale].name}
        isLoadingMatchPlayer={
          isLoadingCreateMatchPlayer ||
          isLoadingEditMatchPlayer ||
          isLoadingDeleteMatchPlayer
        }
        matchType={
          match.matchType === EMatchType.Home ? EMatchType.Guest : EMatchType.Home
        }
        onAddPlayer={onAddPlayerHandler}
        onChangeTactic={onChangeTactic}
        onDeletePlayer={onDeletePlayerHandler}
        onEditPlayer={onEditHandler}
      />
      <ConfirmationModal
        description={t('content.tactics.changeTacticDescription')}
        isLoading={isLoadingEditMatch}
        isOpened={openedConfirmChangeTacticModal}
        title={t('content.tactics.changeTacticTitle')}
        onClose={closeConfirmChangeTacticModal}
        onConfirm={confirmChangeTactic}
      />
    </>
  );
};

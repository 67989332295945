import { UseQueryOptions } from '@tanstack/react-query';
import { matchPlayerApi } from 'entities/matchPlayer/api';
import { matchPlayerQueryKeys } from 'entities/matchPlayer/consts';
import { mapApiMatchPlayersToDTO } from 'entities/matchPlayer/mapper';
import { TMatchPlayer } from 'entities/matchPlayer/type';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export type TFetchOpponentMatchPlayers = {
  defaultPagination?: TPaginatedParameters;
  filter?: Record<string, string>;
};

export const useFetchMatchPlayersQuery = (
  { defaultPagination = {}, filter }: TFetchOpponentMatchPlayers,
  options: UseQueryOptions<TResponseData<TMatchPlayer[], TPaginatedMeta>> = {},
) => {
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [matchPlayerQueryKeys.matchPlayer, filter, pagination],
      queryFn: () =>
        matchPlayerApi
          .fetchEntities({
            params: { ...filter, ...pagination },
          })
          .then((res) => ({ ...res, data: mapApiMatchPlayersToDTO(res.data) })),
      cacheTime: 0,
      ...options,
    }),
    defaultPagination,
  );
};

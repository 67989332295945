import { useContactModal } from 'feature/contact-modal/hooks/useContactModal';
import { TContactModalProps } from 'feature/contact-modal/types';
import {
  CONTACT_ADDRESS_MAX_LENGTH,
  CONTACT_EMAIL_MAX_LENGTH,
  CONTACT_PHONE_NUMBER_MAX_LENGTH,
  CONTACT_TITLE_MAX_LENGTH,
  CONTACT_WORK_SCHEDULE_MAX_LENGTH,
} from 'feature/contact-modal/validationSchema';
import { TranslationInputs } from 'feature/managerCareerModal/ui/TranslationInputs/TranslationInputs';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { TContentLang } from 'shared/types/common';

import styles from './ContactModal.module.css';

export const ContactModal = (props: TContactModalProps) => {
  const { t } = useTranslation();
  const { locales, control, actionModalProps } = useContactModal(props);

  return (
    <ActionModal {...actionModalProps} contentClassName={styles.content}>
      <TranslationInputs
        control={control}
        locales={locales}
        maxLength={CONTACT_TITLE_MAX_LENGTH}
        pathResolver={(locale: TContentLang) => `translations.${locale}.title`}
        placeholder={t('content.inputPlaceholderText')}
        showMaxLength={false}
        title={t('content.contactModal.title.label')}
      />
      <TranslationInputs
        control={control}
        locales={locales}
        maxLength={CONTACT_ADDRESS_MAX_LENGTH}
        pathResolver={(locale: TContentLang) => `translations.${locale}.address`}
        placeholder={t('content.inputPlaceholderText')}
        showMaxLength={false}
        title={t('content.contactModal.address.label')}
      />
      <FormInput
        control={control}
        fieldName="phoneNumber"
        label={t('content.contactModal.phoneNumber.label')}
        maxLength={CONTACT_PHONE_NUMBER_MAX_LENGTH}
        placeholder={t('content.inputPlaceholder.number')}
        showMaxLength={false}
        size="s"
      />
      <FormInput
        control={control}
        fieldName="email"
        label={t('content.contactModal.email.label')}
        maxLength={CONTACT_EMAIL_MAX_LENGTH}
        placeholder={t('content.inputPlaceholder.email')}
        showMaxLength={false}
        size="s"
      />
      <FormInput
        control={control}
        fieldName="workSchedule"
        label={t('content.contactModal.workSchedule.label')}
        maxLength={CONTACT_WORK_SCHEDULE_MAX_LENGTH}
        placeholder={t('content.inputPlaceholderText')}
        showMaxLength={false}
        size="s"
      />
    </ActionModal>
  );
};

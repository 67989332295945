import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Icon } from 'shared/components/Icon/Icon';
import { Pic } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';
import { TClubFormFields } from 'widgets/ClubForm/types';

import styles from './ClubForm/ClubForm.module.css';

export const ClubFormTitle = () => {
  const { watch } = useFormContext<TClubFormFields>();
  const {
    name,
    whiteImage,
    locale: { selected },
  } = watch();

  const whiteImageSrc = useMemo(
    () => (whiteImage ? URL.createObjectURL(whiteImage) : ''),
    [whiteImage],
  );

  return (
    <div className={styles.titleBlock}>
      <div className={styles.iconWrapper}>
        {whiteImage ? (
          <Pic
            alt="white image preview"
            className={styles.iconClub}
            src={whiteImageSrc}
            placeholder={{
              type: 'image',
            }}
          />
        ) : (
          <Icon className={styles.icon} kind="image-03" size="l" />
        )}
      </div>
      <Typography color="var(--gray-550)" size={16} truncate weight="Bold">
        {name?.[selected] ?? ''}
      </Typography>
    </div>
  );
};

import { UIKit } from 'pages/uikit/UIKit';
import { redirect } from 'react-router-dom';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { isProduction } from 'shared/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const UIKitRoute = {
  path: routes.uikit,
  element: <UIKit />,
  loader: () => {
    if (isProduction) {
      return redirect(routes.root);
    }

    return null;
  },
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchLabelsQuery } from 'entities/labels/queries';
import { TFilterProps } from 'feature/filters/types';
import { EntitiesFilter } from 'feature/filters/ui/EntitiesFilter/EntitiesFilter';
import { TEntityFilter } from 'feature/filters/ui/EntitiesFilter/type';
import { TPhotoGalleryFilter } from 'pages/photogallery/PhotoGalleryPage/types';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';

export const PhotoGalleryLabelFilter = ({
  setFilter,
  filter,
}: TFilterProps<TPhotoGalleryFilter>) => {
  const { defaultLocale } = useLocales();
  const { data: { data: labels } = {}, isLoading } = useFetchLabelsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const mappedLabels = labels?.map<TEntityFilter>(({ id, translations }) => ({
    id,
    name: translations[defaultLocale].name,
  }));

  return (
    <EntitiesFilter
      entities={mappedLabels ?? []}
      filter={filter.labels ?? []}
      isLoading={isLoading}
      onChangeFilter={(labels) => setFilter({ ...filter, labels })}
    />
  );
};

import { TeamRoute } from 'pages/team/teamPage';
import { TeamsPage } from 'pages/team/TeamsPage/TeamsPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRecord } from 'shared/types/router';

export const TeamsRoutes = {
  List: {
    path: routes.teams,
    element: <TeamsPage />,
    roles: DEFAULT_ROUTER_ROLES,
  },
  TeamPage: TeamRoute,
} satisfies TProtectedRecord;

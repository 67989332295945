import { Typography } from 'shared/components/Typography/Typography';
import { TWithChildren } from 'shared/types/common';

import styles from './AdBannerFormBlock.module.css';

type TProps = {
  title: string;
} & TWithChildren;

export const AdBannerFormBlock = ({ title, children }: TProps) => {
  return (
    <div className={styles.root}>
      <Typography color="var(--gray-500)" size={20} tag="h2" weight="SemiBold">
        {title}
      </Typography>
      {children}
    </div>
  );
};

import { PlayerSocialNetworksPage } from 'pages/player/socialNetworks/PlayerSocialNetworksPage/PlayerSocialNetworksPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const PlayerSocialNetworksRoute = {
  path: routes.playerSocialNetworks.url,
  element: <PlayerSocialNetworksPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

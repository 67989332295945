import { TAdBanner } from 'entities/ad/banners/model/types';
import { getFileFromUrl } from 'shared/lib/getFileFromUrl';
import { toString } from 'shared/lib/toString';
import { TContentLang } from 'shared/types/common';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';

export const mapAdBannerToFormFields = async (
  adBanner: TAdBanner,
  defaultLocale: TContentLang,
) =>
  ({
    name: adBanner.translations[defaultLocale].name,
    link: adBanner.link,
    mainBanner: adBanner.mainBanner,
    image: await getFileFromUrl(adBanner.content.x1),
    locations: adBanner.locations.map((location) => toString(location.id)),
    slotId: toString(adBanner.slot.id),
  }) satisfies TAdBannerFormFields;

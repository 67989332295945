import { queryClient } from 'app/Providers';
import { matchesQueryKeys } from 'entities/matches/consts';
import { useDeleteMatchMutation } from 'entities/matches/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useDeleteMatch = () => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useDeleteMatchMutation();

  const deleteMatch = (activeMatchId: TEntityId) => {
    mutate(activeMatchId, {
      onError: () =>
        notify(t('content.matches.notifyNotDeleteMatch'), { type: 'error' }),
      onSuccess: () => {
        return queryClient.resetQueries([matchesQueryKeys.matches]);
      },
    });
  };

  return {
    isDeletingMatch: isLoading,
    deleteMatch,
  };
};

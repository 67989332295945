import { TeamsList } from 'pages/team/TeamsPage/TeamsList';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './TeamsPage.module.css';

export const TeamsPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Typography size={24} tag="h1" weight="Bold">
        {t('content.team')}
      </Typography>
      <TeamsList />
    </div>
  );
};

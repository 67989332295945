import { useLocales } from 'entities/config/lib/useLocales';
import { useDeleteManagerMutation } from 'entities/managers/api/mutations';
import { TManager } from 'entities/managers/model/types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Card } from 'shared/components/Card/Card';
import { ECardType } from 'shared/components/Card/types';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { routes } from 'shared/routes';

type TProps = {
  manager: TManager;
};

export const ManagerCard = ({ manager }: TProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { teamId = '' } = useParams();

  const { defaultLocale } = useLocales();
  const { mutateAsync: deleteManager, isLoading } = useDeleteManagerMutation();

  const { openDeleteModal, closeDeleteModal, openedDeleteModal } =
    useModal('Delete');

  const onDeleteHandler = () => {
    deleteManager(manager.id, {
      onError: () => notify(t('content.manager.notifyNotFound')),
    });
  };

  return (
    <>
      <Card
        key={manager.id}
        type={ECardType.Manager}
        entity={{
          name: manager.translations[defaultLocale].firstName,
          lastName: manager.translations[defaultLocale].lastName,
          picture: manager?.picture?.x3 ?? '',
          position: manager.managerPosition,
        }}
        onDelete={openDeleteModal}
        onEdit={() => navigate(routes.editManager.as(teamId, manager.id))}
      />
      <ConfirmationModal
        description={t('content.manager.warningDelete')}
        isLoading={isLoading}
        isOpened={openedDeleteModal}
        title={t('content.manager.notifyDelete')}
        onClose={closeDeleteModal}
        onConfirm={onDeleteHandler}
      />
    </>
  );
};

import { EMatchType, TMatch, TMatchDTO } from 'entities/matches/types';
import { TEntityId } from 'shared/types/common';

export const mapEditMatchTacticToDTO = ({
  match,
  tacticId,
  opponentTacticId,
}: {
  match: TMatch<'team' | 'opponentTeam'>;
  tacticId?: TEntityId;
  opponentTacticId?: TEntityId;
}): TMatchDTO => {
  return {
    startAt: match.startAt,
    stadiumId: match.stadium,
    matchType: match.matchType,
    teamId:
      match.matchType === EMatchType.Home ? match.team.id : match.opponentTeam.id,
    opponentTeamId:
      match.matchType === EMatchType.Home ? match.opponentTeam.id : match.team.id,
    tacticId: tacticId,
    opponentTacticId: opponentTacticId,
  };
};

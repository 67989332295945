import cn from 'classnames';
import { forwardRef, HTMLAttributes, useRef } from 'react';
import formCommonStyles from 'shared/components/Form/FormCommonStyles.module.css';
import { componentsMap } from 'shared/components/Icon/constants';
import { Icon } from 'shared/components/Icon/Icon';
import { Typography } from 'shared/components/Typography/Typography';
import { TWithOptionalChildren } from 'shared/types/common';

import styles from './DatePickerBaseSelect.module.css';
import { TDatePickerBaseSelectProps } from './types';

export const DatePickerBaseSelect = forwardRef<
  HTMLDivElement,
  Omit<HTMLAttributes<HTMLDivElement>, 'placeholder'> &
    TWithOptionalChildren &
    TDatePickerBaseSelectProps
>(
  (
    {
      type,
      placeholder,
      size = 's',
      icon,
      isActive,
      className,
      inputClassName,
      children,
      search = false,
      onSearch,
      name,
      placeholderClassName,
      disabled = false,
      isSpaceData = false,
      onChange,
      ...props
    },
    ref,
  ) => {
    const ImageXClose = componentsMap['x'];

    const inputRef = useRef<HTMLInputElement>(null);
    const placeholderIsString = typeof placeholder === 'string';

    const handleFocusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    return (
      <div
        className={cn(
          styles.root,
          {
            [styles.disabled]: disabled,
          },
          className,
        )}
        onClick={handleFocusInput}
      >
        <div
          ref={ref}
          className={cn(
            formCommonStyles.formControl,
            formCommonStyles[size],
            styles.select,
            {
              [styles.controlActive]: isActive,
            },
            inputClassName,
          )}
          {...props}
        >
          <div className={styles.leftSide}>
            <Icon className={styles.icon} kind={icon} size="s" />
            {search && (
              <input
                autoComplete="off"
                autoFocus
                className={styles.searchInput}
                name={name}
                ref={inputRef}
                type="text"
                placeholder={
                  typeof placeholder === 'string' ? placeholder : undefined
                }
                onChange={(e) => onSearch?.(e.target.value)}
              />
            )}
            {!search && placeholderIsString && (
              <Typography
                className={cn(styles.placeholder, placeholderClassName)}
                size={12}
              >
                {placeholder}
              </Typography>
            )}
            {!search && !placeholderIsString && placeholder}
          </div>
          {type === 'datepicker' && !isSpaceData && (
            <ImageXClose
              className={styles.closeIcon}
              onClick={(e) => {
                onChange?.('');
                e.stopPropagation();
              }}
            />
          )}
        </div>
        {children}
      </div>
    );
  },
);

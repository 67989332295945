import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';

import styles from './Notification.module.css';

export const NotificationContainer = () => (
  <ToastContainer
    bodyClassName={styles.toastBody}
    className={styles.toastContainer}
    limit={1}
    toastClassName={styles.toast}
  />
);

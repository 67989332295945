import cn from 'classnames';
import { Icon } from 'shared/components/Icon/Icon';
import { TPicPlaceholderVideoSize } from 'shared/components/Pic/type';

import styles from './PicPlaceholderVideo.module.css';

type TProps = {
  size?: TPicPlaceholderVideoSize;
};

export const PicPlaceholderVideo = ({ size = 'l' }: TProps) => {
  return <Icon className={cn(styles.icon, styles[size])} customSize kind="video" />;
};

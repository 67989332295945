import cn from 'classnames';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TCropperMode } from 'shared/components/cropper/types';
import { ImageInput } from 'shared/components/Form/ImageInput/ImageInput';
import { PreviewImageIconButton } from 'shared/components/PreviewImageButton/PreviewImageIconButton';
import { Typography } from 'shared/components/Typography/Typography';

import formStyles from '../RightPanel.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  wrapperClassName?: string;
  label: string;
  imageUrl?: string;
  tip?: string;
  modalTitle: string;
  showIcon?: boolean;
  aspectRatio: number;
  hint?: string;
  required?: boolean;
  labelClassName?: string;
  imageRequirementText?: string;
  mode?: TCropperMode;
};

// Общий компонент для загрузки изображений
export const UploadImage = <T extends FieldValues>({
  control,
  fieldName,
  wrapperClassName,
  label,
  imageUrl,
  tip,
  modalTitle,
  showIcon = true,
  aspectRatio,
  mode,
  hint,
  required = false,
  labelClassName,
  imageRequirementText,
}: TProps<T>) => {
  const { t } = useTranslation();
  const { fieldState } = useController({
    control,
    name: fieldName,
  });

  return (
    <div className={wrapperClassName}>
      <div className={formStyles.previewWrapper}>
        <ImageInput
          aspectRatio={aspectRatio}
          control={control}
          fieldName={fieldName}
          hint={hint}
          icon="file-download-03"
          label={label}
          labelClassName={labelClassName}
          modalTitle={modalTitle}
          mode={mode}
          placeholder={t('shared.Workspace.uploadImage')}
          required={required}
          size="s"
          tip={tip}
          withCropping
          className={cn(formStyles.preview, {
            [formStyles.previewWithIcon]: showIcon,
          })}
        />
        {showIcon && (
          <PreviewImageIconButton
            url={imageUrl}
            className={cn(formStyles.previewButton, {
              [formStyles.previewInvalid]: fieldState.invalid,
            })}
          />
        )}
      </div>
      <Typography className={formStyles.hintText}>
        {imageRequirementText ?? t('shared.defaultImageRequirementText')}
      </Typography>
    </div>
  );
};

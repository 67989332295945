import { useMutation } from '@tanstack/react-query';
import {
  createAdBanner,
  deleteAdBanner,
  editAdBanner,
} from 'entities/ad/banners/model/api';

export const useCreateAdBannerMutation = () => {
  return useMutation({
    mutationFn: createAdBanner,
  });
};

export const useEditAdBannerMutation = () => {
  return useMutation({
    mutationFn: editAdBanner,
  });
};

export const useDeleteAdBannerMutation = () => {
  return useMutation({
    mutationFn: deleteAdBanner,
  });
};

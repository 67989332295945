import cn from 'classnames';
import {
  forwardRef,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useRef,
} from 'react';
import formCommonStyles from 'shared/components/Form/FormCommonStyles.module.css';
import styles from 'shared/components/Form/OldSelect/OldSelect.module.css';
import { TInputSize } from 'shared/components/Form/types';
import { Icon } from 'shared/components/Icon/Icon';
import { TIconType } from 'shared/components/Icon/types';
import { Typography } from 'shared/components/Typography/Typography';
import { TWithOptionalChildren } from 'shared/types/common';

export type TBaseSelectProps = {
  nextIcon?: TIconType;
  prevIcon?: TIconType;
  placeholder: ReactNode;
  placeholderClassName?: string;
  size?: TInputSize;
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  inputClassName?: string;
  search?: boolean;
  onSearch?: (value: string) => void;
  name?: string;
  disabled?: boolean;
  selectClassName?: string;
  iconClassName?: string;
  downButton?: {
    text: string;
    onClick: () => void;
  };
};

export const BaseSelect = forwardRef<
  HTMLDivElement,
  Omit<HTMLAttributes<HTMLDivElement>, 'placeholder'> &
    TWithOptionalChildren &
    TBaseSelectProps
>(
  (
    {
      placeholder,
      size = 's',
      nextIcon,
      prevIcon,
      isActive,
      className,
      inputClassName,
      children,
      search = false,
      onSearch,
      name,
      placeholderClassName,
      iconClassName,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const placeholderIsString = typeof placeholder === 'string';

    const handleFocusInput = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    return (
      <div
        className={cn(
          styles.root,
          {
            [styles.disabled]: disabled,
          },
          className,
        )}
        onClick={handleFocusInput}
      >
        <div
          ref={ref}
          className={cn(
            formCommonStyles.formControl,
            formCommonStyles[size],
            styles.select,
            {
              [styles.controlActive]: isActive,
            },
            inputClassName,
          )}
          {...props}
        >
          {prevIcon && (
            <Icon
              className={cn(styles.icon, iconClassName)}
              kind={prevIcon}
              size="xs"
            />
          )}
          {search && (
            <input
              autoComplete="off"
              autoFocus
              className={styles.searchInput}
              name={name}
              placeholder={typeof placeholder === 'string' ? placeholder : undefined}
              ref={inputRef}
              type="text"
              onChange={(e) => onSearch?.(e.target.value)}
            />
          )}
          {!search && placeholderIsString && (
            <Typography
              className={cn(styles.placeholder, placeholderClassName)}
              size={12}
            >
              {placeholder}
            </Typography>
          )}
          {!search && !placeholderIsString && placeholder}
          {nextIcon && (
            <Icon
              className={cn(styles.icon, iconClassName)}
              kind={nextIcon}
              size="xs"
            />
          )}
        </div>
        {children}
      </div>
    );
  },
);

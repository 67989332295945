import { noop } from 'lodash';
import { useCallback, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FieldValues, useForm } from 'react-hook-form';
import { UseFormProps } from 'react-hook-form/dist/types';
import {
  TSubmitHandler,
  TUseReactHookFormCustomReturn,
  TUseReactHookFormProps,
} from 'shared/hooks/useReactHookForm/types';

export const useReactHookFormCustom = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
>(
  props: TUseReactHookFormProps<TFieldValues, TContext>,
): TUseReactHookFormCustomReturn<TFieldValues, TContext> => {
  const {
    actionSubmit,
    validateOnLoad = false,
    submitOnChange = false,
    needReset = false,
    needResetAfterSubmit = false,
    ...formProps
  } = props;

  const { handleSubmit, reset, trigger, watch, ...restForm } = useForm<
    TFieldValues,
    TContext
  >(formProps as UseFormProps<TFieldValues, TContext>);

  const onValid = useCallback<TSubmitHandler<TFieldValues>>(
    (data, event) => {
      actionSubmit?.(data, event);
      if (needResetAfterSubmit) {
        reset();
      }
    },
    [actionSubmit, needResetAfterSubmit, reset],
  );

  const submit = useCallback(handleSubmit(onValid as TSubmitHandler<TFieldValues>), [
    onValid,
    handleSubmit,
  ]);

  useEffect(() => {
    if (needReset) {
      reset();
    }
    if (validateOnLoad) {
      trigger();
    }
  }, [needReset, validateOnLoad, reset, trigger]);

  useEffect(() => {
    if (!submitOnChange) {
      return noop;
    }

    const subscription = watch(() => submit());
    return () => subscription.unsubscribe();
  }, [submit, watch, submitOnChange]);

  return {
    handleSubmit,
    reset,
    submit,
    trigger,
    watch,
    ...restForm,
  };
};

import { compareAsc, parseISO } from 'date-fns';
import { TContentLang } from 'shared/types/common';

import { ENewsPostStatus, EParagraphType, TParagraph } from './types';

export const getNewsPostStatus = (date: string): ENewsPostStatus => {
  if (!date) {
    return ENewsPostStatus.Draft;
  }
  const currentDate = parseISO(new Date().toISOString());
  const publishDate = parseISO(date);

  if (compareAsc(currentDate, publishDate) > 0) {
    return ENewsPostStatus.Published;
  }

  return ENewsPostStatus.Postponed;
};

export const getTitleFromParagraphs = (
  paragraphs: TParagraph[],
  locale: TContentLang,
) => {
  const titleParagraph = paragraphs.find((p) => p.type === EParagraphType.Title);

  if (!titleParagraph) {
    return '';
  }

  const content = titleParagraph.translations[locale];

  return content && 'title' in content ? content.title : '';
};

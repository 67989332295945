import cn from 'classnames';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './Information.module.css';

type TCommonInformation = {
  name: string;
  lastName: string;
  position: string;
};

export const CardCommonInformation = ({
  name,
  lastName,
  position,
}: TCommonInformation) => {
  return (
    <div className={styles.content}>
      <Typography
        className={styles.text}
        color="var(--gray-500)"
        size={16}
        tag="p"
        weight="Bold"
      >
        {name}
      </Typography>
      <Typography
        className={styles.text}
        color="var(--gray-500)"
        size={16}
        tag="p"
        weight="Bold"
      >
        {lastName}
      </Typography>
      <Typography
        className={cn(styles.position, styles.text)}
        color="var(--gray-450)"
        size={12}
        tag="p"
      >
        {position}
      </Typography>
    </div>
  );
};

import cn from 'classnames';
import { Button } from 'shared/components/Button/Button';
import { ImageModal } from 'shared/components/ImageModal/ImageModal';
import { useModal } from 'shared/hooks/useModal';

import styles from './PreviewImageButton.module.css';

type TProps = {
  url?: string;
  className?: string;
};

export const PreviewImageIconButton = ({ url, className }: TProps) => {
  const {
    openShowImagePreviewModal,
    closeShowImagePreviewModal,
    openedShowImagePreviewModal,
  } = useModal('ShowImagePreview');

  return (
    <>
      <Button
        className={cn(styles.previewIconButton, className)}
        color="gray"
        icon="play"
        iconPosition="after"
        isDisabled={!url}
        kind="light"
        size="s"
        onClick={openShowImagePreviewModal}
      />
      <ImageModal
        imageUrl={url ?? ''}
        isShow={openedShowImagePreviewModal}
        onClose={closeShowImagePreviewModal}
      />
    </>
  );
};

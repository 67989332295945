import { format } from 'date-fns';
import { useState } from 'react';
import { Button } from 'shared/components/Button/Button';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { Typography } from 'shared/components/Typography/Typography';
import { useModal } from 'shared/hooks/useModal';

export const DatePickerExample = () => {
  const { openModal, closeModal, openedModal } = useModal();
  const [date, setDate] = useState<Date>();
  return (
    <>
      <Typography size={24} tag="h2">
        Date Picker
      </Typography>
      <Button onClick={openModal}>
        {date ? `picket date: ${format(date, 'yyyy-MM-dd')}` : 'Open me!'}
      </Button>
      <DatePicker
        isOpened={openedModal}
        onClose={closeModal}
        onDateChange={(event?: Date) => {
          setDate(event);
          closeModal();
        }}
      />
    </>
  );
};

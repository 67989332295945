import { useLocales } from 'entities/config/lib/useLocales';
import { SocialNetworkModal } from 'feature/socialNetworkModal/ui/SocialNetworkModal/SocialNetworkModal';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { Pic } from 'shared/components/Pic/Pic';
import { Table } from 'shared/components/SimpleTable';
import { Typography } from 'shared/components/Typography/Typography';
import { TEntityId, TGroupAction } from 'shared/types/common';
import { useDeleteClubSocialNetwork } from 'widgets/ClubForm/hooks/useDeleteClubSocialNetwork';
import { useEditClubSocialNetwork } from 'widgets/ClubForm/hooks/useEditClubSocialNetwork';
import { TClubSocialNetworkItem } from 'widgets/ClubForm/types';

import styles from './ClubSocialLinks/ClubSocialLinks.module.css';

type TProps = {
  isActive: boolean;
  onIconClick: () => void;
  closeActiveItemId: () => void;
  clubSocialNetwork: TClubSocialNetworkItem;
  clubId: TEntityId;
};

export const ClubSocialLinksRow = ({
  clubSocialNetwork,
  closeActiveItemId,
  isActive,
  clubId,
  onIconClick,
}: TProps) => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();

  const { editModalProps, openEditClubSocialNetworkModal } =
    useEditClubSocialNetwork({
      clubSocialNetwork,
      clubId,
      closeActiveItemId,
    });

  const { deleteModalProps, openDeleteClubSocialNetworkModal } =
    useDeleteClubSocialNetwork({
      clubSocialNetworkId: clubSocialNetwork.id,
      closeActiveItemId,
    });

  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditClubSocialNetworkModal,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: openDeleteClubSocialNetworkModal,
    },
  ];

  return (
    <Table.Row className={styles.row} isActive={isActive}>
      <Table.Cell>
        <div className={styles.icon}>
          <Pic
            alt={`social network ${clubSocialNetwork.socialNetwork.translations[defaultLocale].name}`}
            src={clubSocialNetwork.socialNetwork.picture.x3}
            placeholder={{
              type: 'image',
              size: 'xs',
            }}
          />
        </div>
        <Typography color="var(--gray-500)" size={12}>
          {clubSocialNetwork.socialNetwork.translations[defaultLocale].name}
        </Typography>
      </Table.Cell>
      <Table.Cell>
        <Actions
          actions={actions}
          isOpen={isActive}
          itemId={clubSocialNetwork.id}
          onIconClick={onIconClick}
        />
        {deleteModalProps.isOpened && <ConfirmationModal {...deleteModalProps} />}

        {editModalProps.isOpened && <SocialNetworkModal {...editModalProps} />}
      </Table.Cell>
    </Table.Row>
  );
};

import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { RightPanelCollapseWrapper } from 'feature/rightPanel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { UploadImage } from 'feature/rightPanel/ui/UploadImage/UploadImage';
import { useEffect } from 'react';
import { Control, ControllerFieldState, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SwitchField } from 'shared/components/Form';
import { Typography } from 'shared/components/Typography/Typography';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';

import formStyles from '../RightPanel.module.css';

type TProps<T extends FieldValues> = {
  sliderImage?: File;
  onChange: (base64: string) => void;
  control: Control<T>;
  fieldState: ControllerFieldState;
  title: string;
  description: string;
  switchField: {
    fieldName: Path<T>;
    description: string;
  };
  cover: {
    fieldName: Path<T>;
    important: boolean;
    imageUrl: string;
    tip: string;
  };
  aspectRatio: number;
};

export const CommonImportance = <T extends FieldValues>({
  sliderImage,
  description,
  onChange,
  control,
  switchField,
  cover,
  fieldState,
  aspectRatio,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  const { t } = useTranslation();

  useEffect(() => {
    getBase64FromFile(sliderImage).then((data) => {
      onChange(data);
    });
  }, [sliderImage]);

  return (
    <RightPanelCollapseWrapper
      description={description}
      isError={fieldState.invalid}
      label={t('content.importance')}
      {...restProps}
    >
      <div className={formStyles.switchWrapper}>
        <SwitchField control={control} fieldName={switchField.fieldName} />
        <Typography size={12}>{switchField.description}</Typography>
      </div>
      <UploadImage
        aspectRatio={aspectRatio}
        control={control}
        fieldName={cover.fieldName}
        imageUrl={cover.imageUrl}
        label={t('content.sliderCover')}
        modalTitle={t('content.settingSliderCover')}
        required
        tip={cover.tip}
        wrapperClassName={!cover.important ? formStyles.sliderWrapper : ''}
      />
    </RightPanelCollapseWrapper>
  );
};

import { AxiosRequestConfig } from 'axios';
import { apiClient } from 'shared/api/config';
import { TBaseMeta, TMeta, TResponseData } from 'shared/api/types';
import snakecaseKeys from 'snakecase-keys';

export const get = <ResponseData, Meta extends TMeta = TBaseMeta>(
  resource: string,
  config?: AxiosRequestConfig,
) => apiClient.get<TResponseData<ResponseData, Meta>>(resource, config);

export const post = <ResponseData, Meta extends TMeta = TBaseMeta, Config = unknown>(
  resource: string,
  data: AxiosRequestConfig['data'],
  config?: AxiosRequestConfig<Config>,
) =>
  apiClient.post<TResponseData<ResponseData, Meta>>(
    resource,
    snakecaseKeys(data, { deep: true }),
    config,
  );

export const patch = <
  ResponseData,
  Meta extends TMeta = TBaseMeta,
  Config = unknown,
>(
  resource: string,
  data: AxiosRequestConfig['data'],
  config?: AxiosRequestConfig<Config>,
) =>
  apiClient.patch<TResponseData<ResponseData, Meta>>(
    resource,
    snakecaseKeys(data, { deep: true }),
    config,
  );

export const del = (resource: string) => apiClient.delete(resource);

import { queryClient } from 'app/Providers';
import { matchesQueryKeys } from 'entities/matches/consts';
import { useEditMatchMutation } from 'entities/matches/mutations';
import { TMatch } from 'entities/matches/types';
import { mapEditMatchTacticToDTO } from 'pages/matches/tactic/mapper/mapEditMatchTacticToDTO';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TEntityId } from 'shared/types/common';

export const useEditMatchTactic = () => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useEditMatchMutation();

  const editMatch = async ({
    match,
    tacticId,
    opponentTacticId,
  }: {
    match: TMatch<'team' | 'opponentTeam'>;
    tacticId?: TEntityId;
    opponentTacticId?: TEntityId;
  }) => {
    const prepareNewMatchDTO = mapEditMatchTacticToDTO({
      match,
      tacticId,
      opponentTacticId,
    });

    await mutateAsync(
      { id: match.id, data: prepareNewMatchDTO },
      {
        onSuccess: () => {
          queryClient.resetQueries([matchesQueryKeys.match]);
        },
        onError: () => {
          notify(t('content.matches.notifyNotEditMatch'), { type: 'error' });
        },
      },
    );
  };

  return {
    isLoading,
    editMatch,
  };
};

import { useLocales } from 'entities/config/lib/useLocales';
import { EEditorElementType, TEditorVideo } from 'shared/components/Editor/types';
import { VideoPlayer } from 'shared/components/VideoPlayer/VideoPlayer';
import { TWorkspaceElement } from 'shared/components/Workspace/types';
import { TContentLang } from 'shared/types/common';

export const AddNewPostPreviewVideoElement = ({
  element,
  lang,
}: {
  element: TWorkspaceElement;
  lang: TContentLang;
}) => {
  const { defaultLocale } = useLocales();
  if (element.type !== EEditorElementType.Video) {
    return null;
  }

  const videoDefaultLang = element.translations[defaultLocale] as TEditorVideo;
  const videoCurrentLang = element.translations[lang] ?? videoDefaultLang;

  return <VideoPlayer {...videoCurrentLang} />;
};

import { PicPlaceholderCup } from 'shared/components/Pic/placeholders/PicPlaceholderCup/PicPlaceholderCup';
import { PicPlaceholderImage } from 'shared/components/Pic/placeholders/PicPlaceholderImage/PicPlaceholderImage';
import { PicPlaceholderMap } from 'shared/components/Pic/placeholders/PicPlaceholderMap/PicPlaceholderMap';
import { PicPlaceholderPlayer } from 'shared/components/Pic/placeholders/PicPlaceholderPlayer/PicPlaceholderPlayer';
import { PicPlaceholderSoccerTeam } from 'shared/components/Pic/placeholders/PicPlaceholderSoccerTeam/PicPlaceholderSoccerTeam';
import { PicPlaceholderVideo } from 'shared/components/Pic/placeholders/PicPlaceholderVideo/PicPlaceholderVideo';
import { TPicPlaceholder } from 'shared/components/Pic/type';

export const renderPlaceholder = (placeholder: TPicPlaceholder) => {
  switch (placeholder.type) {
    case 'image':
      return <PicPlaceholderImage size={placeholder.size} />;
    case 'soccerTeam':
      return <PicPlaceholderSoccerTeam size={placeholder.size} />;
    case 'video':
      return <PicPlaceholderVideo size={placeholder.size} />;
    case 'map':
      return <PicPlaceholderMap size={placeholder.size} />;
    case 'player':
      return <PicPlaceholderPlayer size={placeholder.size} />;
    case 'cup':
      return <PicPlaceholderCup size={placeholder.size} />;
    default:
      return null;
  }
};

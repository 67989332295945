import { useLocales } from 'entities/config/lib/useLocales';
import { TNewsPost } from 'entities/news/types';
import i18n from 'i18next';
import { Badge } from 'shared/components/Badge/Badge';
import { DateTimeCell } from 'shared/components/SimpleTable/components/DateTimeCell';
import { ImageCell } from 'shared/components/SimpleTable/components/ImageCell/ImageCell';
import { Status } from 'shared/components/Status';
import { Typography } from 'shared/components/Typography/Typography';
import { TConfigCreator } from 'widgets/elementsList/types';

export const useNewsColumnsConfig: TConfigCreator<TNewsPost> = () => {
  const { defaultLocale } = useLocales();

  return [
    {
      title: i18n.t('content.title'),
      render: (item) => (
        <ImageCell picProps={{ src: item.pictures.base.x1 }} title={item.title} />
      ),
    },
    {
      title: i18n.t('content.category'),
      render: (item) => (
        <Typography>
          {item.categories?.[0]?.translations[defaultLocale]?.name ?? ''}
        </Typography>
      ),
    },
    {
      title: i18n.t('content.createDate'),
      render: (item) => <DateTimeCell dateTimeString={item.createdAt ?? ''} />,
    },
    {
      title: i18n.t('content.importance'),
      render: (item) =>
        item.importance > 0 ? (
          <Badge
            color="green"
            content={i18n.t('content.slider')}
            size="s"
            type="outlined"
          />
        ) : null,
    },
    {
      title: i18n.t('content.status'),
      render: (item) => <Status status={item.status} />,
    },
    {
      title: i18n.t('content.publicDate'),
      render: (item) => <DateTimeCell dateTimeString={item?.published ?? ''} />,
    },

    {
      title: 'ID',
      render: (item) => item.id,
    },
  ];
};

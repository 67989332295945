import { UnpublishConfirmationModal } from 'feature/video/ui/modals/UnpublishConfirmationModal';
import { WorkspacePanel } from 'feature/workspacePanel/ui/WorkspacePanel';
import { getRenderActions } from 'feature/workspacePanel/utils';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { DatePicker } from 'shared/components/DatePicker/DatePicker';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';
import { useModal } from 'shared/hooks/useModal';
import { routes } from 'shared/routes';
import { useCreateVideoPost } from 'widgets/videoForm/hooks/useCreateVideoPost';
import { useEditVideoPost } from 'widgets/videoForm/hooks/useEditVideoPost';
import { useIsEditVideo } from 'widgets/videoForm/hooks/useIsEditVideo';
import { useIsPublishVideo } from 'widgets/videoForm/hooks/useIsPublishVideo';
import { useVideoFormPublishButton } from 'widgets/videoForm/hooks/useVideoFormPublishButton';
import { useVideoFormUnpublishButton } from 'widgets/videoForm/hooks/useVideoFormUnpublishButton';
import { TVideoPostFields } from 'widgets/videoForm/types';
import styles from 'widgets/videoForm/ui/VideoFormWorkspacePanel/VideoFormPreview/VideoFormPreview.module.css';
import { VideoFormPreviewContent } from 'widgets/videoForm/ui/VideoFormWorkspacePanel/VideoFormPreview/VideoFormPreviewContent';
import { VideoFormPublishModal } from 'widgets/videoForm/ui/VideoFormWorkspacePanel/VideoFormPublish/VideoFormPublishModal';
import { useVideoTranslation } from 'widgets/videoForm/useVideoTranslation';

export const VideoFormWorkspacePanel = () => {
  const { t } = useTranslation();
  const {
    selectedLocale,
    createdLocales,
    handleAddLocale,
    handleSelectLocale,
    handleDeleteLocale,
  } = useVideoTranslation();
  const navigate = useNavigate();
  const { openDeleteModal, closeDeleteModal, openedDeleteModal } =
    useModal('Delete');
  const { openPreviewModal, closePreviewModal, openedPreviewModal } =
    useModal('Preview');
  const {
    formState: { isValid, isSubmitting },
    handleSubmit,
    control,
    trigger,
  } = useFormContext<TVideoPostFields>();
  const { field } = useController({
    control,
    name: 'publishDate',
  });
  const isEdit = useIsEditVideo();
  const isPublish = useIsPublishVideo();
  const createVideoPost = useCreateVideoPost();
  const editVideoPost = useEditVideoPost();

  const {
    publishDate,
    isOpenPublishModal,
    onClosePublishModal,
    isOpenChooseDateModal,
    onCloseChooseDate,
    onClickPublish,
    onDateChange,
    onPublish,
    onClickChooseDate,
  } = useVideoFormPublishButton({
    isEdit,
    isPublish,
    editVideoPost,
    createVideoPost,
    control,
    trigger,
    handleSubmit,
  });

  const unpublishProps = useVideoFormUnpublishButton({
    editVideoPost,
    control,
    trigger,
    handleSubmit,
  });

  const onDraftNewsPost = () => {
    if (isEdit) {
      return;
    }
    field.onChange(undefined);
    handleSubmit(createVideoPost)();
  };

  const draftButtonProps = isEdit
    ? undefined
    : {
        children: t('shared.NewsPostForm.AddNewsPostDraftButton.button'),
        onClick: onDraftNewsPost,
        isValid,
        isDisabled: isSubmitting,
      };

  const unpublishButtonProps =
    !isEdit || !isPublish
      ? undefined
      : {
          isValid,
          children: t('content.deleteFromPublication'),
          onClick: unpublishProps?.onClickUnpublishBtn,
          isDisabled: isSubmitting,
        };

  const handleBack = () => {
    navigate(routes.video);
  };

  const renderActions = getRenderActions({
    draftButtonProps,
    unpublishButtonProps,
    publishButtonProps: {
      onClick: onClickPublish,
      isValid,
      isLoading: isSubmitting,
      children: t('shared.confirm'),
    },
    onClickPreviewButton: openPreviewModal,
  });

  return (
    <>
      <WorkspacePanel
        renderActions={renderActions}
        backButtonProps={{
          onClick: openDeleteModal,
        }}
        localesControlProps={{
          createdLocales,
          selectedLocale,
          onLocaleAdd: handleAddLocale,
          onLocaleDelete: handleDeleteLocale,
          onLocaleSelect: handleSelectLocale,
        }}
      />
      <ConfirmationModal
        description={t('content.video.comeBackPageWarning')}
        isOpened={openedDeleteModal}
        title={t('shared.backToRoot.confirmationModal.titleWithMark')}
        onClose={closeDeleteModal}
        onConfirm={handleBack}
      />
      <OverlayModal
        innerClassName={styles.modalInner}
        opened={openedPreviewModal}
        onClose={closePreviewModal}
      >
        <IconButton
          className={styles.closeButton}
          icon="x"
          iconSize="s"
          onClick={closePreviewModal}
        />
        <VideoFormPreviewContent />
      </OverlayModal>
      <VideoFormPublishModal
        isOpened={isOpenPublishModal}
        onClickChooseDate={onClickChooseDate}
        onClose={onClosePublishModal}
        onPublish={onPublish}
      />
      <DatePicker
        date={publishDate}
        isOpened={isOpenChooseDateModal}
        onClose={onCloseChooseDate}
        onDateChange={onDateChange}
      />
      {unpublishProps && (
        <UnpublishConfirmationModal
          isOpened={unpublishProps.isOpenConfirmUnpublishModal}
          onClose={unpublishProps?.onCloseUnpublish}
          onConfirm={unpublishProps?.onConfirmUnpublish}
        />
      )}
    </>
  );
};

import cn from 'classnames';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Field } from 'shared/components/Form';
import { Icon } from 'shared/components/Icon/Icon';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { Pic } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';
import { TWorkspaceProps } from 'shared/components/Workspace/Workspace';
import { getImageSettings } from 'shared/constants';
import { useModal } from 'shared/hooks/useModal';
import { formatSizeUnits } from 'shared/lib/formatSizeUnits';
import { getBase64FromFile } from 'shared/lib/getBase64FromFile';

import styles from './WorkspacePreview.module.css';

export const WorkspacePreview = ({ control, currentLocale }: TWorkspaceProps) => {
  const { t } = useTranslation();

  const { openImageLoadModal, closeImageLoadModal, openedImageLoadModal } =
    useModal('ImageLoad');
  const { field: previewFile, fieldState } = useController({
    control,
    name: `content.preview.translations.${currentLocale}.file`,
  });
  const { field: previewData } = useController({
    control,
    name: `content.preview.translations.${currentLocale}.data`,
  });

  const loadFile = (files: File[]) => {
    const file = files[0];
    if (!file) {
      return;
    }
    previewFile.onChange(file);
    getBase64FromFile(file).then((data) => {
      previewData.onChange(data);
    });
    closeImageLoadModal();
  };

  const deleteImage = () => {
    previewFile.onChange(null);
    previewData.onChange(null);
  };

  return (
    <>
      <Field
        className={styles.preview}
        error={fieldState.error}
        label={t('shared.Workspace.Field.title')}
        required
        showErrors
        tip={t('shared.Workspace.fieldDescription')}
      >
        {previewFile.value ? (
          <div className={cn(styles.zone, styles.previewImage)}>
            <div className={styles.imageWrapper}>
              <Pic
                alt="news post preview"
                objectFit="contain"
                placeholderBackgroundColor="transparent"
                src={previewData.value}
                placeholder={{
                  size: 'ml',
                  type: 'image',
                }}
              />
            </div>
            <div className={styles.infoWrapper}>
              <Typography
                className={styles.filename}
                color="var(--gray-600)"
                size={16}
              >
                {previewFile.value.name}
              </Typography>
              <Typography color="var(--gray-400)" size={14}>
                {formatSizeUnits(previewFile.value.size)}
              </Typography>
            </div>
            <div className={styles.buttonWrapper}>
              <IconButton
                className={styles.button}
                icon="edit-02"
                iconSize="s"
                onClick={openImageLoadModal}
              />
              <IconButton
                className={styles.button}
                icon="trash"
                iconSize="s"
                onClick={deleteImage}
              />
            </div>
          </div>
        ) : (
          <div
            className={cn(styles.zone, styles.loadImage, {
              [styles.zoneError]: fieldState.error,
            })}
            onClick={openImageLoadModal}
          >
            <Icon className={styles.icon} kind="file-download-03" />
            <Typography color="var(--gray-400)" size={14}>
              {t('shared.Workspace.uploadImage')}
            </Typography>
          </div>
        )}
      </Field>
      <ImageUploadModal
        aspectRatio={getImageSettings(t).news.cover.aspectRatio}
        mode="optional"
        opened={openedImageLoadModal}
        title={t('shared.Workspace.uploadImage')}
        withCropping
        onClose={closeImageLoadModal}
        onConfirm={loadFile}
      />
    </>
  );
};

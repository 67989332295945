import i18n from 'i18next';
import { secureProtocol } from 'shared/constants';
import { getTextMinLengthMessage } from 'shared/consts';
import { isValidUrl } from 'shared/lib/isValidUrl';
import { linkInWhitelist } from 'shared/lib/linkInWhitelist';
import { toBoolean } from 'shared/lib/toBoolean';
import { getMaxLengthTextGenerate } from 'shared/utils/getMaxLengthTextGenerate';
import { isSecuredLink } from 'shared/utils/links';
import z from 'zod';

const MAX_LINK_LENGTH = 10000;

export const matchFormValidationSchema = (whitelistDomains: string[]) =>
  z.object({
    homeTeam: z.string().min(1, getTextMinLengthMessage()),
    tournament: z.string().min(1, getTextMinLengthMessage()),
    guestTeam: z.string().min(1, getTextMinLengthMessage()),
    tourId: z.string().optional(),
    stadiumId: z.string().min(1, getTextMinLengthMessage()),
    startAt: z.any().refine((time) => toBoolean(time)),
    ticketsUrl: z
      .string()
      .max(MAX_LINK_LENGTH, getMaxLengthTextGenerate(MAX_LINK_LENGTH))
      .optional()
      .refine(
        (link) => (link ? isValidUrl(link) : true),
        i18n.t('content.notAvailableLink'),
      )
      .refine(
        (link) => (link ? isSecuredLink(link) : true),
        `${i18n.t('content.startLink')} ${secureProtocol}`,
      )
      .refine(
        (link) => (link ? linkInWhitelist(link, whitelistDomains) : true),
        i18n.t('content.notAvailableDomain'),
      ),
  });

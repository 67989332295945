import cn from 'classnames';
import { TStoryButtonElementSize } from 'entities/stories/types';
import { fontStyleMap } from 'pages/stories/edit/EditStory/components/forms/constants';
import { TElementProps } from 'pages/stories/edit/EditStory/types';
import { IconFactory } from 'shared/components/Icon/IconFactory';

import styles from './CopyElement.module.css';
import copyIcon from './copyIcon.svg';

const Icon = IconFactory(copyIcon);

export const CopyElement = ({ settings }: TElementProps<'copy'>) => {
  const { title, fill, background, fontStyle, fontFamily, size } = settings;

  const fontStyles = fontStyleMap[fontStyle];
  const lowerCasedSize = size.toLowerCase() as Lowercase<TStoryButtonElementSize>;

  return (
    <div
      className={cn(styles.root, styles[lowerCasedSize])}
      style={{
        color: fill,
        backgroundColor: background,
        fontFamily: fontFamily,
        ...fontStyles,
      }}
    >
      <i>
        <Icon />
      </i>
      <span>{title}</span>
    </div>
  );
};

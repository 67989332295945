import { EditPhotoGalleryRoute } from 'pages/photogallery/edit';
import { PhotoGalleryPage } from 'pages/photogallery/PhotoGalleryPage/PhotoGalleryPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRecord } from 'shared/types/router';

export const PhotoGalleryRoutes = {
  List: {
    path: routes.photoGallery,
    element: <PhotoGalleryPage />,
    roles: DEFAULT_ROUTER_ROLES,
  },
  Edit: EditPhotoGalleryRoute,
} satisfies TProtectedRecord;

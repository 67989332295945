import { clubContactsApi } from 'entities/club-contacts/api';
import { clubContactsQueryKeys } from 'entities/club-contacts/consts';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export const useFetchClubContactsQuery = (pagination: TPaginatedParameters) => {
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [clubContactsQueryKeys.clubContacts, pagination],
      queryFn: () => clubContactsApi.fetchEntities({ params: pagination }),
      cacheTime: 0,
    }),
    pagination,
  );
};

import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchCountriesQuery } from 'entities/countries/query';
import { CountryLabel } from 'feature/countrySelect/CountryLabel/CountryLabel';
import { useMemo } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OldFormSelect } from 'shared/components/Form/OldSelect/OldFormSelect';
import { TSelectOption } from 'shared/components/Form/OldSelect/types';
import { toString } from 'shared/lib/toString';
import { compareIds } from 'shared/utils/entityIds';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  className?: string;
  labelClassName?: string;
};

const COUNTRY_SELECT_PAGE_SIZE = 500;

export const CountrySelect = <T extends FieldValues>({
  control,
  fieldName,
  className,
  labelClassName,
}: TProps<T>) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const {
    field: { value: selectedCountryId },
  } = useController({
    control,
    name: fieldName,
  });

  const { data: { data: countries = [] } = {} } = useFetchCountriesQuery({
    pageSize: COUNTRY_SELECT_PAGE_SIZE,
  });

  const countryOptions = useMemo(
    () =>
      countries.map<TSelectOption>((country) => ({
        value: toString(country.id),
        label: country.translations[defaultLocale]?.name ?? '',
        renderLabel: (label: string) => (
          <CountryLabel image={country.picture.x1} label={label} />
        ),
      })),
    [countries],
  );

  const countryPlaceholder = useMemo(() => {
    if (selectedCountryId && countries.length) {
      const selectedCountry = countries.find((c) =>
        compareIds(c.id, selectedCountryId),
      );

      return (
        <CountryLabel
          key={selectedCountry!.code}
          flagSize="m"
          image={selectedCountry!.picture.x1}
          label={selectedCountry!.translations[defaultLocale]?.name ?? ''}
        />
      );
    }

    return t('content.chooseCountry');
  }, [selectedCountryId, countryOptions]);

  return (
    <>
      <OldFormSelect
        className={className}
        control={control}
        fieldName={fieldName}
        label={t('content.country')}
        labelClassName={labelClassName}
        multiple={false}
        options={countryOptions}
        placeholder={countryPlaceholder}
        required
        search
      />
    </>
  );
};

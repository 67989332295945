import { useQueryClient } from '@tanstack/react-query';
import { clubSocialNetworksQueryKeys } from 'entities/club-social-network/consts';
import { useDeleteClubSocialNetworkMutation } from 'entities/club-social-network/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';

type TProps = {
  clubSocialNetworkId: TEntityId;
  closeActiveItemId: () => void;
};

export const useDeleteClubSocialNetwork = ({
  clubSocialNetworkId,
  closeActiveItemId,
}: TProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    openDeleteClubSocialNetworkModal,
    closeDeleteClubSocialNetworkModal,
    openedDeleteClubSocialNetworkModal,
  } = useModal('DeleteClubSocialNetwork');
  const { mutateAsync: deleteClubSocialNetwork, isLoading } =
    useDeleteClubSocialNetworkMutation();

  const onConfirmDeleteClubSocialNetwork = async () => {
    await deleteClubSocialNetwork(clubSocialNetworkId, {
      onSuccess: () =>
        queryClient.resetQueries([clubSocialNetworksQueryKeys.clubSocialNetworks]),
      onError: () =>
        notify(t('content.club.notifyNotDeleteSocial'), { type: 'error' }),
    });
  };

  const onClose = () => {
    closeDeleteClubSocialNetworkModal();
    closeActiveItemId();
  };

  return {
    openDeleteClubSocialNetworkModal,
    deleteModalProps: {
      onClose,
      onConfirm: onConfirmDeleteClubSocialNetwork,
      isOpened: openedDeleteClubSocialNetworkModal,
      description: t('content.deleteWarning'),
      title: t('content.deleteSocial'),
      isLoading,
    },
  };
};

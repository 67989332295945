import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

import { adBannerApiKeys } from './consts';
import { TAdBanner, TCreateAdBannerDTO, TEditAdBannerDTO } from './types';

export const fetchAdBanners = ({ params }: TFetchParams) =>
  get<TAdBanner[], TPaginatedMeta>(adBannerApiKeys.getAdBanners, { params }).then(
    (response) => response.data,
  );

export const fetchAdBanner = (id: TEntityId) =>
  get<TAdBanner>(adBannerApiKeys.getAdBanner(id)).then(
    (response) => response.data.data,
  );

export const createAdBanner = (data: TCreateAdBannerDTO) =>
  post(adBannerApiKeys.createAdBanner, data).then((response) => response.data);

export const editAdBanner = ({ id, ...data }: TEditAdBannerDTO) =>
  patch(adBannerApiKeys.editAdBanner(id), data).then((response) => response.data);

export const deleteAdBanner = (id: TEntityId) =>
  del(adBannerApiKeys.deleteAdBanner(id));

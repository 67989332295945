import { useMutation } from '@tanstack/react-query';
import { editClub } from 'entities/club/api';
import { TClubDTO } from 'entities/club/types';

export const useEditClubMutation = () => {
  return useMutation({
    mutationFn: (data: TClubDTO) => editClub(data),
    cacheTime: 0,
  });
};

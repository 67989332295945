import { EEditorElementType, TEditor } from 'shared/components/Editor/types';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';
import { editorHeadingController } from 'shared/components/Editor/utils/heading/editorHeadingController';

export const editorWithHeadingElement = (
  editor: TEditor,
  disabledStructureChange: boolean,
) => {
  const { deleteBackward } = editor;

  editor.deleteBackward = (unit) => {
    if (editorController.isEmptySelection(editor) && !disabledStructureChange) {
      const headingTwoElement = editorController.getElement(
        editor,
        EEditorElementType.HeadingTwo,
      );

      if (headingTwoElement && headingTwoElement.children.length === 1) {
        editorHeadingController.changeHeading(editor, EEditorElementType.Paragraph);
      } else {
        deleteBackward(unit);
      }
    } else {
      deleteBackward(unit);
    }
  };

  return editor;
};

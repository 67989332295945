import { MatchLayout } from 'feature/match-layout/MatchLayout';
import { useCreateMatch } from 'pages/matches/new/hooks/useCreateMatch';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MatchForm } from 'widgets/match-form/ui/MatchForm/MatchForm';
import { useMatchForm } from 'widgets/match-form/ui/MatchForm/useMatchForm';

export const NewMatchPage = () => {
  const { t } = useTranslation();
  const { isLoading, createMatch } = useCreateMatch();

  const {
    getValues,
    control,
    formState: { isValid },
  } = useMatchForm();

  const onContinueHandler = () => {
    const formData = getValues();

    createMatch(formData);
  };
  return (
    <MatchLayout
      title={t('content.match.create')}
      confirmButtonProps={{
        onClick: onContinueHandler,
        children: t('shared.continue'),
        isDisabled: !isValid,
        isLoading: isLoading,
      }}
    >
      <MatchForm control={control} />
    </MatchLayout>
  );
};

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { seasonsQueryKeys } from 'entities/seasons/consts';
import { TSeason } from 'entities/seasons/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchSeason, fetchSeasons } from './api';

export const useFetchSeasonsQuery = ({
  options = {},
  defaultPagination,
}: {
  options?: UseQueryOptions<TResponseData<TSeason[], TPaginatedMeta>>;
  defaultPagination?: TPaginatedParameters;
}) => {
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [seasonsQueryKeys.seasons, pagination],
      queryFn: () =>
        fetchSeasons({
          params: {
            ...pagination,
          },
        }),
      ...options,
    }),
    defaultPagination,
  );
};

export const useFetchSeasonQuery = (id: TEntityId) =>
  useQuery({
    queryKey: [seasonsQueryKeys.season(id)],
    queryFn: () => fetchSeason(id),
    enabled: toBoolean(id),
  });

import { components, ContainerProps } from 'react-select';
import { Badge } from 'shared/components/Badge/Badge';
import styles from 'shared/components/Form/Select/Select.module.css';
import { TSelectOption } from 'shared/components/Form/Select/types';

export const SelectContainer = (props: ContainerProps<TSelectOption>) => {
  const { children, isMulti, hasValue, getValue, setValue } = props;
  const options = getValue();

  return (
    <>
      <components.SelectContainer {...props}>{children}</components.SelectContainer>
      {isMulti && hasValue && (
        <div className={styles.optionsBadges}>
          {options.map((item) => (
            <Badge
              key={item.value}
              color="green"
              content={item.label}
              shape="pilled"
              size="s"
              type="solid"
              closeIconProps={{
                size: 'xs',
                onClick: () => {
                  setValue(
                    options.filter((option) => option.value !== item.value),
                    'deselect-option',
                  );
                },
              }}
            />
          ))}
        </div>
      )}
    </>
  );
};

import { getFilenameFromUrl } from 'shared/lib/getFilenameFromUrl';

export const getFileFromUrl = async (url: string) => {
  const filename = getFilenameFromUrl(url);
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const type = response.headers.get('Content-Type') ?? '';
    return new File([blob], filename, { type });
  } catch (e) {
    return new File([], filename);
  }
};

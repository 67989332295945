import { TEntityId } from 'shared/types/common';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';

export const generateAdBannerFormValues = (
  slotId: TEntityId,
): TAdBannerFormFields => ({
  slotId,
  name: '',
  locations: [],
  mainBanner: false,
  link: '',
});

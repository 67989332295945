import { ManagerCareersRoute } from 'pages/manager/careers';
import { CreateManagerRoute } from 'pages/manager/create';
import { EditManagerRoute } from 'pages/manager/edit';
import { ManagerSocialNetworksRoute } from 'pages/manager/socialNetworks';
import { ManagerTeamAchievementsRoute } from 'pages/manager/teamAchievements';
import { TProtectedRecord } from 'shared/types/router';

export const ManagerRoutes = {
  Create: CreateManagerRoute,
  Edit: EditManagerRoute,
  Careers: ManagerCareersRoute,
  SocialNetworks: ManagerSocialNetworksRoute,
  TeamAchievements: ManagerTeamAchievementsRoute,
} satisfies TProtectedRecord;

import cn from 'classnames';
import { TAdSlot } from 'entities/ad/slots/model/types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ImageInput } from 'shared/components/Form/ImageInput/ImageInput';
import { Typography } from 'shared/components/Typography/Typography';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';
import styles from 'widgets/adBannerForm/ui/AdBannerForm/AdBannerForm.module.css';

type TProps = {
  selectedSlot?: TAdSlot;
};

export const AdBannerFormImage = ({ selectedSlot }: TProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext<TAdBannerFormFields>();

  const aspectRatio = selectedSlot
    ? selectedSlot.width / selectedSlot.height
    : undefined;

  const orientation = selectedSlot
    ? getOrientation(selectedSlot.width, selectedSlot.height)
    : 'horizontal';

  const hint =
    orientation === 'horizontal'
      ? t('content.adBanners.form.image.hintHorizontalOrientation')
      : t('content.adBanners.form.image.hintVerticalOrientation');

  return (
    <div className={cn(styles.imageInputWrapper, styles.field)}>
      <ImageInput
        aspectRatio={aspectRatio}
        control={control}
        fieldName="image"
        hint={hint}
        icon="file-download-03"
        label={t('content.adBanners.form.image.label')}
        modalTitle={t('content.adBanners.form.image.modalTitle')}
        placeholder={t('content.adBanners.form.image.placeholder')}
        required
        withCropping={selectedSlot ? true : undefined}
      />
      <Typography color="var(--dark-blue-250)" size={12}>
        {hint}
      </Typography>
    </div>
  );
};

type TOrientation = 'horizontal' | 'vertical';

function getOrientation(width: number, height: number): TOrientation {
  if (height > width) {
    return 'vertical';
  } else {
    return 'horizontal';
  }
}

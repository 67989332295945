import { useQueryClient } from '@tanstack/react-query';
import { clubSocialNetworksQueryKeys } from 'entities/club-social-network/consts';
import { useEditClubSocialNetworkMutation } from 'entities/club-social-network/mutations';
import { TSocialNetworkModalValue } from 'feature/socialNetworkModal/types';
import { useTranslation } from 'react-i18next';
import { handleValidationError } from 'shared/api/errors';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';
import { TClubSocialNetworkItem } from 'widgets/ClubForm/types';

type TProps = {
  clubSocialNetwork: TClubSocialNetworkItem;
  clubId: TEntityId;
  closeActiveItemId: () => void;
};

export const useEditClubSocialNetwork = ({
  clubId,
  clubSocialNetwork,
  closeActiveItemId,
}: TProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    openedEditClubSocialNetworkModal,
    closeEditClubSocialNetworkModal,
    openEditClubSocialNetworkModal,
  } = useModal('EditClubSocialNetwork');
  const { mutateAsync: editClubSocialNetworkMutation, isLoading } =
    useEditClubSocialNetworkMutation();

  const editClubSocialNetwork = async (
    clubSocialNetworkFields: TSocialNetworkModalValue,
  ) => {
    await editClubSocialNetworkMutation(
      {
        id: clubSocialNetwork.id,
        data: {
          ...clubSocialNetworkFields,
          clubId,
        },
      },
      {
        onSuccess: () =>
          queryClient.resetQueries([clubSocialNetworksQueryKeys.clubSocialNetworks]),
        onError: (error) =>
          handleValidationError(error, () => {
            notify(t('content.club.notifyNotUploadSocial'), { type: 'error' });
          }),
      },
    );
    closeEditClubSocialNetworkModal();
    closeActiveItemId();
  };

  const closeModal = () => {
    closeEditClubSocialNetworkModal();
    closeActiveItemId();
  };

  return {
    openEditClubSocialNetworkModal,
    editModalProps: {
      initialValue: {
        url: clubSocialNetwork.url,
        socialNetworkId: toString(clubSocialNetwork.socialNetwork.id),
      },
      title: t('content.editLink'),
      closeModal,
      actionSubmit: editClubSocialNetwork,
      isOpened: openedEditClubSocialNetworkModal,
      isLoading,
    },
  };
};

import { useLocales } from 'entities/config/lib/useLocales';
import {
  useDeleteLabelsMutation,
  useEditLabelsMutation,
} from 'entities/labels/mutations';
import { TLabel } from 'entities/labels/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from 'shared/components/Actions/Actions';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { LocalizationModal } from 'shared/components/LocalizationModal/LocalizationModal';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TGroupAction, TTranslations } from 'shared/types/common';
import { TTableActions } from 'widgets/elementsList/types';

export const LabelActions = ({
  isActive,
  onIconClick,
  closeActiveItemId,
  item: label,
}: TTableActions<TLabel>) => {
  const { t } = useTranslation();
  const { locales } = useLocales();

  const { openDeleteModal, closeDeleteModal, openedDeleteModal } =
    useModal('Delete');

  const { openEditModal, closeEditModal, openedEditModal } = useModal('Edit');

  const { mutateAsync: editLabels, isLoading: isLoadingEdit } =
    useEditLabelsMutation();
  const { mutateAsync: deleteLabels, isLoading: isLoadingDelete } =
    useDeleteLabelsMutation();
  const isLoading = isLoadingDelete || isLoadingEdit;

  const closeEditModalHandler = useCallback(() => {
    closeActiveItemId();
    closeEditModal();
  }, []);
  const editLabel = async (data: TTranslations<string>) => {
    closeEditModal();
    await editLabels({
      data: {
        translations: reduceTranslationObject(locales, (locale) => ({
          name: data[locale],
        })),
      },
      id: label.id,
    });
    closeActiveItemId();
  };
  const onConfirmDeleteLabel = () => {
    closeEditModal();
    deleteLabels(label?.id).catch(() =>
      notify(t('content.settings.notifyDeleteLabel')),
    );
  };
  const closeDeleteModalHandler = useCallback(() => {
    closeDeleteModal();
    closeActiveItemId();
  }, []);

  const actions: TGroupAction[] = [
    {
      title: t('content.edit'),
      icon: 'edit-02',
      action: openEditModal,
    },
    {
      title: t('content.delete'),
      icon: 'trash',
      action: openDeleteModal,
    },
  ];
  return (
    <>
      <Actions
        actions={actions}
        isLoading={isLoading}
        isOpen={isActive}
        itemId={label.id}
        onIconClick={onIconClick}
      />
      <ConfirmationModal
        isOpened={openedDeleteModal}
        title={t('content.settings.deleteLabel')}
        onClose={closeDeleteModalHandler}
        onConfirm={onConfirmDeleteLabel}
      />
      <LocalizationModal
        actionSubmit={editLabel}
        closeModal={closeEditModalHandler}
        createInitialValue={(locale) => label?.translations[locale].name ?? ''}
        isOpened={openedEditModal}
        subtitle={t('content.settings.labelName')}
        title={t('content.settings.editLabel')}
        inputProps={{
          maxLength: 255,
          placeholder: t('content.inputPlaceholder'),
          required: true,
          size: 's',
        }}
      />
    </>
  );
};

import { useQueryClient } from '@tanstack/react-query';
import { managerSocialNetworksQueryKeys } from 'entities/managerSocialNetworks/consts';
import { useEditManagerSocialNetworkMutation } from 'entities/managerSocialNetworks/mutations';
import { TEditManagerSocialNetworkDTO } from 'entities/managerSocialNetworks/types';
import { useTranslation } from 'react-i18next';
import { handleValidationError } from 'shared/api/errors';
import { notify } from 'shared/components/Notification';

export const useEditManagerSocialNetwork = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: editManagerSocialNetworkMutation, isLoading } =
    useEditManagerSocialNetworkMutation();

  const editManagerSocialNetwork = (
    managerSocialNetwork: TEditManagerSocialNetworkDTO,
    onSuccess: () => void,
  ) => {
    editManagerSocialNetworkMutation(managerSocialNetwork, {
      onSuccess: () => {
        queryClient.resetQueries([
          managerSocialNetworksQueryKeys.managerSocialNetworks,
        ]);
        onSuccess();
      },
      onError: (error) =>
        handleValidationError(error, () => {
          notify(t('content.manager.notifyNotUpdateSocial'), { type: 'error' });
        }),
    });
  };

  return { isEditingManagerSocialNetwork: isLoading, editManagerSocialNetwork };
};

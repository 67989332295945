import { useQueryClient } from '@tanstack/react-query';
import { managerSocialNetworksQueryKeys } from 'entities/managerSocialNetworks/consts';
import { useCreateManagerSocialNetworkMutation } from 'entities/managerSocialNetworks/mutations';
import { TSocialNetworkModalValue } from 'feature/socialNetworkModal/types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';
import { handleValidationError } from 'shared/api/errors';
import { notify } from 'shared/components/Notification';

export const useCreateManagerSocialNetwork = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isCreateModalOpen, setIsCreateModalOpen] = useBoolean(false);
  const { managerId = '' } = useParams<{
    teamId: string;
    managerId: string;
  }>();
  const openCreateModal = () => setIsCreateModalOpen(true);
  const closeCreateModal = () => setIsCreateModalOpen(false);

  const { mutate: createManagerSocialNetworkMutation, isLoading } =
    useCreateManagerSocialNetworkMutation();

  const createManagerSocialNetwork = (socialNetwork: TSocialNetworkModalValue) => {
    createManagerSocialNetworkMutation(
      {
        ...socialNetwork,
        managerId,
      },
      {
        onError: (error) =>
          handleValidationError(error, () => {
            notify(t('content.manager.notifyNotCreateSocial'), { type: 'error' });
          }),
        onSuccess: () => {
          queryClient.resetQueries([
            managerSocialNetworksQueryKeys.managerSocialNetworks,
          ]);
          closeCreateModal();
        },
      },
    );
  };

  return {
    openCreateModal,
    createSocialNetworkModalProps: {
      title: t('content.addLink'),
      closeModal: closeCreateModal,
      actionSubmit: createManagerSocialNetwork,
      isOpened: isCreateModalOpen,
      isLoading,
      initialValue: {
        url: 'https://',
        socialNetworkId: '',
      },
    },
  };
};

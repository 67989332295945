import { format, parseISO } from 'date-fns';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './DateTimeCell.module.css';

type TProps = {
  dateTimeString: Date | string | null;
  hiddenTime?: boolean;
};

const datePlaceholder = '--/--/--';
const timePlaceholder = '--:--';

export const DateTimeCell = ({ dateTimeString, hiddenTime }: TProps) => {
  const parsedDate = dateTimeString ? parseISO(dateTimeString as string) : null;
  const date = parsedDate ? format(parsedDate, 'dd/MM/yyyy') : datePlaceholder;
  const time = parsedDate ? format(parsedDate, 'HH:mm') : timePlaceholder;

  if (hiddenTime) {
    return <Typography className={styles.rootDate}>{date}</Typography>;
  }
  return (
    <>
      <Typography className={styles.root}>
        {`${date} — `}
        <Typography className={styles.time}>{`${time}`}</Typography>
      </Typography>
    </>
  );
};

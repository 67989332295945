import { OpponentTeamsList } from 'pages/opponentTeam/OpponentTeamsPage/OpponentTeamsList';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './OpponentTeamsPage.module.css';

export const OpponentTeamsPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <Typography size={24} tag="h1" weight="Bold">
        {t('content.opponentTeams')}
      </Typography>
      <OpponentTeamsList />
    </div>
  );
};

import { useLocales } from 'entities/config/lib/useLocales';
import { TMatch } from 'entities/matches/types';
import {
  useCreateMatchPlayerMutation,
  useDeleteMatchPlayerMutation,
  useEditMatchPlayerMutation,
} from 'entities/matchPlayer/mutations';
import { TMatchPlayer } from 'entities/matchPlayer/type';
import { useChangeTacticId } from 'pages/matches/tactic/hooks/useChangeTacticId';
import { useFetchMatchPlacementPlayers } from 'pages/matches/tactic/hooks/useFetchMatchPlacementPlayers';
import { mapAddPlayerToDTO } from 'pages/matches/tactic/mapper/mapAddPlayerToDTO';
import { mapEditMatchPlayerToDTO } from 'pages/matches/tactic/mapper/mapEditMatchPlayerToDTO';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { autoSaveNotify } from 'shared/components/Notification/AutoSaveNotification/AutoSaveNotification';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';
import { TMatchPlacementAddPlayer } from 'widgets/matchPlacement/types';
import { MatchPlacement } from 'widgets/matchPlacement/ui/MatchPlacement/MatchPlacement';

export const TeamMatchPlacement = ({
  match,
}: {
  match: TMatch<'team' | 'opponentTeam'>;
}) => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();

  const {
    mutateAsync: createMatchPlayerMutation,
    isLoading: isLoadingCreateMatchPlayer,
  } = useCreateMatchPlayerMutation();
  const {
    mutateAsync: editMatchPlayerMutation,
    isLoading: isLoadingEditMatchPlayer,
  } = useEditMatchPlayerMutation();
  const {
    mutateAsync: deleteMatchPlayerMutation,
    isLoading: isLoadingDeleteMatchPlayer,
  } = useDeleteMatchPlayerMutation();

  const { matchPlayers, players } = useFetchMatchPlacementPlayers({
    matchId: match.id,
    teamId: match.team.id,
  });

  const onAddPlayerHandler = async (player: TMatchPlacementAddPlayer[]) => {
    const newPlayers = player.map((player) =>
      mapAddPlayerToDTO({ player, matchId: match.id }),
    );

    await createMatchPlayerMutation(newPlayers);
    autoSaveNotify();
  };

  const haveMatchPlayers = useMemo(
    () => matchPlayers.some((player) => player.reserve === false),
    [matchPlayers],
  );

  const {
    openedConfirmChangeTacticModal,
    confirmChangeTactic,
    onChangeTactic,
    isLoadingEditMatch,
    closeConfirmChangeTacticModal,
  } = useChangeTacticId({
    match,
    haveMatchPlayers,
    changeTacticField: 'tacticId',
  });

  const onDeletePlayerHandler = async (matchPlayerId: TEntityId) => {
    await deleteMatchPlayerMutation(matchPlayerId);
    autoSaveNotify();
  };

  const onEditHandler = async (matchPlayer: TMatchPlayer) => {
    await editMatchPlayerMutation({
      id: toString(matchPlayer.id),
      data: mapEditMatchPlayerToDTO(match.id, matchPlayer),
    });
    autoSaveNotify();
  };

  return (
    <>
      <MatchPlacement
        matchPlayers={matchPlayers}
        matchType={match.matchType}
        players={players}
        tacticId={match.tactic}
        teamName={match.team.translations[defaultLocale].name}
        isLoadingMatchPlayer={
          isLoadingCreateMatchPlayer ||
          isLoadingEditMatchPlayer ||
          isLoadingDeleteMatchPlayer
        }
        onAddPlayer={onAddPlayerHandler}
        onChangeTactic={onChangeTactic}
        onDeletePlayer={onDeletePlayerHandler}
        onEditPlayer={onEditHandler}
      />
      <ConfirmationModal
        description={t('content.tactics.changeTacticDescription')}
        isLoading={isLoadingEditMatch}
        isOpened={openedConfirmChangeTacticModal}
        title={t('content.tactics.changeTacticTitle')}
        onClose={closeConfirmChangeTacticModal}
        onConfirm={confirmChangeTactic}
      />
    </>
  );
};

import { IconButton } from 'shared/components/IconButton/IconButton';
import { OverlayModal } from 'shared/components/OverlayModal/OverlayModal';
import { Pic } from 'shared/components/Pic/Pic';

import styles from './ImageModal.module.css';

type TProps = {
  imageUrl: string;
  isShow: boolean;
  onClose: () => void;
};

export const ImageModal = ({ imageUrl, isShow, onClose }: TProps) => {
  return (
    <OverlayModal innerClassName={styles.overlay} opened={isShow} onClose={onClose}>
      <div className={styles.closeButtonWrapper}>
        <IconButton
          className={styles.closeButton}
          icon="x"
          iconSize="s"
          onClick={onClose}
        />
      </div>
      <Pic
        alt=""
        className={styles.previewImage}
        placeholderClassName={styles.previewImagePlaceholder}
        src={imageUrl}
        placeholder={{
          type: 'image',
          size: 'xxl',
        }}
      />
    </OverlayModal>
  );
};

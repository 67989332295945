import { EMatchStatus } from 'entities/matches/types';
import { MatchStatus } from 'entities/matches/ui/MatchStatus/MatchStatus';
import { TMatchesFilterParams } from 'entities/matches/utils';
import { TFilterProps } from 'feature/filters/types';
import { EntitiesFilter } from 'feature/filters/ui/EntitiesFilter/EntitiesFilter';

import styles from '../MatchesPage.module.css';

export const STATUSES = Object.values(EMatchStatus);

export const MatchesStatusFilter = ({
  filter,
  setFilter,
}: TFilterProps<TMatchesFilterParams>) => {
  return (
    <EntitiesFilter
      entities={STATUSES.map((status) => ({ id: status, name: status }))}
      filter={filter.status}
      renderCheckboxLabel={(status) => (
        <MatchStatus className={styles.filterStatusLabel} status={status} />
      )}
      onChangeFilter={(status) => setFilter({ ...filter, status: status })}
    />
  );
};

import cn from 'classnames';
import { Pic, TPicProps } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';
import { TWithClassName } from 'shared/types/common';

import styles from './ImageCell.module.css';

type TProps = {
  title: string;
  picProps: Omit<TPicProps, 'alt' | 'placeholder'> &
    Partial<Pick<TPicProps, 'placeholder'>>;
  previewWrapperClassName?: string;
} & TWithClassName;

export const ImageCell = ({
  title,
  className,
  previewWrapperClassName,
  picProps: {
    placeholder = {
      type: 'image',
      size: 'xs',
    },
    ...picProps
  },
}: TProps) => {
  return (
    <div className={cn(styles.root, className)}>
      <div className={cn(styles.previewWrapper, previewWrapperClassName)}>
        <Pic alt={`${title} preview`} placeholder={placeholder} {...picProps} />
      </div>
      <Typography>{title}</Typography>
    </div>
  );
};

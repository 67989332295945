import cn from 'classnames';
import { Pic } from 'shared/components/Pic/Pic';
import { TPicPlaceholder } from 'shared/components/Pic/type';

import styles from './BlurredImage.module.css';

type TProps = {
  imageUrl: string;
  className?: string;
  alt: string;
  imagePlaceholder?: TPicPlaceholder;
};

export const BlurredImage = ({
  imageUrl,
  className,
  alt,
  imagePlaceholder = {
    type: 'image',
    size: 'm',
  },
}: TProps) => (
  <div className={cn(styles.root, className)}>
    <div
      className={styles.background}
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    />
    <Pic
      alt={alt}
      className={styles.image}
      objectFit="contain"
      placeholder={imagePlaceholder}
      placeholderBackgroundColor="transparent"
      src={imageUrl}
    />
  </div>
);

import { PlayerAchievementsRoute } from 'pages/player/achievements';
import { PlayerCareersRoute } from 'pages/player/careers';
import { CreatePlayerRoute } from 'pages/player/create';
import { EditPlayerRoute } from 'pages/player/edit';
import { PlayerSocialNetworksRoute } from 'pages/player/socialNetworks';
import { PlayerTeamAchievementsRoute } from 'pages/player/teamAchievements';
import { TProtectedRecord } from 'shared/types/router';

export const PlayerRoutes = {
  Create: CreatePlayerRoute,
  Edit: EditPlayerRoute,
  Careers: PlayerCareersRoute,
  SocialNetworks: PlayerSocialNetworksRoute,
  Achievements: PlayerAchievementsRoute,
  TeamAchievements: PlayerTeamAchievementsRoute,
} satisfies TProtectedRecord;

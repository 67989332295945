import JSONAPISerializer from 'json-api-serializer';
import { JSONApiSerializer } from 'shared/api/jsonApi/JSONApiSerializer';

const entitiesTypes = new Set();

export function registerJsonApiDocument(
  resource: JSONAPISerializer.ResourceObject<unknown>,
) {
  registerResource(resource);
}

function registerResource(resource: JSONAPISerializer.ResourceObject<unknown>) {
  if (!resource || !resource.type) {
    return;
  }

  registerJsonApiType(resource.type);

  if (resource.relationships) {
    for (const key in resource.relationships) {
      const relation = resource.relationships[key];

      if (relation.data) {
        if (Array.isArray(relation.data)) {
          relation.data.forEach(registerResource);
        } else {
          registerResource(relation.data);
        }
      }
    }
  }
}

function registerJsonApiType(type: string | undefined) {
  if (type && !entitiesTypes.has(type)) {
    JSONApiSerializer.register(type);
    entitiesTypes.add(type);
  }
}

import cn from 'classnames';
import { Icon } from 'shared/components/Icon/Icon';
import { TPicPlaceholderMapSize } from 'shared/components/Pic/type';

import styles from './PicPlaceholderMap.module.css';

type TProps = {
  size?: TPicPlaceholderMapSize;
};

export const PicPlaceholderMap = ({ size = 'm' }: TProps) => {
  return <Icon className={cn(styles.icon, styles[size])} customSize kind="map-01" />;
};

import { TournamentsPage } from 'pages/settings/tournaments/TournamentsPage/TournamentsPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const SettingsTournamentsRoute = {
  element: <TournamentsPage />,
  path: routes.tournaments,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

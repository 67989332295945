import { useFetchLabelsQuery } from 'entities/labels/queries';
import { useFetchPhotoGalleryCategoriesQuery } from 'entities/photoGalleryCategories/queries';
import { useRightPanelLabel } from 'feature/rightPanel/hooks/useRightPanelLabel';
import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { CommonLabel } from 'feature/rightPanel/ui/CommonLabel/CommonLabel';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';

export const PhotoGalleryCategorizations = (
  commonProps: TDefaultRightPanelProps,
) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TPhotoGalleryFields>();
  const { data: { data: labelsData } = {} } = useFetchLabelsQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });
  const { data: { data: categoriesData } = {} } =
    useFetchPhotoGalleryCategoriesQuery({
      pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
    });

  const { labels, categories } = useRightPanelLabel<TPhotoGalleryFields>({
    labels: labelsData,
    labelsFieldName: 'labels',
    categoriesFieldName: 'categories',
    categories: categoriesData,
  });

  return (
    <CommonLabel
      categories={categories}
      control={control}
      description={t('content.photoGallery.rightPanel.categorization.description')}
      labels={labels}
      {...commonProps}
    />
  );
};

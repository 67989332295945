import { useGetClubFormFields } from 'pages/aboutClubPage/hooks/useGetClubFormFields';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { ClubForm } from 'widgets/ClubForm/ui/ClubForm/ClubForm';

export const AboutClubPage = () => {
  const { data: clubData, isLoading, isError, error } = useGetClubFormFields();

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return (
      <Centered>
        <Loader size="l" />
      </Centered>
    );
  }

  return (
    <ClubForm clubFormFields={clubData.clubFormFields} clubId={clubData.club.id} />
  );
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/Icon/Icon';
import { ImageUploadModal } from 'shared/components/ImageUploadModal/ImageUploadModal';
import { Pic } from 'shared/components/Pic/Pic';
import { useModal } from 'shared/hooks/useModal';
import { getUrlFromFile, removeUrlFromMemory } from 'shared/lib/getUrlFromFile';

import styles from './UploadAvatar.module.css';

type TProps = {
  avatar?: File;
  onLoadAvatar: (avatar: File) => void;
  aspectRatio: number;
};

export const UploadAvatar = ({ avatar, onLoadAvatar, aspectRatio }: TProps) => {
  const { t } = useTranslation();
  const { openLoadAvatarModal, closeLoadAvatarModal, openedLoadAvatarModal } =
    useModal('LoadAvatar');

  const [filePreview, setFilePreview] = useState<string>(
    avatar ? getUrlFromFile(avatar) : '',
  );

  useEffect(() => {
    setFilePreview(avatar ? getUrlFromFile(avatar) : '');
    removeUrlFromMemory(filePreview);
  }, [avatar]);

  useEffect(() => {
    return () => {
      removeUrlFromMemory(filePreview);
    };
  }, []);

  const onConfirm = (files: File[]) => {
    const file = files[0];
    onLoadAvatar(file);
    const url = getUrlFromFile(file);
    removeUrlFromMemory(filePreview);
    setFilePreview(url);
    closeLoadAvatarModal();
  };

  return (
    <>
      <div className={styles.root} onClick={openLoadAvatarModal}>
        {filePreview ? (
          <>
            <Pic
              alt="avatar"
              src={filePreview}
              placeholder={{
                type: 'image',
                size: 'm',
              }}
            />
            <div className={styles.editWrapper}>
              <Icon className={styles.editIcon} kind="edit-02" />
            </div>
          </>
        ) : (
          <div className={styles.addWrapper}>
            <Icon className={styles.addIcon} kind="image-plus" />
          </div>
        )}
      </div>
      <ImageUploadModal
        aspectRatio={aspectRatio}
        opened={openedLoadAvatarModal}
        title={t('shared.imageSetting')}
        withCropping
        onClose={closeLoadAvatarModal}
        onConfirm={onConfirm}
      />
    </>
  );
};

import {
  TEntityId,
  TPartialRecord,
  TPartialTranslations,
} from 'shared/types/common';
import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';

export enum EEditorElementType {
  Slider = 'slider',
  Paragraph = 'paragraph',
  Image = 'image',
  Video = 'video',
  Link = 'link',
  BulletedList = 'bulleted-list',
  ListItem = 'list-item',
  HeadingTwo = 'heading-two',
}

export type TEditorHeadingElements =
  | EEditorElementType.HeadingTwo
  | EEditorElementType.Paragraph;

export enum EEditorMarks {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  Deleted = 'deleted',
}

export type TEditorDefaultText = {
  text: string;
} & TPartialRecord<EEditorMarks, boolean>;

export type TEditorImage = {
  filename: string;
  url: string;
};

export type TEditorLink = {
  text: string;
  url: string;
};

export type TEditorVideo = {
  src: string;
  hosting: string;
};

export type TEditorSliderElement = {
  id?: TEntityId;
  type: EEditorElementType.Slider;
  children: TEditorDefaultText[];
  translations: TPartialTranslations<TEditorImage[]>;
  files: TEditorImage[];
};

export type TEditorParagraphElement = {
  id?: TEntityId;
  type: EEditorElementType.Paragraph;
  translations: TPartialTranslations<TEditorDefaultText>;
  children: (TEditorDefaultText | TEditorLinkElement)[];
};

export type TEditorImageElement = {
  id?: TEntityId;
  type: EEditorElementType.Image;
  translations: TPartialTranslations<TEditorImage>;
  file: TEditorImage;
  children: TEditorDefaultText[];
};

export type TEditorLinkElement = {
  type: EEditorElementType.Link;
  url: string;
  translations: TPartialTranslations<TEditorLink>;
  children: TEditorDefaultText[];
};

export type TEditorVideoElement = {
  id?: TEntityId;
  type: EEditorElementType.Video;
  translations: TPartialTranslations<TEditorVideo>;
  file: TEditorVideo;
  children: TEditorDefaultText[];
};

export type TEditorBulletedListElement = {
  id?: TEntityId;
  type: EEditorElementType.BulletedList;
  translations: TPartialTranslations<TEditorDefaultText>;
  children: TEditorListItemElement[];
};

export type TEditorListItemElement = {
  type: EEditorElementType.ListItem;
  children: TEditorDefaultText[];
};

export type TEditorHeadingTwoElement = {
  id?: TEntityId;
  type: EEditorElementType.HeadingTwo;
  translations: TPartialTranslations<TEditorDefaultText>;
  children: TEditorDefaultText[];
};

export type TEditorElement =
  | TEditorParagraphElement
  | TEditorSliderElement
  | TEditorLinkElement
  | TEditorBulletedListElement
  | TEditorListItemElement
  | TEditorHeadingTwoElement
  | TEditorImageElement
  | TEditorVideoElement;

export type TMatchTEditorElement<T extends EEditorElementType> =
  T extends EEditorElementType.HeadingTwo
    ? TEditorHeadingTwoElement
    : T extends EEditorElementType.Paragraph
      ? TEditorParagraphElement
      : T extends EEditorElementType.Video
        ? TEditorVideoElement
        : T extends EEditorElementType.BulletedList
          ? TEditorBulletedListElement
          : T extends EEditorElementType.ListItem
            ? TEditorListItemElement
            : T extends EEditorElementType.Slider
              ? TEditorSliderElement
              : T extends EEditorElementType.Link
                ? TEditorLinkElement
                : T extends EEditorElementType.Image
                  ? TEditorImageElement
                  : never;

export type TEditor = BaseEditor & ReactEditor;

import { useFetchMatchQuery } from 'entities/matches/queries';
import { EMatchType } from 'entities/matches/types';
import { MatchLayout } from 'feature/match-layout/MatchLayout';
import { useCheckMatchTactic } from 'pages/matches/tactic/hooks/useCheckMatchTactic';
import { OpponentTeamMatchPlacement } from 'pages/matches/tactic/OpponentTeamMatchPlacement/OpponentTeamMatchPlacement';
import { TacticErrorModal } from 'pages/matches/tactic/TacticErrorModal';
import { TeamMatchPlacement } from 'pages/matches/tactic/TeamMatchPlacement/TeamMatchPlacement';
import { useUserFromCreateMatch } from 'pages/matches/useUserFromCreateMatch';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Centered } from 'shared/components/Centered/Centered';
import { Loader } from 'shared/components/Loader/Loader';
import { Typography } from 'shared/components/Typography/Typography';
import { toString } from 'shared/lib/toString';

import styles from './TacticPage.module.css';

export const TacticPage = () => {
  const { t } = useTranslation();

  const { matchId } = useParams<{
    matchId: string;
  }>();

  const fromCreateMatch = useUserFromCreateMatch();

  const {
    data: match,
    error,
    isError,
    isLoading: isLoadingMatch,
  } = useFetchMatchQuery<'team' | 'opponentTeam'>({
    id: toString(matchId),
    params: {
      include: ['team', 'opponent_team'],
    },
  });
  const { isLoading, checkMatchTactic, errorModalState, closeErrorModal } =
    useCheckMatchTactic(match);

  const isHome = match?.matchType === EMatchType.Home;

  if (isError) {
    throw error;
  }

  if (isLoadingMatch) {
    return (
      <Centered>
        <Loader size="l" />
      </Centered>
    );
  }

  return (
    <MatchLayout
      confirmButtonProps={{
        onClick: checkMatchTactic,
        children: t('shared.confirm'),
        isLoading,
      }}
      title={
        fromCreateMatch ? t('content.match.create') : t('content.matches.editMatch')
      }
    >
      <div className={styles.root}>
        <Typography color="var(--gray-500)" size={20} tag="h2" weight="SemiBold">
          {t('content.match.tactic')}
        </Typography>
        <div className={styles.wrapper}>
          {isHome ? (
            <>
              <TeamMatchPlacement match={match} />
              <OpponentTeamMatchPlacement match={match} />
            </>
          ) : (
            <>
              <OpponentTeamMatchPlacement match={match} />
              <TeamMatchPlacement match={match} />
            </>
          )}
        </div>
      </div>
      {errorModalState && (
        <TacticErrorModal type={errorModalState} onClose={closeErrorModal} />
      )}
    </MatchLayout>
  );
};

import { useLocales } from 'entities/config/lib/useLocales';
import { TPhotoGallery } from 'entities/photoGallery/types';
import { PhotoGalleryCategoryFilter } from 'pages/photogallery/PhotoGalleryPage/PhotoGalleryCategoryFilter';
import { PhotoGalleryLabelFilter } from 'pages/photogallery/PhotoGalleryPage/PhotoGalleryLabelFilter';
import {
  TPhotoGalleryFilter,
  TPhotoGalleryTableSortParams,
} from 'pages/photogallery/PhotoGalleryPage/types';
import { useTranslation } from 'react-i18next';
import { DateTimeCell } from 'shared/components/SimpleTable/components/DateTimeCell';
import { ImageCell } from 'shared/components/SimpleTable/components/ImageCell/ImageCell';
import { Typography } from 'shared/components/Typography/Typography';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { TConfigCreator } from 'widgets/elementsList/types';

export const usePhotoGalleryColumnsConfig: TConfigCreator<
  TPhotoGallery,
  TPhotoGalleryTableSortParams,
  TPhotoGalleryFilter
> = () => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  return [
    {
      title: t('content.photoGalleryList.title'),
      headCellProps: {
        sort: {
          sortParam: 'name',
        },
      },
      render: (item) => (
        <ImageCell
          picProps={{ src: item.pictures.base.x1 }}
          title={item?.translations[defaultLocale]?.name}
        />
      ),
    },
    {
      title: t('content.createDate'),
      headCellProps: {
        sort: {
          sortParam: 'created_at',
        },
      },
      render: (item) => <DateTimeCell dateTimeString={item.createdAt} />,
    },
    {
      title: t('content.category'),
      render: (item) => (
        <Typography>
          {item.categories?.[0]?.translations[defaultLocale]?.name ?? ''}
        </Typography>
      ),
      headCellProps: {
        filter: {
          filterName: 'categories',
          getIsFiltered: (filter) =>
            toBoolean(filter.categories && filter.categories.length > 0),
          getFilterBadge: (filter) =>
            filter.categories?.length ? toString(filter.categories?.length) : null,
          renderFilter: PhotoGalleryCategoryFilter,
        },
      },
    },
    {
      title: t('content.commonList.labelColumnLabel'),
      render: (item) => (
        <Typography>
          {item.labels?.[0]?.translations[defaultLocale]?.name}
        </Typography>
      ),
      headCellProps: {
        filter: {
          filterName: 'labels',
          getIsFiltered: (filter) =>
            toBoolean(filter.labels && filter.labels.length > 0),
          getFilterBadge: (filter) =>
            filter.labels?.length ? toString(filter.labels?.length) : null,
          renderFilter: PhotoGalleryLabelFilter,
        },
      },
    },
    {
      title: t('content.publicDate'),
      render: (item) => (
        <Typography>
          <DateTimeCell dateTimeString={item?.published ?? ''} />
        </Typography>
      ),
      headCellProps: {
        sort: {
          sortParam: 'published',
        },
      },
    },
    {
      title: 'ID',
      render: (item) => <Typography>{item?.id}</Typography>,
    },
  ];
};

import { TCreateAdBannerDTO } from 'entities/ad/banners/model/types';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import { mapFileToUpload } from 'shared/utils/image';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';

export const mapAdBannerFormToCreateDTO = async (
  availableLocales: TContentLang[],
  data: TAdBannerFormFields,
) =>
  ({
    slotId: data.slotId,
    locations: data.locations,
    mainBanner: data.mainBanner,
    link: data.link,
    bannerType: 'image',
    content: await mapFileToUpload(data.image!),
    translations: reduceTranslationObject(availableLocales, () => ({
      name: data.name,
    })),
  }) satisfies TCreateAdBannerDTO;

export const mapAdBannerFormToEditDTO = async (
  availableLocales: TContentLang[],
  data: Partial<TAdBannerFormFields>,
) =>
  ({
    slotId: data.slotId,
    locations: data.locations,
    mainBanner: data.mainBanner,
    link: data.link,
    bannerType: 'image',
    content: data.image ? await mapFileToUpload(data.image!) : undefined,
    translations: data.name
      ? reduceTranslationObject(availableLocales, () => ({
          name: data?.name ?? '',
        }))
      : undefined,
  }) satisfies Partial<TCreateAdBannerDTO>;

import { useRightPanelConnections } from 'feature/rightPanel/hooks/useRightPanelConnections';
import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { CommonConnections } from 'feature/rightPanel/ui/CommonConnections/CommonConnections';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TVideoPostFields } from 'widgets/videoForm/types';

export const VideoConnections = (commonProps: TDefaultRightPanelProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<TVideoPostFields>();

  const { tournament, teams, players, managers, season, match } =
    useRightPanelConnections<TVideoPostFields>({
      seasonFieldName: 'season',
      tournamentFieldName: 'tournament',
      playersFieldName: 'players',
      managersFieldName: 'managers',
      matchFieldName: 'match',
      teamsFieldName: 'teams',
      control,
    });

  return (
    <CommonConnections
      control={control}
      description={t('content.video.videoConnection')}
      managers={managers}
      match={match}
      players={players}
      season={season}
      teams={teams}
      tournament={tournament}
      {...commonProps}
    />
  );
};

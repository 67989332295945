import { generateMockData } from 'pages/uikit/utils';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import { Switch } from 'shared/components/Form/Switch';
import { Icon } from 'shared/components/Icon/Icon';
import { Table } from 'shared/components/SimpleTable';
import { DateTimeCell } from 'shared/components/SimpleTable/components/DateTimeCell';
import { TCommonSkeleton } from 'shared/components/Skeleton/types';
import { Status } from 'shared/components/Status';
import { Typography } from 'shared/components/Typography/Typography';
import { toString } from 'shared/lib/toString';

import styles from '../UIKit.module.css';

const data = generateMockData(5);

const skeletonTemplate: TCommonSkeleton[] = [
  { variant: 'round', width: 32, height: 32 },
  { variant: 'default', width: 188 },
  { variant: 'default', width: 128 },
  { variant: 'default', width: 236 },
  { variant: 'default', width: 108 },
  { variant: 'default', width: 128 },
  { variant: 'default', width: 16, height: 16 },
];
export const SkeletonExample = () => {
  const { t } = useTranslation();
  const [isLoading, toggleLoading] = useToggle(false);
  return (
    <div>
      <Typography size={24} tag="h2">
        Skeleton
      </Typography>
      <div className={styles.row} style={{ marginBottom: 16 }}>
        <Typography>Switch loading</Typography>
        <Switch
          checked={isLoading}
          size="l"
          value={toString(isLoading)}
          onChange={toggleLoading}
        />
      </div>
      <Table>
        <Table.Head>
          <Table.Row className={styles.rowGridTemplate} hoverable={false}>
            <Table.Cell>
              <Typography>{t('content.title')}</Typography>
            </Table.Cell>
            <Table.Cell>
              <Typography>{t('content.createDate')}</Typography>
            </Table.Cell>
            <Table.Cell>
              <Typography>ID</Typography>
            </Table.Cell>
            <Table.Cell>
              <Typography>{t('content.status')}</Typography>
            </Table.Cell>
            <Table.Cell>
              <Typography>{t('content.publicDate')}</Typography>
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {isLoading ? (
            <Table.Skeleton
              className={styles.skeletonGridTemplate}
              rowsCount={5}
              skeletonTemplate={skeletonTemplate}
            ></Table.Skeleton>
          ) : (
            data.map((item) => (
              <Table.Row key={item.id} className={styles.rowGridTemplate}>
                <Table.Cell className={styles.publicationNameColumn}>
                  <div>
                    <Icon kind="picture" size="s" />
                  </div>
                  <Typography>{item.publication}</Typography>
                </Table.Cell>
                <Table.Cell>
                  <DateTimeCell dateTimeString={item.dateCreated} />
                </Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell>
                  <Status status={item.status} />
                </Table.Cell>
                <Table.Cell>
                  <DateTimeCell dateTimeString={item.datePublished} />
                </Table.Cell>
                <Table.Cell />
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

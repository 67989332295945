import { PlayerTeamAchievementsPage } from 'pages/player/teamAchievements/PlayerTeamAchievementsPage/PlayerTeamAchievementsPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const PlayerTeamAchievementsRoute = {
  path: routes.playerTeamAchievements.url,
  element: <PlayerTeamAchievementsPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

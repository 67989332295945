import cn from 'classnames';
import { ETeamTabType, TTeamTab } from 'pages/team/types';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './SwitcherTeams.module.css';

type TProps = {
  tabs: TTeamTab[];
  selected: ETeamTabType;
};

export const SwitcherTeams = ({ tabs, selected }: TProps) => {
  return (
    <div className={styles.switchWrapper}>
      {tabs?.map((tab, index) => (
        <div
          key={index}
          className={cn(styles.control, {
            [styles.isSelected]: selected === tab.tabName,
          })}
          onClick={tab.onClick}
        >
          <Typography>{tab.text}</Typography>
        </div>
      ))}
    </div>
  );
};

import { PlayerAchievementsPage } from 'pages/player/achievements/PlayerAchievementsPage/PlayerAchievementsPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const PlayerAchievementsRoute = {
  path: routes.playerAchievements.url,
  element: <PlayerAchievementsPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

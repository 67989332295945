import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { matchesQueryKeys } from 'entities/matches/consts';
import { TMatch, TMatchInclude } from 'entities/matches/types';
import { TFetchMatchParams } from 'entities/matches/utils';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';
import { toBoolean } from 'shared/lib/toBoolean';
import { TEntityId } from 'shared/types/common';

import { fetchMatch, fetchMatches } from './api';

export const useFetchMatchesQuery = <Include extends TMatchInclude = never>({
  options = {},
  params,
}: {
  options?: UseQueryOptions<TResponseData<TMatch<Include>[], TPaginatedMeta>>;
  params: TFetchMatchParams;
}) => {
  const { include, filter, sort, ...defaultPagination } = params;
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [matchesQueryKeys.matches, pagination, filter, sort],
      queryFn: () =>
        fetchMatches<Include>({
          params: {
            ...pagination,
            ...params,
            include: include?.join(','),
          },
        }),
      ...options,
    }),
    defaultPagination,
  );
};

export const useFetchMatchQuery = <Include extends TMatchInclude = never>({
  id = '',
  params = {},
  options = {},
}: {
  id: TEntityId;
  params?: TFetchMatchParams;
  options?: UseQueryOptions<TMatch<Include>, unknown, TMatch<Include>>;
}) => {
  const fetchParams = {
    ...params,
    include: params.include?.join(','),
  };

  return useQuery({
    queryKey: [matchesQueryKeys.match, id, fetchParams],
    queryFn: () =>
      fetchMatch<Include>(id, {
        params: fetchParams,
      }),
    enabled: toBoolean(id),
    cacheTime: 0,
    ...options,
  });
};

import cn from 'classnames';
import { EMatchType } from 'entities/matches/types';
import { useFetchTacticsQuery } from 'entities/tactics/queries';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'shared/components/Form/Select/Select';
import { TEntityId } from 'shared/types/common';
import { compareIds } from 'shared/utils/entityIds';

import styles from './MatchPlacementTacticSelect.module.css';

type TProps = {
  matchType?: EMatchType;
  tacticId?: TEntityId;
  onChangeTactic: ({ tacticId }: { tacticId: TEntityId }) => void;
};

export const MatchPlacementTacticSelect = ({
  matchType,
  tacticId,
  onChangeTactic,
}: TProps) => {
  const { t } = useTranslation();

  const { data: { data: tactics = [] } = {} } = useFetchTacticsQuery();

  const tacticSelectOptions = useMemo(() => {
    return tactics.map((tactic) => ({
      label: tactic.name,
      value: tactic.id,
    }));
  }, [tactics]);

  const onChangeTacticHandler = (tactic: string | number) => {
    onChangeTactic({ tacticId: tactic });
  };

  return (
    <div
      className={cn(styles.tacticSelect, {
        [styles.tacticSelectGuest]: matchType === EMatchType.Guest,
      })}
    >
      <Select
        isSearchable
        options={tacticSelectOptions}
        placeholder={t('content.tactics.placeholder')}
        selectWidth={138}
        size="small"
        getOptionLabel={(option) =>
          `${t('content.tactics.tactic')}: ${option.label}`
        }
        value={
          tacticSelectOptions?.find((option) =>
            compareIds(option.value, tacticId),
          ) ?? null
        }
        onChange={(option) => onChangeTacticHandler(option?.value ?? '')}
      />
    </div>
  );
};

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/Icon/Icon';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Loader } from 'shared/components/Loader/Loader';
import { Tooltip } from 'shared/components/Tooltip/Tooltip';
import { Typography } from 'shared/components/Typography/Typography';
import { TImageToUpload } from 'widgets/photoGalleryForm/ui/PhotoGalleryFormDropzone/types';

import styles from './PhotoGalleryFormUploadModalItem.module.css';

type TProps = {
  id: string;
  onDeleteUploading: (id: string) => void;
} & TImageToUpload;

export const PhotoGalleryFormUploadModalItem = ({
  id,
  onDeleteUploading,
  file,
  status,
}: TProps) => {
  const { t } = useTranslation();
  const isImageToDelete = status === 'error' || status === 'cancelled';

  return (
    <li className={styles.uploadListItem}>
      <Typography
        className={styles.fileName}
        color={isImageToDelete ? 'var(--red-200)' : 'var(--gray-500)'}
        size={12}
      >
        {file.name}
      </Typography>
      {status === 'uploading' && <Loader size="xs" />}
      {isImageToDelete && (
        <Tooltip
          placement="top-end"
          tip={t('content.photoGallery.uploadModal.deleteItemTip')}
        >
          <IconButton
            className={cn(styles.closeButton, styles.icon, styles[status])}
            icon="x-circle"
            iconSize="m"
            onClick={() => onDeleteUploading(id)}
          />
        </Tooltip>
      )}
      {status === 'success' && (
        <Icon
          className={cn(styles.icon, styles[status])}
          kind="check-circle"
          size="m"
        />
      )}
    </li>
  );
};

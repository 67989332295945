import {
  TApiMatchOpponentPlayer,
  TMatchOpponentPlayer,
} from 'entities/opponentMatchPlayer/type';

export const mapApiMatchOpponentPlayersToDTO = (
  apiMatchOpponentPlayers: TApiMatchOpponentPlayer[],
): TMatchOpponentPlayer[] => {
  return apiMatchOpponentPlayers?.map((player) => ({
    ...player,
    matchId: player?.match,
    playerId: player?.player,
  }));
};

import { useState } from 'react';
import { Table } from 'shared/components/SimpleTable';
import { TEntityId } from 'shared/types/common';
import { compareIds } from 'shared/utils/entityIds';
import { useClubSocialNetworks } from 'widgets/ClubForm/hooks/useClubSocialNetworks';
import { ClubSocialLinksRow } from 'widgets/ClubForm/ui/ClubSocialLinksRow';

export const ClubSocialLinksTable = ({ clubId }: { clubId: TEntityId }) => {
  const [activeItemId, setActiveItemId] = useState<TEntityId | null>(null);
  const { socialLinks } = useClubSocialNetworks();

  return (
    <Table>
      <Table.Body>
        {socialLinks.map((socialLink) => {
          const isActive = compareIds(activeItemId, socialLink.id);

          return (
            <ClubSocialLinksRow
              key={socialLink.id}
              closeActiveItemId={() => setActiveItemId(null)}
              clubId={clubId}
              clubSocialNetwork={socialLink}
              isActive={isActive}
              onIconClick={() => {
                setActiveItemId(isActive ? null : socialLink.id);
              }}
            />
          );
        })}
      </Table.Body>
    </Table>
  );
};

import { TMatchPlayer } from 'entities/matchPlayer/type';
import { useFetchTacticQuery } from 'entities/tactics/queries';
import React, { useState } from 'react';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';
import {
  TMatchPlacementAddPlayer,
  TMatchPlacementPlayer,
} from 'widgets/matchPlacement/types';

import { MatchPlacementPositionSelect } from '../MatchPlacementPositionSelect/MatchPlacementPositionSelect';

type TProps = {
  selectedTacticId?: TEntityId;
  matchPlayers: TMatchPlayer[];
  players: TMatchPlacementPlayer[];
  onAddPlayer: (data: TMatchPlacementAddPlayer[]) => void;
  onEditPlayer: (matchPlayer: TMatchPlayer) => void;
  isLoadingMatchPlayer: boolean;
};

export const MatchPlacementPlayerPositions = ({
  selectedTacticId,
  matchPlayers,
  players,
  onAddPlayer,
  onEditPlayer,
  isLoadingMatchPlayer,
}: TProps) => {
  const [activePositionId, setActivePositionId] = useState<TEntityId | null>(null);

  const { data: playersPositions } = useFetchTacticQuery({
    id: toString(selectedTacticId),
    params: {
      include: ['positions'],
    },
  });

  const onAddPlayerHandler = (position: TEntityId | null) => {
    setActivePositionId(position);
  };

  return playersPositions?.positions?.map((position) => (
    <MatchPlacementPositionSelect
      key={position.id}
      activePositionId={activePositionId}
      isLoadingMatchPlayer={isLoadingMatchPlayer}
      matchPlayers={matchPlayers}
      players={players}
      position={position}
      tactic={playersPositions}
      onAddPlayer={onAddPlayer}
      onClickContainer={onAddPlayerHandler}
      onEditPlayer={onEditPlayer}
    />
  ));
};

import { CardCommonInformation } from 'shared/components/Card/CardInformationPart/CardCommonInformation';
import { ECardType, TCardInformation } from 'shared/components/Card/types';

import styles from './Information.module.css';

export const CardManagerInformation = ({ type, entity }: TCardInformation) => {
  if (type !== ECardType.Manager) {
    return null;
  }

  const { lastName, position, name } = entity;

  return (
    <div className={styles.wrapper}>
      <CardCommonInformation lastName={lastName} name={name} position={position} />
    </div>
  );
};

import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { NextButton } from 'feature/rightPanel/ui/NextButton/NextButton';
import { Collapse, TCollapseProps } from 'shared/components/Collapse/Collapse';
import { Typography } from 'shared/components/Typography/Typography';
import { TWithChildren } from 'shared/types/common';

import styles from '../RightPanel.module.css';

type TProps = {
  title: string;
  description: string;
  onClickNext?: () => void;
  disabledNext?: boolean;
} & TWithChildren;

export const RightPanelCollapseWrapper = ({
  children,
  title,
  description,
  onClickNext,
  disabledNext,
  ...collapseProps
}: TDefaultRightPanelProps & TCollapseProps & TProps) => {
  return (
    <Collapse {...collapseProps}>
      <Typography className={styles.title} size={14} weight="SemiBold">
        {title}
      </Typography>
      <Typography className={styles.description} size={12}>
        {description}
      </Typography>
      {children}
      {onClickNext && <NextButton disabled={disabledNext} onClick={onClickNext} />}
    </Collapse>
  );
};

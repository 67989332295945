import cn from 'classnames';
import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import iphone13mockup from 'shared/assets/images/iphone-13Mockup.png';
import { Divider } from 'shared/components/Divider';
import defaultElementsStyles from 'shared/components/Editor/elements/editorElementsStyle.module.css';
import { Typography } from 'shared/components/Typography/Typography';
import { TODAY } from 'shared/constants';
import { TVideoPostFields } from 'widgets/videoForm/types';
import { VideoFormPreviewImage } from 'widgets/videoForm/ui/VideoFormWorkspacePanel/VideoFormPreview/VideoFormPreviewImage';
import { VideoFormRecommendedVideoCard } from 'widgets/videoForm/ui/VideoFormWorkspacePanel/VideoFormPreview/VideoFormRecommendedVideoCard';

import styles from './VideoFormPreview.module.css';

export const VideoFormPreviewContent = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<TVideoPostFields>();
  const {
    preview,
    translations,
    locale: { selected: selectedLocale },
  } = getValues();
  const title = translations[selectedLocale]?.title;
  const todayDate = format(TODAY, 'dd MMMM yyyy');
  return (
    <>
      <img alt="iphone 13 mockup" className={styles.mockup} src={iphone13mockup} />
      <div className={styles.content}>
        <VideoFormPreviewImage preview={preview} />
        <div className={cn(styles.body, defaultElementsStyles.root)}>
          <Typography className={styles.title} size={12} weight="SemiBold">
            {title || t('content.video.hereTitle')}
          </Typography>
          <Typography className={styles.date}>{todayDate}</Typography>
        </div>
        <Divider />
        <div className={cn(styles.videos, defaultElementsStyles.root)}>
          <Typography className={styles.title} size={12} weight="SemiBold">
            {t('content.video.recommendation')}
          </Typography>
          <VideoFormRecommendedVideoCard date={todayDate} />
          <VideoFormRecommendedVideoCard date={todayDate} />
        </div>
      </div>
    </>
  );
};

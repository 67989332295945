import { BackButton } from 'pages/stories/edit/EditStory/components/forms/components/BackButton/BackButton';
import { FontSettings } from 'pages/stories/edit/EditStory/components/forms/components/FontSettings/FontSettings';
import {
  alignmentOptions,
  fontSizeOptions,
} from 'pages/stories/edit/EditStory/components/forms/constants';
import {
  TCommonElementFormProps,
  TTextElementFormValues,
} from 'pages/stories/edit/EditStory/components/forms/types';
import { useStoryEditorContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorContext';
import { useStoryEditorDispatchContext } from 'pages/stories/edit/EditStory/hooks/useStoryEditorDispatchContext';
import { useTranslation } from 'react-i18next';
import { Divider } from 'shared/components/Divider';
import { Form, ModeSelectField } from 'shared/components/Form';
import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';
import { FormSelect } from 'shared/components/Form/FormSelect/FormSelect';
import { useForm } from 'shared/hooks/useReactHookForm';

import styles from '../ElementForm.module.css';

export const TextElementForm = ({
  selectedElement,
}: TCommonElementFormProps<'text'>) => {
  const { t } = useTranslation();
  const { selectedLocale } = useStoryEditorContext();
  const dispatch = useStoryEditorDispatchContext();
  const submitForm = (data: TTextElementFormValues) => {
    dispatch({ type: 'updateSelectedStoryElementSettings', payload: data });
  };

  const { submit, control } = useForm<TTextElementFormValues>({
    values: selectedElement?.translations[selectedLocale].settings,
    actionSubmit: submitForm,
    submitOnChange: true,
  });

  return (
    <div className={styles.root}>
      <Form action={submit} className={styles.formComponent}>
        <FontSettings
          control={control}
          fontFamilyName="fontFamily"
          fontStyleName="fontStyle"
        />
        <FormSelect
          control={control}
          fieldName="fontSize"
          inline
          inlineInputClassName={styles.fontSize}
          isSearchable={false}
          label={t('content.story.size')}
          menuWidth={76}
          options={fontSizeOptions}
          placeholder="Select"
          selectWidth={76}
        />
        <ModeSelectField
          control={control}
          fieldName="textAlign"
          inline
          inlineInputClassName={styles.formField}
          label={t('content.story.alignment')}
          options={alignmentOptions}
        />
        <ColorInput
          control={control}
          fieldName="fill"
          inline
          inlineInputClassName={styles.formField}
          label={t('content.story.textColor')}
          placeholder=""
        />
        <Divider />
        <ColorInput
          control={control}
          fieldName="background"
          inputClassName={styles.input}
          label={t('content.story.backgroundText')}
          placeholder=""
        />
      </Form>
      <BackButton />
    </div>
  );
};

import { useLocales } from 'entities/config/lib/useLocales';
import { useFetchToursQuery } from 'entities/tours/queries';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_PAGINATION_PAGE_SIZE } from 'shared/api/constants';
import { TSelectOption } from 'shared/components/Form/Select/types';
import { toString } from 'shared/lib/toString';

export const useTourSelectorsMatch = () => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();

  const { data: toursData } = useFetchToursQuery({
    pageSize: DEFAULT_NO_PAGINATION_PAGE_SIZE,
  });

  const toursOptions: TSelectOption[] = useMemo(() => {
    return (
      toursData?.data.map((tour) => {
        return {
          value: toString(tour?.id),
          label: tour.translations[defaultLocale].name,
        };
      }) ?? []
    );
  }, [toursData]);

  return {
    tours: {
      options: toursOptions,
      placeholder: t('content.matches.chooseTour'),
    },
  };
};

import i18n, { TFunction } from 'i18next';
import { getTextMinLengthMessage } from 'shared/consts';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang } from 'shared/types/common';
import z from 'zod';

export const CONTACT_TITLE_MAX_LENGTH = 50;
export const CONTACT_PHONE_NUMBER_MAX_LENGTH = 50;
export const CONTACT_EMAIL_MAX_LENGTH = 255;
export const CONTACT_ADDRESS_MAX_LENGTH = 50;
export const CONTACT_WORK_SCHEDULE_MAX_LENGTH = 50;

const createTranslationsSchema = (locales: TContentLang[], t: TFunction) => {
  return reduceTranslationObject(locales, () =>
    z.object({
      title: z
        .string()
        .min(1, getTextMinLengthMessage())
        .max(
          CONTACT_TITLE_MAX_LENGTH,
          `${i18n.t('content.contactModal.title.lengthValidation')} ${CONTACT_TITLE_MAX_LENGTH} ${t('content.symbols')}`,
        ),
      address: z
        .string()
        .min(1, getTextMinLengthMessage())
        .max(
          CONTACT_EMAIL_MAX_LENGTH,
          `${i18n.t('content.contactModal.address.lengthValidation')} ${CONTACT_EMAIL_MAX_LENGTH} ${t('content.symbols')}`,
        ),
    }),
  );
};

export const contactModalValidationSchema = (
  locales: TContentLang[],
  t: TFunction,
) =>
  z.object({
    translations: z.object({ ...createTranslationsSchema(locales, t) }),
    phoneNumber: z
      .string()
      .max(
        CONTACT_PHONE_NUMBER_MAX_LENGTH,
        `${t('content.contactModal.phoneNumber.lengthValidation')} ${CONTACT_PHONE_NUMBER_MAX_LENGTH} ${t('content.symbols')}`,
      ),
    email: z
      .string()
      .max(
        CONTACT_EMAIL_MAX_LENGTH,
        `${t('content.contactModal.email.lengthValidation')} ${CONTACT_EMAIL_MAX_LENGTH} ${t('content.symbols')}`,
      ),
    workSchedule: z
      .string()
      .max(
        CONTACT_WORK_SCHEDULE_MAX_LENGTH,
        `${t('content.contactModal.workSchedule.lengthValidation')} ${CONTACT_WORK_SCHEDULE_MAX_LENGTH} ${t('content.symbols')}`,
      ),
  });

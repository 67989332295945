import cn from 'classnames';
import { Icon } from 'shared/components/Icon/Icon';
import { TPicPlaceholderCupSize } from 'shared/components/Pic/type';

import styles from './PicPlaceholderCup.module.css';

type TProps = {
  size?: TPicPlaceholderCupSize;
};

export const PicPlaceholderCup = ({ size = 'm' }: TProps) => {
  return <Icon className={cn(styles.icon, styles[size])} customSize kind="cup" />;
};

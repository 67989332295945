import { useFetchManagerCareersQuery } from 'entities/managerCareers/api/queries';
import { ManagerCareerModal } from 'feature/managerCareerModal/ui/ManagerCareerModal/ManagerCareerModal';
import { useCreateManagerCareer } from 'pages/manager/careers/ManagerCareersPage/hooks/useCreateManagerCareer';
import { ManagerCareersActions } from 'pages/manager/careers/ManagerCareersPage/ManagerCareersActions/ManagerCareersActions';
import { useManagerCareersColumnsConfig } from 'pages/manager/careers/ManagerCareersPage/ManagerCareersTable/columnsConfig';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getApiFilters } from 'shared/lib/apiFilters';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './ManagerCareersTable.module.css';

export const ManagerCareersTable = () => {
  const { t } = useTranslation();
  const { managerId = '' } = useParams<{
    managerId: string;
  }>();

  const { openCreateModal, createCareerModalProps } = useCreateManagerCareer();

  const {
    data: { data: managerCareers = [], meta: { total = 0, page = 1 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchManagerCareersQuery({
    filter: getApiFilters({
      name: 'manager_id',
      value: managerId,
    }),
  });

  return (
    <>
      <CommonList
        className={styles.wrapper}
        configCreator={useManagerCareersColumnsConfig}
        emptyComponentDescription=""
        error={error}
        isEmpty={false}
        isLoading={isLoading}
        title={t('content.career')}
        createButtonProps={{
          content: t('content.manager.createCareer'),
          onClick: openCreateModal,
          kind: 'outlined',
          size: 's',
          color: 'blue',
        }}
        paginationProps={{
          selectedPage: page,
          totalItems: total,
          onPageChange: changePage,
        }}
        tableProps={{
          data: managerCareers,
          rowClassName: styles.rowGridTemplate,
          ActionComponent: ManagerCareersActions,
        }}
      />
      <ManagerCareerModal {...createCareerModalProps} />
    </>
  );
};

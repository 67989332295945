import cn from 'classnames';
import { Property } from 'csstype';
import { ImgHTMLAttributes, ReactEventHandler, useMemo, useState } from 'react';
import { renderPlaceholder } from 'shared/components/Pic/renderPlaceholder';
import { TPicPlaceholder } from 'shared/components/Pic/type';
import { toBoolean } from 'shared/lib/toBoolean';

import styles from './Pic.module.css';

/**
 * Пропсы для компонента Pic.
 */
export type TPicProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'height'> & {
  /**
   * Конфигурация плейсхолдера для отображения, когда изображение не удалось загрузить.
   *
   * **Возможные значения:**
   * - `{type: 'image', size?: 'xxs' | 'xs' | 's' | 'm' | 'ml' | 'l' | 'xl' | 'xxl'}`
   * - `{
   *      type: 'soccerTeam',
   *      size?: 16 | 20 | 24 | 28 | 32 | 36 | 40 | 44 | 56 | 60 | 72 | 96 | 120 | 128 | 160
   *    }`
   * - `{type: 'video', size?: 'l' | 'xxl'}`
   * - `{type: 'map', size?: 'm'}`
   * - `{type: 'player', size?: 's' | 'm' | 'l'}`
   * - `{type: 'cup', size?: 's' | 'm'}`
   */
  placeholder: TPicPlaceholder;
  /**
   * Соотношение сторон изображения.
   * Если указано, контейнер изображения будет сохранять это соотношение сторон.
   * Популярные значения:
   * - 1 (1:1)
   * - 16/9 (16:9)
   * - 9/16 (9:16)
   * - 4/3 (4:3)
   * - 3/4 (3:4)
   * - 5/7 (5:7)
   */
  aspectRatio?: number;
  /**
   * Альтернативный текст описания изображения.
   */
  alt: string;
  /**
   * Ширина изображения.
   */
  width?: number | string;
  /**
   * Фон плейсхолдера изображения.
   */
  placeholderBackgroundColor?: Property.Color;
  /**
   * То, как изображение будет подстраиваться под заданные размеры.
   */
  objectFit?: Property.ObjectFit;
  placeholderClassName?: string;
};

/**
 * Компонент Pic для отображения изображений с опциональными плейсхолдерами и соотношениями сторон.
 *
 * @param props - Пропсы для компонента Pic.
 * @returns Компонент Pic.
 */
export const Pic = ({
  placeholder,
  alt,
  aspectRatio,
  placeholderBackgroundColor = 'var(--gray-150)',
  objectFit = 'cover',
  style,
  onError,
  width,
  className,
  src,
  placeholderClassName,
  ...imageProps
}: TPicProps) => {
  const [error, setError] = useState(false);
  const isShowPlaceholder = useMemo(() => !toBoolean(src), [src]);
  const errorHandler: ReactEventHandler<HTMLImageElement> = (event) => {
    setError(true);
    onError?.(event);
  };

  const rootStyle = {
    aspectRatio: aspectRatio ? aspectRatio.toString() : undefined,
    width,
  };

  const imageStyle = {
    ...style,
    objectFit,
  };

  return (
    <div className={cn(styles.root, className)} style={rootStyle}>
      {isShowPlaceholder || error ? (
        <div
          aria-label={`${placeholder.type} Placeholder`}
          role="img"
          className={cn(styles.placeholder, placeholderClassName, {
            [styles.player]: placeholder.type === 'player',
          })}
          style={{
            backgroundColor: placeholderBackgroundColor,
          }}
        >
          {renderPlaceholder(placeholder)}
        </div>
      ) : (
        <img
          {...imageProps}
          alt={alt}
          className={styles.image}
          src={src}
          style={imageStyle}
          width={width}
          onError={errorHandler}
        />
      )}
    </div>
  );
};

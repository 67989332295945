import { useCreateMatchMutation } from 'entities/matches/mutations';
import { useNavigateToTacticFromCreateMatch } from 'pages/matches/useUserFromCreateMatch';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { TMatchFormFields } from 'widgets/match-form/types/types';
import { mapMatchFieldsToDTO } from 'widgets/match-form/ui/MatchForm/mapMatchFieldsToDTO';

export const useCreateMatch = () => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useCreateMatchMutation();

  const navigateToTacticFromCreateMatch = useNavigateToTacticFromCreateMatch();

  const createMatch = (data: TMatchFormFields) => {
    const dto = mapMatchFieldsToDTO(data);

    mutate(dto, {
      onSuccess: (match) => {
        navigateToTacticFromCreateMatch(match.id);
      },
      onError: () => {
        notify(t('content.matches.createError'), { type: 'error' });
      },
    });
  };

  return {
    isLoading,
    createMatch,
  };
};

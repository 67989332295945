import { TEntitiesFilterProps } from 'feature/filters/ui/EntitiesFilter/EntitiesFilter';
import { useCallback, useMemo } from 'react';
import { useForm } from 'shared/hooks/useReactHookForm';

type TValues = Record<string | number, boolean>;

export const useEntitiesFilterForm = <TEntityValue extends string | number>({
  onChangeFilter,
  entities,
  filter,
}: Omit<TEntitiesFilterProps<TEntityValue>, 'isLoading'>) => {
  const actionSubmit = useCallback(
    (values: TValues) => {
      const filteredEntities = entities
        .filter((entity) => values[entity.id])
        .map((entity) => entity.id);
      if (!filteredEntities.length) {
        onChangeFilter(undefined);
        return;
      }

      onChangeFilter(filteredEntities);
    },
    [onChangeFilter, entities],
  );

  const defaultValues = useMemo(
    () =>
      entities?.reduce<TValues>(
        (acc, { id }) => ({ ...acc, [id]: (filter ?? []).includes(id) }),
        {},
      ),
    [entities, filter],
  );

  return useForm<TValues>({
    actionSubmit,
    defaultValues,
  });
};

import { PlayerCareersPage } from 'pages/player/careers/PlayerCareersPage/PlayerCareersPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const PlayerCareersRoute = {
  path: routes.playerCareers.url,
  element: <PlayerCareersPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { TSelectProps } from 'shared/components/Form/Select/types';
import { toBoolean } from 'shared/lib/toBoolean';

import { getClassNames, getComponents, getTheme, SelectContext } from './configs';
import styles from './Select.module.css';

export const Select = <IsMulti extends boolean = false>({
  type = 'basic',
  size = 'medium',
  isLoading,
  error,
  isMulti,
  getMultiValueDescription,
  menuPlacement = 'auto',
  selectWidth = 'auto',
  menuWidth = '100%',
  ...reactSelectProps
}: TSelectProps<IsMulti>) => {
  const { t } = useTranslation();

  return (
    <SelectContext.Provider value={{ getMultiValueDescription, size }}>
      <ReactSelect
        {...reactSelectProps}
        components={getComponents<IsMulti>()}
        controlShouldRenderValue={!isMulti}
        isLoading={isLoading}
        isMulti={isMulti}
        menuPlacement={menuPlacement}
        menuPortalTarget={document.body}
        noOptionsMessage={() => t('shared.Form.select.noOptions')}
        theme={getTheme(size, type)}
        classNames={getClassNames<IsMulti>({
          size,
          type,
          isLoading,
          isError: toBoolean(error),
        })}
        styles={{
          menu: (base) => ({
            ...base,
            borderRadius: 'var(--border-radius-default)',
            width: menuWidth,
          }),
          noOptionsMessage: (base) => ({
            ...base,
            padding: 0,
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: '400',
            color: 'var(--gray-500)',
          }),
          menuPortal: (base) => ({ ...base, zIndex: 'var(--select-index)' }),
          container: (base) => ({
            ...base,
            width: selectWidth,
          }),
        }}
      />
      {error && <span className={styles.errorText}>{error}</span>}
    </SelectContext.Provider>
  );
};

import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';
import { useLocales } from 'entities/config/lib/useLocales';
import { TMatchPlayer } from 'entities/matchPlayer/type';
import { TPosition, TTactic } from 'entities/tactics/types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'shared/components/Dropdown/Dropdown';
import { RadioButton } from 'shared/components/Form/RadioButton';
import { Icon } from 'shared/components/Icon/Icon';
import { Typography } from 'shared/components/Typography/Typography';
import { useFloat } from 'shared/hooks/useFloat';
import { toString } from 'shared/lib/toString';
import { TEntityId } from 'shared/types/common';
import { compareIds } from 'shared/utils/entityIds';
import {
  TMatchPlacementAddPlayer,
  TMatchPlacementPlayer,
} from 'widgets/matchPlacement/types';
import {
  MATCH_PLACEMENT_HEIGHT,
  MATCH_PLACEMENT_WIDTH,
} from 'widgets/matchPlacement/ui/MatchPlacement/const';

import styles from './MatchPlacementPositionSelect.module.css';

type TProps = {
  tactic: TTactic;
  position: TPosition;
  players: TMatchPlacementPlayer[];
  matchPlayers: TMatchPlayer[];
  onAddPlayer: (player: TMatchPlacementAddPlayer[]) => void;
  onClickContainer: (id: TEntityId | null) => void;
  activePositionId?: TEntityId | null;
  onEditPlayer: (matchPlayer: TMatchPlayer) => void;
  isLoadingMatchPlayer: boolean;
};

const getRemainingPlayers = (
  players: TMatchPlacementPlayer[],
  matchPlayerIds: Set<TEntityId>,
) => {
  return players.filter((player) => !matchPlayerIds.has(toString(player.id)));
};

const SELECT_COLUMN_ROW = 1;
const FIELD_MARGIN_ROW = 1;

export const MatchPlacementPositionSelect = ({
  tactic,
  position,
  players,
  matchPlayers,
  onAddPlayer,
  onClickContainer,
  activePositionId,
  onEditPlayer,
  isLoadingMatchPlayer,
}: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();

  const [isOpen, setOpen] = useState(false);

  const closeMenu = () => {
    setOpen(false);
    onClickContainer(null);
  };

  const onChangeFloatHandler = (isOpen: boolean) => {
    if (!isOpen) {
      closeMenu();
    } else {
      setOpen(isOpen);
    }
  };

  const {
    trigger: { triggerRef, onClick: onClickFloat, ...trigger },
    floating,
  } = useFloat({
    isOpen,
    offset: 8,
    placement: 'right-start',
    onChange: onChangeFloatHandler,
    customOutsidePress: true,
  });

  const colSize =
    MATCH_PLACEMENT_HEIGHT / (tactic?.maxCol + SELECT_COLUMN_ROW + FIELD_MARGIN_ROW);
  const colRow = MATCH_PLACEMENT_WIDTH / (tactic?.maxRow + FIELD_MARGIN_ROW);

  const remainingPlayers = useMemo(() => {
    const matchPlayerIds = new Set(
      matchPlayers.map((matchPlayer) => matchPlayer.playerId),
    );

    return getRemainingPlayers(players, matchPlayerIds);
  }, [players, matchPlayers]);

  const currentMatchPlayer = matchPlayers.find(
    (matchPlayer) =>
      compareIds(matchPlayer.row, position.row) &&
      compareIds(matchPlayer.col, position.col),
  );

  const currentPlayer = players.find((player) =>
    compareIds(player.id, currentMatchPlayer?.playerId),
  );

  const handlePlayerChange = (player: TMatchPlacementPlayer) => {
    if (isLoadingMatchPlayer) {
      return;
    }

    if (currentMatchPlayer) {
      onEditPlayer({ ...currentMatchPlayer, playerId: player.id, captain: false });
    } else {
      onAddPlayer([
        {
          player: player,
          position: position,
          goalkeeper: position.goalkeeper,
          reserve: false,
          captain: false,
        },
      ]);
    }
    closeMenu();
  };

  const onClickContainerHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClickFloat(e);
    if (isOpen) {
      onClickContainer(null);
    } else {
      onClickContainer(position.id);
    }
  };

  return (
    <>
      <div
        ref={triggerRef}
        className={cn(styles.player, {
          [styles.disable]:
            activePositionId && !compareIds(activePositionId, position.id),
        })}
        style={{
          bottom: `${colSize * position.col}px`,
          left: `${colRow * position.row}px`,
        }}
        {...trigger}
        onClick={onClickContainerHandler}
      >
        {currentPlayer ? (
          <>
            <Icon
              className={styles.editIcon}
              kind="edit-02"
              size="xs"
              svgProps={{ className: styles.svgIcon }}
            />
            <Typography color="var(--dark-blue-250)" size={16} weight="SemiBold">
              {currentPlayer.number}
            </Typography>
            <Typography
              className={styles.playerName}
              color="var(--gray-50)"
              size={12}
              weight="SemiBold"
            >
              {`${currentPlayer.translations[defaultLocale].firstName[0]}. ${currentPlayer.translations[defaultLocale].lastName}`}
            </Typography>
          </>
        ) : (
          <Icon kind="plus" size="s" />
        )}
      </div>
      {isOpen && (
        <FloatingPortal>
          <Dropdown className={styles.playerPositionDropdown} {...floating}>
            {currentPlayer && (
              <>
                <Typography
                  className={styles.label}
                  color="var(--gray-400)"
                  size={12}
                >
                  {t('content.tactics.chosePlayer')}
                </Typography>
                <RadioButton
                  checked
                  className={styles.playerItem}
                  label={`${currentPlayer?.translations[defaultLocale].firstName} ${currentPlayer?.translations[defaultLocale].lastName}`}
                />
              </>
            )}
            <Typography
              className={cn(styles.label, { [styles.labelTop]: currentPlayer })}
              color="var(--gray-400)"
              size={12}
            >
              {currentPlayer
                ? t('content.teams.determinationTeam')
                : t('content.tactics.selectPlayer')}
            </Typography>
            {remainingPlayers.map((player) => (
              <RadioButton
                key={player.id}
                checked={false}
                className={styles.playerItem}
                label={
                  <Typography className={styles.playerItemText} size={14}>
                    {player.translations[defaultLocale].firstName}{' '}
                    {player.translations[defaultLocale].lastName}
                  </Typography>
                }
                onClick={() => handlePlayerChange(player)}
              />
            ))}
          </Dropdown>
        </FloatingPortal>
      )}
    </>
  );
};

import i18n from 'i18next';
import { declination } from 'shared/lib/declination';

type TGenerateChooseTextArgs = {
  count?: number;
  singular: string;
  few: string;
  many: string;
  defaultText: string;
  ruWordGender?: 'male' | 'female';
};

export const generateSelectedText = (args: TGenerateChooseTextArgs): string => {
  const { count, singular, few, many, defaultText, ruWordGender } = args;
  const choose =
    ruWordGender === 'female'
      ? i18n.t('content.chooseFeminized')
      : i18n.t('content.choose');

  return count
    ? declination(count, [
        `${choose} ${count} ${singular}`,
        `${i18n.t('content.chooseOtherEnding')} ${count} ${few}`,
        `${i18n.t('content.chooseOtherEnding')} ${count} ${many}`,
      ])
    : defaultText;
};

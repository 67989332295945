import { TAdSlot } from 'entities/ad/slots/model/types';
import { useLocales } from 'entities/config/lib/useLocales';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionButtonADS } from 'shared/components/ActionButtonADS/ActionButtonADS';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { Field } from 'shared/components/Form';
import { toString } from 'shared/lib/toString';
import { compareIds } from 'shared/utils/entityIds';
import { useAdBannerChangeSlot } from 'widgets/adBannerForm/hooks/useAdBannerChangeSlot';
import { TAdBannerFormFields } from 'widgets/adBannerForm/types';

import styles from './AdBannerFormSlots.module.css';

type TProps = {
  slots: TAdSlot[];
};

export const AdBannerFormSlots = ({ slots }: TProps) => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const { control } = useFormContext<TAdBannerFormFields>();
  const selectedSlotId = useWatch({ control, name: 'slotId' });

  const { openedSlotChangeModal, onChangeSlot, closeSlotChangeModal, onSelectSlot } =
    useAdBannerChangeSlot({ control });

  return (
    <>
      <Field
        className={styles.field}
        label={t('content.adBanners.form.slots.title')}
        required
      >
        <div className={styles.root}>
          {slots.map(({ id, translations, image }) => (
            <ActionButtonADS
              key={id}
              active={compareIds(selectedSlotId, id)}
              description={translations[defaultLocale].description}
              imageSrc={image.x3}
              title={translations[defaultLocale].name}
              onClick={() => onSelectSlot(toString(id))}
            />
          ))}
        </div>
      </Field>
      <ConfirmationModal
        description={t('content.adBanners.form.slots.modalDescription')}
        isOpened={openedSlotChangeModal}
        title={t('content.adBanners.form.slots.modalTitle')}
        onClose={closeSlotChangeModal}
        onConfirm={onChangeSlot}
      />
    </>
  );
};

import { useFetchPhotoGalleriesQuery } from 'entities/photoGallery/queries';
import { usePhotoGalleryColumnsConfig } from 'pages/photogallery/PhotoGalleryPage/columnsConfig';
import { PhotoGalleryActions } from 'pages/photogallery/PhotoGalleryPage/PhotoGalleryActions';
import {
  TPhotoGalleryFilter,
  TPhotoGalleryTableSortParams,
} from 'pages/photogallery/PhotoGalleryPage/types';
import { useCreatePhotoGalleryPage } from 'pages/photogallery/PhotoGalleryPage/useCreatePhotoGalleryPage';
import { createFilterQuery, createSortQuery } from 'pages/video/VideoPage/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TSortOptions } from 'shared/types/table';
import { useCommonListTableSort } from 'widgets/elementsList/hooks/useCommonListTableSort';
import { CommonList } from 'widgets/elementsList/ui/CommonList/CommonList';

import styles from './PhotoGalleryPage.module.css';

const DEFAULT_PHOTO_GALLERY_OPTIONS: TSortOptions<TPhotoGalleryTableSortParams> = {
  direction: 'desc',
  sortBy: 'created_at',
};

export const PhotoGalleryPage = () => {
  const { t } = useTranslation();
  const { isLoading: isCreatingPhotoGallery, createDefaultPhotoGallery } =
    useCreatePhotoGalleryPage();

  const { sortOptions, handleSort } =
    useCommonListTableSort<TPhotoGalleryTableSortParams>(
      DEFAULT_PHOTO_GALLERY_OPTIONS,
    );

  const [filter, setFilter] = useState<TPhotoGalleryFilter | null>(null);

  const {
    data: { data: photos = [], meta: { total = 0, page = 1 } = {} } = {},
    isLoading,
    error,
    changePage,
  } = useFetchPhotoGalleriesQuery({
    sort: createSortQuery(sortOptions),
    filter: createFilterQuery(filter),
  });

  const handleFilter = (filter: TPhotoGalleryFilter) => {
    setFilter(filter);
    changePage(1);
  };

  return (
    <CommonList
      configCreator={usePhotoGalleryColumnsConfig}
      emptyComponentDescription={t('content.photoGallery.emptyTitle')}
      error={error}
      isLoading={isLoading}
      title={t('content.photoGallery.title')}
      createButtonProps={{
        content: t('shared.create'),
        onClick: createDefaultPhotoGallery,
        isLoading: isCreatingPhotoGallery,
      }}
      paginationProps={{
        selectedPage: page,
        totalItems: total,
        onPageChange: changePage,
      }}
      tableProps={{
        data: photos,
        rowClassName: styles.rowGridTemplate,
        ActionComponent: PhotoGalleryActions,
        sortOptions,
        onSort: handleSort,
        filter,
        onFilter: handleFilter,
      }}
    />
  );
};

import { nanoid } from 'nanoid';
import { TODAY } from 'shared/constants';
import { EListStatus, TContentStatus } from 'shared/types/common';

function randomDate(start: Date, end: Date): Date {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime()),
  );
}

const STATUSES: TContentStatus[] = [
  EListStatus.Postponed,
  EListStatus.Published,
  EListStatus.Draft,
];

export type TListItem = {
  publication: string;
  dateCreated: string;
  id: string;
  status: TContentStatus;
  datePublished: string;
};

export function generateMockData(count: number): TListItem[] {
  const data = [];

  for (let i = 0; i < count; i++) {
    const publication = `Publication ${i + 1}`;
    const dateCreated =
      i === 0
        ? TODAY.toISOString()
        : randomDate(new Date(2021, 0, 1), new Date()).toISOString();
    const id = nanoid();
    const status = STATUSES[Math.floor(Math.random() * STATUSES.length)];
    const datePublished =
      i === 0
        ? TODAY.toISOString()
        : randomDate(new Date(2021, 0, 1), new Date()).toISOString();

    const item: TListItem = {
      publication,
      dateCreated,
      id,
      status,
      datePublished,
    };

    data.push(item);
  }

  return data;
}

import cn from 'classnames';
import { HTMLAttributes } from 'react';

import styles from './TRow.module.css';

export type TTableRowProps = {
  isActive?: boolean;
  hoverable?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const TRow = ({
  isActive,
  hoverable = true,
  children,
  className,
  ...other
}: TTableRowProps) => (
  <div
    {...other}
    className={cn(
      styles.row,
      { [styles.hoverable]: hoverable, [styles.isActive]: isActive },
      className,
    )}
  >
    {children}
  </div>
);

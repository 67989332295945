import { seasonsApiKeys } from 'entities/seasons/consts';
import { TEditSeasonDTO, TSeason, TSeasonDto } from 'entities/seasons/types';
import { del, get, patch, post } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchSeasons = ({ params }: TFetchParams) =>
  get<TSeason[], TPaginatedMeta>(seasonsApiKeys.getSeasons, {
    params,
  }).then((response) => response.data);

export const fetchSeason = (id: TEntityId) =>
  get<TSeason>(seasonsApiKeys.getSeason(id)).then((response) => response.data.data);

export const createSeason = (data: TSeasonDto) =>
  post<TSeason>(seasonsApiKeys.createSeason, data).then(
    (response) => response.data.data,
  );

export const editSeason = (season: TEditSeasonDTO) =>
  patch<TSeason>(`${seasonsApiKeys.editSeason(season.id)}`, { season }).then(
    (response) => response.data.data,
  );

export const deleteSeason = (id: TEntityId) =>
  del(seasonsApiKeys.deleteSeason(id)).then((response) => response.data.data);

import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import {
  TContactModalFields,
  TContactModalProps,
} from 'feature/contact-modal/types';
import { contactModalValidationSchema } from 'feature/contact-modal/validationSchema';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TActionModalProps } from 'shared/components/ActionModal/ActionModal';
import { useForm } from 'shared/hooks/useReactHookForm';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';

export const useContactModal = ({
  actionSubmit,
  closeModal,
  values,
  isOpened,
  title,
  isLoading,
}: TContactModalProps) => {
  const { t } = useTranslation();
  const { locales } = useLocales();

  const defaultValues = useMemo<TContactModalFields>(
    () => ({
      email: '',
      workSchedule: '',
      phoneNumber: '',
      translations: reduceTranslationObject(locales, () => ({
        title: '',
        address: '',
      })),
    }),
    [locales],
  );

  const {
    control,
    submit,
    formState: { isValid, isDirty },
    reset,
  } = useForm<TContactModalFields>({
    values: values ?? defaultValues,
    mode: 'onChange',
    resolver: zodResolver(contactModalValidationSchema(locales, t)),
    actionSubmit,
  });

  const handleClose = () => {
    reset();
    closeModal();
  };

  return {
    actionModalProps: {
      opened: isOpened,
      title,
      width: 542,
      showCloseButton: false,
      confirmButtonProps: {
        isDisabled: !isValid || !isDirty,
        onClick: submit,
        isLoading,
      },
      onClose: handleClose,
    } satisfies TActionModalProps,
    locales,
    control,
  };
};

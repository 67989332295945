import {
  EEditorElementType,
  TEditor,
  TEditorLink,
} from 'shared/components/Editor/types';
import { editorController } from 'shared/components/Editor/utils/editor/editorController';
import { createLinkElement } from 'shared/components/Editor/utils/link/createLinkElement';
import { TContentLang } from 'shared/types/common';
import { Editor, Element, Transforms } from 'slate';

export const editorLinkController = {
  toggleLinkElement(
    editor: TEditor,
    anchor: TEditorLink,
    defaultLocale: TContentLang,
  ) {
    if (editorController.isActiveElement(editor, EEditorElementType.Link)) {
      this.deleteLinkElement(editor);
    }

    const anchorElement = createLinkElement(
      {
        ...anchor,
        text: anchor.text || anchor.url,
      },
      defaultLocale,
    );
    Transforms.insertNodes(editor, anchorElement);
  },

  deleteLinkElement(editor: TEditor) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        n.type === EEditorElementType.Link,
    });
  },

  getSelectedLinks(editor: TEditor) {
    return editorController.getNode(editor, EEditorElementType.Link);
  },
};

import { useLocales } from 'entities/config/lib/useLocales';
import { Control, useController } from 'react-hook-form';
import { TContentLang } from 'shared/types/common';
import { TClubFormFields } from 'widgets/ClubForm/types';

export const useEditClubTranslation = (control: Control<TClubFormFields>) => {
  const { defaultLocale } = useLocales();

  const {
    field: { value: selectedLocale, onChange: changeSelectedLocale },
  } = useController({ control, name: 'locale.selected' });

  const {
    field: { value: createdLocales, onChange: changeCreatedLocales },
  } = useController({ control, name: 'locale.created' });

  const handleAddLocale = (locale: TContentLang) => {
    changeCreatedLocales([...createdLocales, locale]);
    changeSelectedLocale(locale);
  };

  const handleSelectLocale = (locale: TContentLang) => {
    changeSelectedLocale(locale);
  };

  const handleDeleteLocale = (locale: TContentLang) => {
    changeSelectedLocale(defaultLocale);
    changeCreatedLocales(createdLocales.filter((l: TContentLang) => l !== locale));
  };

  return {
    selectedLocale,
    createdLocales,
    handleAddLocale,
    handleSelectLocale,
    handleDeleteLocale,
  };
};

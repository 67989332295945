import { useEditMatchMutation } from 'entities/matches/mutations';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notify } from 'shared/components/Notification';
import { routes } from 'shared/routes';
import { TMatchFormFields } from 'widgets/match-form/types/types';
import { mapMatchFieldsToDTO } from 'widgets/match-form/ui/MatchForm/mapMatchFieldsToDTO';

export const useEditMatch = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate, isLoading } = useEditMatchMutation();

  const editMatch = (data: TMatchFormFields, id: string) => {
    const prepareNewMatchDTO = mapMatchFieldsToDTO(data);

    mutate(
      { data: prepareNewMatchDTO, id },
      {
        onSuccess: (match) => {
          navigate(routes.tacticMatch.as(match.id));
        },
        onError: () => {
          notify(t('content.matches.notifyNotEditMatch'), { type: 'error' });
        },
      },
    );
  };

  return {
    isLoading,
    editMatch,
  };
};

import { useLocales } from 'entities/config/lib/useLocales';
import {
  useCreateStoryGroupMutation,
  useCreateStoryMutation,
} from 'entities/stories/mutations';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'shared/hooks/useModal';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { toNumber } from 'shared/lib/toNumber';
import { toString } from 'shared/lib/toString';
import { routes } from 'shared/routes';

export const useCreateStory = () => {
  const { t } = useTranslation();
  const { openImageUploadModal, closeImageUploadModal, openedImageUploadModal } =
    useModal('ImageUpload');

  const navigate = useNavigate();
  const { locales, defaultLocale } = useLocales();

  const storyGroupMutation = useCreateStoryGroupMutation();
  const storyMutation = useCreateStoryMutation();

  const handleFileAdd = useCallback(
    (files: File[]) => {
      const file: File = files[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imageData = reader.result as string;
        if (!imageData) {
          return;
        }
        storyGroupMutation
          .mutateAsync({
            availableLocales: [defaultLocale],
            translations: reduceTranslationObject(locales, (locale) => ({
              name: `${t('content.story.defaultText')} ${locale}`,
            })),
          })
          .then((result) => {
            storyMutation.mutate({
              image: {
                filename: file.name,
                data: imageData.split(',')[1],
              },
              position: 1,
              storyGroupId: toNumber(result.id),
            });
            return result.id;
          })
          .then((storyGroupId) => {
            closeImageUploadModal();
            navigate(
              routes.editStory.replace(':storyGroupId', toString(storyGroupId)),
            );
          });
      };
    },
    [defaultLocale, locales, storyGroupMutation, storyMutation],
  );

  return {
    isImageUploadOpened: openedImageUploadModal,
    handleFileAdd,
    handleOpenUploadModal: openImageUploadModal,
    handleCloseUploadModal: closeImageUploadModal,
  } as const;
};

import {
  TEntityId,
  TImageContent,
  TImageUpload,
  TTranslationsObject,
} from 'shared/types/common';

export enum ECareerJobTitle {
  HeadCoach = 'Head coach',
  Manager = 'Manager',
}

export enum EManagerStatus {
  PartOfTeam = 'part_of_team',
  Outstaff = 'outstaff',
}

export type TManager = {
  id: TEntityId;
  team: TEntityId;
  country: TEntityId;
  status: EManagerStatus;
  birthDate?: string;
  debutDate?: string;
  discardedAt?: string;
  picture?: TImageContent;
  photo?: TImageContent;
  managerPosition: string;
} & TTranslationsObject<{
  firstName: string;
  lastName: string;
  biography?: string;
  sharingLink?: string;
  shopLink?: string;
}>;

export type TCreateManagerDTO = {
  status: EManagerStatus;
  discardedAt?: string | null;
  managerPositionId: number;
  teamId: number;
  countryId: number;
  debutDate?: string | null;
  birthDate?: string | null;
  nickname?: string;
  picture?: TImageUpload | null;
  photo?: TImageUpload | null;
} & TTranslationsObject<{
  firstName: string;
  lastName: string;
  biography?: string;
  sharingLink?: string;
  shopLink?: string;
}>;

export type TEditManagerDTO = {
  managerId: TEntityId;
} & TCreateManagerDTO;

import { useLocales } from 'entities/config/lib/useLocales';
import { useTeam } from 'pages/team/hooks/useTeam';
import { SwitcherTeams } from 'pages/team/switcher/SwitcherTeams';
import { TeamPageManagerTab } from 'pages/team/teamPage/TeamPageManagerTab';
import { TeamPagePlayerTab } from 'pages/team/teamPage/TeamPagePlayerTab';
import { useTeamPageTabs } from 'pages/team/teamPage/useTeamPageTabs';
import { ETeamTabType } from 'pages/team/types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'shared/components/Button/Button';
import { Centered } from 'shared/components/Centered/Centered';
import { IconButton } from 'shared/components/IconButton/IconButton';
import { Loader } from 'shared/components/Loader/Loader';
import { Pic } from 'shared/components/Pic/Pic';
import { Typography } from 'shared/components/Typography/Typography';
import { routes } from 'shared/routes';

import styles from './TeamPage.module.css';

export const TEAM_PAGE_GAP = 24;

export const TeamPage = () => {
  const { t } = useTranslation();
  const { defaultLocale } = useLocales();
  const navigate = useNavigate();

  const { teamId } = useParams<{
    teamId: string;
  }>();

  const { tabs, selectedTab } = useTeamPageTabs();

  const { data: team, isLoading } = useTeam(teamId);

  const teamName = team?.translations[defaultLocale].name ?? '';

  return (
    <div className={styles.root}>
      {isLoading ? (
        <Centered>
          <Loader showLabel />
        </Centered>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.titleWrapper}>
              <IconButton
                className={styles.backButton}
                icon="chevron-left"
                onClick={() => navigate(routes.teams)}
              />
              <div className={styles.imageWrapper}>
                <Pic
                  alt={`${teamName} image`}
                  loading="lazy"
                  placeholderBackgroundColor="transparent"
                  src={team?.picture.x1}
                  placeholder={{
                    type: 'soccerTeam',
                    size: 32,
                  }}
                />
              </div>
              <Typography className={styles.title} size={24} tag="h1" weight="Bold">
                {teamName}
              </Typography>
            </div>
            <SwitcherTeams selected={selectedTab} tabs={tabs} />
            <Button className={styles.button} onClick={() => navigate(routes.teams)}>
              {t('shared.confirm')}
            </Button>
          </div>
          {selectedTab === ETeamTabType.Player ? (
            <TeamPagePlayerTab />
          ) : (
            <TeamPageManagerTab />
          )}
        </>
      )}
    </div>
  );
};

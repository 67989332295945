import { createDefaultElement } from 'shared/components/Editor/helpers';
import {
  EEditorElementType,
  EEditorMarks,
  TEditorLink,
  TEditorLinkElement,
} from 'shared/components/Editor/types';
import { TContentLang, TPartialRecord } from 'shared/types/common';

export const createLinkElement = (
  { text, url }: TEditorLink,
  defaultLocale: TContentLang,
  marks: TPartialRecord<EEditorMarks, boolean> = {},
) =>
  ({
    type: EEditorElementType.Link,
    url,
    translations: {
      [defaultLocale]: { text, url },
    },
    children: createDefaultElement(text, marks),
  }) as TEditorLinkElement;

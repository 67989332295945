import { useLocales } from 'entities/config/lib/useLocales';
import { TMatchesFilterParams } from 'entities/matches/utils';
import { TOpponentTeam } from 'entities/opponentTeams/types';
import { TTeam } from 'entities/teams/types';
import { TFilterProps } from 'feature/filters/types';
import { EntitiesFilter } from 'feature/filters/ui/EntitiesFilter/EntitiesFilter';
import { TEntityFilter } from 'feature/filters/ui/EntitiesFilter/type';
import { TEntityId } from 'shared/types/common';

type TProps = {
  teams: (TOpponentTeam | TTeam)[];
  isLoading: boolean;
};

export const MatchesGuestTeamFilter = ({
  setFilter,
  filter,
  teams,
  isLoading,
}: TFilterProps<TMatchesFilterParams> & TProps) => {
  const { defaultLocale } = useLocales();

  const mappedTeams = teams.map<TEntityFilter>(({ id, translations }) => ({
    id,
    name: translations[defaultLocale].name,
  }));

  const onChangeFilter = (teamIds?: TEntityId[]) => {
    setFilter({ ...filter, guest_team_id: teamIds });
  };

  return (
    <EntitiesFilter
      entities={mappedTeams}
      filter={filter.guest_team_id}
      isLoading={isLoading}
      onChangeFilter={onChangeFilter}
    />
  );
};

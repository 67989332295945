import { useProtectedRoutes } from 'app/hooks/useProtectedRoutes';
import { AboutClubRoute } from 'pages/aboutClubPage';
import { AdBannersRoutes } from 'pages/adBanners';
import { AuthRoute } from 'pages/auth';
import { DashboardRoute } from 'pages/dashboard';
import { ErrorPage } from 'pages/errorPage/ErrorPage';
import { ManagerRoutes } from 'pages/manager';
import { MatchesRoutes } from 'pages/matches';
import { NewsRoutes } from 'pages/news';
import { OpponentTeamsRoutes } from 'pages/opponentTeam';
import { PhotoGalleryRoutes } from 'pages/photogallery';
import { PlayerRoutes } from 'pages/player';
import { SettingsRoute } from 'pages/settings';
import { StoriesRoutes } from 'pages/stories';
import { TeamsRoutes } from 'pages/team';
// import { TournamentSeasonsRoutes } from 'pages/tournamentSeason';
import { UIKitRoute } from 'pages/uikit';
import { VideoRoutes } from 'pages/video';
import { redirect, RouteObject } from 'react-router-dom';
import { getTokenState } from 'shared/api/token';
import { routes } from 'shared/routes';
import { sentryCreateBrowserRouter } from 'shared/sentry';
import { TProtectedRoutes } from 'shared/types/router';
import { RootLayout } from 'shared/ui/layouts/rootLayout/RootLayout';
import { Navigation } from 'widgets/navigation/ui/Navigation/Navigation';

const permissionRoutes = [
  UIKitRoute,
  DashboardRoute,
  StoriesRoutes.List,
  StoriesRoutes.Edit,
  NewsRoutes.List,
  NewsRoutes.Add,
  NewsRoutes.Edit,
  VideoRoutes.List,
  VideoRoutes.Add,
  VideoRoutes.Edit,
  PlayerRoutes.Create,
  PlayerRoutes.Edit,
  PlayerRoutes.Careers,
  PlayerRoutes.SocialNetworks,
  PlayerRoutes.Achievements,
  PlayerRoutes.TeamAchievements,
  ManagerRoutes.Create,
  ManagerRoutes.Edit,
  ManagerRoutes.Careers,
  ManagerRoutes.SocialNetworks,
  ManagerRoutes.TeamAchievements,
  SettingsRoute,
  TeamsRoutes.List,
  TeamsRoutes.TeamPage,
  OpponentTeamsRoutes.List,
  OpponentTeamsRoutes.Page,
  PhotoGalleryRoutes.List,
  PhotoGalleryRoutes.Edit,
  MatchesRoutes.New,
  MatchesRoutes.List,
  MatchesRoutes.Edit,
  MatchesRoutes.Tactic,
  AdBannersRoutes.List,
  AdBannersRoutes.Create,
  AdBannersRoutes.Edit,
  // TournamentSeasonsRoutes.List,
  // TournamentSeasonsRoutes.Create,
  // TournamentSeasonsRoutes.Edit,
  AboutClubRoute,
] satisfies TProtectedRoutes;

const protectedLoader = () => {
  const { token } = getTokenState();

  if (!token) {
    return redirect(routes.auth);
  }

  return null;
};

const baseRoute: RouteObject = {
  path: '/',
  element: <RootLayout navigation={<Navigation />} />,
  errorElement: <ErrorPage />,
  loader: protectedLoader,
};

export const useCreateRouter = () => {
  const protectedRoutes = useProtectedRoutes(permissionRoutes);

  const privateRoutes = {
    ...baseRoute,
    children: protectedRoutes,
  };

  return sentryCreateBrowserRouter([privateRoutes, AuthRoute]);
};

import { useLocales } from 'entities/config/lib/useLocales';
import { TPlayerTeamAchievement } from 'entities/playerTeamAchievements/types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'shared/components/Typography/Typography';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './PlayerTeamAchievementsPage.module.css';

export const usePlayerTeamAchievementsColumnsConfig: TConfigCreator<
  TPlayerTeamAchievement
> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();

  return [
    {
      title: t('content.title'),
      cellClassName: styles.nameColumn,
      render: (item) => (
        <Typography className={styles.text} color="var(--gray-500)">
          {item.translations?.[defaultLocale]?.title}
        </Typography>
      ),
    },
    {
      title: t('content.year'),
      render: (item) => <Typography color="var(--gray-500)">{item.year}</Typography>,
    },
    {
      title: 'ID',
      render: (item) => <Typography color="var(--gray-500)">{item.id}</Typography>,
    },
  ];
};

import { getTenYearsFromNow } from './utils';

const YEARS = getTenYearsFromNow();
const DECEMBER_INDEX = 11;
const LAST_DECEMBER_DAY = 31;
const FIRST_MONTH_DAY = 1;

export const MIDNIGHT_TIME = 0;
export const DATE_PICKER_MAX_DATE = new Date(
  YEARS[YEARS.length - 1],
  DECEMBER_INDEX,
  LAST_DECEMBER_DAY,
);

export const getMinDateFromDate = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), FIRST_MONTH_DAY, MIDNIGHT_TIME);

import { useState } from 'react';
import { ActionButtonADS } from 'shared/components/ActionButtonADS/ActionButtonADS';
import { Typography } from 'shared/components/Typography/Typography';

import styles from '../../UIKit.module.css';
import image from './image.svg';

const initialTitles = ['Title 1', 'Title 2', 'Title 3'];

const initialDescriptions = ['Description 1', 'Description 2', 'Description 3'];

export const ActionButtonADSExample = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(1);
  const [titles, setTitles] = useState<string[]>(initialTitles);
  const [descriptions, setDescriptions] = useState<string[]>(initialDescriptions);

  const handleButtonClick = (index: number) => {
    setActiveIndex(index);
  };

  const handleTitleChange = (index: number, value: string) => {
    setTitles((prev) => {
      const newTitles = [...prev];
      newTitles[index] = value;
      return newTitles;
    });
  };

  const handleDescriptionChange = (index: number, value: string) => {
    setDescriptions((prev) => {
      const newDescriptions = [...prev];
      newDescriptions[index] = value;
      return newDescriptions;
    });
  };

  return (
    <>
      <Typography size={24} tag="h2">
        Action Button Example
      </Typography>
      <div className={styles.row}>
        {[1, 2, 3].map((_el, index) => (
          <div key={index}>
            <ActionButtonADS
              active={activeIndex === index}
              description={descriptions[index]}
              disabled={index === 2}
              imageSrc={image}
              title={titles[index]}
              onClick={() => handleButtonClick(index)}
            />
            <Typography size={14} tag="p">
              {index === activeIndex
                ? 'Active'
                : index === 2
                  ? 'Disabled'
                  : 'Default'}
            </Typography>
            <input
              placeholder="Title"
              type="text"
              value={titles[index]}
              onChange={(e) => handleTitleChange(index, e.target.value)}
            />
            <br />
            <input
              placeholder="Description"
              type="text"
              value={descriptions[index]}
              onChange={(e) => handleDescriptionChange(index, e.target.value)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

import { TEntityId } from 'shared/types/common';

export const seasonsQueryKeys = {
  seasons: 'seasons',
  season: (seasonId: TEntityId) => `seasons/${seasonId}`,
};

const seasonsBaseUrl = 'seasons';

export const seasonsApiKeys = {
  createSeason: seasonsBaseUrl,
  getSeasons: seasonsBaseUrl,
  editSeason: (seasonId: TEntityId) => `${seasonsBaseUrl}/${seasonId}`,
  getSeason: (seasonId: TEntityId) => `${seasonsBaseUrl}/${seasonId}`,
  deleteSeason: (seasonId: TEntityId) => `${seasonsBaseUrl}/${seasonId}`,
};

import { get } from 'shared/api/methods';
import { TFetchParams, TPaginatedMeta } from 'shared/api/types';

import { adSlotsApiKeys } from './consts';
import { TAdSlot } from './types';

export const fetchAdSlots = ({ params }: TFetchParams) =>
  get<TAdSlot[], TPaginatedMeta>(adSlotsApiKeys.getAdSlots, { params }).then(
    (response) => response.data,
  );

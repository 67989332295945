import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { TEditorVideo } from 'shared/components/Editor/types';
import { FormInput } from 'shared/components/Form';
import { Typography } from 'shared/components/Typography/Typography';
import { useValidVideoLink } from 'shared/components/VideoPlayer/helpers';
import { VideoPlayer } from 'shared/components/VideoPlayer/VideoPlayer';
import { secureProtocol } from 'shared/constants';
import { onBlurVideoLinkHandler } from 'shared/handlers';
import { useForm } from 'shared/hooks/useReactHookForm';

import styles from './AttachVideoLinkModal.module.css';

type TAddVideoField = {
  video: string;
};

type TProps = {
  isOpened: boolean;
  title: string;
  onClose: () => void;
  onConfirm: (link: TEditorVideo) => void;
};

export const AttachVideoLinkModal = ({
  isOpened,
  title,
  onClose,
  onConfirm,
}: TProps) => {
  const { control, reset } = useForm<TAddVideoField>();
  const {
    field: { value },
  } = useController({
    control,
    name: 'video',
  });
  const { t } = useTranslation();
  const { isValidLink, attachedVideoHosting } = useValidVideoLink(value);

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleConfirm = () => {
    attachedVideoHosting && onConfirm({ src: value, hosting: attachedVideoHosting });
    handleClose();
  };

  return (
    <ActionModal
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValidLink,
        onClick: handleConfirm,
      }}
      onClose={handleClose}
    >
      <>
        <FormInput
          className={styles.preview}
          control={control}
          fieldName="video"
          label={t('shared.AttachVideo.label')}
          placeholder={t('shared.addLink')}
          required
          size="s"
          onBlur={() => onBlurVideoLinkHandler(isValidLink, value)}
        />
        <Typography className={styles.hintText} size={12}>
          {t('shared.AttachVideo.hintText')} {secureProtocol}
        </Typography>
        {isValidLink && (
          <div className={styles.videoWrapper}>
            <VideoPlayer
              hosting={attachedVideoHosting ?? ''}
              src={value}
              width={494}
            />
          </div>
        )}
      </>
    </ActionModal>
  );
};

import { EditPlayerPage } from 'pages/player/edit/EditPlayerPage/EditPlayerPage/EditPlayerPage';
import { DEFAULT_ROUTER_ROLES } from 'shared/api/constants';
import { routes } from 'shared/routes';
import { TProtectedRoute } from 'shared/types/router';

export const EditPlayerRoute = {
  path: routes.editPlayer.url,
  element: <EditPlayerPage />,
  roles: DEFAULT_ROUTER_ROLES,
} satisfies TProtectedRoute;

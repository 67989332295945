import cn from 'classnames';
import { ValueContainerProps } from 'react-select';
import { useSelectContext } from 'shared/components/Form/Select/hooks';
import { TSelectOption } from 'shared/components/Form/Select/types';

import styles from '../Select.module.css';

export const SelectValueContainer = (props: ValueContainerProps<TSelectOption>) => {
  const {
    isMulti,
    hasValue,
    innerProps,
    getClassNames,
    children,
    className,
    getValue,
    selectProps,
  } = props;

  const { getMultiValueDescription } = useSelectContext();
  const hasMultiSelect = isMulti && hasValue && !selectProps.inputValue;
  const multiValueDescription =
    hasMultiSelect && getMultiValueDescription?.(getValue());

  return (
    <div
      className={cn(getClassNames('valueContainer', props), className)}
      {...innerProps}
    >
      {children}
      {hasMultiSelect && (
        <div className={styles.multiValueDescription}>{multiValueDescription}</div>
      )}
    </div>
  );
};

import cn from 'classnames';
import { createContext } from 'react';
import { ClassNamesConfig, GroupBase, ThemeConfig } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { SelectContainer } from 'shared/components/Form/Select/components/SelectContainer';
import { SelectControl } from 'shared/components/Form/Select/components/SelectControl';
import { SelectDropdownIndicator } from 'shared/components/Form/Select/components/SelectDropdownIndicator';
import { SelectLoadingIndicator } from 'shared/components/Form/Select/components/SelectLoadingIndicator';
import { SelectMenuList } from 'shared/components/Form/Select/components/SelectMenuList';
import { SelectMenuPortal } from 'shared/components/Form/Select/components/SelectMenuPortal';
import { SelectOption } from 'shared/components/Form/Select/components/SelectOption';
import { SelectPlaceholder } from 'shared/components/Form/Select/components/SelectPlaceholder';
import { SelectSingleValue } from 'shared/components/Form/Select/components/SelectSingleValue';
import { SelectValueContainer } from 'shared/components/Form/Select/components/SelectValueContainer';
import styles from 'shared/components/Form/Select/Select.module.css';
import {
  TSelectContext,
  TSelectOption,
  TSelectSize,
  TSelectType,
} from 'shared/components/Form/Select/types';

type TGetClassNames = {
  size: TSelectSize;
  type: TSelectType;
  isLoading?: boolean;
  isError?: boolean;
};
export const getClassNames = <IsMulti extends boolean = false>({
  size,
  type,
  isLoading,
  isError,
}: TGetClassNames): ClassNamesConfig<TSelectOption, IsMulti> => ({
  container: (props) =>
    cn(styles.container, styles[size], styles[type], {
      [styles.hasValue]: props.hasValue,
      [styles.disabled]: props.isDisabled,
      [styles.loading]: isLoading,
      [styles.error]: isError,
    }),
  control: (props) =>
    cn(styles.control, styles[size], styles[type], {
      [styles.menuIsOpen]: props.menuIsOpen,
      [styles.hasValue]: props.hasValue,
      [styles.focus]: props.isFocused,
      [styles.error]: isError,
    }),
  valueContainer: (props) =>
    cn(styles.valueContainer, styles[size], styles[type], {
      [styles.hasValue]: props.hasValue,
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
    }),
  indicatorsContainer: () => cn(styles.indicatorsContainer, styles[size]),
  placeholder: (props) =>
    cn(styles.placeholder, styles[size], styles[type], {
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
      [styles.loading]: props.selectProps.isLoading,
    }),
  input: (props) =>
    cn(styles.input, styles[size], styles[type], {
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
      [styles.loading]: props.selectProps.isLoading,
    }),
  singleValue: (props) =>
    cn(styles.singleValue, styles[size], styles[type], {
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
      [styles.loading]: props.selectProps.isLoading,
    }),
  loadingIndicator: () => cn(styles.loader, styles[size]),
  dropdownIndicator: (props) =>
    cn(styles.dropdownIndicator, styles[size], styles[type], {
      [styles.menuIsOpen]: props.selectProps.menuIsOpen,
    }),
  clearIndicator: () => styles.clearIndicator,
  multiValue: () => styles.multiValue,
  menu: () => styles.menu,
});

export const getComponents = <IsMulti extends boolean = false>(): Partial<
  SelectComponents<TSelectOption, IsMulti, GroupBase<TSelectOption>>
> => ({
  LoadingIndicator: SelectLoadingIndicator,
  Option: SelectOption,
  DropdownIndicator: SelectDropdownIndicator,
  IndicatorSeparator: null,
  Control: SelectControl,
  Placeholder: SelectPlaceholder,
  MenuList: SelectMenuList,
  MenuPortal: SelectMenuPortal,
  ValueContainer: SelectValueContainer,
  SingleValue: SelectSingleValue,
  SelectContainer,
});

export const controlHeights: Record<TSelectSize, number> = {
  large: 38,
  medium: 34,
  small: 30,
};

export const getTheme = (size: TSelectSize, type: TSelectType): ThemeConfig => ({
  colors: {
    primary: 'var(--green-400)',
    primary75: 'var(--green-400)',
    primary50: 'var(--green-400)',
    primary25: 'var(--green-100)',
    danger: 'var(--red-100)',
    dangerLight: 'var(--red-50)',
    neutral0: 'var(--gray-50)',
    neutral5: 'var(--gray-150)',
    neutral10: 'var(--gray-150)',
    neutral20: 'var(--gray-150)',
    neutral30: 'var(--gray-250)',
    neutral40: 'var(--gray-300)',
    neutral50: 'var(--gray-400)',
    neutral60: 'var(--gray-400)',
    neutral70: 'var(--gray-450)',
    neutral80: 'var(--gray-500)',
    neutral90: 'var(--gray-450)',
  },
  borderRadius: type === 'underline' || type === 'ghost' ? 0 : 8,
  spacing: {
    baseUnit: 10,
    controlHeight: controlHeights[size],
    menuGutter: 8,
  },
});

export const SelectContext = createContext<TSelectContext>({
  size: 'medium',
});

import { useMutation } from '@tanstack/react-query';
import { clubSocialNetworkApi } from 'entities/club-social-network/api';
import { TClubSocialNetworkDTO } from 'entities/club-social-network/types';

export const useDeleteClubSocialNetworkMutation = () =>
  useMutation({
    mutationFn: clubSocialNetworkApi.deleteEntity,
  });

export const useEditClubSocialNetworkMutation = () =>
  useMutation({
    mutationFn: clubSocialNetworkApi.editEntity<TClubSocialNetworkDTO>,
  });

export const useCreateClubSocialNetworkMutation = () =>
  useMutation({
    mutationFn: clubSocialNetworkApi.createEntity<TClubSocialNetworkDTO>,
  });

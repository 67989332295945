import cn from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';

import styles from './TCell.module.css';

export type TTableCellProps = HTMLAttributes<HTMLDivElement>;

export const TCell = forwardRef<HTMLDivElement, TTableCellProps>(
  ({ children, className, ...other }, ref) => (
    <div ref={ref} {...other} className={cn(styles.cell, className)}>
      {children}
    </div>
  ),
);

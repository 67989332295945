import { EEditorElementType } from 'shared/components/Editor/types';
import { TWorkspaceParagraphElement } from 'shared/components/Workspace/types';
import { TContentLang } from 'shared/types/common';

export const createWorkspaceParagraphElement = (
  text: string,
  defaultLocale: TContentLang,
): TWorkspaceParagraphElement => ({
  type: EEditorElementType.Paragraph,
  translations: {
    [defaultLocale]: { text },
  },
});

import { TMatchPlayer } from 'entities/matchPlayer/type';
import React from 'react';
import { TEntityId } from 'shared/types/common';
import {
  TMatchPlacementAddPlayer,
  TMatchPlacementPlayer,
} from 'widgets/matchPlacement/types';
import { MatchPlacementCaptain } from 'widgets/matchPlacement/ui/MatchPlacementCaptain/MatchPlacementCaptain';
import { MatchPlacementReservation } from 'widgets/matchPlacement/ui/MatchPlacementReservation/MatchPlacementReservation';

import styles from './MatchPlacementTeamCompositionTactics.module.css';

type TProps = {
  classname?: string;
  teamName?: string;
  players: TMatchPlacementPlayer[];
  matchPlayers: TMatchPlayer[];
  onAddPlayer: (data: TMatchPlacementAddPlayer[]) => void;
  onDeletePlayer: (id: TEntityId) => void;
  onEditPlayer: (matchPlayer: TMatchPlayer) => void;
};

export const MatchPlacementTeamCompositionTactics = ({
  teamName,
  players,
  matchPlayers,
  onAddPlayer,
  onDeletePlayer,
  onEditPlayer,
}: TProps) => {
  return (
    <div className={styles.container}>
      <MatchPlacementCaptain
        matchPlayers={matchPlayers}
        players={players}
        teamName={teamName}
        onEditPlayer={onEditPlayer}
      />
      <MatchPlacementReservation
        matchPlayers={matchPlayers}
        players={players}
        onAddPlayer={onAddPlayer}
        onDeletePlayer={onDeletePlayer}
      />
    </div>
  );
};

import { TPhotoGalleryDTO } from 'entities/photoGallery/types';
import { clearMetaDataFromBase64Url } from 'shared/lib/clearMetaDataFromBase64Url';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { convertIdToNumberArray, mapIdsToNumbers } from 'shared/utils/entityIds';
import { TPhotoGalleryFields } from 'widgets/photoGalleryForm/types';

export const mapPhotoGalleryFormToDTO = (
  formData: TPhotoGalleryFields,
): TPhotoGalleryDTO => {
  return {
    availableLocales: formData.locale.created,
    translations: reduceTranslationObject(formData.locale.created, (locale) => ({
      name: formData.translations[locale]?.title ?? '',
      sharingLink: formData.sharingLink,
    })),
    relationships: {
      categories: formData.categories?.length
        ? mapIdsToNumbers(formData.categories)
        : [],
      labels: formData.labels?.length ? mapIdsToNumbers(formData.labels) : [],
      players: formData.players?.length ? mapIdsToNumbers(formData.players) : [],
      managers: formData.managers?.length ? mapIdsToNumbers(formData.managers) : [],
      tournaments: formData.tournament
        ? convertIdToNumberArray(formData.tournament)
        : [],
      seasons: formData.season ? convertIdToNumberArray(formData.season) : [],
      teams: formData.teams?.length ? mapIdsToNumbers(formData.teams) : [],
      matches: formData.match ? convertIdToNumberArray(formData.match) : [],
    },
    pictures: {
      baseImage: {
        filename: formData.preview.file?.name ?? 'base_image',
        data: clearMetaDataFromBase64Url(formData.preview.data),
      },
    },
    published: formData.published ? formData.published.toISOString() : '',
  };
};

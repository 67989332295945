import { UseQueryOptions } from '@tanstack/react-query';
import { TAdSlot } from 'entities/ad/slots/model/types';
import { TPaginatedMeta, TResponseData } from 'shared/api/types';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

import { fetchAdSlots } from './api';
import { adSlotsQueryKeys } from './consts';

export const useFetchAdSlots = (
  defaultPagination?: TPaginatedParameters,
  options: UseQueryOptions<TResponseData<TAdSlot[], TPaginatedMeta>> = {},
) => {
  return usePaginationQuery(
    (pagination) => ({
      queryKey: [adSlotsQueryKeys.adSlots, pagination],
      queryFn: () => fetchAdSlots({ params: pagination }),
      ...options,
    }),
    defaultPagination,
  );
};

import i18n from 'i18next';
import { toBoolean } from 'shared/lib/toBoolean';
import { routes } from 'shared/routes';
import { TEntityId } from 'shared/types/common';
import { TSecondLayoutNavigationLink } from 'shared/ui/layouts/secondLayout/types';

export const getMatchNavigationLinks = (
  matchId: TEntityId = '',
): TSecondLayoutNavigationLink[] => [
  {
    name: i18n.t('content.baseData'),
    route: matchId ? routes.editMatch.as(matchId) : routes.newMatch,
  },
  {
    name: i18n.t('content.match.tactic'),
    route: routes.tacticMatch.as(matchId),
    disabled: !toBoolean(matchId),
  },
];

type TCardBase = {
  onDelete?: () => void;
  onEdit?: () => void;
  onClick?: () => void;
  onPreview?: () => void;
  badge?: string;
};

export enum ECardType {
  Player = 'player',
  OpponentPlayer = 'opponentPlayer',
  Manager = 'manager',
  Team = 'team',
  Banner = 'banner',
}

type TCardTeamProps = {
  picture: string;
  name: string;
};

type TCardManagerProps = TCardTeamProps & {
  lastName: string;
  position: string;
};
type TCardOpponentPlayerProps = TCardTeamProps & {
  lastName: string;
  number: string;
};

type TCardPlayerProps = TCardManagerProps & {
  number: number;
};

type TCardPlayerInformation = {
  type: ECardType.Player;
  entity: TCardPlayerProps;
};

type TCardManagerInformation = {
  type: ECardType.Manager;
  entity: TCardManagerProps;
};

type TCardTeamInformation = {
  type: ECardType.Team;
  entity: TCardTeamProps;
};

type TCardOpponentPlayerInformation = {
  type: ECardType.OpponentPlayer;
  entity: TCardOpponentPlayerProps;
};

type TCardBannerInformation = {
  type: ECardType.Banner;
  entity: TCardTeamProps;
};

export type TCardInformation =
  | TCardPlayerInformation
  | TCardManagerInformation
  | TCardTeamInformation
  | TCardOpponentPlayerInformation
  | TCardBannerInformation;

export type TCardProps = TCardBase & TCardInformation;

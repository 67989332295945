import { useMemo } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { ActionMeta, Options } from 'react-select';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { Field } from 'shared/components/Form';
import { Select } from 'shared/components/Form/Select/Select';
import { TSelectOption, TSelectProps } from 'shared/components/Form/Select/types';
import { TCommonProps } from 'shared/components/Form/types';
import { compareIds } from 'shared/utils/entityIds';

export type TFormSelectProps<
  T extends FieldValues,
  IsMulti extends boolean = false,
> = TCommonProps<T> & {
  labelClassName?: string;
} & Partial<Pick<TSelectProps<IsMulti>, 'onChange'>> &
  Omit<TSelectProps<IsMulti>, 'value' | 'onChange'>;

export const FormSelect = <T extends FieldValues, IsMulti extends boolean = false>({
  label,
  className,
  fieldName,
  control,
  rules,
  tip,
  required,
  isDisabled,
  labelClassName,
  onChange,
  size = 'small',
  options,
  inline,
  inlineInputClassName,
  isMulti,
  ...props
}: TFormSelectProps<T, IsMulti>) => {
  const {
    field: { value: fieldValue, onChange: onChangeController },
  } = useController<T>({
    control,
    name: fieldName,
    rules,
  });

  const onChangeHandler = (
    option: OnChangeValue<TSelectOption, IsMulti>,
    actionMeta: ActionMeta<TSelectOption>,
  ) => {
    if (onChange) {
      onChange(option, actionMeta);
    }

    if (option) {
      if ('length' in option) {
        onChangeController(option.map((o) => o.value));
      } else {
        onChangeController(option.value);
      }
    }
  };

  const value = useMemo(() => {
    const mappedOptions = options as Options<TSelectOption>;

    if (isMulti) {
      return mappedOptions.filter((o) => fieldValue.includes(o.value));
    }

    return mappedOptions.find((o) => compareIds(o.value, fieldValue)) ?? null;
  }, [options, fieldValue, isMulti]);

  return (
    <Field
      className={className}
      disabled={isDisabled}
      inline={inline}
      inlineInputClassName={inlineInputClassName}
      label={label}
      labelClassName={labelClassName}
      required={required}
      tip={tip}
    >
      <Select
        {...props}
        isDisabled={isDisabled}
        isMulti={isMulti}
        name={fieldName}
        options={options}
        size={size}
        value={value}
        onChange={onChangeHandler}
      />
    </Field>
  );
};

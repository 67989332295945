import { FieldValues } from 'react-hook-form';
import { UseFormProps } from 'react-hook-form/dist/types';
import {
  TUseReactHookFormCustomProps,
  TUseReactHookFormProps,
} from 'shared/hooks/useReactHookForm/types';

const CUSTOM_PROPS_KEYS: Set<keyof TUseReactHookFormCustomProps> = new Set([
  'actionSubmit',
  'needReset',
  'needResetAfterSubmit',
  'validateOnLoad',
  'submitOnChange',
]);

export const isCustomProps = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
>(
  props:
    | TUseReactHookFormProps<TFieldValues, TContext>
    | UseFormProps<TFieldValues, TContext>,
): props is TUseReactHookFormProps<TFieldValues, TContext> => {
  if (!props || typeof props !== 'object') {
    return false;
  }

  for (const key of CUSTOM_PROPS_KEYS) {
    if (key in props) {
      return true;
    }
  }

  return false;
};

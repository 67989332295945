import { EEditorMarks, TEditorDefaultText } from 'shared/components/Editor/types';
import { createEditorParagraphElement } from 'shared/components/Editor/utils/paragraph/createEditorParagraphElement';
import { TContentLang, TPartialRecord } from 'shared/types/common';
import { BaseOperation } from 'slate';

export const createDefaultElement = (
  text: string,
  marks?: TPartialRecord<EEditorMarks, boolean>,
): TEditorDefaultText[] => [{ text, ...marks }];

export const editorEmptyParagraphElement = (defaultLocale: TContentLang) =>
  createEditorParagraphElement('', defaultLocale);

export const deleteSelectionOperations = (operations: BaseOperation[]) =>
  operations.filter((o) => {
    const isSelection = o.type === 'set_selection';

    return !isSelection;
  });

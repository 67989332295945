import { useQueryClient } from '@tanstack/react-query';
import { clubContactsQueryKeys } from 'entities/club-contacts/consts';
import { useEditClubContactMutation } from 'entities/club-contacts/mutations';
import { TClubContact } from 'entities/club-contacts/types';
import {
  TContactModalFields,
  TContactModalProps,
} from 'feature/contact-modal/types';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';

type TProps = {
  clubContact: TClubContact;
  clubId: TEntityId;
  closeActiveItemId: () => void;
};

export const useEditClubContact = ({
  clubId,
  clubContact,
  closeActiveItemId,
}: TProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    openedEditClubContactModal,
    closeEditClubContactModal,
    openEditClubContactModal,
  } = useModal('EditClubContact');
  const { mutate: editClubContactMutation, isLoading } =
    useEditClubContactMutation();

  const editClubContact = (clubContactFields: TContactModalFields) => {
    editClubContactMutation(
      {
        id: clubContact.id,
        data: {
          clubContact: {
            ...clubContactFields,
            clubId,
          },
        },
      },
      {
        onSettled: () => {
          closeEditClubContactModal();
          closeActiveItemId();
        },
        onSuccess: () =>
          queryClient.resetQueries([clubContactsQueryKeys.clubContacts]),
        onError: () =>
          notify(t('content.club.notifyNotUploadContact'), { type: 'error' }),
      },
    );
  };

  const closeModal = () => {
    closeEditClubContactModal();
    closeActiveItemId();
  };

  return {
    openEditClubContactModal,
    editModalProps: {
      values: {
        email: clubContact?.email ?? '',
        phoneNumber: clubContact?.phoneNumber ?? '',
        workSchedule: clubContact?.workSchedule ?? '',
        translations: clubContact.translations,
      },
      title: t('content.contactModal.editContact.title'),
      closeModal,
      actionSubmit: editClubContact,
      isOpened: openedEditClubContactModal,
      isLoading,
    } satisfies TContactModalProps,
  };
};

import { TFunction } from 'i18next';
import { getTextMaxLengthMessage } from 'pages/stories/edit/EditStory/components/forms/utils';
import { secureProtocol } from 'shared/constants';
import { getTextMinLengthMessage, URL_REGEXP } from 'shared/consts';
import { linkInWhitelist } from 'shared/lib/linkInWhitelist';
import { getMaxLengthTextGenerate } from 'shared/utils/getMaxLengthTextGenerate';
import { fileZodValidation } from 'shared/zod/fileZodValidation';
import {
  MAX_AD_BANNER_FORM_LINK_LENGTH,
  MAX_AD_BANNER_FORM_NAME_LENGTH,
} from 'widgets/adBannerForm/constants';
import z from 'zod';

export const adBannerFormValidationSchema = (
  t: TFunction,
  whitelistDomains: string[],
) =>
  z
    .object({
      slotId: z
        .string()
        .min(1, { message: t('shared.Form.validations.defaultRequired') }),
      name: z
        .string()
        .min(1, { message: getTextMinLengthMessage() })
        .max(MAX_AD_BANNER_FORM_NAME_LENGTH, {
          message: getMaxLengthTextGenerate(MAX_AD_BANNER_FORM_NAME_LENGTH),
        }),
      image: fileZodValidation({
        errorMessage: t('shared.validation.preview'),
      }),
      locations: z.array(z.string()).min(1),
      mainBanner: z.boolean().optional(),
      link: z
        .string()
        .min(1, { message: getTextMinLengthMessage() })
        .max(MAX_AD_BANNER_FORM_LINK_LENGTH, {
          message: getTextMaxLengthMessage(MAX_AD_BANNER_FORM_LINK_LENGTH, 'link'),
        })
        .regex(URL_REGEXP, { message: t('content.notAvailableLink') })
        .refine((val) => val.startsWith('https://'), {
          message: `${t('content.startLink')} ${secureProtocol}`,
        })
        .refine((val) => linkInWhitelist(val, whitelistDomains), {
          message: t('content.notAvailableDomain'),
        }),
    })
    .passthrough();

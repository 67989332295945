import { zodResolver } from '@hookform/resolvers/zod';
import { useLocales } from 'entities/config/lib/useLocales';
import { playerCareerModalValidationSchema } from 'feature/playerCareerModal/validationSchema';
import { UploadImage } from 'feature/rightPanel/ui/UploadImage/UploadImage';
import { useTranslation } from 'react-i18next';
import { ActionModal } from 'shared/components/ActionModal/ActionModal';
import { FormInput } from 'shared/components/Form';
import { Typography } from 'shared/components/Typography/Typography';
import { getImageSettings } from 'shared/constants';
import { getLangNameMap } from 'shared/consts';
import { useForm } from 'shared/hooks/useReactHookForm';
import { reduceTranslationObject } from 'shared/lib/reduceTranslationObject';
import { TContentLang, TTranslations } from 'shared/types/common';

import styles from './PlayerCareerModal.module.css';

const getDefaultValues = (locales: TContentLang[]) => ({
  season: '',
  championshipCount: '',
  cupsCount: '',
  eurocupsCount: '',
  gamesCount: '',
  teamPicture: undefined,
  translations: reduceTranslationObject(locales, () => ({ teamName: '' })),
});

export type TPlayerCareerModalFields = {
  season: string;
  championshipCount: string;
  cupsCount: string;
  eurocupsCount: string;
  gamesCount: string;
  teamPicture?: File;
  translations: TTranslations<{
    teamName: string;
  }>;
};

type TProps = {
  title: string;
  closeModal: () => void;
  actionSubmit: (data: TPlayerCareerModalFields) => void;
  isOpened: boolean;
  initialValues?: TPlayerCareerModalFields;
  isLoading?: boolean;
};

export function PlayerCareerModal({
  closeModal,
  actionSubmit,
  initialValues,
  isOpened,
  title,
  isLoading,
}: TProps) {
  const { t } = useTranslation();
  const { locales } = useLocales();

  const {
    control,
    formState: { isValid },
    submit,
  } = useForm<TPlayerCareerModalFields>({
    values: initialValues ?? getDefaultValues(locales),
    mode: 'onChange',
    resolver: zodResolver(playerCareerModalValidationSchema(locales)),
    actionSubmit,
    needReset: !isOpened,
  });

  return (
    <ActionModal
      opened={isOpened}
      title={title}
      width={542}
      confirmButtonProps={{
        isDisabled: !isValid,
        onClick: submit,
        isLoading,
      }}
      onClose={closeModal}
    >
      <Typography size={14} tag="h3" weight="SemiBold">
        {t('content.manager.clubName')}
      </Typography>
      <div className={styles.form}>
        {locales?.map((locale) => {
          return (
            <FormInput
              key={locale}
              control={control}
              fieldName={`translations.${locale}.teamName`}
              inputClassName={styles.inputForm}
              label={getLangNameMap()[locale]}
              maxLength={255}
              placeholder={t('content.inputPlaceholder')}
              required
              size="s"
            />
          );
        })}
      </div>
      <div className={styles.form}>
        <Typography size={14} tag="h3" weight="SemiBold">
          {t('content.manager.gameData')}
        </Typography>
        <FormInput
          control={control}
          fieldName="season"
          inputClassName={styles.inputForm}
          label={t('content.season')}
          maxLength={9}
          placeholder={t('content.seasonPeriod')}
          required
          size="s"
        />
        <FormInput
          control={control}
          fieldName="championshipCount"
          inputClassName={styles.inputForm}
          label={t('content.player.championshipGames')}
          placeholder={t('content.player.countGames')}
          required
          size="s"
          type="number"
        />
        <FormInput
          control={control}
          fieldName="cupsCount"
          inputClassName={styles.inputForm}
          label={t('content.player.cup')}
          placeholder={t('content.player.countGames')}
          required
          size="s"
          type="number"
        />
        <FormInput
          control={control}
          fieldName="eurocupsCount"
          inputClassName={styles.inputForm}
          label={t('content.player.euroCup')}
          placeholder={t('content.player.countGames')}
          required
          size="s"
          type="number"
        />
        <FormInput
          control={control}
          fieldName="gamesCount"
          inputClassName={styles.inputForm}
          label={t('content.player.countGamesInSeason')}
          placeholder={t('content.player.countGames')}
          size="s"
          type="number"
        />
        <UploadImage
          aspectRatio={getImageSettings(t).playerCareer.teamPicture.aspectRatio}
          control={control}
          fieldName="teamPicture"
          hint={getImageSettings(t).playerCareer.teamPicture.hint}
          label={t('content.player.clubLogo')}
          modalTitle={t('content.player.optionImage')}
          required
          showIcon={false}
          imageRequirementText={t('shared.imageRequirementText.warningUpload', {
            size: '1:1',
            formats: 'JPEG, JPG, PNG, WebP',
          })}
        />
      </div>
    </ActionModal>
  );
}

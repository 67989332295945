import { ColorInput } from 'shared/components/Form/ColorInput/ColorInput';
import { Typography } from 'shared/components/Typography/Typography';
import { useForm } from 'shared/hooks/useReactHookForm';

import styles from '../UIKit.module.css';

export const ColorInputExample = () => {
  const { control } = useForm({
    defaultValues: {
      color: '#ffffff',
    },
  });

  return (
    <div>
      <Typography size={24} tag="h2">
        Color Input
      </Typography>
      <div className={styles.row}>
        <ColorInput control={control} fieldName="color" placeholder="Hello" />
      </div>
    </div>
  );
};

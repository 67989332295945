import { Typography } from 'shared/components/Typography/Typography';
import { RenderElementProps } from 'slate-react';

export const EditorParagraphElement = (props: RenderElementProps) => {
  return (
    <Typography size={14} tag="p" {...props.attributes}>
      {props.children}
    </Typography>
  );
};

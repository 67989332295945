import { useLocales } from 'entities/config/lib/useLocales';
import { TVideoCategory } from 'entities/videoCategories/types';
import { TVideoCategoriesSortParams } from 'pages/settings/categories/VideoCategoriesPage/types';
import { useTranslation } from 'react-i18next';
import { ImageCell } from 'shared/components/SimpleTable/components/ImageCell/ImageCell';
import { Typography } from 'shared/components/Typography/Typography';
import { TConfigCreator } from 'widgets/elementsList/types';

import styles from './VideoCategoriesPage.module.css';

export const useVideoCategoriesColumnsConfig: TConfigCreator<
  TVideoCategory,
  TVideoCategoriesSortParams
> = () => {
  const { defaultLocale } = useLocales();
  const { t } = useTranslation();
  return [
    {
      title: t('content.title'),
      headCellProps: {
        sort: {
          sortParam: 'name',
        },
      },
      render: (item) => (
        <ImageCell
          picProps={{ src: item.picture.x1 }}
          title={item?.translations[defaultLocale]?.name}
        />
      ),
    },
    {
      title: t('content.importance'),
      headCellProps: {
        sort: {
          sortParam: 'importance',
        },
      },
      render: (item) => (
        <Typography className={styles.importance}>{item?.importance}</Typography>
      ),
    },
  ];
};

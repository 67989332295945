import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app/Providers';
import {
  opponentMatchPlayerApi,
  opponentMatchPlayerButchesApi,
} from 'entities/opponentMatchPlayer/api';
import { opponentMatchPlayerQueryKeys } from 'entities/opponentMatchPlayer/consts';
import { TMatchOpponentPlayerDTO } from 'entities/opponentMatchPlayer/type';
import { TEntityId } from 'shared/types/common';

export const useCreateOpponentMatchPlayerMutation = () => {
  return useMutation({
    mutationFn: (data: TMatchOpponentPlayerDTO[]) =>
      opponentMatchPlayerButchesApi.createEntity({ data }),
    onSuccess: () => {
      return queryClient.invalidateQueries([
        opponentMatchPlayerQueryKeys.opponentMatchPlayer,
      ]);
    },
  });
};

export const useEditOpponentMatchPlayerMutation = () => {
  return useMutation({
    mutationFn: (data: { id: TEntityId; data: TMatchOpponentPlayerDTO }) =>
      opponentMatchPlayerApi.editEntity(data),
    onSuccess: () => {
      return queryClient.invalidateQueries([
        opponentMatchPlayerQueryKeys.opponentMatchPlayer,
      ]);
    },
  });
};

export const useDeleteOpponentMatchPlayerMutation = () => {
  return useMutation({
    mutationFn: opponentMatchPlayerApi.deleteEntity,
    onSuccess: () => {
      return queryClient.invalidateQueries([
        opponentMatchPlayerQueryKeys.opponentMatchPlayer,
      ]);
    },
  });
};

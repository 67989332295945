import { useQueryClient } from '@tanstack/react-query';
import { clubContactsQueryKeys } from 'entities/club-contacts/consts';
import { useCreateClubContactMutation } from 'entities/club-contacts/mutations';
import {
  TContactModalFields,
  TContactModalProps,
} from 'feature/contact-modal/types';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';

type TProps = {
  clubId: TEntityId;
};

export const useCreateClubContact = ({ clubId }: TProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    openCreateClubContactModal,
    closeCreateClubContactModal,
    openedCreateClubContactModal,
  } = useModal('CreateClubContact');

  const { mutate: createClubContactMutation, isLoading } =
    useCreateClubContactMutation();

  const createClubContact = (contactFields: TContactModalFields) => {
    createClubContactMutation(
      {
        clubContact: {
          ...contactFields,
          clubId,
        },
      },
      {
        onSettled: closeCreateClubContactModal,
        onError: () =>
          notify(t('content.club.notifyNotCreateContact'), { type: 'error' }),
        onSuccess: () => {
          return queryClient.resetQueries([clubContactsQueryKeys.clubContacts]);
        },
      },
    );
  };

  return {
    openCreateClubContactModal,
    createContactModalProps: {
      title: t('content.contactModal.addContact.title'),
      closeModal: closeCreateClubContactModal,
      actionSubmit: createClubContact,
      isOpened: openedCreateClubContactModal,
      isLoading,
    } satisfies TContactModalProps,
  };
};

import { useLocales } from 'entities/config/lib/useLocales';
import { EMatchType, TMatch, TMatchInclude } from 'entities/matches/types';
import { MatchStatus } from 'entities/matches/ui/MatchStatus/MatchStatus';
import { TMatchesFilterParams, TMatchesSortParams } from 'entities/matches/utils';
import i18n from 'i18next';
import { MatchesSeasonFilter } from 'pages/matches/list/filters/MatchesSeasonFilter';
import { MatchesStatusFilter } from 'pages/matches/list/filters/MatchesStatusFilter';
import { MatchesTournamentFilter } from 'pages/matches/list/filters/MatchesTournamentFilter';
import { useGetMatchesTeamColumns } from 'pages/matches/list/filters/useGetMatchesTeamColumns';
import { DateTimeCell } from 'shared/components/SimpleTable/components/DateTimeCell';
import { Typography } from 'shared/components/Typography/Typography';
import { toBoolean } from 'shared/lib/toBoolean';
import { toString } from 'shared/lib/toString';
import { TColumnConfig } from 'widgets/elementsList/types';

const DEFAULT_MATCH_SCORE = '0';

export const getMatchesColumnsConfig = (): TColumnConfig<
  TMatch<TMatchInclude>,
  TMatchesSortParams,
  TMatchesFilterParams
>[] => {
  const { defaultLocale } = useLocales();

  const { homeTeamColumn, guestTeamColumn } = useGetMatchesTeamColumns();

  return [
    homeTeamColumn,
    {
      title: i18n.t('content.matches.basicСheck'),
      render: (item) => {
        const teamScore = toString(item?.teamScore) || DEFAULT_MATCH_SCORE;
        const opponentTeamScore =
          toString(item.opponentTeamScore) || DEFAULT_MATCH_SCORE;

        return (
          <Typography>
            {item.matchType === EMatchType.Home
              ? `${teamScore}:${opponentTeamScore}`
              : `${opponentTeamScore}:${teamScore}`}
          </Typography>
        );
      },
    },
    {
      title: i18n.t('content.matches.penalty'),
      render: (item) => {
        const penalty =
          item.matchType === EMatchType.Home
            ? `${item.teamPenaltyScore}:${item.opponentTeamPenaltyScore}`
            : `${item.opponentTeamPenaltyScore}:${item.teamPenaltyScore}`;
        return (
          <Typography>
            {item.teamPenaltyScore && item.opponentTeamPenaltyScore ? penalty : ''}
          </Typography>
        );
      },
    },
    guestTeamColumn,
    {
      title: i18n.t('content.matches.dateAndTime'),
      render: (item) => <DateTimeCell dateTimeString={item?.startAt ?? ''} />,
      headCellProps: {
        sort: {
          sortParam: 'start_at',
        },
      },
    },
    {
      title: i18n.t('content.tournamentLabel'),
      headCellProps: {
        filter: {
          filterName: 'tournament_id',

          getIsFiltered: (filter) =>
            toBoolean(filter.tournament_id && filter.tournament_id.length > 0),
          getFilterBadge: (filter) =>
            filter.tournament_id?.length
              ? toString(filter.tournament_id.length)
              : null,
          renderFilter: MatchesTournamentFilter,
        },
      },
      render: (item) => (
        <Typography>{item.tournament.translations[defaultLocale].name}</Typography>
      ),
    },
    {
      title: i18n.t('content.season'),
      headCellProps: {
        filter: {
          filterName: 'season_id',
          getIsFiltered: (filter) =>
            toBoolean(filter.season_id && filter.season_id.length > 0),
          getFilterBadge: (filter) =>
            filter.season_id?.length ? toString(filter.season_id.length) : null,
          renderFilter: MatchesSeasonFilter,
        },
      },
      render: (item) => (
        <Typography>{item.season.translations[defaultLocale].name}</Typography>
      ),
    },
    {
      title: i18n.t('content.matches.status'),
      headCellProps: {
        filter: {
          filterName: 'status',
          getIsFiltered: (filter) =>
            toBoolean(filter.status && filter.status.length > 0),
          getFilterBadge: (filter) =>
            filter.status?.length ? toString(filter.status.length) : null,
          renderFilter: MatchesStatusFilter,
        },
      },
      render: (item) => <MatchStatus status={item.status} />,
    },
    {
      title: 'ID',
      render: (item) => item.id,
    },
  ];
};

import { useTranslation } from 'react-i18next';
import recommendedVideoImg from 'shared/assets/images/previewVideo.jpeg';
import { Icon } from 'shared/components/Icon/Icon';
import { Typography } from 'shared/components/Typography/Typography';

import styles from './VideoFormPreview.module.css';

type TProps = {
  date: string;
};
export const VideoFormRecommendedVideoCard = ({ date }: TProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.recommendedVideo}>
      <div className={styles.recommendedVideoImageWrapper}>
        <img alt="mock recommended video preview image" src={recommendedVideoImg} />
        <Icon className={styles.iconPlay} kind="play-circle" size="l" />
        <Typography className={styles.timeStatus}>24:38</Typography>
      </div>
      <div className={styles.recommendedVideoContent}>
        <Typography className={styles.title}>
          {t('content.video.mockDescription')}
        </Typography>
        <Typography className={styles.date}>{date}</Typography>
      </div>
    </div>
  );
};

import { queryClient } from 'app/Providers';
import { adBannerQueryKeys } from 'entities/ad/banners/model/consts';
import { useDeleteAdBannerMutation } from 'entities/ad/banners/model/mutations';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/components/Notification';
import { useModal } from 'shared/hooks/useModal';
import { TEntityId } from 'shared/types/common';

export const useDeleteAdBanner = (adBannerId: TEntityId) => {
  const { t } = useTranslation();
  const { openDeleteModal, closeDeleteModal, openedDeleteModal } =
    useModal('Delete');

  const { mutate, isLoading: isDeletingBanner } = useDeleteAdBannerMutation();

  const deleteAdBanner = async () => {
    mutate(adBannerId, {
      onSuccess: () => {
        queryClient.resetQueries([adBannerQueryKeys.adBanners]);
        closeDeleteModal();
      },
      onError: () => {
        notify(t('content.adBanners.page.deleteBannerError'), { type: 'error' });
      },
    });
  };

  return {
    isDeletingBanner,
    deleteAdBanner,
    closeDeleteModal,
    openedDeleteModal,
    openDeleteModal,
  };
};

import { tacticsApiKeys } from 'entities/tactics/constants';
import { TTactic } from 'entities/tactics/types';
import { get } from 'shared/api/methods';
import { TFetchParams } from 'shared/api/types';
import { TEntityId } from 'shared/types/common';

export const fetchTactics = () =>
  get<TTactic[]>(tacticsApiKeys.getTactics).then((response) => response.data);

export const fetchTactic = (id: TEntityId, { params }: TFetchParams) =>
  get<TTactic>(tacticsApiKeys.fetchTactic(id), {
    params,
  }).then((response) => response.data.data);

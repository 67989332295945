import { labelsApi } from 'entities/labels/api';
import { labelsQueryKeys } from 'entities/labels/consts';
import { TPaginatedParameters } from 'shared/hooks/usePagination';
import { usePaginationQuery } from 'shared/hooks/usePaginationQuery';

export const useFetchLabelsQuery = (defaultPagination?: TPaginatedParameters) =>
  usePaginationQuery(
    (pagination) => ({
      queryKey: [labelsQueryKeys.labels, pagination],
      queryFn: () =>
        labelsApi.fetchEntities({
          params: { ...pagination, sort: 'name' },
        }),
      cacheTime: 0,
    }),
    defaultPagination,
  );

import { TDefaultRightPanelProps } from 'feature/rightPanel/types';
import { RightPanelCollapseWrapper } from 'feature/rightPanel/ui/RightPanelCollapseWrapper/RightPanelCollapseWrapper';
import { Control, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from 'shared/components/Form';
import { Typography } from 'shared/components/Typography/Typography';
import { secureProtocol } from 'shared/constants';
import { DEFAULT_APP_URL } from 'shared/consts';

import styles from './CommonSharingLink.module.css';

type TProps<T extends FieldValues> = {
  control: Control<T>;
  fieldName: Path<T>;
  description?: string;
  label?: string;
  title: string;
  isError: boolean;
};

export const CommonSharingLink = <T extends FieldValues>({
  control,
  fieldName,
  description,
  label,
  title,
  isError,
  ...restProps
}: TDefaultRightPanelProps & TProps<T>) => {
  const { t } = useTranslation();
  return (
    <RightPanelCollapseWrapper
      description={description ?? t('content.rightPanel.sharingLink.description')}
      isError={isError}
      label={label ?? t('content.rightPanel.sharingLink.label')}
      title={title}
      {...restProps}
    >
      <div className={styles.fieldWrapper}>
        <FormInput
          control={control}
          fieldName={fieldName}
          label={t('content.rightPanel.sharingLink.inputLabel')}
          placeholder={DEFAULT_APP_URL}
          showErrors
          size="s"
        />
        <Typography color="var(--dark-blue-250)">
          {t('content.rightPanel.sharingLink.secureProtocolDescription', {
            secureProtocol,
          })}
        </Typography>
      </div>
    </RightPanelCollapseWrapper>
  );
};
